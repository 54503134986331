import {takeEvery} from 'typed-redux-saga';
import {eventChannel} from 'redux-saga';

import {
	mediaDeviceClientOnlyActions,
	mediaDeviceClientToServerActions,
} from '@messenger/core/src/Redux/MediaDevice/Actions';
import {streamServerToClientActions, streamClientToServerActions} from '@messenger/core/src/Redux/Stream/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {chatsClientOnlyActions} from '@messenger/core/src/Redux/Chats/Actions';
import releaseMediaDevicePermissionsSaga from '@messenger/core/src/Redux/MediaDevice/Sagas/releaseMediaDevicePermissionsSaga';

import getAvailableMediaDevicesSaga from 'src/Redux/MediaDevice/Sagas/getAvailableMediaDevicesSaga';
import muteWebRTCAudioSaga from 'src/Redux/MediaDevice/Sagas/muteWebRTCAudioSaga';
import requestMediaDevicePermissionsSaga from 'src/Redux/MediaDevice/Sagas/requestMediaDevicePermissionsSaga';
import setChosenMediaDevicesSaga from 'src/Redux/MediaDevice/Sagas/setChosenMediaDevicesSaga';
import {setStreamingDeviceStatusSaga} from 'src/Redux/MediaDevice/Sagas/setStreamingDeviceStatusSaga';
import muteMediaStreamAudioSaga from 'src/Redux/MediaDevice/Sagas/muteMediaStreamAudioSaga';
import {deviceOrientationSaga} from 'src/Redux/MediaDevice/Sagas/deviceOrientationSaga';
import {sendInitialStreamOrientationSaga} from 'src/Redux/MediaDevice/Sagas/sendInitialStreamOrientationSaga';
import updateMediaDeviceConstraintsSaga from 'src/Redux/MediaDevice/Sagas/updateMediaDeviceConstraintsSaga';

function* mediaDeviceSagaWatcher() {
	const devOrientationChannel = eventChannel((emitter) => {
		ServiceFactory.uiContainer.addEventListener('orientationchange', emitter);

		return () => ServiceFactory.uiContainer.removeEventListener('orientationchange', emitter);
	});

	yield* takeEvery(streamClientToServerActions.sendPowerEstimation, releaseMediaDevicePermissionsSaga);
	yield* takeEvery(streamServerToClientActions.configUpdated, updateMediaDeviceConstraintsSaga);
	yield* takeEvery(mediaDeviceClientOnlyActions.requestMediaDevicePermissions, requestMediaDevicePermissionsSaga);
	yield* takeEvery(mediaDeviceClientOnlyActions.releaseMediaDevicePermissions, releaseMediaDevicePermissionsSaga);
	yield* takeEvery(mediaDeviceClientOnlyActions.setChosenMediaDevices, setChosenMediaDevicesSaga);
	yield* takeEvery(mediaDeviceClientOnlyActions.setIsMicDisabled, setStreamingDeviceStatusSaga);
	yield* takeEvery(mediaDeviceClientOnlyActions.requestMediaDevicesList.type, getAvailableMediaDevicesSaga);
	yield* takeEvery(mediaDeviceClientToServerActions.setMicStatus.type, muteWebRTCAudioSaga);
	yield* takeEvery(mediaDeviceClientOnlyActions.setIsMicDisabled, muteMediaStreamAudioSaga);
	yield* takeEvery(devOrientationChannel, deviceOrientationSaga);
	yield* takeEvery(chatsClientOnlyActions.upsertMany, sendInitialStreamOrientationSaga);
}

export default mediaDeviceSagaWatcher;
