import {CMDC_CONTACTNOTE} from 'cmd-control-client-lib';

import cachedViewModelFactory from '@messenger/core/src/Utils/cachedViewModelFactory';
import {ContactNoteViewModel} from '@messenger/core/src/Redux/ContactNote/ViewModel';

const getContactNoteVMCached = cachedViewModelFactory<CMDC_CONTACTNOTE['params'], ContactNoteViewModel>(
	ContactNoteViewModel,
);

export default getContactNoteVMCached;
