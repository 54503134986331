import {put} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import notificationsClientOnlyActions from '@messenger/core/src/Redux/Notifications/Actions/notificationsClientOnlyActions';
import {queryClientOnlyActions} from '@messenger/core/src/Redux/QueryUser/Actions/queryClientOnlyActions';
import EnumSnackbarNotificationKeys from '@messenger/core/src/BusinessLogic/EnumSnackbarNotificationKeys';
import {queryServerToClientActions} from '@messenger/core/src/Redux/QueryUser/Actions/queryServerToClientActions';

/**
 * @note errored actions do not hit saga's - only success cases
 */
const removeNotificationWithTimerSaga = function* ({
	payload: queryId,
}:
	| ReturnType<typeof queryServerToClientActions.queryAnswerResultReceived>
	| ReturnType<typeof queryServerToClientActions.queryAnswered>
	| ReturnType<typeof queryClientOnlyActions.abortQuery>) {
	try {
		const notificationId = `${EnumSnackbarNotificationKeys.QUERY_REQUEST}${queryId}`;

		yield* put(notificationsClientOnlyActions.closeSnackbar(notificationId));
		yield* put(queryClientOnlyActions.resetError());
		yield* put(queryClientOnlyActions.setNotLoading());
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'removeNotificationWithTimerSaga', queryId});
	}
};

export default removeNotificationWithTimerSaga;
