import {put, select} from 'typed-redux-saga';
import _ from 'lodash';
import {batchActions} from 'redux-batched-actions';

import selectGuestTypeFilter from '@messenger/core/src/Redux/Client/Selectors/GuestTypeFilter/selectGuestTypeFilter';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';
import {channelsMapClientOnlyActions} from '@messenger/core/src/Redux/ChannelsMap/Actions/channelsMapClientOnlyActions';
import {selectChannelVMsListByFilter} from '@messenger/core/src/Redux/Channels/Selectors/selectChannelVMsListByFilter';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {DEFAULT_CHANNELS_MAP_TOTAL} from '@messenger/core/src/Redux/ChannelsMap/channelsMapInitialState';
import selectRunningChatsChannelIds from '@messenger/core/src/Redux/Chats/Selectors/selectRunningChatsChannelIds';
import {selectChannelsMeta} from '@messenger/core/src/Redux/ChannelsMap/Selectors/selectChannelsMeta';
import {selectChannelsMapTotal} from '@messenger/core/src/Redux/ChannelsMap/Selectors/selectChannelsMapTotal';

export const resetChannelsMapSaga = function* () {
	try {
		const channelsMapTotal = yield* select(selectChannelsMapTotal);

		if (_.isUndefined(channelsMapTotal)) {
			return;
		}

		const guestTypeFilter = yield* select(selectGuestTypeFilter);
		const runningChatsChannelIds = yield* select(selectRunningChatsChannelIds);
		const currentChannelsMeta = {...(yield* select(selectChannelsMeta))};
		const channelsMeta = _.chain(currentChannelsMeta)
			.pick(runningChatsChannelIds)
			.mapValues((meta) => ({...meta, isFiltered: false}))
			.value();

		if (guestTypeFilter === EnumGuestType.CHAT) {
			yield* put(
				batchActions([
					channelsMapClientOnlyActions.removeAll(),
					channelsMapClientOnlyActions.setTotal({total: 0}),
					channelsMapClientOnlyActions.setChannelsMeta({channelsMeta}),
				]),
			);

			return;
		}

		const channelVMs = yield* select(selectChannelVMsListByFilter);

		const newMap = _.chain(channelVMs)
			.slice(0, DEFAULT_CHANNELS_MAP_TOTAL)
			.map(({channelId, weight}, index) => ({
				id: index,
				channelId,
				weight,
			}))
			.value();

		yield* put(
			batchActions([
				channelsMapClientOnlyActions.removeAll(),
				channelsMapClientOnlyActions.addMany(newMap),
				channelsMapClientOnlyActions.setTotal({total: newMap.length}),
				channelsMapClientOnlyActions.setChannelsMeta({channelsMeta}),
			]),
		);

		yield* put(
			channelsMapClientOnlyActions.requestChannelsMap({
				startIndex: 0,
				endIndex: Math.max(newMap.length, DEFAULT_CHANNELS_MAP_TOTAL),
			}),
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'resetChannelsMapSaga'});
	}
};
