import {createSlice, EntityState} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {cam2camPreviewAdapter} from '@messenger/core/src/Redux/Cam2CamPreview/entityAdapter';
import {cam2camPreviewServerToClientActions} from '@messenger/core/src/Redux/Cam2CamPreview/Actions/cam2camPreviewServerToClientActions';
import {cam2camPreviewClientOnlyActions} from '@messenger/core/src/Redux/Cam2CamPreview/Actions/cam2camPreviewClientOnlyActions';

import ICam2CamConnectionConfig from './Model';

const initialState: TCam2CamPreviewState = cam2camPreviewAdapter.getInitialState({});

const cam2CamPreviewSlice = createSlice({
	name: EnumStore.CAM_2_CAM_PREVIEW,
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(cam2camPreviewServerToClientActions.guestStartsCam2Cam, cam2camPreviewAdapter.addOne);
		builder.addCase(cam2camPreviewServerToClientActions.guestStopsCam2Cam, cam2camPreviewAdapter.removeOne);
		builder.addCase(cam2camPreviewClientOnlyActions.setPrimaryCam2CamId, (state, action) => {
			state.primaryCam2CamId = action.payload;
		});
		builder.addCase(cam2camPreviewClientOnlyActions.resetStore, () => initialState);
	},
});

export type TCam2CamPreviewState = EntityState<ICam2CamConnectionConfig> & {
	primaryCam2CamId?: string;
};

export default cam2CamPreviewSlice;
