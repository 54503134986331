import _ from 'lodash';
import {AnyAction} from 'redux';
import {CMDC_CMSG, SystemMessageKey} from 'cmd-control-client-lib';

import {chatsClientOnlyActions} from '@messenger/core/src/Redux/Chats/Actions';
import {chatTicketMatcher} from '@messenger/core/src/Redux/Chats/Model';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';

const someMessageMatchesByKeyAndChannelId = (messages: CMDC_CMSG[], key: string, channelId: string) =>
	_.some(
		messages,
		(messageData) => _.get(messageData, 'messageKey') === key && _.get(messageData, 'channelId') === channelId,
	);

/**
 * Ensure that we get the chat on the channel that we requested
 * AND it's private show chat, not a usual one
 */
export const createTakePatternForStartPrivateChat = (requestedForChannelId: string) => (action: AnyAction) =>
	action.type === chatsClientOnlyActions.upsertMany.type &&
	!_.isUndefined(action.payload) &&
	_.some(action.payload, (chatData) => chatTicketMatcher(chatData, requestedForChannelId));

export const createTakeForSystemMessageOnChannelWithKey =
	(requestedForChannelId: string, key: SystemMessageKey) => (action: AnyAction) =>
		action.type === messagesClientOnlyActions.setMany.type &&
		someMessageMatchesByKeyAndChannelId(action.payload, key, requestedForChannelId);
