import {TChannelGroup} from '@messenger/core/src/Redux/ChannelGroups/channelGroupsEntityAdapter';
import {parseBoolean} from '@messenger/core/src/Utils/StringUtil';

class ChannelGroupVM {
	readonly channelId: string;
	readonly channelName: string;
	readonly groupId: string;
	readonly isDeleted: boolean;

	constructor(channelGroup: TChannelGroup, userAlias: string) {
		this.channelId = channelGroup.channelId;
		this.channelName = userAlias;
		this.groupId = channelGroup.groupId;
		this.isDeleted = parseBoolean(channelGroup.isDeleted);
	}
}

export default ChannelGroupVM;
