import {createSlice} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {liveSessionChatsEntityAdapter} from '@messenger/core/src/Redux/LiveSessionChats/liveSessionChatsEntityAdapter';
import {liveSessionChatsClientOnlyActions} from '@messenger/core/src/Redux/LiveSessionChats/Actions/liveSessionChatsClientOnlyActions';

export const initialLiveSessionChatsState = liveSessionChatsEntityAdapter.getInitialState();

const liveSessionChatsSlice = createSlice({
	name: EnumStore.LIVE_SESSION_CHATS,
	initialState: initialLiveSessionChatsState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(liveSessionChatsClientOnlyActions.addMany, liveSessionChatsEntityAdapter.addMany);
		builder.addCase(liveSessionChatsClientOnlyActions.resetStore, () => initialLiveSessionChatsState);
	},
});

export default liveSessionChatsSlice;
