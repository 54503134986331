import _, {forEach} from 'lodash';
import {put, select} from 'typed-redux-saga';
import {EnumBooleanStringified, EnumCurrency, MediaFile} from 'cmd-control-client-lib';

import {messageInputClientOnlyActions} from '@messenger/core/src/Redux/MessageInput/Actions/messageInputClientOnlyActions';
import {messagesClientOnlyActions, messagesClientToServerActions} from '@messenger/core/src/Redux/Messages/Actions';
import {selectReplyMessageVM} from '@messenger/core/src/Redux/Messages/Selectors/selectReplyMessageVM';
import selectChatVmByChannelId from '@messenger/core/src/Redux/Chats/Selectors/selectChatVmByChannelId';
import AttachmentVM from '@messenger/core/src/Redux/Attachment/AttachmentVM';
import {selectAllExitedChatsWithoutVoyeurAndAdminIds} from '@messenger/core/src/Redux/Chats/Selectors/selectAllExitedChatsWithoutVoyeurAndAdminIds';
import {chatsClientOnlyActions} from '@messenger/core/src/Redux/Chats/Actions';
import {selectChatForSendingMessageIds} from '@messenger/core/src/Redux/Chats/Selectors/selectChatForSendingMessageIds';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';
import {selectCurrentMessageInputId} from '@messenger/core/src/Redux/MessageInput/Selectors/selectCurrentMessageInputId';

export const submitMessageWithoutFileUploadingSaga = function* (
	text?: string,
	channelId?: string,
	attachment?: AttachmentVM,
	groupId?: string,
	targetSystemGroupNames?: string[],
) {
	const replyMessageVM = yield* select(selectReplyMessageVM);
	const guestIdentity = yield* select(selectCurrentGuestIdentity);
	const isChargeable = !_.isUndefined(attachment?.price) && attachment?.price !== 0;

	const options: TSubmitMessageWithoutFileUploading | undefined = attachment
		? {
				...attachment.duplicateMediaVM?.media,
				...(isChargeable
					? {
							mediaPrice: attachment.price,
							currency: attachment.currency,
							isChargeable,
					  }
					: {}),
				mediaMd5: attachment.mediaMd5,
		  }
		: undefined;

	if (!_.isUndefined(replyMessageVM) && guestIdentity?.guestType === EnumGuestType.ALL) {
		let chatId = replyMessageVM.chatId;

		if (!_.isUndefined(replyMessageVM.channelId)) {
			const replyMessageChatVM = yield* select(selectChatVmByChannelId, {channelId: replyMessageVM.channelId});

			if (!_.isUndefined(replyMessageChatVM)) {
				chatId = replyMessageChatVM.chatId;
			} else {
				chatId = undefined;
			}
		}

		if (!_.isUndefined(chatId)) {
			if (_.isUndefined(text)) {
				throw new Error('Missing text for sendMessage');
			}

			yield* put(
				messagesClientToServerActions.sendMessage({chatIDs: chatId}, text, {
					relationId: replyMessageVM.messageId,
					relationText: replyMessageVM.text,
					...options,
				}),
			);
			yield* put(messageInputClientOnlyActions.removeOne(chatId));
		} else {
			const channelId = replyMessageVM.channelId;

			if (_.isUndefined(channelId)) {
				throw new Error('Missing replyMessageVM.channelId');
			}

			if (_.isUndefined(text)) {
				throw new Error('Missing text for reply');
			}

			yield* put(
				messagesClientToServerActions.sendMessage({channelId}, text, {
					relationId: replyMessageVM.messageId,
					...options,
				}),
			);
			yield* put(messageInputClientOnlyActions.removeOne(channelId));
			yield* put(messagesClientOnlyActions.notifyModelAboutSendingReply(replyMessageVM));
		}
	} else if (
		(channelId || groupId || !_.isEmpty(targetSystemGroupNames)) &&
		_.includes([EnumGuestType.CHANNEL, EnumGuestType.BULK], guestIdentity?.guestType)
	) {
		if (_.isUndefined(text)) {
			throw new Error('Missing text');
		}

		const systemGroupsObject = {};

		forEach(targetSystemGroupNames, (item) => _.set(systemGroupsObject, item, EnumBooleanStringified.TRUE));

		yield* put(
			messagesClientToServerActions.sendMessage(
				{channelId: channelId ? channelId : '', selectGroupId: groupId ? groupId : ''},
				text,
				{
					...options,
					...systemGroupsObject,
				},
			),
		);
		yield* put(messageInputClientOnlyActions.removeOne(yield* select(selectCurrentMessageInputId)));
	} else if (guestIdentity?.guestType === EnumGuestType.CHAT || guestIdentity?.guestType === EnumGuestType.ALL) {
		if (_.isUndefined(text)) {
			throw new Error('Missing text for sendMessage');
		}

		const chatIdsForSendingMessage = yield* select(selectChatForSendingMessageIds);

		yield* put(
			messagesClientToServerActions.sendMessage(
				{
					chatIDs:
						guestIdentity.guestType === EnumGuestType.ALL ? chatIdsForSendingMessage.join(',') : guestIdentity.chatId,
				},
				text,
				options,
			),
		);

		if (guestIdentity?.guestType === EnumGuestType.ALL) {
			yield* put(chatsClientOnlyActions.removeMany(yield* select(selectAllExitedChatsWithoutVoyeurAndAdminIds)));
		}

		yield* put(messageInputClientOnlyActions.removeOne(yield* select(selectCurrentMessageInputId)));
	}
};

type TChargeableMessageProps = {
	isChargeable: boolean;
	mediaPrice: number;
	currency: EnumCurrency;
};

type TSubmitMessageWithoutFileUploading =
	| (MediaFile & TChargeableMessageProps)
	| MediaFile
	| TChargeableMessageProps
	| Record<string, never>;
