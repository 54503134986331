import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux/createSelector';
import {initialState} from '@messenger/core/src/Redux/Channels/initialState';
import selectChannelsFilter from '@messenger/core/src/Redux/Channels/Selectors/selectChannelsFilter';
import IRootState from '@messenger/core/src/Redux/IRootState';
import selectGuestTypeFilter from '@messenger/core/src/Redux/Client/Selectors/GuestTypeFilter/selectGuestTypeFilter';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

const getIsPermanentUserAbilitiesFilterSelected = (
	_state: IRootState,
	props?: {isPermanentUserAbilitiesFilter?: boolean},
) => (_.isUndefined(props) ? undefined : props.isPermanentUserAbilitiesFilter);

const selectAreAllChannelsShown = createSelector(
	[selectChannelsFilter, getIsPermanentUserAbilitiesFilterSelected, selectGuestTypeFilter],
	(filter, isPermanentUserAbilitiesFilter, guestFilterType) => {
		if (guestFilterType !== EnumGuestType.ALL) {
			return false;
		}

		const fieldsToIgnoreInComparison: Array<keyof typeof filter> | keyof typeof filter = isPermanentUserAbilitiesFilter
			? [
					'skip',
					'limit',
					'filterCanReceiveImage',
					'filterCanReceiveAudio',
					'filterCanReceiveVideo',
					'filterCanReceiveTicket',
					'filterCanPurchaseMedia',
			  ]
			: ['limit', 'skip'];

		return _.isEqual(
			_.omit(filter, fieldsToIgnoreInComparison),
			_.omit(initialState.filter, fieldsToIgnoreInComparison),
		);
	},
);

export default selectAreAllChannelsShown;
