import _ from 'lodash';
import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {toyClientToServerActions} from '@messenger/core/src/Redux/Toy/Actions/ToyClientToServerActions';
import {selectSelectedToy} from '@messenger/core/src/Redux/Toy/Selectors/selectSelectedToy';
import {toyClientOnlyActions} from '@messenger/core/src/Redux/Toy/Actions/ToyClientOnlyActions';

/**
 * @param {Object} action
 * @return {Generator|void}
 */
const notifyControlOfStatusSaga = function* (action: ReturnType<typeof toyClientOnlyActions.receiveToys>) {
	try {
		const selectedToyId = yield* select(selectSelectedToy);

		if (!_.isUndefined(selectedToyId)) {
			const active =
				action.payload &&
				Object.prototype.hasOwnProperty.call(action.payload, selectedToyId) &&
				_.get(action, ['payload', selectedToyId, 'status'], 0) === 1;

			// update server on active device
			yield* put(toyClientToServerActions.sendToyState(active));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'notifyControlOfStatusSaga', action});
	}
};

export default notifyControlOfStatusSaga;
