import _ from 'lodash';
import {call, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {chatsServerToClientActions} from '@messenger/core/src/Redux/Chats/Actions';
import {updateChatSummarySaga} from '@messenger/core/src/Redux/ChatSummary/Sagas/updateChatSummarySaga';
import {selectChannelIdFromChat} from '@messenger/core/src/Redux/Chats/Selectors/selectChannelIdFromChat';
import {getChatIdForSelector} from '@messenger/core/src/Redux/Chats/Selectors/defaultSelectors';

export const processGuestStopsVoyeurSaga = function* (
	action: ReturnType<typeof chatsServerToClientActions.guestStopsVoyeur>,
) {
	try {
		if (_.isUndefined(action.payload.changes.chatID)) {
			return;
		}

		const channelId = yield* select(selectChannelIdFromChat, getChatIdForSelector(action.payload.changes.chatID));

		if (!_.isUndefined(channelId)) {
			yield* call(updateChatSummarySaga, [channelId]);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processGuestStopsVoyeurSaga'});
	}
};
