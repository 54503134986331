enum EnumChatPageTestIds {
	SELECTED = 'e2e-selected',
	GREEN_COLOR = 'e2e-color-green',
	//guests list
	CHANNEL = 'e2e-channel-list-item-container',
	CHANNEL_ITEM = 'e2e-channel-list-item',
	CHANNEL_UNSEEN_BADGE = 'e2e-channel-unseen-badge',
	GUEST_ACTIONS_BUTTON = 'e2e-guest-actions-button',
	RUNNING_CHAT = 'e2e-chat-list-item-container',
	RUNNING_CHATS_LIST_HEADER = 'e2e-running-chats-list-header',
	GUESTS_CHANNEL_HEADER = 'e2e-guests-channel-header',
	GUEST_NAME = 'e2e-guest-name',

	ADD_TO_GROUP = 'e2e-add-to-group',
	MODEL_NAME = 'e2e-model-name',
	MIC_BUTTON = 'e2e-mic-button',
	GUESTS_LIST = 'e2e-guests-list',
	GUESTS_LIST_LOADER = 'e2e-guests-list-loader',
	GUEST_AVATAR = 'e2e-guest-avatar',
	CAM_2_CAM_USER_AVATAR = 'e2e-cam-2-cam-user-avatar',
	CLOSE_MODAL_CROSS_BUTTON = '.material-design__dialog--actions-close',
	CLOSE_MEDIA_PREVIEW_BUTTON = 'e2e-close-media-preview-button',
	MEDIA_PREVIEW = 'e2e-media-preview',
	OPEN_MENU = 'e2e-open-menu',
	CLOSE_MODAL_BTN = 'e2e-close-modal-btn',
	MESSAGE_DETAILS = 'e2e-message-details',
	VIDEO_CHAT_PRICE_TITLE = 'e2e-video-chat-price-title',
	//settings text templates
	SETTINGS_SHORTCUTS = 'e2e-settings-shortcuts',
	SETTINGS_SHORTCUTS_ALERT = 'e2e-settings-shortcuts-alert',
	DELETE_MACRO = 'e2e-delete-macro',
	CREATE_NEW_MACRO = 'e2e-create-new-macro',
	CONFIRM = 'e2e-confirm',
	EDIT_MACRO = 'e2e-edit-macro',
	AUTO_SEND_MACRO_CHECKBOX = 'e2e-auto-send-macro-checkbox',
	MACRO_TEXT = 'e2e-macro-text',
	MACRO = 'e2e-macro',
	NO_CREATED_MACRO_TEXT = 'e2e-no-created-macro-text',
	NEW_SHORTCUT_TEXT = 'e2e-shortcut-text',
	MACRO_AUTOSEND_CHECKBOX = 'e2e-autosend-checkbox',
	MACRO_AUTOSEND_CHECKBOX_CHECKED = 'e2e-autosend-checkbox-checked',
	INPUT_MACRO_MENU = 'e2e-input-macro-menu',
	SEND_MACRO_FROM_MENU = 'e2e-shortcut',
	MACRO_SENDING_BTN = 'e2e-macro-sending-btn',

	START_SPEED_TEST = 'e2e-start-speed-test',
	VXLOGO_SPINNER = 'e2e-vxlogo-spinner',
	OPEN_SPEED_TEST = 'e2e-open-speed-test',
	SPEED_TEST_SUCCESS = 'e2e-speedtest-success',
	SETTINGS_SPEEDTEST_REQUESTS = 'e2e-settings-speedtest-requests',
	SETTINGS_SPEEDTEST_DATA = 'e2e-settings-speedtest-data',
	FONT_SIZE = 'e2e-fontsize',
	FONT_SIZE_TAB_TITLE = 'e2e-fontsize-tab-title',
	LOG_OUT = 'e2e-log-out',
	MESSAGE_INPUT_CONTENT = 'e2e-message-input-content',
	OPEN_INTRO = 'e2e-open-intro',
	OPEN_SETTINGS = 'e2e-open-settings',
	SETTINGS_MODAL = 'e2e-settings-modal',
	SELECTED_GUEST = 'e2e-selected-guest',
	ENABLED_SEND_MESSAGE_BUTTON = 'e2e-enabled-send-message-btn',
	DISABLED_SEND_MESSAGE_BUTTON = 'e2e-disabled-send-message-btn',

	GO_ONLINE = 'e2e-go-online',
	GO_OFFLINE = 'e2e-go-offline',
	MUTE_MIC = '.responsive-controls-bar>span:nth-child(1)',
	SEARCH_CHANNELS_BUTTON = 'e2e-search-channels-button',
	SEARCH_FOR_USERS = 'e2e-channel-filter-search',
	CHANNEL_HEADER_USERNAME = 'e2e-channel-header-username',

	//start broadcasting modal
	START_BROADCASTING_MODAL = 'e2e-start-broadcasting-modal',
	START_BROADCASTING_BUTTON = 'e2e-start-broadcasting-button',
	START_BROADCASTING_ACCESS_ALERT = 'e2e-start-broadcasting-access-alert',
	START_BROADCASTING_ERROR = 'e2e-start-broadcasting-error-in',

	FILTERS_ONLY_CHAT = 'e2e-filter-only:chat',
	FILTERS_ONLY_MSN = 'e2e-filter-only:msn',
	FILTERS_ONLY_ARCHIVE = 'e2e-filter-only:archive',

	MENU_PROFILE = 'e2e-menu-profile',
	MENU_HELP = 'e2e-menu-help',
	MENU_RESET_PASSWORD = 'e2e-reset-password',
	CHANNELS_BULK_MENU = 'div.MuiListItemSecondaryAction-root.channel-list__item__actions',
	BULK_MESSAGES = 'e2e-bulk-messages',
	LINK_TO_SUPPORT = 'a.support-link',
	PHONE_ICON = 'e2e-phone-',
	PHONE_ICON_ACTIVE = 'e2e-phone-active',
	PHONE_ICON_INACTIVE = 'e2e-phone-inactive',
	CHANNEL_FILTER_RESULTS = 'e2e-channel-filter-result',
	LOADING_BAR = `e2e-loading-bar`,
	MODELS_MOTTO = 'e2e-models-motto',
	CHANGE_LANG = 'e2e-change-lang-',
	NOT_VERIFIED_PHONE = 'h3.MuiTypography-root.phone-service__notverified__title',
	//user info
	USER_INFO = 'e2e-user-info',
	VIEW_USER_INFO_BUTTON = 'e2e-view-user-info-button',
	USER_INFO_FIRST_NAME = 'e2e-user-info-first-name',
	USER_INFO_LAST_NAME = 'e2e-user-info-last-name',
	USER_INFO_AGE = 'e2e-user-info-age',
	USER_INFO_CITY = 'e2e-user-info-city',
	USER_INFO_NOTES = 'e2e-user-info-notes',
	USER_INFO_SAVE_PROGRESS = 'e2e-user-info-save-progress',
	MESSAGE_AREA_USER_NOTES = 'e2e-message-area-user-notes',
	NOTES_ICON = 'e2e-icon-notes',
	CHANNEL_MENU_ICON = 'e2e-icon-more-vert',

	BUTTON_MARKS_USER_AS_REGULAR = 'e2e-button-marks-user-as-regular',
	BUTTON_MARKS_USER_AS_NOT_REGULAR = 'e2e-button-marks-user-as-not-regular',
	SNACKBAR_UNDO_BUTTON = 'e2e-snackbar-undo-button',

	SNACKBAR_ACTION_BUTTON = 'e2e-snackbar-action-button',

	FAVORITE_ICON = `e2e-icon-favorite`,
	FAVORITE_ICON_BORDER = `e2e-icon-favorite-border`,
	REGULAR_CUSTOMER_TOGGLE = 'e2e-regular-customer-toggle',
	CLOSE_USER_INFO = 'e2e-close-user-info',
	USER_INFO_PREFERENCES = 'e2e-user-info-preferences',
	USER_INFO_ABOUT_CHATS = 'e2e-user-info-about-chats',
	USER_PREFERENCE = 'e2e-user-preference',
	USER_PREFERENCE_BAR = 'e2e-user-preference-bar',
	USER_INFO_CAM_2_CAM_PREVIEW = 'user-info-cam-2-cam-preview',
	ALL_MESSAGES_CAM_2_CAM_LIST = 'e2e-all-messages-cam-2-cam-list',

	NOTIFICATION_YOU_HAVE_LOGOUT_DE = 'div[role="alert"]:has-text("Du hast dich abgemeldet")',
	USER_MENU_AREA = 'e2e-use-menu-area',
	OFFLINE_POSTER = 'div.stream-preview__offlineposter',
	MESSAGES_CONTAINER = 'e2e-messages-container',
	MESSAGE_CONTENT = 'e2e-message-content',
	MESSAGE_CONTENT_FULL = 'e2e-message-content-full',
	MESSAGE_HEADER_WITH_PRICE = 'e2e-message-header-with-price',
	MESSAGE_HEADER_PRICE = 'e2e-message-header-price',
	MESSAGE_HEADER_PURCHAISED_MEDIA_LABEL = 'e2e-message-header-purchaised-media-label',

	MESSAGE_MEDIA_TYPE = `e2e-message-media-type-`,
	MESSAGE_MEDIA_TYPE_UNDEFINED = `e2e-message-media-type-undefined`,
	MESSAGE_MEDIAA_TYPE_BITMAP = `e2e-message-media-type-Bitmap`,
	MESSAGE_MEDIAA_TYPE_VIDEO = `e2e-message-media-type-Video`,
	MESSAGE_MEDIAA_TYPE_AUDIO = `e2e-message-media-type-Audio`,

	CHATS_ALL = 'e2e-chats-all',
	REPLY_BUTTON = 'e2e-reply-button',
	REPLY_BUTTON_DESKTOP = 'e2e-reply-button-desktop',
	REPLY_MESSAGE_PREVIEW = 'e2e-reply-message-preview',
	REPLY_MESSAGE_PREVIEW_CLOSE_BTN = 'e2e-reply-message-preview-close-btn',
	MESSAGE_AREA_REPLY_ADDITIONAL_VIEW = 'e2e-reply-message-additional-view',
	ATTACH_BUTTON = 'e2e-attach-button',
	CATALOG_SORT_BUTTON = 'e2e-catalog-sort-button',
	GIFT = 'e2e-message-gift',
	MESSAGE = 'e2e-message',
	MESSAGE_FROM_GUEST = 'e2e-message-from-guest_',
	SYSTEM_MESSAGE = 'e2e-system-message',
	MESSAGE_TO_GUEST = 'e2e-message-to-guest_',

	SPAM_GUEST_OPTION = 'e2e-spam-guest-option',
	UNSPAM_GUEST_OPTION = 'e2e-unspam-guest-option',
	PRICE_UNDER_MESSAGE = 'e2e-price-under-message',
	PRIVATE_SHOWS_LIST = 'e2e-private-shows-list',
	PRIVATE_SHOW_ERROR = 'e2e-private-show-error',
	PRIVATE_SHOW_LOADING = 'e2e-private-show-loading',
	PRIVATE_SHOW_START = 'e2e-private-show-start',
	PRIVATE_SHOW_STOP = 'e2e-private-show-stop',
	PRIVATE_SHOW_LIST_ITEM = 'e2e-private-show-item',
	PRIVATE_SHOW_DESCRIPTION_CONTAINER = 'e2e-private-show-desc-container',
	//settings modal
	SETTINGS_FEEDBACK_TAB = 'e2e-settings-feedback-tab',
	FEEDBACK_TITLE = 'e2e-feedback-tab-title',
	FEEDBACK_DESCRIPTION = 'e2e-feedback-description',
	FEEDBACK_LOGIN = 'e2e-feedback-login',
	FEEDBACK_LOGIN_INPUT = 'e2e-feedback-login-input',
	FEEDBACK_MODEL_NAME = 'e2e-feedback-model-name',
	FEEDBACK_MODEL_NAME_INPUT = 'e2e-feedback-model-name-input',
	FEEDBACK_PHONE_NUMBER = 'e2e-feedback-phone-number',
	FEEDBACK_PHONE_NUMBER_INPUT = 'e2e-feedback-phone-number-input',
	FEEDBACK_EMAIL = 'e2e-feedback-email',
	FEEDBACK_EMAIL_INPUT = 'e2e-feedback-email-input',
	FEEDBACK_SHORT_DESCRIPTION = 'e2e-feedback-short-description',
	FEEDBACK_SHORT_DESCRIPTION_INPUT = 'e2e-feedback-short-description-input',
	FEEDBACK_LONG_DESCRIPTION = 'e2e-feedback-long-description',
	FEEDBACK_LONG_DESCRIPTION_INPUT = 'e2e-feedback-long-description-input',
	SEND_FEEDBACK_BUTTON = 'e2e-send-feedback-button',
	VIDEO_CHAT_PRICE_DESCRIPTION = 'e2e-video-chat-price-description',
	MESSENGER_PRICE = 'e2e-messenger-price',
	PRIVATE_CHAT_PRICE = 'e2e-private-chat-price',
	VIDEO_CHAT_PRICE = 'e2e-video-chat-price',
	PRICE_INPUT = 'e2e-price-input',
	PRICE_SLIDER = 'e2e-price-slider',
	SETTINGS_VIDEO_CHAT_SAVE_BTN = 'e2e-settings-video-chat-save-btn',
	VIDEO_CHAT_PRICES_SAVE_PROGRESS = 'e2e-video-chat-prices-save-progress',
	TOY_ACTIVATION_HEADER = 'e2e-toy-activation-header',
	TOY_ACTIVATION_REQUEST_RESULT = 'e2e-toy-activation-request-result',
	SETTINGS_TOY_ACTIVATION_REQUEST_BTN = 'e2e-settings-toy-activation-request-btn',
	SETTINGS_DEVICE_TOYS_TAB = 'e2e-settings-device-toys-tab',
	TOY_STATUS_LIST_HEADER = 'e2e-toy-status-tab-list-header',
	TOY_STATUS_LIST_PROFILE = 'e2e-toy-status-list-profile',
	TOY_STATUS_LIST_CONTROL = 'e2e-toy-status-list-control',
	TOY_STATUS_LIST_SELECTED_TOY = 'e2e-toy-status-list-selected-toy',
	TOY_INTENSITY_TITLE = 'e2e-toy-intensity-title',
	TOY_INTENSITY_SLIDER = 'e2e-toy-control-slider',
	TOY_TEST_VIBRATE_BTN = 'e2e-toy-test-vibrate-btn',
	SELECTED_TOY_TITLE = 'e2e-selected-toy-title',
	SELECTED_TOY_INPUT = 'e2e-selected-toy-input',
	TOY_SETTINGS_SAVE_BTN = 'e2e-toy-settings-save-btn',
	SETTING_EXTERNAL_DEVICE = 'e2e-settings-external-device',
	SETTINGS_EXTERNAL_DEVICE_SAVE_BTN = 'e2e-settings-external-device-save-btn',
	SETTINGS_EXTERNAL_DEVICE_SELECTED_DEVICE = 'e2e-settings-device-external-selected-device',
	SETTINGS_EXTERNAL_DEVICE_LIST_ITEM = 'e2e-external-device-select-menu-item',
	START_STREAM_CONFIG_WEWBCAM_SETUP_BTN = 'e2e-start-stream-config-actions-webcam-setup-btn',
	SETTINGS_FONT_SIZE = 'e2e-settings-font-size',
	SETTINGS_VIDEO_CHAT_TAB = 'e2e-settings-video-chat-tab',
	SETTINGS_TICKET_LIVE_SHOW_TAB = 'e2e-settings-ticket-live-show-tab',
	SETTINGS_GROUPS_TAB = 'e2e-settings-groups-tab',

	//Settings/Webcam times tab
	SETTINGS_HEATMAP_TAB = 'e2e-settings-heatmap-tab',
	SETTINGS_HEATMAP_SAVE_BTN = 'e2e-settings-heatmap-save-btn',
	SETTINGS_HEATMAP_TAB_TEXT_INPUT = 'e2e-heatmap-text-input',
	HEATMAP_TAB_TITLE = 'e2e-heatmap-tab-title',
	WEBCAM_TIMES_SWITCHER = 'e2e-webcam-times-switcher',
	WEBCAM_TIMES_NOTES_IN_DE = 'e2e-webcam-times-notes-in-de',
	WEBCAM_TIMES_NOTES_IN_EN = 'e2e-webcam-times-notes-in-en',
	BUTTON_PROGRESS_BAR = 'e2e-button-progress-bar',

	NOTIFICATION_ABOUT_SOUND = 'e2e-notification-about-sound',
	NOTIFICATION_ABOUT_SOUND_BUTTON_YES = 'e2e-notification-about-sound-button-yes',
	NOTIFICATION_ABOUT_SOUND_BUTTON_NO = 'e2e-notification-about-sound-button-no',
	NOTIFICATION_BAR = 'notification-bar',
	NOTIFICATION_BAR_BTN_0 = 'notification-bar-btn0',
	NOTIFICATION_BAR_BTN_1 = 'notification-bar-btn1',
	CONTROL_BAR_MIC_BUTTON = 'e2e-control-bar-mic-button',
	CONTROL_BAR_SOUND_OPTION = 'e2e-control-bar-sound-option',
	CONTROL_BAR_SOUND_OPTION_ACTIVE = 'e2e-control-bar-sound-option-active',
	CONTROL_BAR_AVS_OPTION = 'e2e-control-bar-avs-option',
	START_STREAM_MODAL = 'e2e-start-stream-modal',
	FEATURE_MODAL = 'e2e-feature-modal',
	LOADING_SPINNER = 'e2e-spinner',
	LOADING_SPINNER_ON_STREAM_PREVIEW = 'e2e-spinner-on-stream-preview',
	FEEDBACK_BUTTON = 'e2e-feedback-button',
	FEEDBACK_BUTTON_TEXT = 'e2e-feedback-button-text',
	STREAM_LOADING_MODAL = 'e2e-stream-loading-modal',
	CHAT_VIEWERS_LIST_LOADING_PROGRESS_BAR = 'e2e-chat-viewers-list-loading-progress-bar',
	OPEN_EMOJI_BUTTON = 'e2e-emoji-button',
	EMOJI_ITEM = 'e2e-emoji-item',
	EMOJI_CONTAINER = 'e2e-emoji-container',
	SMILE_EMOJI_UNIFIED = '1f63c',
	ALL_CHANNELS_FILTER_BUTTON = 'e2e-all-channels-filter-button',
	ICON = 'e2e-icon',
	SNACK_BAR_ITEM_NOTIFICATION = 'e2e-snack-bar-item-notification',
	SNACKBAR_TEXT = 'e2e-snack-bar-text',
	ERROR_SNACKBAR_SEVERITY = 'error',
	LOGIN_FORM = 'e2e-login-page',
	//user actions menu
	ACTIONS_MENU_MODAL = 'e2e-actions-menu-modal',
	ACTION_MENU_GUEST_NAME = 'e2e-actions-menu-guest-name',
	ACTION_MENU_CLOSE_BUTTON = 'e2e-actions-menu-close-button',
	KICK_GUEST = 'e2e-kick-guest',
	CONFIRM_KICK_GUEST = 'e2e-confirm-kick-guest',
	SET_DISCOUNT_BUTTON = 'e2e-set-discount-button',
	CHOOSE_DISCOUNT_VALUE = 'e2e-choose-discount-value',
	CHOOSE_DURATION_VALUE = 'e2e-choose-duration-value',
	CONFIRM_DISCOUNT = 'e2e-confirm-discount',
	MESSAGE_ABOUT_GIVEN_DISCOUNT = 'e2e-message-about-given-discount',
	CONFIRM_SPAM_GUEST = 'e2e-confirm-spam-guest',
	MUTE_GUEST_BUTTON = 'e2e-mute-guest-button',
	UNMUTE_GUEST_BUTTON = 'e2e-unmute-guest-button',
	PIN_GUEST_BUTTON = 'e2e-pin-guest-button',
	UNPIN_GUEST_BUTTON = 'e2e-unpin-guest-button',
	BAN_BADGE = 'e2e-ban-badge',
	CONFIRM_BAN_GUEST = 'e2e-confirm-ban-guest',
	BAN_AND_SPAM_GUEST = 'e2e-ban-and-spam-guest',
	ACTIONS_MENU_BAN_CHANNEL_OPTION = 'e2e-actions-menu-ban-channel-option',
	ACTIONS_MENU_UNBAN_CHANNEL_OPTION = 'e2e-actions-menu-unban-channel-option',
	ACTIONS_MENU_CHAT_REPORT = 'e2e-actions-menu-chat-report',

	// media preview
	MEDIA_CONTENT_PREVIEW = 'e2e-media-content-preview',
	OPENED_IMAGE = 'e2e-opened-image',
	VIDEO = 'e2e-video',
	MEDIA_CONTENT_PREVIEW_CLOSE_BUTTON = 'e2e-media-content-preview-close-button',
	CONTENT_SELL_FORM = 'e2e-content-sell-form',
	CUSTOM_PRICE_INPUT = 'e2e-custom-price-input',
	LABEL_ABOUT_PRICES = 'e2e-label-about-prices',
	SELL_PRICE = 'e2e-sell-price',
	FULL_SCREEN_VIEW = 'e2e-full-screen-view',
	SYSTEM_MESSAGE_OFFLINE = 'e2e-system-message-h_session_offline',
	SYSTEM_MESSAGE_ONLINE = 'e2e-system-message-h_session_online',
	IMAGE_PREVIEW = 'e2e-image-preview',
	VIDEO_PREVIEW = 'e2e-video-preview',
	MEDIA_SPINNER = 'e2e-media-spinner',
	MEDIA_PLACEHOLDER = 'e2e-media-placeholder',
	MESSAGE_MEDIA = 'e2e-message-media',
	//channel
	USER_ABILITIES_CAN_PURCHASE_ICON = 'e2e-user-abilities-can-purchase-icon',
	//snackbar about purchaised content
	PURCHAISED_MEDIA_SNACKBAR = 'e2e-purchaised-media-snackbar',
	PURCHAISED_MEDIA_SNACKBAR_CLOSE_BTN = 'e2e-purchaised-media-snackbar-close-btn',
	PURCHAISED_MEDIA_SNACKBAR_SHOW_BTN = 'e2e-purchaised-media-snackbar-show-btn',
	// private chat
	PRIVATE_CHAT_REQUEST_NOTIFICATION = 'notification-bar-single',
	PRIVATE_CHAT_VOYEUR_ALLOWED_TOGGLE = 'e2e-private-chat-voyeur-allowed-toggle',
	PRIVATE_CHAT_VOYEUR_DISALLOWED_TOGGLE = 'e2e-private-chat-voyeur-disallowed-toggle',
	STREAM_OPTIONS_BUTTON = 'e2e-stream-options-button',
	STREAM_OPTIONS_MENU = 'e2e-stream-options-menu',
	MESSAGE_AREA_INPUT_WARNING = 'e2e-message-area-input-warning',
	//audio draft/audio recording
	AUDIO_DRAFT_IN_INPUT = 'e2e-audio-draft-in-input',
	AUDIO_RECORDING_SELL_BUTTON = 'e2e-audio-recording-sell-button',
	AUDIO_RECORDING_DELETE_BUTTON = 'e2e-audio-recording-delete-button',
	AUDIO_RECORDING_STOP_BUTTON = 'e2e-audio-recording-stop-button',
	PLAY_AUDIO_BUTTON = 'e2e-play-audio-button',
	AUDIO_RECORDING_INDICATOR = 'e2e-audio-recording-indicator',
	AUDIO_RECORDING_DURATION = 'e2e-audio-recording-duration',
	RECORDED_AUDIO_DURATION = 'e2e-recorded-audio-duration',
	SAVING_CHANGES_MODAL = 'e2e-saving-changes-dialog',
	SAVING_CHANGES_MODAL_CANCEL_BTN = 'e2e-saving-changes-dialog-cancel-btn',
	SAVING_CHANGES_MODAL_LEAVE_BTN = 'e2e-saving-changes-dialog-leave-btn',
	SHORTCUT_DURING_AUDIO_RECORD_MODAL = 'e2e-shortcut-during-audio-record-modal',
	SHORTCUT_DURING_AUDIO_RECORD_MODAL_STOP_BTN = 'e2e-shortcut-during-audio-record-modal-stop-btn',
	SHORTCUT_DURING_AUDIO_RECORD_MODAL_CANCEL_BTN = 'e2e-shortcut-during-audio-record-modal-cansel-btn',
	AUDIO_PAUSE_BUTTON = 'e2e-icon-pause',
	//channels-filters
	EMPTY_CHANNELS_LIST_ITEM = 'e2e-empty-channels-list-item',
	CURRENT_CHATS_FILTER_BUTTON = 'e2e-current-chats-filter-button',
	ONLY_MESSENGER_FILTER_BUTTON = 'e2e-only-messenger-filter-button',
	SPAM_FILTER_BUTTON = 'e2e-spam-filter-button',
	OPEN_FILTER_BUTTON = 'e2e-channels-filter-more-controls',
	OPEN_FILTER_BUTTON_WITH_BADGE = 'e2e-open-filter-button-with-badge',
	CUSTOM_FILTERS_MODAL = 'e2e-custom-filters-modal',
	FILTERS_APPLY = 'e2e-filter-apply',
	CUSTOM_FILTERS_MODAL_RESET_BUTTON = 'e2e-custom-filters-modal-reset-button',
	REGULAR_USER_FILTER = 'e2e-regulars-filter',
	UNREAD_CONVERSATIONS_FILTER = 'e2e-unread-filter',
	UNANSWERED_CONVERSATIONS_FILTER = 'e2e-unsanswered-filter',
	ARCHIVED_USERS_FILTER = 'e2e-archived-filter',
	BANNED_USERS_FILTER = 'e2e-banned-filter',
	ADVERTISED_USERS_FILTER = 'e2e-avertised-filter',
	ALL_PRODUCTS_FILTER = 'e2e-products-all-filter',
	VX_PRODUCTS_FILTER = 'e2e-products-vx-filter',
	PAGES_PRODUCTS_FILTER = 'e2e-products-pages-filter',
	PARTNER_PRODUCTS_FILTER = 'e2e-products-partner-filter',

	INFO_ICON = 'e2e-icon-info',
	SPAM_ICON = 'e2e-icon-archive',
	PLUS_IN_CIRCLE_ICON = 'e2e-icon-add-circle-outline',
	SENT_STATUS_ICON = 'e2e-icon-done-all',
	ADVERTISED_ICON = 'e2e-icon-star',

	//Chat report modal
	CHAT_REPORT_MODAL = 'e2e-chat-report-modal',
	CHAT_REPORT_MODAL_TITLE = 'e2e-chat-report-modal-title',
	CHAT_REPORT_MODAL_HACKED_BUTTON = 'e2e-chat-report-modal-hacked-button',
	CHAT_REPORT_MODAL_HARASSMENT_AND_THREATS_BUTTON = 'e2e-chat-report-modal-harassment-and-threats-button',
	CHAT_REPORT_MODAL_OTHER_BUTTON = 'e2e-chat-report-modal-other-button',
	CHAT_REPORT_MODAL_PRETENDING_TO_BE_SOMEONE_BUTTON = 'e2e-chat-report-modal-pretending-to-be-someone-button',
	CHAT_REPORT_MODAL_TECHNICAL_ISSUE_BUTTON = 'e2e-chat-report-modal-technical-issue-button',
	CHAT_REPORT_MODAL_UNAUTHORIZED_CONTENT_BUTTON = 'e2e-chat-report-modal-unauthorized-content-button',
	CHAT_REPORT_MODAL_INPUT_AREA = 'e2e-chat-report-modal-input-area',
	CHAT_REPORT_MODAL_SEND_BUTTON = 'e2e-chat-report-modal-send-button',

	//go offline modal
	GO_OFFLINE_MODAL = 'e2e-go-offline-modal',
	GO_OFFLINE_MODAL_CONFIRM_BUTTON = 'e2e-go-offline-modal-confirm-button',
	GO_OFFLINE_MODAL_CANCEL_BUTTON = 'e2e-go-offline-modal-cancel-button',

	//Channel icons
	CHANNEL_ICON_AVS = 'e2e-icon-avs-18',
	CHANNEL_ICON_NO_AVS = 'e2e-icon-no-avs-18',
}
export const getChangeLanguageTestId = (language: string) => EnumChatPageTestIds.CHANGE_LANG + language;

export const CHAT_REPORT_KEYS_2_ID_MAP: {[index: string]: EnumChatPageTestIds} = {
	hacked: EnumChatPageTestIds.CHAT_REPORT_MODAL_HACKED_BUTTON,
	'harassment-threats': EnumChatPageTestIds.CHAT_REPORT_MODAL_HARASSMENT_AND_THREATS_BUTTON,
	other: EnumChatPageTestIds.CHAT_REPORT_MODAL_OTHER_BUTTON,
	pretending: EnumChatPageTestIds.CHAT_REPORT_MODAL_PRETENDING_TO_BE_SOMEONE_BUTTON,
	tech: EnumChatPageTestIds.CHAT_REPORT_MODAL_TECHNICAL_ISSUE_BUTTON,
	'unauthorized-content': EnumChatPageTestIds.CHAT_REPORT_MODAL_UNAUTHORIZED_CONTENT_BUTTON,
};

export default EnumChatPageTestIds;
