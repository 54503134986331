import {PayloadAction} from '@reduxjs/toolkit';
import {call} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumLocalStorageKeys} from '@messenger/core/src/BusinessLogic/EnumLocalStorageKeys';
import EnumStoreType from '@messenger/core/src/BusinessLogic/EnumStoreType';

/**
 * @param {Object} action
 * @return {Generator|void}
 */
const saveMaxIntensitySaga = function* (action: PayloadAction<number>) {
	try {
		const store = ServiceFactory.store;

		yield* call([store, store.set], EnumLocalStorageKeys.VIBRATE_MAX_INTENSITY, action.payload, {
			storeType: EnumStoreType.LOCAL,
		});
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'saveMaxIntensitySaga', action});
	}
};

export default saveMaxIntensitySaga;
