import _ from 'lodash';
import {createSlice, EntityState} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {mediaEntityAdapter, TAnyMedia} from '@messenger/core/src/Redux/Media/entityAdapter';
import {mediaClientToServerActions} from '@messenger/core/src/Redux/Media/Actions/mediaClientToServerActions';
import {mediaServerToClientActions} from '@messenger/core/src/Redux/Media/Actions/mediaServerToClientActions';
import {mediaClientOnlyActions} from '@messenger/core/src/Redux/Media/Actions/mediaClientOnlyActions';

export const initialMediaState: TMediaSlice = mediaEntityAdapter.getInitialState({
	uploadUrl: '',
	requestedTicketsFor: [],
	contentCatalogIds: [],
	contentCatalogTotal: null,
	contentCatalogFilter: {},
});

const mediaSlice = createSlice({
	name: EnumStore.MEDIA,
	initialState: initialMediaState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(mediaClientOnlyActions.setMediaUploadUrl, (state, action) => {
			state.uploadUrl = action.payload;
		});
		builder.addCase(mediaClientToServerActions.requestTickets, (state, action) => {
			if (_.isUndefined(action.payload.channelId)) {
				return;
			}

			state.requestedTicketsFor = _.uniq([...state.requestedTicketsFor, ..._.split(action.payload.channelId, ',')]);
		});
		builder.addCase(mediaServerToClientActions.ticketsReceived, (state, action) => {
			state.requestedTicketsFor = _.difference(state.requestedTicketsFor, _.split(action.meta.params.channelId, ','));
		});

		builder.addCase(mediaClientOnlyActions.upsertMany, (state, action) => {
			mediaEntityAdapter.upsertMany(state, action);
		});
		builder.addCase(mediaClientOnlyActions.resetStore, () => initialMediaState);
		builder.addCase(mediaClientOnlyActions.resetContentCatalog, (state, action) => {
			state.contentCatalogIds = [];
			state.contentCatalogTotal = null;
		});
		builder.addCase(mediaClientOnlyActions.setContentCatalogIds, (state, action) => {
			state.contentCatalogIds = action.payload;
		});
		builder.addCase(mediaClientOnlyActions.setContentCatalogTotal, (state, action) => {
			state.contentCatalogTotal = action.payload;
		});
	},
});

export type TMediaSlice = EntityState<TAnyMedia> & {
	uploadUrl: string;
	requestedTicketsFor: string[];
	contentCatalogIds: string[];
	contentCatalogTotal: number | null;
};

export default mediaSlice;
