import _ from 'lodash';
import {call, put, race, select, take} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import {attachmentClientOnlyActions} from '@messenger/core/src/Redux/Attachment/Actions/attachmentClientOnlyActions';
import {selectTargetChannelIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetChannelIds';
import {selectTargetGroupIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetGroupIds';
import selectIsAudioRecording from '@messenger/core/src/Redux/Attachment/Selectors/selectIsAudioRecording';
import {selectAreSomeChannelMediaForTargetChannelIdsChecking} from '@messenger/core/src/Redux/ChannelMedia/Selectors/selectAreSomeChannelMediaForTargetChannelIdsChecking';
import {selectChannelGroupsPurchaseAbility} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/Selectors/selectChannelGroupsPurchaseAbility';
import {selectBulkMessagesReceiveCount} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/Selectors/selectBulkMessagesReceiveCount';
import {EnumCanPurchase} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/slice';
import {channelGroupsPurchaseAbilityClientOnlyActions} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/Actions/channelGroupsPurchaseAbilityClientOnlyActions';
import {selectTargetSystemGroupIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetSystemGroupIds';
import {selectCurrentAttachmentVm} from '@messenger/core/src/Redux/Attachment/Selectors/selectCurrentAttachmentVm';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';
import {messageInputClientOnlyActions} from '@messenger/core/src/Redux/MessageInput/Actions/messageInputClientOnlyActions';
import {BULK} from '@messenger/core/src/BusinessLogic/Constants';

import {submitMessageWithoutFileUploadingSaga} from './submitMessageWithoutFileUploadingSaga';
import {publishTicketToPrivateShowSaga} from './publishTicketToPrivateShowSaga';

const submitMessageSaga = function* ({
	payload: {message: messageText, channelId: sendToChannelId},
}: ReturnType<typeof messagesClientOnlyActions.sendMessage>) {
	try {
		const guestIdentity = yield* select(selectCurrentGuestIdentity);

		if (yield* select(selectIsAudioRecording)) {
			const {errorRecording} = yield* race({
				successAttachment: take(attachmentClientOnlyActions.attach.type),
				errorRecording: take(attachmentClientOnlyActions.audioRecordingFailed.type),
			});

			if (errorRecording) {
				return;
			}

			if (guestIdentity?.guestType === EnumGuestType.BULK) {
				yield* take(channelGroupsPurchaseAbilityClientOnlyActions.change);
			}
		}

		if (_.includes([EnumGuestType.ALL, EnumGuestType.CHAT], guestIdentity?.guestType) && !sendToChannelId) {
			yield* call(submitMessageWithoutFileUploadingSaga, messageText);

			return;
		}

		let targetChannelIds: string[];

		if (sendToChannelId) {
			targetChannelIds = [sendToChannelId];
		} else {
			targetChannelIds = yield* select(selectTargetChannelIds);
		}

		const targetGroupIds = yield* select(selectTargetGroupIds);
		const targetSystemGroupIds = yield* select(selectTargetSystemGroupIds);

		if (_.isEmpty(targetChannelIds) && _.isEmpty(targetGroupIds) && _.isEmpty(targetSystemGroupIds)) {
			return;
		}

		const isAttachmentChecking = yield* select(selectAreSomeChannelMediaForTargetChannelIdsChecking, {
			targetChannelIds,
		});

		if (isAttachmentChecking && _.isEmpty(targetSystemGroupIds)) {
			return;
		}

		const attachment = yield* select(selectCurrentAttachmentVm);

		const isTicketShow = !_.isUndefined(attachment) && attachment.isTicketShow;
		const targetChannelGroupsAbility = yield* select(selectChannelGroupsPurchaseAbility, {
			attachmentId: attachment?.attachmentId,
		});

		if (
			targetChannelGroupsAbility?.canReceiveAttachment === EnumCanPurchase.NO_ONE ||
			(attachment?.price && targetChannelGroupsAbility?.canPurchase === EnumCanPurchase.NO_ONE)
		) {
			return;
		}

		targetChannelIds = _.difference(targetChannelIds, targetChannelGroupsAbility?.canNotReceiveChannels || []);
		const bulkReceiveCount = yield* select(selectBulkMessagesReceiveCount);
		let sentChannelCount = !_.isUndefined(attachment)
			? _.size(bulkReceiveCount.canReceiveChannels)
			: bulkReceiveCount.channelCount;

		if (attachment?.price) {
			targetChannelIds = _.difference(targetChannelIds, targetChannelGroupsAbility?.canNotPurchaseChannels || []);
			sentChannelCount = _.size(bulkReceiveCount?.canPurchaseChannels);
		}

		const channelId = _.join(targetChannelIds, ',');
		const groupId = _.join(targetGroupIds, ',');

		if (
			(_.isEmpty(channelId) && _.isEmpty(groupId) && _.isEmpty(targetSystemGroupIds)) ||
			attachment?.hasAttachmentError
		) {
			return;
		}

		yield* put(messagesClientOnlyActions.setSentChannelCount(sentChannelCount));

		const hasUniqueMediaAttachment = !_.isUndefined(attachment) && attachment.hasMedia && !attachment.isDuplicatedMedia;
		const hasDuplicateAttachment = !_.isUndefined(attachment) && attachment.isDuplicatedMedia;
		const isTextOnlyMessage = !_.isEmpty(messageText) && _.isUndefined(attachment);

		if (
			isTextOnlyMessage ||
			hasDuplicateAttachment ||
			(_.isUndefined(channelId) && _.isUndefined(groupId) && _.isEmpty(targetSystemGroupIds))
		) {
			yield* call(
				submitMessageWithoutFileUploadingSaga,
				messageText,
				channelId,
				attachment,
				groupId,
				targetSystemGroupIds,
			);
		} else if (isTicketShow) {
			yield* call(publishTicketToPrivateShowSaga, channelId, attachment, messageText);
		} else if (hasUniqueMediaAttachment) {
			const isBulk = guestIdentity?.guestType === EnumGuestType.BULK;

			if (isBulk) {
				targetChannelIds = attachment.price
					? bulkReceiveCount.canPurchaseChannels
					: bulkReceiveCount.canReceiveChannels;
			}

			yield* put(
				messagesClientOnlyActions.sendMessageWithMediaUpload({
					text: messageText,
					channelId: _.join(targetChannelIds, ','),
					attachment,
					isBulk,
				}),
			);

			yield* put(messageInputClientOnlyActions.removeOne(isBulk ? BULK : targetChannelIds[0]));
		}

		if (!_.isUndefined(attachment)) {
			yield* put(attachmentClientOnlyActions.detach({attachmentId: attachment.attachmentId}));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'submitMessageSaga'});
	}
};

export default submitMessageSaga;
