import {takeEvery} from 'typed-redux-saga';

import {feedbackClientOnlyActions} from '@messenger/core/src/Redux/Feedback/Actions/feedbackClientOnlyActions';
import {SendFeedbackSaga} from '@messenger/core/src/Redux/Feedback/Sagas/SendFeedbackSaga';

function* feedbackSagaWatcher() {
	yield* takeEvery(feedbackClientOnlyActions.send.type, SendFeedbackSaga);
}

export default feedbackSagaWatcher;
