import _ from 'lodash';

import selectChannelsSummary from '@messenger/core/src/Redux/Channels/Selectors/selectChannelsSummary';
import {createSelector} from '@messenger/core/src/Utils/Redux';

const selectPinnedFromSummary = createSelector([selectChannelsSummary], (summary): number =>
	parseInt(_.get(summary, 'pinned', '0'), 10),
);

export default selectPinnedFromSummary;
