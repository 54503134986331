import ISpeedTestState from '@messenger/core/src/Redux/SpeedTest/ISpeedTestState';
import {onceUponATimeInAGalaxyFarFarAway} from '@messenger/core/src/Utils/DateTimeUtil';
import {IAnySpeedTestAction} from '@messenger/core/src/Redux/SpeedTest/ActionCreator';
import EnumActions from '@messenger/core/src/Redux/SpeedTest/EnumActions';
import {speedTestServerToClientActions} from '@messenger/core/src/Redux/SpeedTest/Actions/speedTestServerToClientActions';

export const defaultState = {
	error: '',
	isStarted: false,
	isFinished: false,
	startTime: onceUponATimeInAGalaxyFarFarAway,
	progress: 0,
	requests: 0,
	measured: 0,
	meta: undefined,
};

const Reducer = (state: ISpeedTestState = defaultState, action: IAnySpeedTestAction): ISpeedTestState => {
	switch (action.type) {
		case EnumActions.SET_SPEED_TEST_URL:
			return {
				...state,
				speedTestUrl: action.speedTestUrl,
			};

		case EnumActions.RESET:
			return {
				...state,
				...defaultState,
			};

		case EnumActions.START:
			return {
				...state,
				isStarted: true,
				startTime: action.time,
			} as ISpeedTestState;

		case EnumActions.END:
			return {
				...state,
				isFinished: true,
			} as ISpeedTestState;

		case speedTestServerToClientActions.startTestReceived.type:
			return {
				...state,
				meta: action.payload,
			} as ISpeedTestState;

		case EnumActions.ERROR:
			return {
				...state,
				error: action.error,
			} as ISpeedTestState;

		case EnumActions.SUCCESS:
			return {
				...state,
				isStarted: false,
			} as ISpeedTestState;

		case EnumActions.MARK_PROGRESS:
			return {
				...state,
				requests: state.requests + 1,
				progress: action.progress,
				measured: action.measured,
			} as ISpeedTestState;

		default:
			return state;
	}
};

export default Reducer;
