import 'reflect-metadata';
import {TFunction} from 'i18next';
import _, {parseInt} from 'lodash';
import {Dictionary} from '@reduxjs/toolkit';
import moment, {Moment} from 'moment';
import {
	EnumBooleanDigitized,
	EnumBooleanStringified,
	EnumCurrency,
	EnumMediaState,
	EnumMediaType,
	EnumMessageDirection,
	EnumMessageStyle,
	EnumMessageType,
	EnumTicketType,
	IGiftPrice,
	InitialCurrencies,
	SupportedLanguage,
	SystemMessageKey,
} from 'cmd-control-client-lib';

import {replaceLegacySmileys} from '@messenger/core/src/BusinessLogic/replaceLegacySmileys';
import EnumVLiveMessageType from '@messenger/core/src/BusinessLogic/EnumVLiveMessageType';
import MessageHelper from '@messenger/core/src/BusinessLogic/MessagesDisplayFilters/MessageHelper';
import {TMessageMediaUploadStatus} from '@messenger/core/src/Redux/Messages/MessageMediaUploadStatus';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import buildMessageStructure from '@messenger/core/src/Utils/Messages/BuildMessageStructure';
import formatCurrency from '@messenger/core/src/Utils/Numbers/formatCurrency';
import getMessageChatIds from '@messenger/core/src/Utils/Messages/getMessageChatIds';
import {parseBoolean} from '@messenger/core/src/Utils/StringUtil';
import {EnumMessageHearts} from '@messenger/core/src/BusinessLogic/EnumMessageHearts';
import MediaVM from '@messenger/core/src/Redux/Media/MediaVM';
import EnumKeyInMessage from '@messenger/core/src/BusinessLogic/EnumKeyInMessage';
import {TMediaPreviewInfo} from '@messenger/core/src/Types/media';
import EnumMessageTypePart from '@messenger/core/src/BusinessLogic/EnumMessagePartType';
import GiftVM from '@messenger/core/src/Redux/Gifts/GiftVM';

import {SswMessageType} from './Model';
import {temporaryIdPrefix} from './entityAdapter';

const USERNAME_FALLBACK = '...';

const sergeantPeppersLonelyHeartsClubBand = [
	SystemMessageKey.h_chat_stop,
	SystemMessageKey.h_chat_kicked,
	SystemMessageKey.h_chat_cam2cam_stop,
	SystemMessageKey.h_chat_stop_guest_error,
	SystemMessageKey.h_session_media_purchased,
	SystemMessageKey.h_media_video_purchased,
	SystemMessageKey.h_media_bitmap_purchased,
	SystemMessageKey.h_media_audio_purchased,
];

export class MessageViewModel {
	relationId?: string = undefined;
	chatId?: string = undefined;
	chatIds: string[] = [];
	channelId?: string = undefined;
	channelIds: string[] = [];
	gifts: GiftVM[] = [];
	giftIds: number[] = [];
	giftTotalPrices: IGiftPrice[] = [];
	giftsFromReply?: GiftVM[];
	isSeen = false;
	isDeleted = false;
	mailType: EnumVLiveMessageType | EnumMessageType = EnumVLiveMessageType.SYSTEM;
	messageId = '';
	mobileSite = false;
	reaction?: string = undefined;
	text = '';
	time: Moment = moment();
	timeSeen?: Moment = undefined;
	tip = 0;
	clientData = {};
	sentFailedReason = '';
	sendFromRegular = false;
	decoratedText = '';
	canDelete = false;
	relationText?: string = undefined;
	relationMediaType?: string = undefined;
	readonly userName: string = USERNAME_FALLBACK;
	readonly isTeamChannelMessage;
	readonly sentDate?: string;
	readonly guestAvatar?: string;
	readonly isTvAdminMessage;
	readonly isDeviceControl: boolean;
	readonly direction: EnumMessageDirection;
	readonly isIncoming: boolean;
	readonly isOutgoing: boolean;
	readonly currency: EnumCurrency;
	readonly isUploading: boolean;
	readonly uploadFailReasonCode?: number;
	readonly isPaid: boolean;
	readonly isPaidByGuest: boolean;
	readonly messageType?: EnumMessageType;
	readonly isBeingSent: boolean;
	readonly isSentFailed: boolean;
	readonly isSentSuccessfully: boolean;
	readonly hasImage: boolean;
	readonly hasVideo: boolean;
	readonly hasAudio: boolean;
	readonly videoPoster: string;
	readonly isOpenTicket: boolean;
	readonly hasGifts: boolean;
	readonly hasTip: boolean;
	readonly isInfoHighlightStyle: boolean;
	readonly isInfoStyle: boolean;
	readonly messageKey?: SystemMessageKey | string;
	readonly mediaDuration?: string;
	readonly ticketShowDuration?: string;
	readonly avatar?: string;
	readonly isSystemMessage: boolean;
	readonly isSystem: boolean;
	readonly isSystemMessageTicketPurchased: boolean;
	readonly sent2all: boolean;
	readonly isChargeable: boolean;
	readonly mediaPrice: number;
	readonly isMediaPurchased: boolean;
	readonly hasTranscodingError: boolean;
	readonly isBonusCodeRedeemedMessage: boolean;
	readonly key: string;
	readonly isSingleKey: boolean;
	readonly mediaType?: EnumMediaType;
	readonly isTranscoding: boolean;
	readonly mediaPreviewInfo: TMediaPreviewInfo;
	public isSending = false;
	public isAdminChatMessage = false;
	public mediaVM;
	public isPreview: boolean;
	public isFreeChatAfterPreview: boolean;

	/**
	 * @note in seconds
	 * @todo set it correctly when backend starts sending those
	 */
	public privateTicketShowDuration = 0;

	private readonly isSentToAllChats: boolean;

	constructor(
		private message: SswMessageType,
		giftEntities: Dictionary<GiftVM>,
		guestName?: string,
		guestAvatar?: string,
		isRegularGuest = false,
		isTeamChannel = false,
		isTvAdmin = false,
		protected uploadStatus: TMessageMediaUploadStatus | undefined = undefined,
		sentFailedReason = '',
		isSentToAllChats = false,
		isSending = false,
		isAdminChatMessage = false,
	) {
		this.isSentToAllChats = isSentToAllChats;
		this.isSending = isSending;
		this.isAdminChatMessage = isAdminChatMessage;

		this.giftIds = _.chain(message)
			.get('gift', '')
			.split(',')
			.map((giftId: string) => parseInt(giftId, 10))
			.compact()
			.value();

		this.gifts = _.chain(this.giftIds)
			.map((giftId: number) => giftEntities[giftId])
			.compact()
			.value();

		this.giftTotalPrices = _.chain(this.gifts)
			.groupBy('price.currency')
			.map((gifts: GiftVM[]) => ({
				value: _.sumBy(gifts, 'price.value'),
				currency: _.get(_.first<GiftVM>(gifts), 'price.currency', EnumCurrency.EURO),
			}))
			.value();

		if (this.message.relationText) {
			this.giftsFromReply = _.chain(this.message.relationText)
				.words(/\d+_[a-zA-Z]+/g)
				.map((giftName: string) => {
					const giftId = _.head(_.split(giftName, '_'));

					return !_.isUndefined(giftId) ? giftEntities[giftId] : undefined;
				})
				.compact()
				.value();
		}

		if (message.channelId) {
			this.channelId = message.channelId;
			this.sendFromRegular = isRegularGuest;
		}

		this.isTeamChannelMessage = isTeamChannel;

		// should be un-conditional
		this.chatIds = getMessageChatIds(message);

		this.channelIds = _.chain(message.channelId).split(',').compact().value();

		this.isDeleted = parseBoolean(_.get(message, 'isDeleted', EnumBooleanStringified.FALSE));

		this.canDelete = parseBoolean(_.get(message, 'canDelete', EnumBooleanStringified.FALSE));

		if (message.chatID) {
			this.chatId = message.chatID;
		}

		if (message.clientData) {
			const parsedClientData = _.attempt(JSON.parse, message.clientData);

			if (!_.isError(parsedClientData)) {
				this.clientData = parsedClientData;
			} else {
				this.clientData = message.clientData;
			}
		}

		if (message.mobileSite) {
			this.mobileSite = message.mobileSite === 'true';
		}

		if (message.messageId) {
			this.messageId = message.messageId;
		}

		if (message.reaction) {
			this.reaction = message.reaction;
		}

		if (message.time) {
			this.time = moment(parseInt(message.time, 10));
			this.sentDate = this.time.clone().startOf('day').format();
		}

		this.userName = guestName || USERNAME_FALLBACK;

		if (message.text) {
			this.text = message.text.trim();

			if (this.userName !== USERNAME_FALLBACK && this.sendFromRegular && MessageHelper.isSystem(this.message)) {
				const replace = !_.includes(sergeantPeppersLonelyHeartsClubBand, this.message.messageKey)
					? EnumMessageHearts.RED
					: EnumMessageHearts.BLACK;

				this.decoratedText = this.text.replace(this.userName, this.userName + ` ${replace}`);
			}
		}

		if (!!message.timeSeen && parseInt(message.timeSeen, 10) > 0) {
			this.timeSeen = moment(parseInt(message.timeSeen, 10));
		}

		this.isSeen = _.parseInt(_.get(message, 'timeSeen', '0'), 10) !== 0;

		if (message.tip) {
			this.tip = parseInt(message.tip || '0', 10);
		}

		const defaultMailType =
			_.includes([EnumMessageStyle.SYSTEM, EnumMessageStyle.INFO_HIGHLIGHT], message.style) || message.isSystemMail
				? EnumVLiveMessageType.SYSTEM
				: EnumVLiveMessageType.ENTRY;

		this.mailType = _.get(message, 'mailType', defaultMailType);

		this.sentFailedReason = sentFailedReason;

		this.guestAvatar = guestAvatar;

		this.isTvAdminMessage = isTvAdmin;

		if (message.relationId) {
			this.relationId = message.relationId;
		}

		this.mediaVM = message.mediaType ? new MediaVM(message) : undefined;

		this.isDeviceControl = !_.chain(ServiceFactory.env.getDeviceGiftIds()).intersection(this.giftIds).isEmpty().value();

		this.direction = _.get(this, 'message.direction', EnumMessageDirection.IN);

		this.isIncoming = this.direction === EnumMessageDirection.IN;

		if (!_.isUndefined(message.from) && this.isIncoming) {
			this.userName = message.from;
		}

		this.isOutgoing = this.direction === EnumMessageDirection.OUT;

		this.currency = _.get(this, 'message.currency', EnumCurrency.EURO);

		this.uploadFailReasonCode = this.uploadStatus?.errorCode;

		this.isUploading =
			(!_.isUndefined(this.uploadStatus) && _.isUndefined(this.uploadStatus.reason)) ||
			_.get(this.message, 'uploading', EnumBooleanStringified.FALSE) === EnumBooleanStringified.TRUE ||
			this.message.mediaState === EnumMediaState.UPLOAD;

		this.isPaid = _.get(this, 'message.isPaid', EnumBooleanStringified.FALSE) === EnumBooleanStringified.TRUE;

		this.isPaidByGuest = this.isPaid && this.message.direction === EnumMessageDirection.IN;

		const msgType = _.get(this.message, 'msgType');

		this.messageType = msgType === EnumMessageType.MAIL ? EnumMessageType.MESSENGER : msgType;

		this.isSentFailed = this.sentFailedReason !== '' || !_.isUndefined(this.uploadStatus?.reason);

		this.isBeingSent = this.isUploading || (this.messageId === this.messageKey && this.isSending && !this.isSentFailed);

		this.isSentSuccessfully =
			this.message.direction === EnumMessageDirection.OUT && !_.includes(this.messageId, temporaryIdPrefix);

		this.hasImage =
			this.message.mediaType === EnumMediaType.BITMAP &&
			!!this.mediaVM &&
			_.some([this.mediaVM.imageSource, this.mediaVM.imageSourceSet]);

		this.hasVideo =
			this.message.mediaType === EnumMediaType.VIDEO &&
			(_.some([
				this.message.videoHls,
				this.message.videoPoster,
				this.message.videoPosterPixelated,
				this.message.videoFileUrl,
			]) ||
				_.get(this, 'uploadStatus.progress'));

		this.hasAudio =
			this.message.mediaType === EnumMediaType.AUDIO &&
			_.some([this.message.audioM4a, this.message.audioMp3, this.message.audioOgg]);

		if (this.mediaVM && this.mediaVM.mediaState === EnumMediaState.TRANSCODING && this.message.videoPosterPixelated) {
			this.videoPoster = this.message.videoPosterPixelated;
		} else {
			this.videoPoster = this.message.videoPoster as string;
		}

		this.isChargeable =
			EnumBooleanStringified.TRUE === _.get(this.message, 'isChargeable', EnumBooleanStringified.FALSE);

		this.isOpenTicket =
			!!this.mediaVM &&
			this.mediaVM.isTicketMediaType &&
			this.message.ticketType === EnumTicketType.SingleC2C &&
			this.isChargeable &&
			this.isPaid;

		this.hasGifts = !_.isEmpty(this.giftTotalPrices);

		this.hasTip = this.tip > 0;

		this.isInfoHighlightStyle = this.message.style === EnumMessageStyle.INFO_HIGHLIGHT;

		this.isInfoStyle = this.message.style === EnumMessageStyle.INFO;

		this.messageKey = this.message.messageKey;

		this.mediaDuration = this.mediaVM && this.mediaVM.formattedDuration;

		this.ticketShowDuration = moment
			.duration(this.privateTicketShowDuration, 'seconds')
			.format(ServiceFactory.i18n.t('momentFormat:durationFormat.hoursLongSuffix'));

		this.avatar = this.guestAvatar;

		this.isSystemMessage =
			_.includes([EnumMessageStyle.SYSTEM, EnumMessageStyle.INFO_HIGHLIGHT], this.message.style) ||
			!!this.message.isSystemMail;

		//todo: remove isSystemMessage or getIsSystem
		this.isSystem = MessageHelper.isSystem(this.message);

		this.isSystemMessageTicketPurchased =
			this.message.messageKey === SystemMessageKey.h_media_ticket_purchased && !_.isUndefined(this.channelId);

		this.sent2all = this.isSentToAllChats || this.chatIds.length > 1;

		this.mediaPrice = parseFloat(_.get(this.message, 'mediaPrice', '0'));

		this.isMediaPurchased =
			EnumBooleanStringified.TRUE === _.get(this, 'message.isPaid', EnumBooleanStringified.FALSE) &&
			this.message.direction === EnumMessageDirection.OUT;

		this.hasTranscodingError = !!this.mediaVM && this.mediaVM.hasTranscodingError;

		this.isBonusCodeRedeemedMessage =
			this.messageType === EnumMessageType.SYSTEM && this.messageKey === SystemMessageKey.bonusCodeRedemption;

		this.key = _.get(this.message, 'key', '');

		this.isSingleKey = this.key === EnumKeyInMessage.SINGLE;

		this.mediaType = this.getMediaType();

		this.isTranscoding = this.getIsTranscoding();

		this.mediaPreviewInfo = this.getMediaPreviewInfo();

		this.isPreview = !_.isUndefined(message.previewRun);

		this.isFreeChatAfterPreview = message.previewRun === EnumBooleanDigitized.FALSE;

		this.relationText = this.message.relationText;

		this.relationMediaType = this.message.relationMediaType;
	}

	getText(t?: TFunction): string {
		let text = _.size(this.decoratedText) > 0 ? this.decoratedText : this.text;

		/**
		 * custom message build on client
		 * @todo move to server side
		 */

		if (_.isFunction(t)) {
			switch (true) {
				case this.isDeviceControl: {
					const gift = _.head(this.gifts);

					text = _.chain([
						this.userName,
						this.sendFromRegular ? EnumMessageHearts.RED : undefined,
						t('messages:start-controls'),
						gift && this.isDeleted ? `${gift.id}_${gift.name}` : this.text,
					])
						.compact()
						.join(' ')
						.value();
					break;
				}

				case this.messageKey === SystemMessageKey.feedPostTipped: {
					if (!text) {
						text = t('messages:feed-post.feed-post-tipped', {username: this.userName});
					}

					break;
				}

				case this.messageKey === SystemMessageKey.feedPostBought: {
					if (!text) {
						text = t('messages:feed-post.feed-post-bought', {username: this.userName});
					}

					break;
				}
			}
		}

		return replaceLegacySmileys(text);
	}

	getGiftsFromReply(text: string, gifts: Record<string, GiftVM>) {
		const tmpGifts: GiftVM[] = [];
		const giftsArrayFromText = _.words(text, /\d+[_][a-zA-Z]+/g);

		_.forEach(giftsArrayFromText, (textGift: string) => {
			const giftsArrayIdFromText = _.head(textGift.split('_'));

			if (giftsArrayIdFromText) {
				tmpGifts.push(gifts[giftsArrayIdFromText]);
			}
		});

		return tmpGifts;
	}

	getRelationMessageStructure(language: SupportedLanguage, maxPartLength?: number) {
		return this.relationText
			? buildMessageStructure(
					this.relationText,
					this.giftsFromReply || [],
					language,
					this.isDeleted && !this.isDeviceControl,
					maxPartLength,
			  )
			: undefined;
	}

	getMessageStructure(language: SupportedLanguage, translate?: TFunction, maxPartLength?: number) {
		return buildMessageStructure(
			this.getText(translate),
			this.gifts,
			language,
			this.isDeleted && !this.isDeviceControl,
			maxPartLength,
		);
	}

	getMessageText(language: SupportedLanguage, translate?: TFunction) {
		return _.reduce(
			this.getMessageStructure(language, translate),
			(txt, part) => {
				switch (part.type) {
					case EnumMessageTypePart.TEXT:
						if (part.isNewLine) {
							return txt + '\n';
						}

						return txt + part.value;

					case EnumMessageTypePart.SMILE:
						return txt + part.label;

					// this should not happen for normal messages
					case EnumMessageTypePart.FAVORITE:
						return txt + part.value;
				}

				return txt;
			},
			'',
		);
	}

	private getMediaType(): EnumMediaType | undefined {
		if (!_.isUndefined(this.message.mediaType)) {
			return this.message.mediaType as EnumMediaType;
		}

		// old media upload interface does not set mediaType and mediaState on messages
		// -> fallback to check imgSrc's presence
		if (this.hasImage) {
			return EnumMediaType.BITMAP;
		}

		return undefined;
	}

	private getIsTranscoding(): boolean {
		switch (this.mediaType) {
			case EnumMediaType.VIDEO:
				return (
					!!this.mediaVM &&
					this.mediaVM.mediaState === EnumMediaState.TRANSCODING &&
					!this.isUploading &&
					!_.isUndefined(this.mediaVM.videoFileUrl)
				);

			case EnumMediaType.AUDIO:
				return !!this.mediaVM && this.mediaVM.mediaState === EnumMediaState.TRANSCODING && !this.isUploading;

			default:
				return false;
		}
	}

	getGiftTotalPricesFormatted(
		currencies: InitialCurrencies,
		language: SupportedLanguage,
		isRoundedIfInteger = false,
	): string {
		return _.chain(this.giftTotalPrices)
			.map((price) =>
				formatCurrency(currencies, language, price.currency, parseFloat(price.value.toString()), isRoundedIfInteger),
			)
			.join(', ')
			.value();
	}

	getTipValueFormatted(currencies: InitialCurrencies, language: SupportedLanguage, isRoundedIfInteger = false): string {
		return formatCurrency(currencies, language, EnumCurrency.EURO, this.tip / 100, isRoundedIfInteger);
	}

	getIsBulk(): boolean {
		return this.message.isBulk === EnumBooleanStringified.TRUE;
	}

	private getMediaPreviewInfo(): TMediaPreviewInfo {
		const isUploadFailed =
			!_.isUndefined(this.uploadFailReasonCode) || this.mediaVM?.mediaState === EnumMediaState.ERROR;
		const isTranscodingFailed = this.mediaVM?.mediaState === EnumMediaState.TRANSCODING_ERROR && !isUploadFailed;

		const commonProps = {
			isProcessing: this.isUploading || (this.mediaVM?.mediaState === EnumMediaState.TRANSCODING && !isUploadFailed),
		};

		switch (this.mediaType) {
			case EnumMediaType.VIDEO:
				return {
					...commonProps,
					type: EnumMediaType.VIDEO,
					src: this.mediaVM?.videoUrl,
					poster: this.videoPoster,
					isTranscoded: !(
						_.startsWith(this.mediaVM?.videoUrl, 'blob') ||
						isTranscodingFailed ||
						this.mediaVM?.mediaState === EnumMediaState.TRANSCODING
					),
				};

			case EnumMediaType.AUDIO:
				return {
					...commonProps,
					type: EnumMediaType.AUDIO,
					src: _.head(this.mediaVM?.audioSourcesList) || '',
					duration: this.mediaDuration,
				};

			case EnumMediaType.TICKET:
				return {
					type: EnumMediaType.TICKET,
				};

			case EnumMediaType.BITMAP:
			default:
				return {
					...commonProps,
					type: EnumMediaType.BITMAP,
					src: this.mediaVM?.imageSource,
					srcSet: this.mediaVM?.imgSrcSet,
					file: _.get(this, 'uploadStatus.attachment.file'),
				};
		}
	}
}

export type TMessageImageSource = {
	uri: string;
	width: number;
	height: number;
};
