import _ from 'lodash';
import React, {MouseEvent, SyntheticEvent, useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Dispatch} from '@reduxjs/toolkit';
import classNames from 'classnames';

import notificationsClientOnlyActions from '@messenger/core/src/Redux/Notifications/Actions/notificationsClientOnlyActions';
import {EnumAbstractNotificationCloseReason} from '@messenger/core/src/Services/UINotification';
import EnumChatPageTestIds from '@messenger/uikit/src/TestIds/EnumChatPageTestIds';
import Button from '@messenger/uikit/src/Button/Button';

import {Routes} from 'src/Routes/Routes';
import useRouterLinkModalForMui from 'src/Utils/Navigation/useRouterLinkModalForMui';

import style from './ActionButton.module.scss';

function ActionButton({buttonRoute, onClick, notificationKey, isNonClosable, endIcon, text}: TActionButtonProps) {
	const {t} = useTranslation();
	const dispatch: Dispatch = useDispatch();
	const onClickHandler = useCallback(
		(event: MouseEvent) => {
			if (onClick) {
				onClick(event, EnumAbstractNotificationCloseReason.USER, notificationKey);
			}

			if (!isNonClosable) {
				dispatch(notificationsClientOnlyActions.closeSnackbar(notificationKey));
			}
		},
		[onClick, notificationKey, isNonClosable, dispatch],
	);
	const Link = useRouterLinkModalForMui(buttonRoute || Routes.home, undefined, onClickHandler);

	return (
		<Button
			color="secondary"
			component={buttonRoute ? Link : undefined}
			onClick={buttonRoute ? undefined : onClickHandler}
			data-test-id={EnumChatPageTestIds.SNACKBAR_ACTION_BUTTON}
			className={classNames({[style.buttonIcon]: !_.isUndefined(endIcon)})}
		>
			{text ?? t('common:ok')}
			{endIcon}
		</Button>
	);
}

type TActionButtonProps = {
	notificationKey: string;
	buttonRoute?: string;
	text?: string;
	endIcon?: React.ReactNode;
	isNonClosable?: boolean;
	onClick?: (event: SyntheticEvent<any> | null, reason: EnumAbstractNotificationCloseReason, key?: string) => void;
};

export default ActionButton;
