import {put} from 'typed-redux-saga';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {channelsClientOnlyActions} from '@messenger/core/src/Redux/Channels/Actions';
import {messagesServerToClientActions} from '@messenger/core/src/Redux/Messages/Actions/messagesServerToClientActions';

function* processMessagesHistoryReceivedSaga(
	action: ReturnType<typeof messagesServerToClientActions.messagesHistoryReceived>,
) {
	try {
		const channelIds = _.chain(action).get(['meta', 'params', 'channelId'], '').split(',').compact().value();

		if (!_.isEmpty(channelIds)) {
			yield* put(channelsClientOnlyActions.setChannelsWithHistory({channelIds}));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processMessagesHistoryReceivedSaga'});
	}
}

export default processMessagesHistoryReceivedSaga;
