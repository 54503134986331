import {createSelector, Dictionary} from '@reduxjs/toolkit';
import _ from 'lodash';

import {selectChannelGroupsEntities} from '@messenger/core/src/Redux/ChannelGroups/Selectors/defaultSelectors';
import ChannelGroupVM from '@messenger/core/src/Redux/ChannelGroups/channelGroupVM';
import {selectChannelInfoEntityVMs} from '@messenger/core/src/Redux/ChannelInfos/Selectors/defaultSelectors';

export const selectChannelGroupEntityVMs = createSelector(
	[selectChannelGroupsEntities, selectChannelInfoEntityVMs],
	(channelGroupEntities, channelInfoVMEntities): Dictionary<ChannelGroupVM> =>
		_.mapValues(channelGroupEntities, (channelGroupEntity) =>
			_.isUndefined(channelGroupEntity)
				? undefined
				: new ChannelGroupVM(
						channelGroupEntity,
						_.get(channelInfoVMEntities, [channelGroupEntity.channelId, 'username']),
				  ),
		),
);
