import { EnumBooleanDigitized, JSONString } from "../@types";
import { IBACKENDPAYLOAD } from "./backend";
import { ACTION } from "./command/action";
import { baseParamsType } from "./command/baseparams";
import { ICOMMAND, IRESPONSE, RESULT } from "./command/icommand";
import { SessionState } from "./sessionstate";
import { UploadMediaTus } from "./init";

/** web token format need for login with web token. obligatory fields in token-payload
 * \{
 *   userPoolId: number \{10\}, -  the id of your user pool
 *   usrKey: string \{36 bytes\} - the uniq user key in your pool
 * \}
 */
export type loginWithWebtokenType = {
  webtoken: string;
};

/** login with user name & password */
export type loginWithUserNameType = {
  /** your bussines Id in CmdControl system, will be obligatory in future to check the password by your backend */
  usrId?: string;
  /** username. if usrId not set, your B2B username. if usrId is set the username of customer. LIVE: username of Model */
  login: string;
  /** password in plain MD5 or other format to check it by your backend */
  password: string;
};

export type foreignSIDType = {
  /** upgrade from foreignSID, 8320 only */
  foreignSID?: string;
};

/** additional params */
export type otherLoginParamsType = {
  /** B2B: user key. Obligatory, If you want to create end-user-session and if not set already in webtoken. max 36 symbols */
  usrKey?: string;
  /** this is session with messenger functions, default is "0" */
  msn?: EnumBooleanDigitized;
  // /** unused */
  // jwtKey?: string;
};

/** login union type */
export type loginParamType = loginWithWebtokenType | loginWithUserNameType | foreignSIDType;

export interface ILoginBackendPayload {
  guest: any;
}

export class CMDP_LOGIN implements ICOMMAND, IBACKENDPAYLOAD {
  public action: ACTION = ACTION.CMDP_LOGIN;
  /** login params */
  public params: baseParamsType & loginParamType & otherLoginParamsType;
  /** payload to sent it to backend */
  public payload?: unknown;
}

/**
 *  response for Login
 */
export class CMDP_LOGIN_RESPONSE extends CMDP_LOGIN implements IRESPONSE, IBACKENDPAYLOAD {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: SessionState & {
    uploadMediaTus?: JSONString<UploadMediaTus>;
  };
  /** payload from backend */
  public payload?: ILoginBackendPayload;
}
