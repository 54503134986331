import {CMDC_CHANNELINFO} from 'cmd-control-client-lib';
import _ from 'lodash';

import {ClientOnlyActions, TMultiEntityActionMeta} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {channelInfoEntityProcessor} from '@messenger/core/src/Redux/ChannelInfos/Actions/channelInfoEntityProcessor';
import {TChannelInfoModel} from '@messenger/core/src/Redux/ChannelInfos/Model';

enum EnumClientActions {
	RESET_STORE = 'RESET_STORE',
	UPSERT_MANY = 'UPSERT_MANY',
	ADD_MANY = 'ADD_MANY',
	APPEND_ONE = 'APPEND_ONE',
	REMOVE_ONE = 'REMOVE_ONE',
	UPDATE_STORE = 'UPDATE_STORE',
}

class ChannelInfosClientOnlyActions extends ClientOnlyActions<EnumStore.CHANNEL_INFOS> {
	readonly scope = EnumStore.CHANNEL_INFOS;

	resetStore = this.createAction(EnumClientActions.RESET_STORE);

	upsertMany = this.createAction(
		EnumClientActions.UPSERT_MANY,
		(payload: CMDC_CHANNELINFO['params'][], meta?: TMultiEntityActionMeta<CMDC_CHANNELINFO>) => ({
			payload: _.map(payload, channelInfoEntityProcessor),
			meta,
		}),
	);

	addMany = this.createAction(
		EnumClientActions.ADD_MANY,
		(payload: CMDC_CHANNELINFO['params'][], meta?: TMultiEntityActionMeta<CMDC_CHANNELINFO>) => ({
			payload: _.map(payload, channelInfoEntityProcessor),
			meta,
		}),
	);

	appendOne = this.createAction(
		EnumClientActions.APPEND_ONE,
		(payload: CMDC_CHANNELINFO['params'] | Partial<TChannelInfoModel>) => ({
			payload: channelInfoEntityProcessor(payload),
		}),
	);

	removeOne = this.createAction(EnumClientActions.REMOVE_ONE, this.getPrepareAction<string>());

	updateStore = this.createAction(
		EnumClientActions.UPDATE_STORE,
		this.getPrepareAction<{isLoading: boolean; requestedIds: string[]; channelInfos?: TChannelInfoModel[]}>(),
	);
}

export const channelInfosClientOnlyActions = new ChannelInfosClientOnlyActions();
