import {put} from 'typed-redux-saga';
import _ from 'lodash';

import {mediaClientOnlyActions} from '@messenger/core/src/Redux/Media/Actions/mediaClientOnlyActions';
import {channelMediaClientOnlyActions} from '@messenger/core/src/Redux/ChannelMedia/Actions/channelMediaClientOnlyActions';

export const processMediaReceivedSaga = function* ({
	meta: {channelMedias},
}: ReturnType<typeof mediaClientOnlyActions.upsertMany>) {
	if (!_.isEmpty(channelMedias)) {
		yield* put(channelMediaClientOnlyActions.addChannelMedia(channelMedias));
	}
};
