import _ from 'lodash';
import {EnumMessageType} from 'cmd-control-client-lib';
import {Moment} from 'moment';

import ChannelHelper from '@messenger/core/src/Redux/Channels/ChannelHelper';
import {MessageViewModel} from '@messenger/core/src/Redux/Messages/MessageViewModel';

import MessageHelper from './MessageHelper';

const allChatsFilter = (
	messageVM: MessageViewModel,
	messageKeyToIds: Record<string, string[]>,
	timeFrame?: {start?: Moment; end?: Moment},
) => {
	let isMessageWithinTimeFrame = true;

	if (!_.isUndefined(timeFrame) && !_.isUndefined(messageVM.time)) {
		const messageTime = messageVM.time.unix();

		if (!_.isUndefined(timeFrame.start)) {
			isMessageWithinTimeFrame = timeFrame.start.unix() <= messageTime;
		}

		if (!_.isUndefined(timeFrame.end)) {
			isMessageWithinTimeFrame = isMessageWithinTimeFrame && timeFrame.end.unix() >= messageTime;
		}
	}

	return (
		// chatIds check removed as ToyControl messages do not have a one
		(messageVM.messageType === EnumMessageType.CHAT ||
			messageVM.isSystem ||
			messageVM.isBonusCodeRedeemedMessage ||
			ChannelHelper.isTVChannel(messageVM.channelId)) &&
		MessageHelper.dedupeResult(messageVM, messageKeyToIds) &&
		isMessageWithinTimeFrame
	);
};

export default allChatsFilter;
