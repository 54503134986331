import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux/createSelector';
import {selectChannelGroupVMs} from '@messenger/core/src/Redux/ChannelGroups/Selectors/selectChannelGroupVMs';
import {selectTargetGroupIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetGroupIds';

export const selectTargetChannelInGroupIds = createSelector(
	[selectTargetGroupIds, selectChannelGroupVMs],
	(targetGroupIds, channelGroupVMs) => {
		return _.chain(channelGroupVMs)
			.filter((item) => !_.isUndefined(item) && targetGroupIds.includes(item.groupId) && !item.isDeleted)
			.map('channelId')
			.value();
	},
);
