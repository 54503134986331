import {put, take} from 'typed-redux-saga';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {groupsClientOnlyActions} from '@messenger/core/src/Redux/Groups/Actions/groupsClientOnlyActions';
import {groupsClientToServerActions} from '@messenger/core/src/Redux/Groups/Actions/groupsClientToServerActions';
import {channelGroupsClientToServerActions} from '@messenger/core/src/Redux/ChannelGroups/Actions/channelGroupsClientToServerActions';

const processGroupCreatedSaga = function* ({
	meta: {channelIds},
}: ReturnType<typeof groupsClientToServerActions.createGroup>) {
	try {
		const {payload: createdGroup, error} = yield* take(groupsClientOnlyActions.upsertMany);

		if (!error) {
			const groupId = _.last(_.map(createdGroup, 'groupId'));

			yield* put(channelGroupsClientToServerActions.addChannelsToGroup({groupId, channelIds}));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processGroupCreatedSaga'});
	}
};

export default processGroupCreatedSaga;
