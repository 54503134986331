import _ from 'lodash';
import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import {selectUploadingMessageIds} from '@messenger/core/src/Redux/Messages/Selectors/selectUploadingMessageIds';
import notificationsClientOnlyActions from '@messenger/core/src/Redux/Notifications/Actions/notificationsClientOnlyActions';
import EnumSnackbarNotificationKeys from '@messenger/core/src/BusinessLogic/EnumSnackbarNotificationKeys';

export function* closeProcessingBulkMessageSentSaga({
	payload: tempMessageId,
}: ReturnType<typeof messagesClientOnlyActions.markUploaded>) {
	try {
		const uploadingMessageIds = yield* select(selectUploadingMessageIds);

		if (!_.includes(uploadingMessageIds, tempMessageId)) {
			yield* put(
				notificationsClientOnlyActions.closeSnackbar(EnumSnackbarNotificationKeys.PROCESSING_BULK_MESSAGE_SENT),
			);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'closeProcessingBulkMessageSentSaga'});
	}
}
