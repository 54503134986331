import {createSlice, EntityState, SliceCaseReducers} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {attachmentClientOnlyActions} from '@messenger/core/src/Redux/Attachment/Actions';
import {attachmentAdapter} from '@messenger/core/src/Redux/Attachment/entityAdapter';
import IAttachment from '@messenger/core/src/Redux/Attachment/Model';

export type TAttachmentState = EntityState<IAttachment> & {
	isRecordingAudio: boolean;
	isHoldingRecord: boolean;
	isAudioPreviewCollapsed: boolean;
	recordingAudioAttachmentId?: string;
};

export const initialAttachmentState = attachmentAdapter.getInitialState({
	isRecordingAudio: false,
	isHoldingRecord: false,
	isAudioPreviewCollapsed: true,
});

export const attachmentSlice = createSlice<TAttachmentState, SliceCaseReducers<TAttachmentState>, EnumStore>({
	name: EnumStore.ATTACHMENT,
	initialState: initialAttachmentState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(attachmentClientOnlyActions.attach, attachmentAdapter.upsertOne);
		builder.addCase(attachmentClientOnlyActions.update, attachmentAdapter.updateOne);
		builder.addCase(attachmentClientOnlyActions.detach, attachmentAdapter.removeOne);
		builder.addCase(attachmentClientOnlyActions.resetStore, () => initialAttachmentState);
		builder.addCase(attachmentClientOnlyActions.startRecordingAudio, (state, {payload: {attachmentId}}) => {
			state.isRecordingAudio = true;
			state.recordingAudioAttachmentId = attachmentId;
		});
		builder.addCase(attachmentClientOnlyActions.stopRecordingAudio, (state) => {
			state.isRecordingAudio = false;
			state.recordingAudioAttachmentId = undefined;
		});
		builder.addCase(attachmentClientOnlyActions.audioRecordingFailed, (state) => {
			state.isRecordingAudio = false;
			state.recordingAudioAttachmentId = undefined;
		});
		builder.addCase(attachmentClientOnlyActions.setIsHoldingRecord, (state, {payload: {isHoldingRecord}}) => {
			state.isHoldingRecord = isHoldingRecord;
		});
		builder.addCase(
			attachmentClientOnlyActions.setIsAudioPreviewCollapsed,
			(state, {payload: {isAudioPreviewCollapsed}}) => {
				state.isAudioPreviewCollapsed = isAudioPreviewCollapsed;
			},
		);
	},
});
