import {createEntityAdapter} from '@reduxjs/toolkit';
import {AudioFile, channelIdType, EnumMediaType, ImageFile, Ticket, VideoFile} from 'cmd-control-client-lib';
import _ from 'lodash';

import {descSortComparerByField} from '@messenger/core/src/Utils/descSortComparerByField';

export type TAnyMedia =
	| (ImageFile & {mediaType: EnumMediaType.BITMAP})
	| (AudioFile & {mediaType: EnumMediaType.AUDIO})
	| (VideoFile & {mediaType: EnumMediaType.VIDEO})
	| (Ticket & channelIdType & {mediaType: EnumMediaType.TICKET});

export const mediaEntityAdapter = createEntityAdapter<TAnyMedia>({
	selectId: (state) => _.toString(state.mediaMd5),
	sortComparer: (media1, media2) => descSortComparerByField<TAnyMedia>(media1, media2, 'mediaCreated'),
});
