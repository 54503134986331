import React, {Ref} from 'react';

import notificationsClientOnlyActions from '@messenger/core/src/Redux/Notifications/Actions/notificationsClientOnlyActions';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';
import selectIsSoundTestInProgress from '@messenger/core/src/Redux/Client/Selectors/selectIsSoundTestInProgress';
import {TCanAccessWindow, TCanPlaySound} from '@messenger/core/src/Services/AbstractUiContainer';
import {useStoreSelector} from '@messenger/core/src/Hooks/useStoreSelector';
import useActionDispatcher from '@messenger/core/src/Hooks/useActionDispatcher';
import {useStableCallback} from '@messenger/core/src/Hooks/useStableCallback';

import EnableSoundsContent from 'src/Components/Notifications/EnableSounds/EnableSoundsContent';

const EnableSoundsContentContainer = React.forwardRef(
	({notificationKey, browser}: TEnableSoundsContentContainerProps, ref: Ref<Element>) => {
		const declineSounds = useActionDispatcher(clientClientOnlyActions.soundsDeclined);
		const acceptSounds = useActionDispatcher(clientClientOnlyActions.soundsAccepted);
		const closeSnackbar = useActionDispatcher(notificationsClientOnlyActions.closeSnackbar);
		const setSoundTestResult = useActionDispatcher(clientClientOnlyActions.soundsTestResult);
		const playSound = useActionDispatcher(clientClientOnlyActions.playSound);

		const disableSounds = useStableCallback(() => {
			declineSounds();
			closeSnackbar(notificationKey);
		});

		const markTestFailed = useStableCallback(() => {
			setSoundTestResult(false);
			closeSnackbar();
		});

		const markTestSuccess = useStableCallback(() => {
			setSoundTestResult(true);
			closeSnackbar();
			playSound('text');
		});

		return (
			<EnableSoundsContent
				ref={ref}
				browser={browser}
				isSoundsTestRunning={useStoreSelector(selectIsSoundTestInProgress)}
				disableSounds={disableSounds}
				markTestStarted={acceptSounds}
				markTestFailed={markTestFailed}
				markTestSuccess={markTestSuccess}
			/>
		);
	},
);

type TEnableSoundsContentContainerProps = {
	browser: TCanPlaySound & TCanAccessWindow;
	notificationKey: string;
};

export default EnableSoundsContentContainer;
