import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectChatVms} from '@messenger/core/src/Redux/Chats/Selectors/defaultSelectors';

const selectRunningChatsChannelIds = createSelector([selectChatVms], (chatVms) =>
	_.chain(chatVms)
		.filter((chatVm) => !chatVm.exitCode)
		.map('channelId')
		.compact()
		.value(),
);

export default selectRunningChatsChannelIds;
