import _ from 'lodash';
import {createSelector} from '@reduxjs/toolkit';

import {selectChannelGroupEntityVMs} from '@messenger/core/src/Redux/ChannelGroups/Selectors/selectChannelGroupEntityVMs';
import ChannelGroupVM from '@messenger/core/src/Redux/ChannelGroups/channelGroupVM';

export const selectChannelGroupVMs = createSelector(
	selectChannelGroupEntityVMs,
	(channelGroupEntityVMs): ChannelGroupVM[] => _.chain(channelGroupEntityVMs).values().compact().value(),
);
