import {put, select} from 'typed-redux-saga';
import {EnumMessageDirection, EnumMessageType, SystemMessageKey} from 'cmd-control-client-lib';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {channelsClientOnlyActions} from '@messenger/core/src/Redux/Channels/Actions';
import {selectChannelIds} from '@messenger/core/src/Redux/Channels/Selectors/defaultSelectors';
import {messagesServerToClientActions} from '@messenger/core/src/Redux/Messages/Actions/messagesServerToClientActions';

function* requestMissingChannelsOnMessageHistoryReceivedSaga({
	meta,
}: ReturnType<typeof messagesServerToClientActions.messagesHistoryReceived>) {
	try {
		const loadedChannelIds = yield* select(selectChannelIds);
		const needToLoadChannelIds = _.chain(meta.commands)
			.map(({params: {messageKey, msgType, direction, channelId}}) =>
				(messageKey === SystemMessageKey.bonusCodeRedemption ||
					(msgType === EnumMessageType.CHAT && direction === EnumMessageDirection.IN)) &&
				!_.isUndefined(channelId) &&
				!loadedChannelIds.includes(channelId)
					? channelId
					: null,
			)
			.compact()
			.value();

		if (needToLoadChannelIds.length === 0) {
			return;
		}

		yield* put(channelsClientOnlyActions.requestChannels({channelIds: needToLoadChannelIds}));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'requestMissingChannelsOnMessageHistoryReceivedSaga'});
	}
}

export default requestMissingChannelsOnMessageHistoryReceivedSaga;
