import {createEntityAdapter} from '@reduxjs/toolkit';
import {channelIdType, ChannelMediaLinkState, MediaPrice, UploadedMediaDirection} from 'cmd-control-client-lib';

import {descSortComparerByField} from '@messenger/core/src/Utils/descSortComparerByField';

export const getChannelMediaKey = (channelId: string, mediaMd5: string) => `${channelId}-${mediaMd5}`;

export const channelMediaEntityAdapter = createEntityAdapter<TChannelMedia>({
	selectId: ({channelId, mediaMd5}) => getChannelMediaKey(channelId, mediaMd5),
	sortComparer: (firstChannelMedia, secondChannelMedia) =>
		descSortComparerByField<TChannelMedia>(firstChannelMedia, secondChannelMedia, 'linkDate'),
});

export type TChannelMedia = channelIdType & {
	mediaMd5: string;
	linkState: ChannelMediaLinkState;
	linkDate?: string;
	mediaType?: string;
	direction?: UploadedMediaDirection;
} & MediaPrice;
