import _ from 'lodash';
import {call, put, select} from 'typed-redux-saga';

import {sessionServerToClientActions} from '@messenger/core/src/Redux/Session/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import processTranslationsSaga from '@messenger/core/src/Redux/Session/Sagas/processTranslationsSaga';
import processAutoLoginSaga from '@messenger/core/src/Redux/Session/Sagas/processAutoLoginSaga';
import setDefaultCredentialsSaga from '@messenger/core/src/Redux/LoginForm/Sagas/setDefaultCredentialsSaga';
import {mediaClientOnlyActions} from '@messenger/core/src/Redux/Media/Actions/mediaClientOnlyActions';
import ActionCreator from '@messenger/core/src/Redux/SpeedTest/ActionCreator';
import {feedbackClientOnlyActions} from '@messenger/core/src/Redux/Feedback/Actions/feedbackClientOnlyActions';
import {reactionsClientOnlyActions} from '@messenger/core/src/Redux/Reactions/Actions/reactionsClientOnlyActions';
import {resetAllStores} from '@messenger/core/src/Redux/Reducers';
import selectShouldAutoLogin from '@messenger/core/src/Redux/LoginForm/Selectors/selectShouldAutoLogin';
import selectCanBeLoggedByForeignSID from '@messenger/core/src/Redux/Session/Selectors/selectCanBeLoggedByForeignSID';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';

const processInitResultsSaga = function* (action: ReturnType<typeof sessionServerToClientActions.initialized>) {
	try {
		yield* put(clientClientOnlyActions.setIsConnected());
		const isAutoLogin = yield* select(selectShouldAutoLogin);
		const canBeLoggedByForeignSID = yield* select(selectCanBeLoggedByForeignSID);

		if (isAutoLogin && !canBeLoggedByForeignSID) {
			// @NOTE After auto login we reset whole redux store except data that are not received from backend
			// as we don't know what data could be updated during the time the session was down
			yield* put(resetAllStores({keepLocalStores: true}));
		}

		yield* call(setDefaultCredentialsSaga);
		yield* call(processAutoLoginSaga);

		if (!_.isUndefined(action.payload.uploadMediaUrl)) {
			yield* put(mediaClientOnlyActions.setMediaUploadUrl(action.payload.uploadMediaUrl));
		} else {
			ServiceFactory.logService.error('Upload media url is not set', {saga: 'processInitResultsSaga'});
		}

		if (_.has(action, 'payload.reactions')) {
			try {
				yield* put(reactionsClientOnlyActions.setReactions(JSON.parse(_.get(action, 'payload.reactions'))));
			} catch (error) {
				ServiceFactory.logService.error(error, {saga: 'processInitResultsSaga'});
			}
		} else {
			ServiceFactory.logService.error('Reactions list is not set', {saga: 'processInitResultsSaga'});
		}

		if (_.has(action, 'payload.speedTestUrl')) {
			yield* put(ActionCreator.setSpeedTestUrl({speedTestUrl: String(action.payload.speedTestUrl)}));
		} else {
			ServiceFactory.logService.error('Speed test url is not set', {saga: 'processInitResultsSaga'});
		}

		if (_.has(action, 'payload.uploadFeedbackUrl')) {
			yield* put(
				feedbackClientOnlyActions.setUploadFeedbackUrl({uploadFeedbackUrl: String(action.payload.uploadFeedbackUrl)}),
			);
		} else {
			ServiceFactory.logService.error('Upload feedback url is not set', {saga: 'processInitResultsSaga'});
		}

		yield* call(processTranslationsSaga, action);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processInitResultsSaga'});
	}
};

export default processInitResultsSaga;
