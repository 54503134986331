import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import selectReplyMessageId from '@messenger/core/src/Redux/Client/Selectors/selectReplyMessageId';
import selectRootState from '@messenger/core/src/Redux/selectRootState';
import {
	getMessageIdForSelector,
	selectMessageVMById,
} from '@messenger/core/src/Redux/Messages/Selectors/defaultSelectors';

export const selectReplyMessageVM = createSelector([selectRootState, selectReplyMessageId], (state, messageId) => {
	if (_.isNull(messageId)) {
		return undefined;
	}

	return selectMessageVMById(state, getMessageIdForSelector(messageId));
});
