import {AudioHTMLAttributes, DetailedHTMLProps, ImgHTMLAttributes, VideoHTMLAttributes} from 'react';
import {EnumMediaType} from 'cmd-control-client-lib';

export type TVideoProps = {
	isTranscoded?: boolean;
	isClickPropagationEnabled?: boolean;
	isLoadRequired?: boolean;
} & Pick<
	DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>,
	'src' | 'autoPlay' | 'controls' | 'muted' | 'disablePictureInPicture' | 'poster'
>;

export type TAudioProps = {duration?: string; isColorful?: boolean} & Pick<
	DetailedHTMLProps<AudioHTMLAttributes<HTMLAudioElement>, HTMLAudioElement>,
	'src'
>;

export type TImageProps = Pick<
	DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
	'src' | 'srcSet'
>;

export type TMediaProps =
	| ({
			type: EnumMediaType.BITMAP;
			file?: File;
	  } & TImageProps)
	| ({
			type: EnumMediaType.VIDEO;
	  } & TVideoProps)
	| ({
			type: EnumMediaType.AUDIO;
	  } & TAudioProps)
	| {
			type: EnumMediaType.TICKET;
	  };

export type TMediaPreviewInfo = {
	isProcessing?: boolean;
} & TMediaProps;

export enum EnumVideoFacingMode {
	user = 'user',
	environment = 'environment',
}
