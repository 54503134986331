import cachedViewModelFactory from '@messenger/core/src/Utils/cachedViewModelFactory';
import IChat from '@messenger/core/src/Redux/Chats/Model';
import {ChatViewModel} from '@messenger/core/src/Redux/Chats/ViewModel';
import ChannelVM from '@messenger/core/src/Redux/Channels/ChannelVM';

export const getChatVmCached = cachedViewModelFactory<
	IChat,
	ChatViewModel,
	[modelAvatar: string | undefined, channelVm: ChannelVM | undefined]
>(ChatViewModel);
