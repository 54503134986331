import {call, select} from 'typed-redux-saga';
import {isMobile} from 'react-device-detect';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {attachmentClientOnlyActions} from '@messenger/core/src/Redux/Attachment/Actions';
import {processSelectAttachmentSaga} from '@messenger/core/src/Redux/Attachment/Sagas/processSelectAttachmentSaga';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';
import {selectIsAudioPreviewCollapsed} from '@messenger/core/src/Redux/Attachment/Selectors/selectIsAudioPreviewCollapsed';

const processAddFileToAttachmentSaga = function* (
	action: ReturnType<typeof attachmentClientOnlyActions.addFileToAttachment>,
) {
	try {
		const hasMobileLayout = isMobile && ServiceFactory.env.isAppMobileEnabled();

		if (hasMobileLayout) {
			const {isChannelPage, goToChannelMessage, goToBulkMessage} = yield* getNavigationContext();
			const guestIdentity = yield* select(selectCurrentGuestIdentity);

			if (guestIdentity?.guestType === EnumGuestType.CHANNEL) {
				if (isChannelPage(guestIdentity.channelId)) {
					goToChannelMessage(guestIdentity.channelId);
				}
			} else if (guestIdentity?.guestType !== EnumGuestType.BULK) {
				goToBulkMessage();
			}
		}

		const isAudioPreviewCollapsed = yield* select(selectIsAudioPreviewCollapsed);

		yield* call(processSelectAttachmentSaga, {
			...action,
			payload: {...action.payload, isFromMediaPreview: !isAudioPreviewCollapsed || hasMobileLayout},
		});
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processAddFileToAttachmentSaga'});
	}
};

export default processAddFileToAttachmentSaga;
