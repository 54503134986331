import _ from 'lodash';

import {selectMessageVMs} from '@messenger/core/src/Redux/Messages/Selectors/defaultSelectors';
import {createSelector} from '@messenger/core/src/Utils/Redux';
import getPropsMessageKey from '@messenger/core/src/Redux/Messages/Selectors/getPropsMessageKey';
import {isTemporaryMessageId} from '@messenger/core/src/Redux/Messages/entityAdapter';

export const selectMessagesByMessageKey = createSelector(
	[selectMessageVMs, getPropsMessageKey],
	(messages, messageKey) =>
		_.chain(messages)
			.filter({messageKey})
			.reduce((result: {messageId: string; channelId?: string}[], message) => {
				if (
					!message.isSystem &&
					!_.isEmpty(message.messageId) &&
					!_.isEmpty(messageKey) &&
					!isTemporaryMessageId(message.messageId)
				) {
					result.push({messageId: message.messageId, channelId: message.channelId});
				}

				return result;
			}, [])
			.value(),
);
