import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {getChannelMediaKey, TChannelMedia} from '@messenger/core/src/Redux/ChannelMedia/entityAdapter';

enum ChannelMediaActions {
	ADD_CHANNEL_MEDIA = 'ADD_CHANNEL_MEDIA',
	DELETE_CHANNEL_MEDIA = 'DELETE_CHANNEL_MEDIA',
	RESET_STORE = 'RESET_STORE',
}

class ChannelMediaClientOnlyActions extends ClientOnlyActions<EnumStore.CHANNEL_MEDIA> {
	readonly scope = EnumStore.CHANNEL_MEDIA;

	addChannelMedia = this.createAction(ChannelMediaActions.ADD_CHANNEL_MEDIA, this.getPrepareAction<TChannelMedia[]>());

	deleteChannelMedia = this.createAction(
		ChannelMediaActions.DELETE_CHANNEL_MEDIA,
		({targetChannelIds, mediaMd5}: {targetChannelIds: string[]; mediaMd5: string}) => ({
			payload: targetChannelIds.map((channelId) => getChannelMediaKey(channelId, mediaMd5)),
		}),
	);

	resetStore = this.createAction(ChannelMediaActions.RESET_STORE);
}

export const channelMediaClientOnlyActions = new ChannelMediaClientOnlyActions();
