import {select, put} from 'typed-redux-saga';
import _ from 'lodash';

import {channelsClientToServerActions} from '@messenger/core/src/Redux/Channels/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import selectAllGuestChatIds from '@messenger/core/src/Redux/Chats/Selectors/selectAllGuestChatIds';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

export function* notifyIsTypingSaga() {
	try {
		const guestIdentity = yield* select(selectCurrentGuestIdentity);

		let payload: Parameters<typeof channelsClientToServerActions.notifyIsTyping>[0] | undefined = undefined;

		if (guestIdentity?.guestType === EnumGuestType.CHANNEL) {
			payload = {channelId: guestIdentity.channelId};
		} else {
			if (guestIdentity?.guestType === EnumGuestType.ALL) {
				const chatIds = yield* select(selectAllGuestChatIds);

				if (_.size(chatIds)) {
					payload = {chatIDs: _.join(chatIds, ',')};
				}
			} else if (guestIdentity?.guestType === EnumGuestType.CHAT) {
				payload = {chatIDs: guestIdentity.chatId};
			}
		}

		if (payload) {
			yield* put(channelsClientToServerActions.notifyIsTyping(payload));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'notifyIsTypingSaga'});
	}
}
