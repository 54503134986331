import {delay, put, race, select, take} from 'typed-redux-saga';
import _ from 'lodash';
import {AnyAction} from 'redux';

import {channelsClientOnlyActions, channelsServerToClientActions} from '@messenger/core/src/Redux/Channels/Actions';
import {selectChannelsMaps} from '@messenger/core/src/Redux/ChannelsMap/Selectors/defaultSelectors';
import {channelsMapClientOnlyActions} from '@messenger/core/src/Redux/ChannelsMap/Actions/channelsMapClientOnlyActions';
import {
	getChannelIdForSelector,
	selectChannelVMById,
} from '@messenger/core/src/Redux/Channels/Selectors/defaultSelectors';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

export const processChannelSelectionSaga = function* ({
	payload: {channelId},
}: ReturnType<typeof channelsMapClientOnlyActions.processChannelSelection>) {
	try {
		if (!channelId) {
			return;
		}

		const channelMap = yield* select(selectChannelsMaps);
		const channelMapItem = _.find(channelMap, {channelId});

		if (!_.isUndefined(channelMapItem)) {
			yield* put(
				channelsMapClientOnlyActions.setSelectedIndex({index: channelMapItem.id, type: EnumGuestType.CHANNEL}),
			);

			return;
		}

		const requestUuid = _.uniqueId();
		let channelVm = yield* select(selectChannelVMById, getChannelIdForSelector(channelId));

		if (!channelVm) {
			yield* put(channelsClientOnlyActions.requestChannels({channelIds: [channelId], requestUuid}));

			const [result] = yield* race([
				take(
					(action: AnyAction) =>
						channelsServerToClientActions.channelsReceived.type === action.type &&
						_.get(action, 'meta.params.requestUuid') === requestUuid,
				),
				delay(ServiceFactory.env.getFocusOnSelectedChannelTimeout()),
			]);

			if (!result) {
				return;
			}
		}

		channelVm = yield* select(selectChannelVMById, getChannelIdForSelector(channelId));

		if (channelVm) {
			yield* put(
				channelsMapClientOnlyActions.requestChannelsMapByWeight(
					{maxWeight: channelVm.weight},
					{selectChannelId: channelId},
				),
			);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processChannelSelectionSaga'});
	}
};
