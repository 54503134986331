import _ from 'lodash';
import {PayloadAction} from '@reduxjs/toolkit';
import {
	ACTION,
	chatIdType,
	CMDC_CMSG,
	CMDP_SQUERYSTARTSINGLEC2C_RESPONSE,
	SystemMessageKey,
} from 'cmd-control-client-lib';
import {call, delay, put, race, take} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';

import {
	createTakeForSystemMessageOnChannelWithKey,
	createTakePatternForStartPrivateChat,
} from './createTakePatternForStartPrivateChat';

export const stopPrivateTicketShowByTimerSaga = function* (
	action: PayloadAction<
		CMDP_SQUERYSTARTSINGLEC2C_RESPONSE['params'],
		ACTION.CMDP_SQUERYSTARTSINGLEC2C,
		any,
		string | boolean
	>,
) {
	const logService = ServiceFactory.logService;
	const i18n = ServiceFactory.i18n;

	try {
		if (action.error) {
			yield* call([logService, 'error'], {name: 'Failed to start private ticket show, skipping timer'} as Error);

			return;
		}

		const {started, rejected, serverTimeout} = yield* race({
			started: take<PayloadAction<chatIdType[]>>(createTakePatternForStartPrivateChat(action.payload.channelId)),
			rejected: take<PayloadAction<CMDC_CMSG['params'][]>>(
				createTakeForSystemMessageOnChannelWithKey(
					action.payload.channelId,
					SystemMessageKey.h_session_singlec2c_query_declined,
				),
			),
			serverTimeout: take<PayloadAction<CMDC_CMSG['params'][]>>(
				createTakeForSystemMessageOnChannelWithKey(
					action.payload.channelId,
					SystemMessageKey.h_session_singlec2c_query_timeout,
				),
			),
			// wait on client side a little longer to give server some time, just in case
			clientTimeout: delay(ServiceFactory.env.startPrivateTicketShowTimeout() * 2),
		});

		let reasonText = i18n.t('privateShow:start-timeout-reached');

		switch (true) {
			case !_.isUndefined(started):
				yield* put(streamClientOnlyActions.resetErrorAndMeta());

				// wait for guest to enable camera
				yield* take<PayloadAction<CMDC_CMSG['params'][]>>(
					createTakeForSystemMessageOnChannelWithKey(
						action.payload.channelId,
						SystemMessageKey.h_session_singlec2c_query_timeout,
					),
				);

				// open cam2cam section
				yield* put(clientClientOnlyActions.openCam2CamArea({chatId: _.get(started, ['payload', 0, 'chatID'])}));
				break;

			case !_.isUndefined(rejected):
				reasonText = _.chain(rejected?.payload)
					.find({messageKey: SystemMessageKey.h_session_singlec2c_query_declined})
					.get('text', i18n.t('privateShow:start-guest-rejected'))
					.value();
				break;

			case !_.isUndefined(serverTimeout):
				reasonText = _.chain(serverTimeout?.payload)
					.find({messageKey: SystemMessageKey.h_session_singlec2c_query_timeout})
					.get('text', i18n.t('privateShow:start-timeout-reached'))
					.value();
				break;
		}

		yield* put(streamClientOnlyActions.stopPrivateTicketShowWithError(reasonText));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'stopPrivateTicketShowByTimerSaga'});
	}
};
