import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import {TMediaPricesState} from '@messenger/core/src/Redux/MediaPrice/initialState';
import {selectMediaPricesState} from '@messenger/core/src/Redux/MediaPrice/Selectors/selectMediaPricesState';
import getPropsChannelId from '@messenger/core/src/Redux/Channels/Selectors/getPropsChannelId';

export const selectArePricesLoadingByChannelId = createSelector(
	[selectMediaPricesState, getPropsChannelId],
	(mediaPricesState: TMediaPricesState, channelId) =>
		!_.isUndefined(channelId) && !_.isUndefined(mediaPricesState.loading[channelId]),
);
