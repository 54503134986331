import {PayloadAction} from '@reduxjs/toolkit';
import {put, select} from 'typed-redux-saga';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {channelMediaClientToServerActions} from '@messenger/core/src/Redux/ChannelMedia/Actions';
import {selectAttachmentVMById} from '@messenger/core/src/Redux/Attachment/Selectors/selectAttachmentVMById';
import {selectTargetGroupIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetGroupIds';
import {selectTargetChannelIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetChannelIds';
import {selectTargetSystemGroupIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetSystemGroupIds';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

export const checkUploadedMediaTargetsChangesSaga = function* ({
	payload: {attachmentId},
}: PayloadAction<{attachmentId?: string}>) {
	try {
		const attachment = yield* select(selectAttachmentVMById, {attachmentId});
		const guestIdentity = yield* select(selectCurrentGuestIdentity);
		const mediaMd5 = attachment?.mediaMd5;

		if (guestIdentity?.guestType !== EnumGuestType.BULK || _.isUndefined(mediaMd5)) {
			return;
		}

		const targetChannelIds = yield* select(selectTargetChannelIds);
		const targetGroupIds = yield* select(selectTargetGroupIds);
		const targetSystemGroupIds = yield* select(selectTargetSystemGroupIds);

		if (!_.isEmpty(targetChannelIds) || !_.isEmpty(targetGroupIds) || !_.isEmpty(targetSystemGroupIds)) {
			yield* put(
				channelMediaClientToServerActions.checkUploadedMedia({
					targetChannelIds,
					mediaMd5,
				}),
			);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'checkUploadedMediaWhenTargetsChanges'});
	}
};
