import { ACTION, baseParamsType, ICOMMAND, IKeyMaybeValue, IRESPONSE, RESULT } from "../cmd-protocol";
import { channelIdType } from "./channel";
import { ChannelMediaLinkState, CMDC_CMEDIA, MayHaveChannelId } from "./media";
import { MediaMd5, MediaOffer, Ticket } from "./message";

type TicketOrMediaMd5 = Ticket | MediaMd5;

/**
 * Frontent command for offer media.
 * the Ticket.mediaMd5 should be not set, will be set by server.
 */
export class CMDP_SOPENMEDIAOFFER implements ICOMMAND {
  public action = ACTION.CMDP_SOPENMEDIAOFFER;
  public params: baseParamsType & channelIdType & TicketOrMediaMd5 & MediaOffer & { text?: string };
}

/**
 * Response command for CMDP_SOPENMEDIAOFFER.
 */
export class CMDP_SOPENMEDIAOFFER_RESPONSE extends CMDP_SOPENMEDIAOFFER implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}

/**
 * Frontend command for close of offer.
 */
export class CMDP_SCLOSEMEDIAOFFER implements ICOMMAND {
  public action = ACTION.CMDP_SCLOSEMEDIAOFFER;
  public params: baseParamsType &
    channelIdType & {
      messageId?: string;
    };
}

/**
 * Response command for CMDP_SCLOSEMEDIAOFFER.
 */
export class CMDP_SCLOSEMEDIAOFFER_RESPONSE extends CMDP_SCLOSEMEDIAOFFER implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}

/** get opened tickets for channels */
export class CMDP_SGETTICKETS implements ICOMMAND {
  public action = ACTION.CMDP_SGETTICKETS;
  /** channelId is comaseparated channelId list */
  public params: baseParamsType &
    MayHaveChannelId & {
      linkState?: ChannelMediaLinkState;
    };
}

/**
 * Response command for CMDP_SGETTICKETS.
 */
export class CMDP_SGETTICKETS_RESPONSE extends CMDP_SGETTICKETS implements IRESPONSE {
  public result: RESULT;
  /** the ticketinfos per channel */
  public commands: CMDC_CMEDIA[];
  public values: IKeyMaybeValue;
}

/**
 * Frontend command for close of ticket.
 */
export class CMDP_SCLOSETICKET implements ICOMMAND {
  public action = ACTION.CMDP_SCLOSETICKET;
  public params: baseParamsType & channelIdType;
}

/**
 * Response command for CMDP_SCLOSETICKED.
 */
export class CMDP_SCLOSETICKET_RESPONSE extends CMDP_SCLOSETICKET implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}
