import React from 'react';
import {put, select, take} from 'typed-redux-saga';

import notificationsClientOnlyActions from '@messenger/core/src/Redux/Notifications/Actions/notificationsClientOnlyActions';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';
import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectIsLoggedIn} from '@messenger/core/src/Redux/Session/Selectors/selectIsLoggedIn';
import EnumSnackbarNotificationKeys from '@messenger/core/src/BusinessLogic/EnumSnackbarNotificationKeys';

import EnableSoundsContentContainer from 'src/Components/Notifications/EnableSounds/EnableSoundsContentContainer';

/**
 * @link https://codepen.io/kslstn/pen/pagLqL
 */
export const showEnableSoundsSnackbarSaga = function* () {
	const isLoggedIn = yield* select(selectIsLoggedIn);

	if (!isLoggedIn) {
		return;
	}

	// wait for chat screen to render
	yield* take(clientClientOnlyActions.notifyChatScreenRendered);

	yield* put(
		notificationsClientOnlyActions.enqueuePersistent(
			'', // @note this message will not be used
			EnumAbstractNotificationVariant.INFO,
			{
				key: EnumSnackbarNotificationKeys.ENABLE_SOUND_NOTIFICATIONS_KEY,
				content: (
					<EnableSoundsContentContainer
						browser={ServiceFactory.uiContainer}
						notificationKey={EnumSnackbarNotificationKeys.ENABLE_SOUND_NOTIFICATIONS_KEY}
					/>
				),
			},
		),
	);
};
