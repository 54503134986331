import moment from 'moment';
import {END, eventChannel} from 'redux-saga';
import {call, put, select, spawn, take} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import ActionCreator from '@messenger/core/src/Redux/SpeedTest/ActionCreator';
import ProcessTestRequest from '@messenger/core/src/Redux/SpeedTest/Sagas/ProcessTestRequestSaga';
import {
	selectSpeedTestMeasured,
	selectSpeedTestStartTime,
	selectSpeedTestTime,
} from '@messenger/core/src/Redux/SpeedTest/Selectors';

/**
 * @link https://stackoverflow.com/a/38574266/1847769
 * @link https://redux-saga.js.org/docs/advanced/Channels.html
 */
const startSpeedTestSaga = function* () {
	try {
		const started = yield* select(selectSpeedTestStartTime);
		const seconds = yield* select(selectSpeedTestTime);
		const channel = yield* call(countdown, seconds, started);

		while (true) {
			// take(END) will cause the saga to terminate by jumping to the finally block
			const remainingMs = yield* take(channel);

			yield* spawn(ProcessTestRequest, remainingMs);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'SaveTestMeta'});
	} finally {
		// mark complete
		const measured = yield* select(selectSpeedTestMeasured);

		yield* put(ActionCreator.markProgress(100, measured));
		yield* put(ActionCreator.markEnd());
	}
};

function countdown(secs: number, start: Date) {
	const end = moment(start).add(secs, 'seconds');

	return eventChannel<number | END>((emitter) => {
		const iv = setInterval(() => {
			const now = moment();

			if (now < end) {
				emitter(end.diff(now, 'milliseconds'));
			} else {
				emitter(END); // terminate
			}
		}, ServiceFactory.env.getSpeedTestInterval());

		// The subscriber must return an unsubscribe function
		return () => clearInterval(iv);
	});
}

export default startSpeedTestSaga;
