import _ from 'lodash';
import {createSlice, EntityState} from '@reduxjs/toolkit';
import {CMDC_CONTACTNOTE} from 'cmd-control-client-lib';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

import {contactNoteAdapter} from './entityAdapter';
import {contactNoteClientOnlyActions} from './Actions/contactNoteClientOnlyActions';
import {contactNoteClientToServerActions} from './Actions/contactNoteClientToServerAction';

export const initialState: TContactNoteState = contactNoteAdapter.getInitialState({
	isLoading: false,
	isError: false,
	savingIds: [],
});

export const contactInfoNote = createSlice({
	name: EnumStore.CONTACT_NOTE,
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(contactNoteClientOnlyActions.resetStore, () => initialState);
		builder.addCase(contactNoteClientOnlyActions.upsertMany, (state, action) => {
			return contactNoteAdapter.upsertMany(
				{
					...state,
					savingIds: _.differenceWith(
						state.savingIds,
						action.payload,
						(savingId, {channelId}) => savingId === channelId,
					),
				},
				action.payload,
			);
		});
		builder.addCase(contactNoteClientOnlyActions.addMany, contactNoteAdapter.addMany);
		builder.addCase(contactNoteClientToServerActions.saveContactNote, (state, action) =>
			contactNoteAdapter.upsertOne(
				{
					...state,
					savingIds: _.union(state.savingIds, [action.payload.channelId]),
				},
				action.payload,
			),
		);
	},
});

export type TContactNoteState = EntityState<CMDC_CONTACTNOTE['params']> & {
	isLoading: boolean;
	isError: boolean;
	savingIds: string[];
};
