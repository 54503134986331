import {createSlice} from '@reduxjs/toolkit';
import _ from 'lodash';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {mediaPricesClientToServerActions} from '@messenger/core/src/Redux/MediaPrice/Actions/mediaPricesClientToServerActions';
import {mediaPricesServerToClientActions} from '@messenger/core/src/Redux/MediaPrice/Actions/mediaPricesServerToClientActions';
import {mediaPricesAdapter} from '@messenger/core/src/Redux/MediaPrice/entityAdapter';
import {mediaPricesInitialState} from '@messenger/core/src/Redux/MediaPrice/initialState';

export const mediaPricesSlice = createSlice({
	name: EnumStore.MEDIA_PRICES,
	initialState: mediaPricesInitialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(
			mediaPricesClientToServerActions.getByTargetChannelIds,
			(state, {payload: {channelId: targetChannelIds}}) => {
				if (_.isEmpty(targetChannelIds)) {
					state.isDefaultPricesLoading = true;
				} else {
					_.forEach(targetChannelIds.split(','), (channelId) => (state.loading[channelId] = true));
				}
			},
		);
		builder.addCase(mediaPricesServerToClientActions.receivedByChannelId, (state, action) => {
			if (action.error) {
				_.forEach(action.payload.channelIds, (channelId: string) => {
					state.errors[channelId] = action.error;
					state.loading[channelId] = false;
				});
				state.defaultPricesError = action.payload.defaultPricesError;
				state.isDefaultPricesLoading = false;

				return;
			}

			if (action.payload.defaultPrices) {
				state.defaultPrices = action.payload.defaultPrices;
				state.isDefaultPricesLoading = false;
			} else {
				_.forEach(action.payload.channelIds, (channelId: string) => {
					state.loading[channelId] = false;
				});
			}

			if (!_.isUndefined(action.payload.prices)) {
				mediaPricesAdapter.upsertMany(state, action.payload.prices);
			}
		});
	},
});
