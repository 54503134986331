import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import getPropsChannelId from '@messenger/core/src/Redux/Channels/Selectors/getPropsChannelId';
import {selectTypingChannels} from '@messenger/core/src/Redux/Channels/Selectors/selectTypingChannels';

export const selectIsChannelTypingById = createSelector(
	[selectTypingChannels, getPropsChannelId],
	(typingChannels, channelId) => !!channelId && _.includes(typingChannels, channelId),
);
