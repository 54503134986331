import _ from 'lodash';
import {PayloadAction} from '@reduxjs/toolkit';
import {call, put} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {TStreamConnectOptions} from '@messenger/core/src/Redux/Stream/Model';
import {mediaDeviceClientOnlyActions} from '@messenger/core/src/Redux/MediaDevice/Actions';
import {dynamicImport} from '@messenger/core/src/Utils/dynamicImport';

function* detectSupportedMediaCodecsSaga(action: PayloadAction<TStreamConnectOptions>) {
	try {
		if (_.isUndefined(action.payload.webRTCConfig)) {
			return;
		}

		const {browserSupportedAudioCodec, browserSupportedVideoCodec} = yield* call(() =>
			dynamicImport(() => import('@campoint/vxwebrtc')),
		);

		yield* put(
			mediaDeviceClientOnlyActions.setSupportedCodecs({
				audio: browserSupportedAudioCodec(action.payload.webRTCConfig.audioCodecs),
				video: browserSupportedVideoCodec(action.payload.webRTCConfig.videoCodecs),
			}),
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'detectSupportedMediaCodecsSaga', action});
	}
}

export default detectSupportedMediaCodecsSaga;
