import {put} from 'typed-redux-saga';
import _ from 'lodash';
import {EnumMediaType, EnumMessageDirection} from 'cmd-control-client-lib';

import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import {channelMediaClientToServerActions} from '@messenger/core/src/Redux/ChannelMedia/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

export const updateMediaChannelAfterMediaUpload = function* ({
	payload,
}: ReturnType<typeof messagesClientOnlyActions.setMany>) {
	try {
		const actions = _.chain(payload)
			.filter(
				({mediaType, channelId, mediaMd5, direction}) =>
					_.includes([EnumMediaType.BITMAP, EnumMediaType.VIDEO], mediaType) &&
					!_.isEmpty(channelId) &&
					!_.isEmpty(mediaMd5) &&
					direction === EnumMessageDirection.OUT,
			)
			.groupBy('mediaMd5')
			.mapValues((messages) => _.chain(messages).map('channelId').compact().uniq().value())
			.omitBy(_.isEmpty)
			.toPairs()
			.map(([mediaMd5, channelIds = []]) =>
				channelMediaClientToServerActions.checkUploadedMedia({
					targetChannelIds: channelIds,
					mediaMd5: mediaMd5,
				}),
			)
			.value();

		for (const action of actions) {
			yield* put(action);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'updateMediaChannelAfterMediaUpload'});
	}
};

export default updateMediaChannelAfterMediaUpload;
