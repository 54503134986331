import {createSelector} from '@reduxjs/toolkit';
import _ from 'lodash';

import {selectAllTicketShowEntitiesVMs} from '@messenger/core/src/Redux/TicketShow/Selectors/selectAllTicketShowEntitiesVMs';
import {_selectTicketIds} from '@messenger/core/src/Redux/TicketShow/Selectors/defaultSelectors';

export const selectAllTicketShowVMs = createSelector(
	[selectAllTicketShowEntitiesVMs, _selectTicketIds],
	(ticketShowEntityVMs, ticketShowIds) =>
		_.compact(_.map(ticketShowIds, (ticketShowId) => ticketShowEntityVMs[ticketShowId])),
);
