import {put, race, select, take} from 'typed-redux-saga';
import {ACTION} from 'cmd-control-client-lib';
import _ from 'lodash';
import {AnyAction} from 'redux';
import {batchActions} from 'redux-batched-actions';

import selectGuestTypeFilter from '@messenger/core/src/Redux/Client/Selectors/GuestTypeFilter/selectGuestTypeFilter';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';
import {channelsClientToServerActions, channelsServerToClientActions} from '@messenger/core/src/Redux/Channels/Actions';
import selectChannelsFilter from '@messenger/core/src/Redux/Channels/Selectors/selectChannelsFilter';
import selectRunningChatsChannelIds from '@messenger/core/src/Redux/Chats/Selectors/selectRunningChatsChannelIds';
import {channelsMapClientOnlyActions} from '@messenger/core/src/Redux/ChannelsMap/Actions/channelsMapClientOnlyActions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectChannelsMeta} from '@messenger/core/src/Redux/ChannelsMap/Selectors/selectChannelsMeta';
import {TChannelsMap} from '@messenger/core/src/Redux/ChannelsMap/types';

import {resetChannelsMapActions} from 'src/Redux/ChannelsMap/channelsMapSagaWatcher';

export const requestChannelsMapSaga = function* ({
	payload,
	meta,
}: ReturnType<
	| typeof channelsMapClientOnlyActions.requestChannelsMap
	| typeof channelsMapClientOnlyActions.requestChannelsMapByWeight
>) {
	try {
		yield* put(channelsMapClientOnlyActions.increaseLoadingCounter());
		const filter = yield* select(selectChannelsFilter);

		let filterExcludeChannelId: string[] = [];
		const guestTypeFilter = yield* select(selectGuestTypeFilter);

		const requestUuid = _.uniqueId();

		if (guestTypeFilter !== EnumGuestType.CHANNEL) {
			const runningChatsChannelIds = yield* select(selectRunningChatsChannelIds);

			filterExcludeChannelId = _.union(filterExcludeChannelId, runningChatsChannelIds);
		}

		yield* put(
			channelsClientToServerActions.requestChannelsByFilter({
				...filter,
				...payload,
				filterExcludeChannelId: _.join(filterExcludeChannelId),
				requestUuid,
			}),
		);

		const [result] = yield* race([
			take(
				(action: AnyAction) =>
					channelsServerToClientActions.channelsReceived.type === action.type &&
					_.get(action, 'meta.params.requestUuid') === requestUuid,
			),
			..._.map(resetChannelsMapActions, (action) => take(action)),
		]);

		if (!result) {
			yield* put(channelsMapClientOnlyActions.decreaseLoadingCounter());

			return;
		}

		const commands = _.get(result, 'meta.commands');
		const channelTotal = _.get(result, 'meta.values.channelTotal');

		const actions: AnyAction[] = [];

		const channelsMapItems: TChannelsMap[] = [];
		const channelsMeta = {...(yield* select(selectChannelsMeta))};

		_.forEach(commands, (command) => {
			if (command.action === ACTION.CMDC_CHANNEL) {
				const channelId = String(_.get(command, 'params.channelId'));
				const weight = _.get(command, 'params.weight', '');

				channelsMapItems.push({
					id: parseInt(_.get(command, 'params.rowNumber'), 10) - 1,
					channelId,
					weight,
				});
				channelsMeta[channelId] = {
					weight,
					isFiltered: true,
				};
			}
		});

		if (!_.isUndefined(channelTotal) || _.get(payload, 'skip') === '0') {
			actions.push(
				channelsMapClientOnlyActions.setTotal({
					total: !_.isUndefined(channelTotal) ? parseInt(channelTotal, 10) : _.size(channelsMapItems),
				}),
			);
		}

		actions.push(
			channelsMapClientOnlyActions.upsertMany(channelsMapItems),
			channelsMapClientOnlyActions.setChannelsMeta({channelsMeta: {...channelsMeta}}),
		);

		yield* put(batchActions(actions));

		const selectChannelId = _.get(meta, 'selectChannelId');

		if (selectChannelId) {
			const guestMapItem = _.find(channelsMapItems, {channelId: selectChannelId});

			if (guestMapItem) {
				yield* put(
					channelsMapClientOnlyActions.setSelectedIndex({index: guestMapItem.id, type: EnumGuestType.CHANNEL}),
				);
			}
		}

		yield* put(channelsMapClientOnlyActions.decreaseLoadingCounter());
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'requestChannelsMapSaga'});
	}
};
