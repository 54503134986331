import {call, select} from 'typed-redux-saga';

import {selectMediaUploadUrl} from '@messenger/core/src/Redux/Media/Selectors/selectMediaUploadUrl';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import selectSessionId from '@messenger/core/src/Redux/Session/Selectors/selectSessionId';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import {selectUploadMediaTusConfig} from '@messenger/core/src/Redux/Session/Selectors/selectUploadMediaTusConfig';

export function* sendMediaSaga({
	payload: {message, attachment},
}: ReturnType<typeof messagesClientOnlyActions.sendMessageWithMediaUpload>) {
	const {file} = attachment;

	try {
		const sessionId = yield* select(selectSessionId);
		const uploadUrl = yield* select(selectMediaUploadUrl);
		const uploadMediaTusConfig = yield* select(selectUploadMediaTusConfig);

		if (!file || !sessionId || !uploadUrl) {
			return;
		}

		const vControlApi = ServiceFactory.getVControlApi();

		yield* call(
			[vControlApi, vControlApi.sendMedia],
			uploadUrl,
			file,
			{
				...message,
				sessionID: sessionId,
			},
			uploadMediaTusConfig,
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'saveMediaSaga'});
	}
}
