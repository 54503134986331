import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux/createSelector';
import {selectAllNotifications} from '@messenger/core/src/Redux/Notifications/Selectors/defaultSelectors';

const getProps = (_: any, props: {key: string}) => props;

export const selectActiveNotificationsByChannelIdCount = createSelector(
	[selectAllNotifications, getProps],
	(notifications, props) => _.chain(notifications).filter({key: props.key}).size().value(),
);
