import React, {useCallback, useEffect, useRef, useState, useDeferredValue, memo, FC, RefObject} from 'react';
import _ from 'lodash';
import {GroupedVirtuosoHandle, TopItemListProps} from 'react-virtuoso';
import {Grid, Tooltip} from '@mui/material';
import classNames from 'classnames';
import {useTranslation} from 'react-i18next';
import {isMobile} from 'react-device-detect';

import PropertyHandler from '@messenger/core/src/Types/PropertyHandler';
import MessageGroupTitle from '@messenger/uikit/src/Messages/MessageGroupTitle/MessageGroupTitle';
import {useDebounced} from '@messenger/core/src/Hooks/useDebounced';
import ScrollToIconButton from '@messenger/uikit/src/IconButton/ScrollToIconButton/ScrollToIconButton';
import EnumChatPageTestIds from '@messenger/uikit/src/TestIds/EnumChatPageTestIds';
import {EmptyVirtuosoItem, GroupedVirtuosoWrapper} from '@messenger/uikit/src/VirtuosoWrapper/VirtuosoWrapper';
import {
	INITIAL_FIRST_INDEX,
	useMessagesHistoryConfig,
} from '@messenger/uikit/src/MessagesHistory/useMessagesHistoryConfig';
import {getObjectStoreServiceRef} from '@messenger/core/src/Services/ObjectStore/getObjectStoreServiceRef';
import {ObjectStoreService} from '@messenger/core/src/Services/ObjectStore/ObjectStoreService';
import {useKeepScrollBottomOnResize} from '@messenger/uikit/src/MessagesHistory/useKeepScrollBottomOnResize';

import {EnumScrollToUnseenBehavior, TMessageComponentProps} from './types';
import style from './MessagesHistory.module.scss';

export const getMessageHistoryRefId = (subjectId: string) => `MESSAGE_HISTORY_REF_ID_${subjectId}`;

const MessagesHistory = ({
	subjectId,
	groupedMessageIds,
	firstUnseenMessageId,
	unseenMessagesCount = 0,
	isLiveChat,
	isShowReactions,
	isShowHeaderMessage,
	onRequestHistory,
	onMarkSeen,
	onVirtuosoChangeFirstItemIndex,
	scrollToUnseenBehavior = EnumScrollToUnseenBehavior.lastUnseen,
	MessageComponent,
}: TMessagesHistoryProps) => {
	const {t} = useTranslation();
	const [isScrolling, setIsScrolling] = useState(false);
	const [isAtBottom, setIsAtBottom] = useState(true);
	const [isAtTop, setIsAtTop] = useState(false);
	const scrollerRef = useRef<HTMLElement | null>(null);

	const {messageIds, groupCounts, groupKeys, inGroupPositions, messagesCount, firstMessageIndex, lastMessageId} =
		useMessagesHistoryConfig(groupedMessageIds);

	const firstMessageIndexDeferred = useDeferredValue(firstMessageIndex);

	useEffect(() => {
		if (!lastMessageId) {
			return;
		}

		const {current: scrollerEl} = scrollerRef;

		if (scrollerEl) {
			const offsetBottom =
				_.get(scrollerEl, 'scrollHeight') - _.get(scrollerEl, 'scrollTop') - _.get(scrollerEl, 'offsetHeight');

			if (!isNaN(offsetBottom) && offsetBottom >= 0 && offsetBottom < OFFSET_THRESHOLD_BOTTOM) {
				const virtuosoHandle = ObjectStoreService.get<GroupedVirtuosoHandle>(getMessageHistoryRefId(subjectId));

				virtuosoHandle?.scrollToIndex({index: 'LAST'});
			}
		}
	}, [subjectId, lastMessageId]);

	useEffect(() => {
		if (onVirtuosoChangeFirstItemIndex && firstMessageIndexDeferred !== INITIAL_FIRST_INDEX) {
			onVirtuosoChangeFirstItemIndex(subjectId);
		}
	}, [subjectId, firstMessageIndexDeferred, onVirtuosoChangeFirstItemIndex]);

	const scrollToUnseenMessage = useCallback(() => {
		const virtuosoHandle = ObjectStoreService.get<GroupedVirtuosoHandle>(getMessageHistoryRefId(subjectId));

		if (scrollToUnseenBehavior === EnumScrollToUnseenBehavior.lastUnseen || unseenMessagesCount <= 1) {
			virtuosoHandle?.scrollToIndex({index: 'LAST'});
		} else {
			const messageIndex = _.indexOf(messageIds, firstUnseenMessageId);

			virtuosoHandle?.scrollToIndex({
				index: messageIndex,
				align: 'end',
			});
		}
	}, [subjectId, messageIds, unseenMessagesCount, scrollToUnseenBehavior, firstUnseenMessageId]);

	const onMessageInView = useCallback(
		(messageId: string) => {
			if (onMarkSeen && unseenMessagesCount) {
				const messageIndex = _.indexOf(messageIds, messageId);

				switch (scrollToUnseenBehavior) {
					case EnumScrollToUnseenBehavior.lastUnseen:
						if (messageIndex === messagesCount - 1) {
							onMarkSeen(messageId);
						}

						break;

					case EnumScrollToUnseenBehavior.firstUnseen:
						if (messageIndex >= _.indexOf(messageIds, firstUnseenMessageId)) {
							onMarkSeen(messageId);
						}

						break;
				}
			}
		},
		[messageIds, messagesCount, unseenMessagesCount, onMarkSeen, scrollToUnseenBehavior, firstUnseenMessageId],
	);

	useKeepScrollBottomOnResize(scrollerRef as RefObject<HTMLElement>, isAtBottom);

	return (
		<Grid
			container
			item
			flex={1}
			flexDirection="column"
			alignItems="stretch"
			className={classNames(style.container, {[style.isAtTop]: isAtTop})}
		>
			<GroupedVirtuosoWrapper
				data-test-id={EnumChatPageTestIds.MESSAGES_CONTAINER}
				followOutput
				isScrolling={useDebounced((isScrolling: boolean) => {
					const {current: scrollerEl} = scrollerRef;

					if (scrollerEl) {
						const scrollTop = _.get(scrollerEl, 'scrollTop');

						if (!isNaN(scrollTop)) {
							setIsAtTop(scrollTop < OFFSET_THRESHOLD_TOP);

							const offsetBottom =
								_.get(scrollerEl, 'scrollHeight') - _.get(scrollerEl, 'scrollTop') - _.get(scrollerEl, 'offsetHeight');

							if (!isNaN(offsetBottom)) {
								setIsAtBottom(offsetBottom < OFFSET_THRESHOLD_BOTTOM);
							}
						}
					}

					setIsScrolling(isScrolling);
				}, SCROLLING_DEBOUNCE_TIME)}
				increaseViewportBy={600}
				startReached={onRequestHistory}
				initialTopMostItemIndex={INITIAL_FIRST_INDEX * 2}
				alignToBottom={true}
				firstItemIndex={firstMessageIndex}
				groupCounts={groupCounts}
				scrollerRef={(sRef) => {
					if (!(sRef instanceof HTMLElement)) {
						return;
					}

					scrollerRef.current = sRef;
				}}
				virtuosoRef={getObjectStoreServiceRef(getMessageHistoryRefId(subjectId))}
				components={{
					TopItemList,
				}}
				topItemCount={0}
				groupContent={(index) =>
					!isLiveChat ? <MessageGroupTitle date={_.get(groupKeys, index)} /> : <EmptyVirtuosoItem />
				}
				defaultItemHeight={isMobile ? 30 : 160}
				itemContent={(index) => {
					const messageIndex = index - firstMessageIndex;
					const messageId = _.get(messageIds, messageIndex);

					return messageId ? (
						<MessageComponent
							messageId={messageId}
							isLiveChat={isLiveChat}
							isShowReactions={isShowReactions}
							isShowHeaderMessage={isShowHeaderMessage}
							inGroupPosition={inGroupPositions[messageId]}
							onMessageInView={onMessageInView}
						/>
					) : (
						<EmptyVirtuosoItem />
					);
				}}
			/>
			{(!isAtBottom || (isLiveChat && unseenMessagesCount > 0)) && !isScrolling && (
				<Tooltip title={`${t('messages:go-to-recent')}`} placement="left">
					<ScrollToIconButton
						size="medium"
						onClick={scrollToUnseenMessage}
						className={classNames(style.scrollToBottom, {[style.isBottom]: isLiveChat})}
						numberMessages={unseenMessagesCount}
					/>
				</Tooltip>
			)}
		</Grid>
	);
};

const SCROLLING_DEBOUNCE_TIME = 200;
const OFFSET_THRESHOLD_TOP = 10;
const OFFSET_THRESHOLD_BOTTOM = 120;

const TopItemList = React.forwardRef<HTMLDivElement, TopItemListProps>((props, ref) => {
	return <div className={style.groupTitle} {...props} ref={ref} />;
});

type TMessagesHistoryProps = {
	subjectId: string;
	groupedMessageIds: {[id: string]: string[][]};
	firstUnseenMessageId?: string;
	unseenMessagesCount?: number;
	isLiveChat?: boolean;
	isShowReactions?: boolean;
	isShowHeaderMessage?: boolean;
	MessageComponent: FC<TMessageComponentProps>;
	scrollToUnseenBehavior?: EnumScrollToUnseenBehavior;

	onRequestHistory: PropertyHandler;
	onMarkSeen?: PropertyHandler<string>;
	onVirtuosoChangeFirstItemIndex?: PropertyHandler<string>;
	navigateToMessage?: PropertyHandler<string, string>;
};

export default memo(MessagesHistory);
