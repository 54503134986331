import _ from 'lodash';
import {ParametricSelector} from '@reduxjs/toolkit';

import IRootState from '@messenger/core/src/Redux/IRootState';

const getPropsAttachmentId: ParametricSelector<IRootState, {attachmentId?: string}, string | undefined> = (
	_state: IRootState,
	props?: {attachmentId?: string},
) => (_.isUndefined(props) ? undefined : props.attachmentId);

export default getPropsAttachmentId;
