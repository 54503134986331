import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

import {channelsMapEntityAdapter} from './channelsMapEntityAdapter';
import {TChannelsMeta, TChannelsRange} from './types';

export const DEFAULT_CHANNELS_MAP_TOTAL = 25;

export const channelsMapInitialState = channelsMapEntityAdapter.getInitialState<{
	loadingCounter: number;
	currentRange?: TChannelsRange;
	previousRange?: TChannelsRange;
	selectedIndex?: {index: number; type: EnumGuestType};
	total?: number;
	channelsMeta: TChannelsMeta;
	updateUuids: string[];
}>({
	loadingCounter: 0,
	channelsMeta: {},
	updateUuids: [],
});
