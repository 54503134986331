import {delay, put} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {ticketShowServerToClientActions} from '@messenger/core/src/Redux/TicketShow/Actions/ticketShowServerToClientActions';
import {ticketShowClientOnlyActions} from '@messenger/core/src/Redux/TicketShow/Actions/ticketShowClientOnlyActions';

export const NOTIFICATION_DISAPPEAR_AFTER = 10000;

const notifyOnTicketEditedSaga = function* (
	action: ReturnType<typeof ticketShowServerToClientActions.ticketShowEdited>,
) {
	try {
		if (action.error) {
			return;
		}

		yield* delay(NOTIFICATION_DISAPPEAR_AFTER);
		yield* put(ticketShowClientOnlyActions.removeNotificationFromList());
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'notifyOnTicketEditedSaga'});
	}
};

export default notifyOnTicketEditedSaga;
