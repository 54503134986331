import {createSelector} from '@reduxjs/toolkit';
import _ from 'lodash';

import {selectChatSummaryEntitiesVMs} from '@messenger/core/src/Redux/ChatSummary/Selectors/selectChatSummaryEntitiesVMs';
import getPropsChannelId from '@messenger/core/src/Redux/Channels/Selectors/getPropsChannelId';

export const selectChatSummaryVMById = createSelector(
	[selectChatSummaryEntitiesVMs, getPropsChannelId],
	(chatSummaryEntitiesVMs, channelId) => _.get(chatSummaryEntitiesVMs, String(channelId)),
);
