import _ from 'lodash';
import {
	ACTION,
	baseParamsType,
	CMDP_SGETMESSAGEHISTORY,
	EnumBooleanStringified,
	EnumBooleanStringifiedExtended,
	EnumLimitType,
	EnumMessageType,
	EnumSortOrder,
	getHistoryParamsType,
	MediaMd5,
	MessageParams,
	CMDP_SMSGREACTION,
} from 'cmd-control-client-lib';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import getMessageKey from '@messenger/core/src/Utils/Messages/getMessageKey';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {ALL_CHATS} from '@messenger/core/src/BusinessLogic/Constants';

export const MESSAGE_HISTORY_SUBJECT_ID = 'messageHistorySubjectId';

export enum EnumMessageHistorySubject {
	CHAT,
	CHANNEL,
	LIVE_SESSION,
}

enum EnumActionAliasesActions {
	SET_REACTION = 'SET_REACTION',
	RESET_REACTION = 'RESET_REACTION',
	REQUEST_MESSAGES_HISTORY = 'REQUEST_MESSAGES_HISTORY',
}

class MessagesClientToServerActions extends ClientToServerActions<EnumStore.MESSAGES> {
	readonly scope = EnumStore.MESSAGES;

	protected payloadSplit = <PayloadData extends TPayloadParams>(
		payload: PayloadData,
		options?: TSendMessageOptions,
	) => ({
		payload: {
			...payload,
			..._.omitBy(options, _.isUndefined),
		},
	});

	setReaction = this.createActionWithAlias(
		ACTION.CMDP_SMSGREACTION,
		EnumActionAliasesActions.SET_REACTION,
		this.getPrepareAction<Omit<CMDP_SMSGREACTION['params'], 'reset'>>(),
	);

	resetReaction = this.createActionWithAlias(
		ACTION.CMDP_SMSGREACTION,
		EnumActionAliasesActions.RESET_REACTION,
		({channelId, messageId}: Pick<CMDP_SMSGREACTION['params'], 'channelId' | 'messageId'>) => ({
			payload: {
				channelId,
				messageId,
				reset: EnumBooleanStringified.TRUE,
			},
		}),
	);

	sendMessage = this.createAction(
		ACTION.CMDP_MSG,
		(
			param: {channelId: string} | {chatIDs: string} | {selectGroupId: string},
			text: string,
			options?: TSendMessageOptions,
		) => {
			const payloadData = {
				...param,
				text,
				messageKey: getMessageKey(),
			};

			return this.payloadSplit<typeof payloadData>(payloadData, options);
		},
	);

	deleteMessage = this.createAction(ACTION.CMDP_MSGDELETE, (channelId: string | undefined, messageId: string) => ({
		payload: {
			channelId,
			messageId,
		},
	}));

	requestHistory = this.createAction(ACTION.CMDP_SGETMESSAGEHISTORY, (requestParams: TRequestHistoryPayload) => {
		let payload: CMDP_SGETMESSAGEHISTORY['params'];

		if (!_.isUndefined(requestParams.chatIDs)) {
			payload = {
				chatIDs: _.join(requestParams.chatIDs, ','),
				sort: requestParams.sort ?? EnumSortOrder.DESC,
				keys: requestParams.keys ?? undefined,
				searchAfterTime: !_.isUndefined(requestParams.searchAfterTime)
					? _.toString(requestParams.searchAfterTime)
					: undefined,
				searchAfterId: !_.isUndefined(requestParams.searchAfterId) ? requestParams.searchAfterId : undefined,
				limit: !_.isUndefined(requestParams.limit) ? _.toString(requestParams.limit) : undefined,
			};
		} else {
			const filterMessageTypeConditionChannelId = _.isUndefined(requestParams.channelId)
				? // Show only CHAT and SYSTEM messages in "ALL MESSAGES"
				  _.join([EnumMessageType.CHAT, EnumMessageType.SYSTEM], ',')
				: undefined;

			payload = {
				sort: requestParams.sort ?? EnumSortOrder.DESC,
				limitType: _.isUndefined(requestParams.channelId) ? EnumLimitType.TOTAL : EnumLimitType.separately,
				limit: !_.isUndefined(requestParams.limit)
					? _.toString(requestParams.limit)
					: ServiceFactory.env.getMessagesHistoryPageSize().toString(),
				keys: requestParams.keys ?? EnumBooleanStringifiedExtended.TRUE,
				channelId: requestParams.channelId,
				searchAfterTime: !_.isUndefined(requestParams.searchAfterTime)
					? _.toString(requestParams.searchAfterTime)
					: undefined,
				searchAfterId: !_.isUndefined(requestParams.searchAfterId) ? requestParams.searchAfterId : undefined,
				filterMessageType: !_.isUndefined(requestParams.filterMessageType)
					? _.join(requestParams.filterMessageType, ',')
					: filterMessageTypeConditionChannelId,
			};
		}

		return {
			payload,
		};
	});

	requestMessagesHistory = this.createActionWithAlias(
		ACTION.CMDP_SGETMESSAGEHISTORY,
		EnumActionAliasesActions.REQUEST_MESSAGES_HISTORY,
		({
			subjectType,
			subjectId,
			searchAfter,
			liveSessionChatIds,
		}: {
			subjectType: EnumMessageHistorySubject;
			subjectId: string;
			searchAfter?: {searchAfterTime?: number; searchAfterId?: string};
			liveSessionChatIds?: string[];
		}) => {
			const commonProps = {
				[MESSAGE_HISTORY_SUBJECT_ID]: subjectId,
				sort: EnumSortOrder.DESC,
				keys: EnumBooleanStringifiedExtended.TRUE,
				limit: ServiceFactory.env.getMessagesHistoryPageSize(),
				limitType: EnumLimitType.TOTAL,
				searchAfterTime: !_.isUndefined(searchAfter?.searchAfterTime)
					? _.toString(searchAfter?.searchAfterTime)
					: undefined,
				searchAfterId: !_.isUndefined(searchAfter?.searchAfterId) ? searchAfter?.searchAfterId : undefined,
			};

			switch (subjectType) {
				case EnumMessageHistorySubject.CHANNEL:
					return {
						payload: {
							...commonProps,
							channelId: subjectId,
						},
					};

				case EnumMessageHistorySubject.CHAT:
					return {
						payload: {
							...commonProps,
							chatIDs: subjectId === ALL_CHATS ? undefined : subjectId,
							filterMessageType:
								subjectId === ALL_CHATS ? _.join([EnumMessageType.CHAT, EnumMessageType.SYSTEM], ',') : undefined,
						},
					};

				case EnumMessageHistorySubject.LIVE_SESSION:
					return {
						payload: {
							...commonProps,
							chatIDs: _.join(liveSessionChatIds),
						},
					};

				default:
					throw new Error('Unknown subject type');
			}
		},
	);
}

type TPayloadParams = {channelId?: string; chatIDs?: string; text?: string; messageKey: string; code?: string};

type TSendMessageFilters = {
	selectActive?: EnumBooleanStringified;
	selectRegularCustomer?: EnumBooleanStringified;
	selectAdvertised?: EnumBooleanStringified;
	selectPinned?: EnumBooleanStringified;
};

type TSendMessageOptions = {relationId?: string; relationText?: string} & MediaMd5 & TSendMessageFilters;

export type TSendMessagePayload = baseParamsType & MessageParams;

type TRequestHistoryPayload = baseParamsType &
	Omit<getHistoryParamsType, 'filterMessageType' | 'searchAfterTime' | 'limit' | 'chatIDs'> & {
		filterMessageType?: EnumMessageType[];
		searchAfterTime?: number;
		limit?: number;
		chatIDs?: string[];
	};

export const messagesClientToServerActions = new MessagesClientToServerActions();
