import {createSlice, EntityState} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {giftsClientOnlyActions} from '@messenger/core/src/Redux/Gifts/Actions/giftsClientOnlyActions';
import {giftsAdapter} from '@messenger/core/src/Redux/Gifts/giftsAdapter';
import GiftVM from '@messenger/core/src/Redux/Gifts/GiftVM';

export const initialState: TGiftsSliceState = giftsAdapter.getInitialState({});

const giftsSlice = createSlice({
	name: EnumStore.GIFTS,
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(giftsClientOnlyActions.setGifts, giftsAdapter.setMany);
	},
});

export type TGiftsSliceState = EntityState<GiftVM>;

export default giftsSlice;
