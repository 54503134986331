import {put, select} from 'typed-redux-saga';
import {PayloadAction} from '@reduxjs/toolkit';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {cam2camPreviewClientOnlyActions} from '@messenger/core/src/Redux/Cam2CamPreview/Actions/cam2camPreviewClientOnlyActions';
import {selectPrimaryCam2CamId} from '@messenger/core/src/Redux/Cam2CamPreview/Selectors/selectPrimaryCam2CamId';

function* closePrimaryCam2CamSaga(action: PayloadAction<string>) {
	try {
		const primaryCam2CamId = yield* select(selectPrimaryCam2CamId);

		if (action.payload === primaryCam2CamId) {
			yield* put(cam2camPreviewClientOnlyActions.setPrimaryCam2CamId(undefined));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'closePrimaryCam2CamSaga'});
	}
}

export default closePrimaryCam2CamSaga;
