import {RESULT} from 'cmd-control-client-lib';

import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

enum EnumActions {
	AUTO_LOGIN_DISABLE = 'LOGIN_AUTO_LOGIN_DISABLE',
	AUTO_LOGIN_ENABLE = 'LOGIN_AUTO_LOGIN_ENABLE',
	CLEAN_UP = 'LOGIN_CLEAN_UP',
	ERROR_RESET = 'LOGIN_ERROR_RESET',
	ERROR_SET = 'LOGIN_SET_ERROR',
	SUBMIT = 'LOGIN_SUBMIT',
	SUBMIT_RESPONSE = 'LOGIN_SUBMIT_RESPONSE',
	SUBMIT_SENT = 'LOGIN_SUBMIT_SENT',
	SUBMIT_SUCCESS = 'LOGIN_SUBMIT_SUCCESS',
	SET_DEFAULT_CREDENTIALS = 'SET_DEFAULT_CREDENTIALS',
	SIGN_UP = 'SIGN_UP',
	RESET_PASSWORD = 'RESET_PASSWORD',
	SET_AUTO_LOGIN_PROCESSED = 'SET_AUTO_LOGIN_PROCESSED',
}

class LoginFormClientOnlyActions extends ClientOnlyActions<EnumStore.LOGIN_FORM> {
	readonly scope = EnumStore.LOGIN_FORM;

	submit = this.createAction(
		EnumActions.SUBMIT,
		this.getPrepareAction<{
			login: string;
			password: string;
			rememberMe: boolean;
		}>(),
	);
	submitSent = this.createAction(EnumActions.SUBMIT_SENT);
	submitResponseReceived = this.createAction(EnumActions.SUBMIT_RESPONSE, this.getPrepareAction<RESULT>());
	submitSuccess = this.createAction(EnumActions.SUBMIT_SUCCESS);
	setError = this.createAction(EnumActions.ERROR_SET, this.getPrepareAction<string>());
	resetError = this.createAction(EnumActions.ERROR_RESET);
	disableAutoLogin = this.createAction(EnumActions.AUTO_LOGIN_DISABLE);
	enableAutoLogin = this.createAction(EnumActions.AUTO_LOGIN_ENABLE);
	cleanUp = this.createAction(EnumActions.CLEAN_UP);
	setDefaultCredentials = this.createAction(
		EnumActions.SET_DEFAULT_CREDENTIALS,
		this.getPrepareAction<{
			login: string;
			passwordHash: string;
			rememberMe: boolean;
		}>(),
	);
	signUp = this.createAction(EnumActions.SIGN_UP, this.getPrepareAction<TSignUpPayload>());
	resetPassword = this.createAction(EnumActions.RESET_PASSWORD, this.getPrepareAction<Pick<TSignUpPayload, 'email'>>());
	setAutoLoginProcessed = this.createAction(EnumActions.SET_AUTO_LOGIN_PROCESSED, this.getPrepareAction<boolean>());
}

export const loginFormClientOnlyActions = new LoginFormClientOnlyActions();

export type TSignUpPayload = {
	email: string;
	password: string;
};
