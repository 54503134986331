import cuid from 'cuid';
import {call, put} from 'typed-redux-saga';
import {ResultCode} from 'cmd-control-client-lib';

import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';
import {streamServerToClientActions} from '@messenger/core/src/Redux/Stream/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';

const processStreamingStartedSaga = function* ({
	meta,
}: ReturnType<typeof streamServerToClientActions.startSendResponseReceived>) {
	try {
		if (meta.result.code !== ResultCode.OK) {
			yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], {
				text: ServiceFactory.i18n.t('notification:startstream-failed'),
				variant: EnumAbstractNotificationVariant.ERROR,
				key: cuid(),
			});

			yield* put(streamClientOnlyActions.goOffline());
		} else {
			yield* put(streamClientOnlyActions.setIsStreamStartedFromCurrentInstance(true));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processStreamingStartedSaga'});
	}
};

export default processStreamingStartedSaga;
