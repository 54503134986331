import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {getQueryId} from '@messenger/core/src/Redux/QueryUser/entityAdapter';
import {IQueryUserModel} from '@messenger/core/src/Redux/QueryUser/IQueryUserModel';

enum EnumQueryClientOnlyActions {
	RESET_ERROR = 'RESET_ERROR',
	SET_NOT_LOADING = 'SET_NOT_LOADING',
	ABORT_QUERY = 'ABORT_QUERY',
}

class QueryClientOnlyActions extends ClientOnlyActions<EnumStore.QUERY_USER> {
	readonly scope = EnumStore.QUERY_USER;

	resetError = this.createAction(EnumQueryClientOnlyActions.RESET_ERROR);
	setNotLoading = this.createAction(EnumQueryClientOnlyActions.SET_NOT_LOADING);
	abortQuery = this.createAction(EnumQueryClientOnlyActions.ABORT_QUERY, (payload: IQueryUserModel) => ({
		payload: getQueryId(payload),
	}));
}

export const queryClientOnlyActions = new QueryClientOnlyActions();
