import {createSlice, EntityState} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {chatSummaryServerToClientActions} from '@messenger/core/src/Redux/ChatSummary/Actions/chatSummaryServerToClientActions';
import {chatSummaryEntityAdapter, TChatSummaryEntity} from '@messenger/core/src/Redux/ChatSummary/entityAdapter';
import {chatSummaryClientToServerActions} from '@messenger/core/src/Redux/ChatSummary/Actions/chatSummaryClientToServerActions';
import {chatSummaryClientOnlyActions} from '@messenger/core/src/Redux/ChatSummary/Actions/chatSummaryClinetOnlyActions';

export const initialChatSummaryState: TChatSummarySlice = chatSummaryEntityAdapter.getInitialState({
	isLoading: false,
	error: undefined,
});

export const chatSummarySlice = createSlice({
	name: EnumStore.CHAT_SUMMARY,
	initialState: initialChatSummaryState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(chatSummaryClientOnlyActions.removeMany, chatSummaryEntityAdapter.removeMany);
		builder.addCase(chatSummaryClientOnlyActions.resetStore, () => initialChatSummaryState);
		builder.addCase(chatSummaryClientToServerActions.fetchChatSummary, (state) => {
			state.isLoading = true;
		});
		builder.addCase(chatSummaryServerToClientActions.chatSummaryReceived, (state, action) => {
			state.isLoading = false;
			state.error = action.error;

			return chatSummaryEntityAdapter.upsertOne(state, action.payload);
		});
	},
});

export type TChatSummarySlice = EntityState<TChatSummaryEntity> & {
	isLoading: boolean;
	error?: string | boolean;
};
