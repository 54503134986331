import {SupportedLanguage, EnumBooleanDigitized, SChatInfo, ChatFlags} from 'cmd-control-client-lib';
import _ from 'lodash';

import EnumChatType from '@messenger/core/src/BusinessLogic/EnumChatType';

/** @TODO: move to ViewModel (by side reducer) */
interface IChatExtraFields {
	[key: string]: string | boolean | EnumBooleanDigitized | SupportedLanguage | EnumChatType | undefined;

	isMuted: boolean;
	isVoyeur: boolean;
	hasAudio: boolean;
	hasVideo: boolean;
	cam2cam: boolean;
}

export default interface IChat extends SChatInfo, IChatExtraFields {
	dataID: string;
	isTestChat: EnumBooleanDigitized;
	isSingle: EnumBooleanDigitized;
	lang: SupportedLanguage;
	messageId: string;
	sessionID: string;
	type: EnumChatType;
	isMobile: EnumBooleanDigitized;
	lobbyUsers: string;
}

export const chatTicketMatcher = (chat: Partial<IChat>, channelId: string) => {
	return chat.channelId === channelId && _.includes(chat.flags, ChatFlags.SINGLEC2C);
};
