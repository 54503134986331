import cuid from 'cuid';
import moment from 'moment';
import {
	EnumBooleanStringified,
	EnumMessageStyle,
	EnumMessageDirection,
	EnumMessageType,
	CMDC_CMSG,
} from 'cmd-control-client-lib';
import type {TFunction} from 'react-i18next';

import EnumVLiveMessageType from '@messenger/core/src/BusinessLogic/EnumVLiveMessageType';
import NumberUtil from '@messenger/core/src/Utils/NumberUtil';
import {EnumReservedChannelIds} from '@messenger/core/src/BusinessLogic/EnumReservedChannelIds';

const JohnDoeChatId = 'dummy-john-doe-chat-id';

export const JohnDoeMessageIds = {
	HISTORY_MESSENGER_MESSAGE: NumberUtil.random(99999, 9999999),
	HISTORY_SYSTEM_GUEST_ENTERS_MESSAGE: NumberUtil.random(99999, 9999999),
	HISTORY_CHAT_MESSAGE: NumberUtil.random(99999, 9999999),
	HISTORY_SYSTEM_GUEST_LEAVES_MESSAGE: NumberUtil.random(99999, 9999999),
};

const commonFields: () => Partial<CMDC_CMSG['params']> = () => ({
	timeSeen: '0',
	channelId: EnumReservedChannelIds.CHANNEL_ID_JOHN_DOE,
	direction: EnumMessageDirection.IN,
	messageKey: cuid.slug(),
});

export const getJohnDoeMessage = (index: number, t: TFunction): CMDC_CMSG['params'] => {
	switch (index) {
		case 0:
			return {
				...commonFields(),
				messageId: `${JohnDoeMessageIds.HISTORY_MESSENGER_MESSAGE}`,
				text: t('intro:message_john_doe_msn_message'),
				gift: '',
				time: moment().format('x'),
				sound: 'text',
				mailType: EnumVLiveMessageType.ENTRY,
				msgType: EnumMessageType.MESSENGER,

				isPaid: EnumBooleanStringified.TRUE,
			};

		case 1:
			return {
				...commonFields(),
				chatID: JohnDoeChatId,
				messageId: `${JohnDoeMessageIds.HISTORY_SYSTEM_GUEST_LEAVES_MESSAGE}`,
				text: t('intro:system_message_john_doe_leaves_chat'),
				gift: '',
				time: moment().subtract(10, 'minutes').format('x'),
				sound: 'user_left',
				mailType: EnumVLiveMessageType.SYSTEM,
				msgType: EnumMessageType.SYSTEM,
			};

		case 2:
			return {
				...commonFields(),
				chatID: JohnDoeChatId,
				messageId: `${JohnDoeMessageIds.HISTORY_CHAT_MESSAGE}`,
				text: t('intro:message_john_doe_in-chat_message'),
				time: moment().subtract(20, 'minutes').format('x'),
				sound: 'text',
				style: EnumMessageStyle.SYSTEM,
				mailType: EnumVLiveMessageType.ENTRY,
				msgType: EnumMessageType.CHAT,
			};

		case 3:
			return {
				...commonFields(),
				chatID: JohnDoeChatId,
				messageId: `${JohnDoeMessageIds.HISTORY_SYSTEM_GUEST_ENTERS_MESSAGE}`,
				text: t('intro:system_message_john_doe_enters_chat'),
				gift: '',
				time: moment().subtract(30, 'minutes').format('x'),
				sound: 'user_entered',
				mailType: EnumVLiveMessageType.SYSTEM,
				msgType: EnumMessageType.SYSTEM,
			};

		default:
			return {
				...commonFields(),
			};
	}
};
