import {call, put, select} from 'typed-redux-saga';
import {PayloadAction} from '@reduxjs/toolkit';
import {batchActions} from 'redux-batched-actions';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {TStreamConnectOptions} from '@messenger/core/src/Redux/Stream/Model';
import {mediaDeviceClientOnlyActions} from '@messenger/core/src/Redux/MediaDevice/Actions';
import {selectIsWebRtcOutputStreamActive} from '@messenger/core/src/Redux/Stream/Selectors/selectIsWebRtcOutputStreamActive';
import {previewClientOnlyActions} from '@messenger/core/src/Redux/Preview/Actions/previewClientOnlyActions';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';

import stopWebRtcOutputStreamSaga from 'src/Redux/Stream/Sagas/stopWebRtcOutputStreamSaga';
import detectSupportedMediaCodecsSaga from 'src/Redux/Stream/Sagas/detectSupportedMediaCodecsSaga';

function* streamRestartReceivedSaga(action: PayloadAction<TStreamConnectOptions>) {
	try {
		const hasWebRtcStream: boolean = yield* select(selectIsWebRtcOutputStreamActive);

		if (!hasWebRtcStream) {
			return;
		}

		yield* put(mediaDeviceClientOnlyActions.setShouldRestartStream(true));

		yield* call(stopWebRtcOutputStreamSaga, false);

		yield* put(
			batchActions([
				previewClientOnlyActions.resetStore(),
				mediaDeviceClientOnlyActions.resetSupportedCodecs(),
				mediaDeviceClientOnlyActions.resetAvailableMediaDevices(),
				mediaDeviceClientOnlyActions.resetChosenMediaDevicesInfo(),
				streamClientOnlyActions.resetBrowserCapabilities(),
				streamClientOnlyActions.resetStateState(),
			]),
		);

		yield* call(detectSupportedMediaCodecsSaga, action);

		yield* put(mediaDeviceClientOnlyActions.requestMediaDevicePermissions({isMicrophoneRequired: true}));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'streamRestartReceivedSaga', action});
	}
}

export default streamRestartReceivedSaga;
