import {SystemMessageKey} from 'cmd-control-client-lib';
import {put} from 'typed-redux-saga';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {mediaClientToServerActions} from '@messenger/core/src/Redux/Media/Actions/mediaClientToServerActions';
import {messagesServerToClientActions} from '@messenger/core/src/Redux/Messages/Actions';
import {onlineStateClientToServerActions} from '@messenger/core/src/Redux/OnlineState/Actions';

const requestPurchasedTicketInfoSaga = function* (
	action: ReturnType<typeof messagesServerToClientActions.messageReceived>,
) {
	try {
		if (action.payload.messageKey === SystemMessageKey.h_media_ticket_purchased) {
			const channelId = action.payload.channelId;

			if (_.isUndefined(channelId)) {
				throw new Error('Missing channelId for h_media_ticket_purchased');
			}

			yield* put(mediaClientToServerActions.requestTickets([channelId]));
			yield* put(onlineStateClientToServerActions.requestOnlineState([channelId]));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'requestPurchasedTicketInfoSaga', action});
	}
};

export default requestPurchasedTicketInfoSaga;
