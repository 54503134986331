import {createSelector} from '@messenger/core/src/Utils/Redux';
import ChatsFilterMatcher from '@messenger/core/src/BusinessLogic/ChatsFilterMatcher';
import selectChannelsFilter from '@messenger/core/src/Redux/Channels/Selectors/selectChannelsFilter';

const selectChatsFilterMatcher = createSelector(
	[selectChannelsFilter],
	(channelsFilter) => new ChatsFilterMatcher(channelsFilter),
);

export default selectChatsFilterMatcher;
