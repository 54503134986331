import {EnumBooleanStringified, EnumSetTp} from 'cmd-control-client-lib';

import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {TKeyboardShortcutAdapted} from '@messenger/core/src/Redux/Session/Selectors/PermanentData/selectKeyboardShortcuts';
import {TKeyboardShortcut} from '@messenger/core/src/Redux/Session/Model';

export enum EnumClientActions {
	RESET_STORE = 'RESET_STORE',
	RESET_STORE_FINISHED = 'RESET_STORE_FINISHED',
	SET_TP = 'SET_TP',
	SET_FOREIGN_SID = 'SET_FOREIGN_SID',
	SET_IS_LOGGED_IN = 'SET_IS_LOGGED_IN',
	START_LOGIN_FOREIGNSID = 'START_LOGIN_FOREIGNSID',
	SAVE_SHORTCUT = 'SAVE_SHORTCUT',
	DELETE_SHORTCUT = 'DELETE_SHORTCUT',
	USE_SHORTCUT = 'USE_SHORTCUT',
	TOGGLE_KEEP_SELECTED_CHANNEL_IN_VIEW_ENABLED = 'TOGGLE_KEEP_SELECTED_CHANNEL_IN_VIEW_ENABLED',
	LOGOUT = 'LOGOUT',
	SET_TOOLTIP_START_ONLINE_VISIBILITY = 'SET_TOOLTIP_START_ONLINE_VISIBILITY',
	TOGGLE_CURRENT_MOBILE_VIDEO_CALL_NOTIFICATION = 'TOGGLE_CURRENT_MOBILE_VIDEO_CALL_NOTIFICATION',
}

class SessionClientOnlyActions extends ClientOnlyActions<EnumStore.SESSION> {
	readonly scope = EnumStore.SESSION;

	resetStore = this.createAction(EnumClientActions.RESET_STORE);

	logOut = this.createAction(EnumClientActions.LOGOUT);

	resetStoreFinished = this.createAction(EnumClientActions.RESET_STORE_FINISHED);

	setTp = this.createAction(EnumClientActions.SET_TP, this.getPrepareAction<EnumSetTp>());

	setForeignSID = this.createAction(EnumClientActions.SET_FOREIGN_SID, this.getPrepareAction<string | undefined>());

	setIsLoggedIn = this.createAction(
		EnumClientActions.SET_IS_LOGGED_IN,
		this.getPrepareAction<EnumBooleanStringified>(),
	);

	startLoginForeignSID = this.createAction(EnumClientActions.START_LOGIN_FOREIGNSID, this.getPrepareAction<string>());

	saveShortcut = this.createAction(
		EnumClientActions.SAVE_SHORTCUT,
		this.getPrepareAction<{shortcut: TKeyboardShortcutAdapted; initialKeyStatus?: string}>(),
	);

	deleteShortcut = this.createAction(EnumClientActions.DELETE_SHORTCUT, this.getPrepareAction<{keyStatus: string}>());

	useShortcut = this.createAction(
		EnumClientActions.USE_SHORTCUT,
		this.getPrepareAction<{shortcut: TKeyboardShortcut}>(),
	);

	toggleKeepSelectedChannelInViewEnabled = this.createAction(
		EnumClientActions.TOGGLE_KEEP_SELECTED_CHANNEL_IN_VIEW_ENABLED,
	);

	setTooltipStartOnlineVisibility = this.createAction(
		EnumClientActions.SET_TOOLTIP_START_ONLINE_VISIBILITY,
		this.getPrepareAction<EnumBooleanStringified>(),
	);

	toggleCurrentMobileVideoCallNotification = this.createAction(
		EnumClientActions.TOGGLE_CURRENT_MOBILE_VIDEO_CALL_NOTIFICATION,
		this.getPrepareAction<boolean>(),
	);
}

export const sessionClientOnlyActions = new SessionClientOnlyActions();
