import {takeEvery} from 'typed-redux-saga';

import EnumActions from '@messenger/core/src/Redux/SpeedTest/EnumActions';
import SendStartSpeedTest from '@messenger/core/src/Redux/SpeedTest/Sagas/SendStartSpeedTestSaga';
import startSpeedTestSaga from '@messenger/core/src/Redux/SpeedTest/Sagas/startSpeedTestSaga';
import SendSpeedTestReport from '@messenger/core/src/Redux/SpeedTest/Sagas/SendSpeedTestReportSaga';
import processResultsSubmitSaga from '@messenger/core/src/Redux/SpeedTest/Sagas/processResultsSubmitSaga';
import {speedTestServerToClientActions} from '@messenger/core/src/Redux/SpeedTest/Actions/speedTestServerToClientActions';

function* speedTestWatcherSaga() {
	yield* takeEvery(speedTestServerToClientActions.startTestReceived, startSpeedTestSaga);
	yield* takeEvery(speedTestServerToClientActions.stopTestReceived, processResultsSubmitSaga);
	yield* takeEvery(EnumActions.START, SendStartSpeedTest);
	yield* takeEvery(EnumActions.END, SendSpeedTestReport);
}

export default speedTestWatcherSaga;
