import {call, put, select} from 'typed-redux-saga';
import moment from 'moment';
import _ from 'lodash';
import {EnumTicketShowStatusType} from 'cmd-control-client-lib';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {ticketShowClientOnlyActions} from '@messenger/core/src/Redux/TicketShow/Actions/ticketShowClientOnlyActions';
import {selectAllTicketShowVMs} from '@messenger/core/src/Redux/TicketShow/Selectors/selectAllTicketShowVMs';
import {selectSoonStartTicketShowVM} from '@messenger/core/src/Redux/TicketShow/Selectors/selectSoonStartTicketShowVM';
import {TAbstractNotificationOptions} from '@messenger/core/src/Services/UINotification';
import EnumSnackbarNotificationKeys from '@messenger/core/src/BusinessLogic/EnumSnackbarNotificationKeys';

export const soonStartTicketShowSaga = function* () {
	try {
		const ticketShowVMs = yield* select(selectAllTicketShowVMs);
		const soonStartTicketShow = yield* select(selectSoonStartTicketShowVM);
		const ticketShow = _.find(
			ticketShowVMs,
			(ticketShowVM) =>
				!_.isUndefined(ticketShowVM) &&
				ticketShowVM.status !== EnumTicketShowStatusType.CANCELLED &&
				moment(ticketShowVM.showStart).isAfter() &&
				moment(ticketShowVM.showStart).subtract(32, 'm').isBefore(),
		);

		if (ticketShow && ticketShow.showId !== soonStartTicketShow?.showId) {
			yield* put(ticketShowClientOnlyActions.setSoonStartTicketShowId({ticketId: ticketShow.showId}));

			const notification: TAbstractNotificationOptions = {
				text: ServiceFactory.i18n.t('ticketShow:info.notification-message', {
					title: ticketShow?.titleDE || ticketShow?.titleEN,
					min: moment(ticketShow?.showStart).diff(moment.now(), 'm'),
				}),
				key: EnumSnackbarNotificationKeys.SOON_TICKET_SHOW,
				notificationTitle: ServiceFactory.i18n.t('ticketShow:info.notification-title'),
				buttonText: ServiceFactory.i18n.t('ticketShow:notification.close'),
				color: 'dark',
				duration: 60000,
				isOnlyMobile: true,
			};

			yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], notification);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'soonStartTicketShowSaga'});
	}
};
