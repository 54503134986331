import _ from 'lodash';
import {ICOMMAND, IRESPONSE, ResultCode} from 'cmd-control-client-lib';
import {AnyAction} from '@reduxjs/toolkit';
import {batchActions} from 'redux-batched-actions';

import {
	commandToActionMap,
	subCommandToActionMap,
	TCommandToActionMap,
	TSubCommandToActionMap,
} from '@messenger/core/src/Actions/serverToClientActionsMap';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {TAnyAction, TAnyActionCreator} from '@messenger/core/src/Actions/ActionCreator';
import {TServerCommands} from '@messenger/core/src/Actions/types';

type TActionsFromCommandsResult = {
	multi: Record<TAnyActionCreator['type'], {actionCreator: TAnyActionCreator; commands: ICOMMAND[]}>;
	single: TAnyAction[];
};

const addMultiCommandToResult = (
	result: TActionsFromCommandsResult,
	actionCreator: TAnyActionCreator,
	command: ICOMMAND,
) => {
	if (!result.multi[actionCreator.type]) {
		result.multi[actionCreator.type] = {
			actionCreator,
			commands: [],
		};
	}

	result.multi[actionCreator.type].commands.push(command);
};

const getActionsFromCommands = (
	map: Partial<TSubCommandToActionMap>,
	parentCommand: IRESPONSE,
	commands: ICOMMAND[],
) => {
	const {multi, single} = _.chain(commands)
		.reduce(
			(result: TActionsFromCommandsResult, command) => {
				const {actionCreator, isMultiEntityAction, actionCreatorGetter} = _.get(map, command.action);

				if (isMultiEntityAction) {
					if (actionCreator) {
						addMultiCommandToResult(result, actionCreator, command);
					} else if (actionCreatorGetter) {
						addMultiCommandToResult(result, actionCreatorGetter(command), command);
					}
				} else if (actionCreator) {
					result.single.push(actionCreator(command.params, {...command, parentAction: parentCommand.action}, false));
				}

				return result;
			},
			{multi: {}, single: []},
		)
		.value();

	return [
		..._.chain(multi)
			.values()
			.map(({actionCreator, commands}) =>
				actionCreator(_.map(commands, 'params'), {commands, parentAction: parentCommand.action}, false),
			)
			.value(),
		...single,
	];
};

const createActionsFromResponse = (command: IRESPONSE): AnyAction[] => {
	const result = [];
	const subCommands = [...(command.commands || [])];
	const actionConfig: TCommandToActionMap[keyof TServerCommands] | undefined = _.get(
		commandToActionMap,
		command.action,
	);

	if (_.isUndefined(actionConfig)) {
		if (ServiceFactory.env.notProduction()) {
			ServiceFactory.logService.error(`Unsupported command ${command.action}`);
		}
	} else {
		const {actionCreator, batchedSubCommandToActionMap} = actionConfig;

		if (actionCreator) {
			result.push(
				actionCreator(
					command['values'],
					command,
					command.result && command.result.code !== ResultCode.OK ? command.result?.reason : false,
				),
			);
		}

		if (batchedSubCommandToActionMap) {
			const commandActions = getActionsFromCommands(
				batchedSubCommandToActionMap,
				command,
				_.remove(subCommands, (subCommand) => _.get(batchedSubCommandToActionMap, subCommand.action)),
			);

			if (!_.isEmpty(commandActions)) {
				result.unshift(batchActions(commandActions));
			}
		}
	}

	const subCommandActions = getActionsFromCommands(
		subCommandToActionMap,
		command,
		_.remove(subCommands, (subCommand) => _.get(subCommandToActionMap, subCommand.action)),
	);

	if (!_.isEmpty(subCommandActions)) {
		result.push(...subCommandActions);
	}

	if (ServiceFactory.env.notProduction()) {
		_.forEach(subCommands, (subCommand) => {
			ServiceFactory.logService.error(`Unsupported subcommand ${subCommand.action} from command ${command.action}`);
		});
	}

	return result;
};

export default createActionsFromResponse;
