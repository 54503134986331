import { chatIdType } from "../chatstate";
import { IMayHaveChannelId } from "../channel";
import { ACTION } from "../command/action";
import { baseParamsType } from "../command/baseparams";
import { ICOMMAND, IKeyMaybeValue, IRESPONSE, RESULT } from "../command/icommand";
import { EnumBooleanDigitized, EnumBooleanStringified, EnumBooleanStringifiedExtended } from "../../@types";

export enum EnumPowerEstimation {
  LOW = "LOW",
  MIDDLE = "MIDDLE",
  HIGH = "HIGH",
}
export class CMDP_STARTSEND implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_STARTSEND;
  public params: baseParamsType & {
    capabilities?: string;
  };
}

export class CMDP_STARTSEND_RESPONSE extends CMDP_STARTSEND implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}

export class CMDP_STOPSEND implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_STOPSEND;
  public params: baseParamsType & {
    streamError?: EnumBooleanStringifiedExtended;
    errorCode?: string; // 2xx
  };
}

export class CMDP_STOPSEND_RESPONSE extends CMDP_STOPSEND implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}

export class CMDP_TRACERT_REPLY implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_TRACERT_REPLY;
  public params: baseParamsType & {
    tracertID: string;
    hopID: string;
    targetIP: string;
    replyHost: string;
    replyIP: string;
    replyTime: string;
    replyCode: string;
    replyString: string;
  };
}

export class CMDP_TRACERT_REPLY_RESPONSE extends CMDP_TRACERT_REPLY implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}

export class CMDP_FSK16 implements ICOMMAND {
  public action: ACTION.CMDP_FSK16;
  public params: baseParamsType & {
    fsk16: EnumBooleanStringifiedExtended;
  };
}

export class CMDP_START_STREAM_PREVIEW implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_START_STREAM_PREVIEW;
  public params: baseParamsType & {
    duration: string; // seconds
  };
}

export class CMDP_START_STREAM_PREVIEW_RESPONSE extends CMDP_START_STREAM_PREVIEW implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: {
    dataURL: string;
    mediaJpegWsUrl: string;
  };
}

export class CMDP_STOP_STREAM_PREVIEW implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_STOP_STREAM_PREVIEW;
  public params: baseParamsType & {
    dataURL: string;
    dataID: string;
  };
}

export class CMDP_STOP_STREAM_PREVIEW_RESPONSE extends CMDP_STOP_STREAM_PREVIEW implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}

export class CMDP_STARTBANDWIDTHTEST implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_STARTBANDWIDTHTEST;
  public params: baseParamsType;
}

export class CMDP_STARTBANDWIDTHTEST_RESPONSE extends CMDP_STARTBANDWIDTHTEST implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: {
    testID: string;
    seconds: string;
    dataURL: string;
    dataID: string;
  };
}

export class CMDP_STOPBANDWIDTHTEST implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_STOPBANDWIDTHTEST;
  public params: baseParamsType & {
    testID: string;
    seconds: string;
    dataID: string;
    bitrate: string;
    cpuUsage: string;
  };
}

export class CMDP_STOPBANDWIDTHTEST_RESPONSE extends CMDP_STOPBANDWIDTHTEST implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: {
    validated: EnumBooleanStringified;
  };
}

export class CMDP_STARTLOOPBACK9 implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_STARTLOOPBACK9;
  public params: baseParamsType;
}

export class CMDP_STARTLOOPBACK9_RESPONSE extends CMDP_STARTLOOPBACK9 implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: {
    testID: string;
    pushUrl: string;
    pullUrl: string;
    pushUrlAudio: string;
    pullUrlAudio: string;
  };
}

export class CMDP_STOPLOOPBACK9 implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_STOPLOOPBACK9;
  public params: baseParamsType & {
    testID: string;
    VideoDevice: string;
    VideoDeviceWidth: string;
    VideoDeviceHeight: string;
    VideoDeviceFPS: string;
    VideoSendBitrate: string;
    VideoSendFPS: string;
    AudioDevice: string | "-"; // "-" for none
    AudioDeviceFPS: string;
    AudioSendFPS: string;
    AudioSendBitrate: string;
    CPU: string | "-1";
    localCode: string | "0";
  };
}

export class CMDP_STOPLOOPBACK9_RESPONSE extends CMDP_STOPLOOPBACK9 implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: {
    statusCode: string;
    statusText: string;
  };
}

export class CMDP_SQUERY_FORMAT implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SQUERY_FORMAT;
  public params: baseParamsType & {
    width: string;
    height: string;
    fps: string;
    bandWidth: string;
    cpuUsage: string;
  };
}

export class CMDP_SQUERY_FORMAT_RESPONSE extends CMDP_SQUERY_FORMAT implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: {
    width: string;
    height: string;
    fps: string;
    ref_width: string;
    ref_height: string;
    ref_fps: string;
    rtmp?: EnumBooleanDigitized.TRUE;
    codec?: string;
  };
}

export enum QueryUserKeyEnum {
  FSK = "fsk",
  SINGLE = "single",
  SINGLEC2C = "singleC2C",
}

export interface IQueryUserParams extends IKeyMaybeValue {
  queryId: string;
  key: QueryUserKeyEnum;
  caption: string;
  text: string;
  btn0: string;
  btn1: string;
  timeout: string;
  sound: string;
  btnDefault: EnumBooleanDigitized;
  btndef: "btn0" | "btn1";
}

export class CMDC_QUERYUSER implements ICOMMAND {
  public action: ACTION = ACTION.CMDC_QUERYUSER;
  public params: IMayHaveChannelId & IQueryUserParams;
}

export class CMDP_QUERYUSER implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_QUERYUSER;
  public params: baseParamsType &
    chatIdType &
    IMayHaveChannelId & {
      key: QueryUserKeyEnum;
      button: EnumBooleanDigitized;
      queryId: string;
      /** if the answer sent by timeout */
      isTimeout?: EnumBooleanDigitized;
    };
}

export class CMDP_QUERYUSER_RESPONSE extends CMDP_QUERYUSER implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}

export class CMDC_QUERYANSWERED implements ICOMMAND {
  public action = ACTION.CMDC_QUERYANSWERED;
  public params: { queryId: string };
}

export class CMDP_SGETSTREAMPARAMS implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_SGETSTREAMPARAMS;
  public params: baseParamsType & {
    powerEstimation: EnumPowerEstimation;
  };
}

export type StreamParams = {
  rtmpURL: string;
  streamName: string;
  webRtc: string;
  powerEstimation: EnumPowerEstimation;
};

export class CMDP_SGETSTREAMPARAMS_RESPONSE extends CMDP_SGETSTREAMPARAMS implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue & StreamParams;
}

export class CMDC_CSTARTSTREAMPUSH implements ICOMMAND {
  public action: ACTION = ACTION.CMDC_CSTARTSTREAMPUSH;
  public params: baseParamsType & StreamParams;
}
