import {call, put} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {preventNextScreenAutoLogin} from '@messenger/core/src/Redux/Session/Sagas/preventNextScreenAutoLogin';
import notificationsClientOnlyActions from '@messenger/core/src/Redux/Notifications/Actions/notificationsClientOnlyActions';

import streamingGoOfflineSaga from 'src/Redux/Stream/Sagas/streamingGoOfflineSaga';

export const processLogOutSaga = function* () {
	try {
		yield* call(streamingGoOfflineSaga);
		yield* call(preventNextScreenAutoLogin);
		yield* put(notificationsClientOnlyActions.closeNonErrorNotifications());
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processLogOutSaga'});
	}
};
