import {put, call, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {previewClientOnlyActions} from '@messenger/core/src/Redux/Preview/Actions/previewClientOnlyActions';
import EnumPreviewType from '@messenger/core/src/BusinessLogic/EnumPreviewType';
import {selectIsLoading} from '@messenger/core/src/Redux/Session/Selectors/selectIsLoading';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';
import {ALL_CHATS} from '@messenger/core/src/BusinessLogic/Constants';
import {mediaDeviceClientOnlyActions} from '@messenger/core/src/Redux/MediaDevice/Actions';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';
import {EnumStartStreamStep} from '@messenger/core/src/Redux/Stream/slice';

function* startExternalToolStreamSaga() {
	try {
		yield* put(streamClientOnlyActions.setStartStreamStep(EnumStartStreamStep.THIRD_PARTY_STREAM_CONFIG));
		yield* put(mediaDeviceClientOnlyActions.releaseMediaDevicePermissions());

		const isStreamLoading = yield* select(selectIsLoading);

		if (isStreamLoading) {
			const {goToChatMessages} = yield* getNavigationContext();

			yield* call(goToChatMessages, ALL_CHATS);
			yield* put(previewClientOnlyActions.changePreviewType(EnumPreviewType.JPEG));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'startExternalToolStreamSaga'});
	}
}

export default startExternalToolStreamSaga;
