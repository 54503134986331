import {takeEvery, takeLatest} from 'typed-redux-saga';

import {mediaClientOnlyActions} from '@messenger/core/src/Redux/Media/Actions/mediaClientOnlyActions';
import {processMediaReceivedSaga} from '@messenger/core/src/Redux/Media/Sagas/processMediaReceivedSaga';
import {processUploadedMediaReceivedSaga} from '@messenger/core/src/Redux/Media/Sagas/processUploadedMediaReceivedSaga';
import {requestMediaForContentCatalogSaga} from '@messenger/core/src/Redux/Media/Sagas/requestMediaForContentCatalogSaga';
import {mediaServerToClientActions} from '@messenger/core/src/Redux/Media/Actions/mediaServerToClientActions';

export default function* mediaSagaWatcher() {
	yield* takeEvery(mediaClientOnlyActions.upsertMany.type, processMediaReceivedSaga);
	yield* takeEvery(mediaServerToClientActions.uploadedMediaReceived, processUploadedMediaReceivedSaga);
	yield* takeLatest(mediaClientOnlyActions.requestMediaForContentCatalog, requestMediaForContentCatalogSaga);
}
