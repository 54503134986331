import {put, select, take} from 'typed-redux-saga';
import _ from 'lodash';
import {ACTION, EnumBooleanStringifiedExtended, EnumMediaState} from 'cmd-control-client-lib';
import {Action} from 'redux';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {mediaClientToServerActions} from '@messenger/core/src/Redux/Media/Actions/mediaClientToServerActions';
import {mediaClientOnlyActions} from '@messenger/core/src/Redux/Media/Actions/mediaClientOnlyActions';
import {selectContentCatalogIds} from '@messenger/core/src/Redux/Media/Selectors/selectContentCatalogIds';
import {selectContentCatalogTotalCount} from '@messenger/core/src/Redux/Media/Selectors/selectContentCatalogTotalCount';
import {DOWNLOAD_MEDIA_COUNT_LIMIT} from '@messenger/core/src/BusinessLogic/Constants';
import {parseBoolean} from '@messenger/core/src/Utils/StringUtil';
import {mediaServerToClientActions} from '@messenger/core/src/Redux/Media/Actions/mediaServerToClientActions';
import selectMediaByChannelId from '@messenger/core/src/Redux/ChannelMedia/Selectors/selectMediaByChannelId';

export const requestMediaForContentCatalogSaga = function* (
	action: ReturnType<typeof mediaClientOnlyActions.requestMediaForContentCatalog>,
) {
	try {
		const loadedCount = _.size(yield* select(selectContentCatalogIds));
		const totalCount = yield* select(selectContentCatalogTotalCount);

		const {mediaType, linkState, direction, channelId, isMediaForSingleUser} = action.payload ?? {};
		const filter = {
			mediaState: EnumMediaState.OK,
			linkState,
			mediaType,
			direction,
		};

		if (_.isNull(totalCount)) {
			yield* put(
				mediaClientToServerActions.requestMedias({
					countOnly: true,
					channelIds: (isMediaForSingleUser || linkState) && channelId ? [channelId] : undefined,
					...filter,
				}),
			);
		} else if (loadedCount >= totalCount) {
			return;
		}

		if (channelId) {
			const channelMedia = yield* select(selectMediaByChannelId, {channelId});

			if (_.isEmpty(channelMedia) || _.isNull(totalCount)) {
				yield* put(
					mediaClientToServerActions.requestMedias({
						...filter,
						dropMediaFields: EnumBooleanStringifiedExtended.TRUE,
						channelIds: [channelId],
					}),
				);
			}
		}

		yield* put(
			mediaClientToServerActions.requestMedias({
				channelIds: channelId && (linkState || isMediaForSingleUser) ? [channelId] : undefined,
				...filter,
				skip: _.isNull(totalCount) ? 0 : loadedCount,
				limit: (_.isNull(totalCount) ? loadedCount : 0) + DOWNLOAD_MEDIA_COUNT_LIMIT,
			}),
		);

		const {meta} = (yield* take((receivedAction: Action) => {
			if (receivedAction.type !== mediaServerToClientActions.uploadedMediaReceived.type) {
				return false;
			}

			const {
				meta: {params},
			} = receivedAction as ReturnType<typeof mediaServerToClientActions.uploadedMediaReceived>;

			return (
				!parseBoolean(params.dropMediaFields) &&
				!parseBoolean(params.countOnly) &&
				params.mediaType === mediaType?.join(',') &&
				params.linkState === linkState?.join(',') &&
				params.direction === direction
			);
		})) as ReturnType<typeof mediaServerToClientActions.uploadedMediaReceived>;

		const contentCatalogIds = yield* select(selectContentCatalogIds);

		const mediaMd5Array = _.reduce(
			meta.commands,
			(md5Array, command) =>
				command.action === ACTION.CMDC_CMEDIA && _.isString(command.params.mediaMd5)
					? [...md5Array, command.params.mediaMd5]
					: md5Array,
			[] as string[],
		);

		if (_.toInteger(meta.params.skip)) {
			const hasAlreadyLoadedChannels = !_.isEmpty(_.intersection(contentCatalogIds, mediaMd5Array));

			if (hasAlreadyLoadedChannels) {
				yield* put(mediaClientOnlyActions.setContentCatalogTotal(null));
				yield* put(mediaClientOnlyActions.requestMediaForContentCatalog({channelId}));

				return;
			}

			yield* put(mediaClientOnlyActions.setContentCatalogIds([...contentCatalogIds, ...mediaMd5Array]));

			return;
		}

		yield* put(mediaClientOnlyActions.setContentCatalogIds(mediaMd5Array));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'requestMediaForContentCatalogSaga', action});
	}
};
