import _ from 'lodash';
import {call, select} from 'typed-redux-saga';
import {isMobile} from 'react-device-detect';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {chatsServerToClientActions} from '@messenger/core/src/Redux/Chats/Actions';
import {ALL_CHATS} from '@messenger/core/src/BusinessLogic/Constants';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';
import {selectRunningNonVoyeurChatIds} from '@messenger/core/src/Redux/Chats/Selectors/selectRunningNonVoyeurChatIds';
import {selectIsStreamStartedFromCurrentInstance} from '@messenger/core/src/Redux/Stream/Selectors/selectIsStreamStartedFromCurrentInstance';

export function* navigateToAllChatsOnFirstAdminEnterSaga(
	action: ReturnType<typeof chatsServerToClientActions.showAdminChat>,
) {
	try {
		if (!isMobile || !ServiceFactory.env.isAppMobileEnabled()) {
			return;
		}

		const isStreamStartedFromCurrentInstance = yield* select(selectIsStreamStartedFromCurrentInstance);

		if (!isStreamStartedFromCurrentInstance) {
			return;
		}

		const runningNonVoyeurChatIds = yield* select(selectRunningNonVoyeurChatIds);

		if (_.size(runningNonVoyeurChatIds) === 1 && _.includes(runningNonVoyeurChatIds, action.payload)) {
			const {goToChatMessages, isChatPage} = yield* getNavigationContext();

			if (!isChatPage(ALL_CHATS)) {
				yield* call(goToChatMessages, ALL_CHATS);
			}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'navigateToAllChatsOnFirstAdminEnter'});
	}
}
