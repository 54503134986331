import {put, select} from 'typed-redux-saga';

import {onlineSummaryClientToServerActions} from '@messenger/core/src/Redux/OnlineSummary/Actions';
import selectSessionId from '@messenger/core/src/Redux/Session/Selectors/selectSessionId';

function* requestOnlineSummarySaga() {
	const sessionId = yield* select(selectSessionId);

	if (!sessionId) {
		return;
	}

	yield* put(onlineSummaryClientToServerActions.getOnlineSummary());
}

export default requestOnlineSummarySaga;
