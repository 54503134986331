import 'reflect-metadata';
import {inject, singleton} from 'tsyringe';
import {chain, get, includes, isEmpty, isString} from 'lodash';
import {EnumBooleanStringified, SupportedLanguage} from 'cmd-control-client-lib';

import DIToken from '@messenger/core/src/BusinessLogic/DIToken';
import AbstractEnvService from '@messenger/core/src/Services/AbstractEnvService';
import EnumEnvironment from '@messenger/core/src/EnumEnvironment';
import getVUsersLanguage from '@messenger/core/src/BusinessLogic/VUsers/Language';
import {parseBoolean} from '@messenger/core/src/Utils/StringUtil';

/**
 * @note this is how messenger does it
 * @link http://phabricator.y/diffusion/23/browse/master/src/services/ApiService.ts
 */

@singleton()
class EnvInterfaceService {
	private readonly _env: Record<string, string | undefined> = {};

	constructor(@inject(DIToken.EnvService) envService: AbstractEnvService) {
		this._env = envService.getAll();
	}

	getWsURL = (): string => this._env.REACT_APP_WSS_DEFAULT_URL || '';

	getWsHttpsEnabled = () => parseBoolean(this._env.REACT_APP_WS_HTTPS ?? EnumBooleanStringified.TRUE);

	getWsHost = () => this._env.REACT_APP_WS_DEFAULT_HOST ?? '';

	getWsPort = () => this._env.REACT_APP_WS_DEFAULT_PORT ?? '443';

	getWsPath = () => this._env.REACT_APP_WS_DEFAULT_PATH ?? '';

	getVersion = (): string => get(this._env, 'REACT_APP_VERSION', '2.0.0');

	getName = (): string => get(this._env, 'REACT_APP_NAME', 'messenger');

	isDev = (): boolean => this._env.REACT_APP_ENV === EnumEnvironment.DEV;

	isCI = () => this._env.CI;

	isProduction = (): boolean => this._env.REACT_APP_ENV === EnumEnvironment.PROD;

	notProduction = (): boolean => this._env.REACT_APP_ENV !== EnumEnvironment.PROD;

	isAppMobileEnabled = (): boolean =>
		parseBoolean(this._env.REACT_APP_MOBILE_DESKTOP_LOADING ?? EnumBooleanStringified.FALSE);

	isContentCatalogShown = (): boolean =>
		parseBoolean(this._env.REACT_APP_SHOW_CONTENT_CATALOG ?? EnumBooleanStringified.FALSE);

	i18nDebugEnabled = (): boolean => parseBoolean(this._env.REACT_APP_I18N_DEBUG_ENABLE ?? EnumBooleanStringified.FALSE);

	reduxLoggerEnabled = (): boolean =>
		parseBoolean(this._env.REACT_APP_REDUX_LOGGER_ENABLE ?? EnumBooleanStringified.FALSE);

	reduxDevToolsEnabled = (): boolean =>
		parseBoolean(this._env.REACT_APP_REDUX_DEVTOOLS_ENABLE ?? EnumBooleanStringified.FALSE);

	getReduxIgnoredActions = (): string[] =>
		this._env.REACT_APP_REDUX_IGNORE_ACTIONS ? this._env.REACT_APP_REDUX_IGNORE_ACTIONS.toString().split(',') : [];

	getCookieDomain = (): string => this._env.REACT_APP_COOKIE_DOMAIN || '';

	getDomain = (): string => this._env.REACT_APP_DOMAIN || '';

	getDeviceLANUrl = (): string => this._env.REACT_APP_DEVICE_LOCAL_URL || '';

	shouldEmulateDeviceApi = (): boolean =>
		parseBoolean(this._env.REACT_APP_DEVICE_MOCK_DEVICES ?? EnumBooleanStringified.FALSE);

	getDeviceTimerInterval = (fallback = 5000): number =>
		parseInt(get(this._env, 'REACT_APP_DEVICE_TIMER_INTERVAL', `${fallback}`), 10);

	getDeviceTestVibrateDuration = (fallback = 30): number =>
		this._env.REACT_APP_DEVICE_TEST_VIBRATE_DURATION
			? parseInt(this._env.REACT_APP_DEVICE_TEST_VIBRATE_DURATION, 10)
			: fallback;

	shouldLogDeviceApiErrors = (): boolean =>
		parseBoolean(this._env.REACT_APP_DEVICE_LOG_ERRORS ?? EnumBooleanStringified.FALSE);

	getDeviceGiftIds = () =>
		chain(this._env)
			.get('REACT_APP_DEVICE_GIFT_IDS', '')
			.split(',')
			.map((id) => parseInt(id, 10))
			.compact()
			.value();

	getDebounceTime = (fallback = 400): number =>
		this._env.REACT_APP_INPUT_DEBOUNCE_TIME ? parseInt(this._env.REACT_APP_INPUT_DEBOUNCE_TIME, 10) : fallback;

	getNotificationAnimatedTime = (fallback = 400): number =>
		this._env.REACT_APP_NOTIFICATION_ANIMATED_TIME
			? parseInt(this._env.REACT_APP_NOTIFICATION_ANIMATED_TIME, 10)
			: fallback;

	isAnalyticsEnabled = (): boolean =>
		parseBoolean(this._env.REACT_APP_ANALYTICS_ENABLED ?? EnumBooleanStringified.FALSE);

	getGoogleAnalyticsTrackerNo = (): string => this._env.REACT_APP_GA_TRACKER || '';

	hasGoogleAnalyticsTrackerNo = (): boolean => !isEmpty(this.getGoogleAnalyticsTrackerNo());

	/**
	 * @deprecated Since 2.18.3 Used only on Login page
	 * @param language
	 */
	getLostPasswordLink = (language = SupportedLanguage.DE): string =>
		(this._env.REACT_APP_V_USERS_LOST_PWD_LINK || '').toString().replace('{{language}}', getVUsersLanguage(language));

	getSignUpLink = (language = SupportedLanguage.DE): string =>
		(this._env.REACT_APP_V_USERS_SIGN_UP_LINK || '').toString().replace('{{language}}', getVUsersLanguage(language));

	getMessagesHistoryPageSize = (fallback = 50): number =>
		this._env.REACT_APP_MESSAGES_HISTORY_PAGE_SIZE
			? parseInt(this._env.REACT_APP_MESSAGES_HISTORY_PAGE_SIZE, 10)
			: fallback;

	getInitialWidgetLiveSessionsTotal = (fallback = 3): number =>
		this._env.REACT_APP_WIDGET_LIVE_SESSIONS_TOTAL
			? parseInt(this._env.REACT_APP_WIDGET_LIVE_SESSIONS_TOTAL, 10)
			: fallback;

	getInitialLimitOfVisibleChats = (fallback = 13): number =>
		this._env.REACT_APP_VISIBLE_CHATS_INITIAL_LIMIT
			? parseInt(this._env.REACT_APP_VISIBLE_CHATS_INITIAL_LIMIT, 10)
			: fallback;

	getVisibleChatsLimitIncrement = (fallback = 5): number =>
		this._env.REACT_APP_VISIBLE_CHATS_LIMIT_INCREMENT
			? parseInt(this._env.REACT_APP_VISIBLE_CHATS_LIMIT_INCREMENT, 10)
			: fallback;

	getInitialLimitOfVisibleChannels = (fallback = 15): number =>
		this._env.REACT_APP_VISIBLE_CHANNELS_INITIAL_LIMIT
			? parseInt(this._env.REACT_APP_VISIBLE_CHANNELS_INITIAL_LIMIT, 10)
			: fallback;

	getVisibleChannelsLimitIncrement = (fallback = 5): number =>
		this._env.REACT_APP_VISIBLE_CHANNELS_LIMIT_INCREMENT
			? parseInt(this._env.REACT_APP_VISIBLE_CHANNELS_LIMIT_INCREMENT, 10)
			: fallback;

	getVisibleChannelsSkipIncrement = (fallback = 25): number =>
		this._env.REACT_APP_VISIBLE_CHANNELS_SKIP_INCREMENT
			? parseInt(this._env.REACT_APP_VISIBLE_CHANNELS_SKIP_INCREMENT, 10)
			: fallback;

	getWebNotificationTimeout = (fallback = 500): number =>
		this._env.REACT_APP_WEB_NOTIFICATION_TIMEOUT
			? parseInt(this._env.REACT_APP_WEB_NOTIFICATION_TIMEOUT, 10)
			: fallback;

	getHighlightingMessageTimeout = (fallback = 2000): number =>
		this._env.REACT_APP_HIGHLIGHTING_MESSAGE_TOMEOUT
			? parseInt(this._env.REACT_APP_HIGHLIGHTING_MESSAGE_TOMEOUT, 10)
			: fallback;

	getFocusOnSelectedChannelTimeout = (fallback = 2000): number =>
		this._env.REACT_APP_FOCUS_ON_SELECTED_CHANNEL_TIMEOUT
			? parseInt(this._env.REACT_APP_FOCUS_ON_SELECTED_CHANNEL_TIMEOUT, 10)
			: fallback;

	isWebNotificationInactiveOnly = () => get(this._env, 'REACT_APP_WEB_NOTIFICATION_INACTIVE_ONLY', 'false') === 'true';
	shouldWebNotificationAlwaysRequest = () =>
		parseBoolean(get(this._env, 'REACT_APP_WEB_NOTIFICATION_ALWAYS_REQUEST', EnumBooleanStringified.FALSE));

	/**
	 * @param {number} fallback
	 * @return {number}
	 */
	getArchivedChannelsLimit = (fallback = 100) =>
		parseInt(get(this._env, 'REACT_APP_ARCHIVED_CHANNELS_LIMIT', fallback.toString()), 10);

	getBannedChannelsLimit = (fallback = 100): number =>
		parseInt(get(this._env, 'REACT_APP_BANNED_CHANNELS_LIMIT', fallback.toString()), 10);

	/**
	 * @param {number} fallback
	 * @return {number}
	 */
	getUnAnsweredChannelsLimit = (fallback = 100) =>
		parseInt(get(this._env, 'REACT_APP_UNANSWERED_CHANNELS_LIMIT', fallback.toString()), 10);

	/**
	 * @param {number} fallback
	 * @return {number}
	 */
	getNormalChannelsLimit = (fallback = 25) =>
		parseInt(get(this._env, 'REACT_APP_NORMAL_CHANNELS_LIMIT', fallback.toString()), 10);

	getSearchChannelsLimit = (fallback = 100) =>
		parseInt(get(this._env, 'REACT_APP_SEARCH_CHANNELS_LIMIT', fallback.toString()), 10);

	isVControlDebugEnabled = () =>
		parseBoolean(this._env.REACT_APP_VCONTROL_LOGGER_ENABLE ?? EnumBooleanStringified.FALSE);

	getSpeedTestAlphabet = (): string => this._env.REACT_APP_SPEED_TEST_ALPHABET || '';
	getSpeedTestPayloadSize = (fallback = 128 * 1024): number =>
		parseInt(get(this._env, 'REACT_APP_SPEED_TEST_PAYLOAD_SIZE', `${fallback}`), 10);

	getSpeedTestInterval = (fallback = 150): number =>
		parseInt(get(this._env, 'REACT_APP_SPEED_TEST_INTERVAL', `${fallback}`), 10);

	getBanForeverDuration = (fallback = 3153600000): number =>
		parseInt(get(this._env, 'REACT_APP_BAN_FOREVER_DURATION', `${fallback}`), 10);

	getEnvName = (): EnumEnvironment => get(this._env, 'REACT_APP_ENV', EnumEnvironment.PROD) as EnumEnvironment;
	getRollbarAccessToken = (): string => this._env.REACT_APP_ROLLBAR_CLIENT_ACCESS_TOKEN || '';

	getMessageSuccessStatusTimeout = (fallback = '15') =>
		parseInt(get(this._env, 'REACT_APP_MESSAGE_SUCCESS_STATUS_TIMEOUT', fallback), 10);

	isReduxTimingTrackerEnabled = () =>
		parseBoolean(this._env.REACT_APP_ENABLE_REDUX_TIMING_TRACKER ?? EnumBooleanStringified.FALSE);
	isReduxSelectorStatsEnabled = () =>
		parseBoolean(this._env.REACT_APP_ENABLE_REDUX_SELECTOR_STATS ?? EnumBooleanStringified.FALSE);

	getStageHostName = (): string => this._env.REACT_APP_STAGE_HOST_NAME || '';

	getSimultaneousWebNotificationsLimit = (): number =>
		parseInt(this._env.REACT_APP_SIMULTANEOUS_WEB_NOTIFICATIONS_LIMIT || '3', 10);

	getStoreCheckInterval = (): number => parseInt(this._env.REACT_APP_STORE_CHECK_INTERVAL || '30000', 10);
	isStoreCheckDebugEnabled = (): boolean =>
		parseBoolean(this._env.REACT_APP_STORE_CHECK_DEBUG_ENABLED ?? EnumBooleanStringified.FALSE);
	getStorePruneMessagesLimit = (): number => parseInt(this._env.REACT_APP_STORE_PRUNE_MESSAGES_LIMIT || '500', 10);
	getStorePruneExitedChatsLimit = (): number => parseInt(this._env.REACT_APP_STORE_PRUNE_EXITED_CHATS_LIMIT || '0', 10);

	getPreviewHelpCenterTimeout = () => parseInt(this._env.REACT_APP_PREVIEW_HELP_CENTER_TIMEOUT ?? '3000', 10);

	isPreviewDomain = (domain: string): boolean =>
		isString(this._env.REACT_APP_PREVIEW_HOST_NAME_SUFFIX) &&
		!isEmpty(this._env.REACT_APP_PREVIEW_HOST_NAME_SUFFIX) &&
		domain.endsWith(this._env.REACT_APP_PREVIEW_HOST_NAME_SUFFIX);

	shouldRedirect = (currentHostname: string): boolean => {
		const domain = this.getDomain();

		return (
			parseBoolean(this._env.REACT_APP_REDIRECT_INVALID_DOMAINS ?? EnumBooleanStringified.FALSE) &&
			this.isProduction() &&
			!isEmpty(domain) &&
			!includes([domain, this.getStageHostName(), 'localhost'], currentHostname) &&
			!this.isPreviewDomain(currentHostname)
		);
	};

	shouldShowCamNavigationTab = (): boolean => {
		return parseBoolean(this._env.REACT_APP_SHOW_PLUS_TAB ?? EnumBooleanStringified.FALSE);
	};

	getSnackTimeout = (fallback = '2000') => parseInt(this._env.REACT_APP_SNACK_TIMEOUT ?? fallback, 10);

	getSoftChatWarningTimeout = (fallback = '3000') =>
		parseInt(this._env.REACT_APP_SOFT_CHAT_WARNING_TIMEOUT ?? fallback, 10);

	getTermsOfServiceLink = (language = SupportedLanguage.DE): string =>
		(this._env.REACT_APP_TOS_LINK || '').toString().replace('{{language}}', getVUsersLanguage(language));

	getPrivacyPolicyLink = (language = SupportedLanguage.DE): string =>
		(this._env.REACT_APP_PRIVACY_LINK || '').toString().replace('{{language}}', getVUsersLanguage(language));

	getImprintPrivacyPolicyLink = (language = SupportedLanguage.DE): string =>
		(this._env.REACT_APP_IMPRINT_LINK || '').toString().replace('{{language}}', getVUsersLanguage(language));

	getUserInfoMaxLines = (maxLines = '2') => parseInt(this._env.REACT_APP_USER_INFO_MAX_LINES ?? maxLines, 10);

	shouldIgnoreExpoLogs = (): boolean => {
		return parseBoolean(this._env.REACT_APP_IGNORE_EXPO_LOGS ?? EnumBooleanStringified.FALSE);
	};

	shouldShowInfoChannel = (): boolean => {
		return parseBoolean(this._env.REACT_APP_SHOW_INFO_CHANNEL ?? EnumBooleanStringified.FALSE);
	};

	shouldShowTVChannel = (): boolean => {
		return parseBoolean(this._env.REACT_APP_SHOW_TV_CHANNEL ?? EnumBooleanStringified.TRUE);
	};

	shouldHideBlockedFilter = (): boolean => {
		return parseBoolean(this._env.REACT_APP_HIDE_BLOCKED_FILTER ?? EnumBooleanStringified.TRUE);
	};

	shouldHideFansFilter = (): boolean => {
		return parseBoolean(this._env.REACT_APP_HIDE_FANS_FILTER ?? EnumBooleanStringified.TRUE);
	};

	isAnalyticsDebugEnabled = (): boolean => {
		return parseBoolean(this._env.REACT_APP_ANALYTICS_DEBUG_ENABLED ?? EnumBooleanStringified.FALSE);
	};

	getGitBranch = (): string | undefined => this._env.REACT_APP_GIT_BRANCH;

	maxPinnedChannelLimit = (defaultLimit = '5') =>
		parseInt(this._env.REACT_APP_PINNED_CHANNEL_LIMIT ?? defaultLimit, 10);

	getMaxTicketShows = (fallback = 3) => parseInt(get(this._env, 'REACT_APP_MAX_TICKET_SHOWS', fallback.toString()), 10);

	isLiveTicketShowEnabled = (): boolean =>
		parseBoolean(this._env.REACT_APP_LIVE_TICKET_SHOW_ENABLED ?? EnumBooleanStringified.FALSE);

	getHowManyUnactiveShowsAtStart = (fallback = 2) =>
		parseInt(get(this._env, 'REACT_APP_LIVE_TICKET_SHOW_LIST_INACTIVE_SHOWS_LIMIT', fallback.toString()), 10);

	getLimitedFeaturesUsrId = (): string | undefined => this._env.REACT_APP_LIMITED_FEATURES_USRID;

	getDefaultCanReceiveTicket = (): boolean => {
		return parseBoolean(this._env.REACT_APP_DEFAULT_CAN_RECEIVE_TICKET ?? EnumBooleanStringified.FALSE);
	};

	getMostContactedChannelsNumberToShow = (defaultNumber = '5') =>
		parseInt(this._env.REACT_APP_NUMBER_OF_MOST_CONTACTED_CHANNELS_TO_SHOW ?? defaultNumber, 10);

	getAllMessagesNavigationAllowed = (): boolean =>
		parseBoolean(this._env.REACT_APP_ALL_MESSAGES_NAVIGATION_ALLOWED ?? EnumBooleanStringified.FALSE);

	getMessageHistoryLimit = (): number => parseInt(this._env.REACT_APP_MESSAGE_HISTORY_LIMIT ?? '200', 10);

	getMessageAdditionalLoadingAttempts = (): number =>
		parseInt(this._env.REACT_APP_MESSAGE_ADDITIONAL_LOADING_ATTEMPTS ?? '3', 10);

	startPrivateTicketShowTimeout = (fallback = 5000): number =>
		parseInt(get(this._env, 'REACT_APP_TICKET_SHOWS_TIMEOUT', fallback.toString()), 10);

	getModelShowsLink = (modelName: string): string =>
		(this._env.REACT_APP_MODEL_SHOWS_LINK || '').toString().replace('{{modelName}}', modelName);

	getAllShowsLink = (): string => this._env.REACT_APP_ALL_SHOWS_LIST_LINK || '';

	getPhoneServiceLink = (): string => this._env.REACT_APP_PHONE_SERVICE_LINK || '';

	getIsStreamModeSwitcherHidden = (): boolean =>
		parseBoolean(this._env.REACT_APP_LIVE_TICKET_SHOW_SWITCHER_HIDDEN ?? EnumBooleanStringified.TRUE);

	getMinImageDimension = (): number => parseInt(this._env.REACT_APP_UPLOAD_IMAGE_DIMENSION_LIMIT ?? '150', 10);

	getAreVideoCallsActive = (): boolean => {
		return parseBoolean(this._env.REACT_APP_VIDEO_CALLS_ACTIVE ?? EnumBooleanStringified.FALSE);
	};

	isSipEnabled = (): boolean => parseBoolean(this._env.REACT_APP_SIP_ENABLED ?? EnumBooleanStringified.FALSE);

	sipRealm = (): string => this._env.REACT_APP_SIP_REALM ?? '';

	sipWebsocketUrl = (): string => this._env.REACT_APP_WEBSOCKET_URL ?? '';
}

export default EnvInterfaceService;
