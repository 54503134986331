import _ from 'lodash';
import {EnumMessageType} from 'cmd-control-client-lib';

import EnumMessageTypePart from '@messenger/core/src/BusinessLogic/EnumMessagePartType';
import IMessagePart from '@messenger/core/src/BusinessLogic/IMessagePart';
import EnumVLiveMessageType from '@messenger/core/src/BusinessLogic/EnumVLiveMessageType';
import {twoMinutes} from '@messenger/core/src/BusinessLogic/TimeIntervals';
import {SswMessageType} from '@messenger/core/src/Redux/Messages/Model';
import {MessageViewModel} from '@messenger/core/src/Redux/Messages/MessageViewModel';

export default class MessageHelper {
	static isSystem = (message: SswMessageType) =>
		_.get(message, 'mailType') === 'system' ||
		_.get(message, 'style') === 's' ||
		_.get(message, 'msgType') === (EnumVLiveMessageType.SYSTEM as any as EnumMessageType) ||
		_.get(message, 'msgType') === EnumMessageType.SYSTEM ||
		_.get(message, 'from') === 'system';

	/**
	 * This is function to be used in filter. If returns TRUE - message will be shown,
	 * if FALSE - hidden.
	 */
	static dedupeResult = (
		{messageKey, messageId, isSystem}: MessageViewModel,
		messageKeyToIds: Record<string, string[]>,
	) => {
		const duplicatedMessageIds = messageKey ? _.get(messageKeyToIds, messageKey, []) : [];
		const bySent2all = _.size(duplicatedMessageIds) > 1 && _.head(duplicatedMessageIds) !== messageId;

		return !bySent2all || isSystem;
	};

	static isExternalMessengerLink = (messageText: string) => {
		return !!messageText.match(/<a href=.*target="messenger"/);
	};

	static isOldMessage = (message: SswMessageType, oldTimeFrame: number = twoMinutes) => {
		const nowTime = new Date().getTime();
		const messageTime = parseInt(_.get(message, 'time', nowTime.toString()), 10);

		return messageTime < nowTime - oldTimeFrame;
	};

	static partsAreSmileOnly = (parts: IMessagePart[]): boolean => {
		return parts.filter((part: IMessagePart) => part.type === EnumMessageTypePart.SMILE).length === parts.length;
	};
}
