import {takeEvery} from 'typed-redux-saga';

import {contactNoteClientOnlyActions} from '@messenger/core/src/Redux/ContactNote/Actions/contactNoteClientOnlyActions';
import {updateContactNoteSaga} from '@messenger/core/src/Redux/ContactNote/Sagas/updateContactNoteSaga';

function* contactNoteSagaWatcher() {
	yield* takeEvery(contactNoteClientOnlyActions.updateContactNote.type, updateContactNoteSaga);
}

export default contactNoteSagaWatcher;
