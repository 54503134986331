import {put} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {mediaDeviceClientOnlyActions} from '@messenger/core/src/Redux/MediaDevice/Actions';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';

function* setVideoFacingModeSaga(action: ReturnType<typeof streamClientOnlyActions.setVideoFacingMode>) {
	try {
		yield* put(
			mediaDeviceClientOnlyActions.requestMediaDevicePermissions({
				isMicrophoneRequired: true,
			}),
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'setFacingModeSaga', action});
	}
}

export default setVideoFacingModeSaga;
