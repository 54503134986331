import {takeEvery} from 'typed-redux-saga';

import {channelGroupsServerToClientActions} from '@messenger/core/src/Redux/ChannelGroups/Actions/channelGroupsServerToClientActions';
import processChannelGroupsReceivedSaga from '@messenger/core/src/Redux/ChannelGroups/Sagas/processChannelGroupsReceivedSaga';
import {channelGroupsClientOnlyActions} from '@messenger/core/src/Redux/ChannelGroups/Actions/channelGroupsClientOnlyActions';
import {requestChannelGroupsOnceSaga} from '@messenger/core/src/Redux/ChannelGroups/Sagas/requestChannelGroupsOnceSaga';
import {processAddOrRemoveChannelGroupSaga} from '@messenger/core/src/Redux/ChannelGroups/Sagas/processAddOrRemoveChannelGroupSaga';

function* channelGroupsSagaWatcher() {
	yield* takeEvery(channelGroupsServerToClientActions.channelGroupsReceivedMany, processChannelGroupsReceivedSaga);
	yield* takeEvery(channelGroupsClientOnlyActions.getChannelGroupsOnce, requestChannelGroupsOnceSaga);
	yield* takeEvery(channelGroupsClientOnlyActions.updateGroup, processAddOrRemoveChannelGroupSaga);
}

export default channelGroupsSagaWatcher;
