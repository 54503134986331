import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import getPropsChannelId from '@messenger/core/src/Redux/Channels/Selectors/getPropsChannelId';
import {selectChatVms} from '@messenger/core/src/Redux/Chats/Selectors/defaultSelectors';

const selectChatVmByChannelId = createSelector([selectChatVms, getPropsChannelId], (chatVms, channelId) =>
	_.find(chatVms, (chatVm) => chatVm.channelId === channelId && _.isNil(chatVm.exitCode)),
);

export default selectChatVmByChannelId;
