import {createEntityAdapter} from '@reduxjs/toolkit';
import {CMDC_CHANNELGROUP} from 'cmd-control-client-lib';

export const getChannelGroupKey = (channelId: string, groupId: string) => `${channelId}-${groupId}`;

export const channelGroupsEntityAdapter = createEntityAdapter<TChannelGroup>({
	selectId: (entity: TChannelGroup) => getChannelGroupKey(entity.channelId, entity.groupId),
});

export type TChannelGroup = CMDC_CHANNELGROUP['params'];
