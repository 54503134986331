import _, {parseInt} from 'lodash';
import {createSlice, Dictionary, EntityState} from '@reduxjs/toolkit';
import {EnumMediaState} from 'cmd-control-client-lib';

import {TMessageMediaUploadStatus} from '@messenger/core/src/Redux/Messages/MessageMediaUploadStatus';
import {
	MESSAGE_HISTORY_SUBJECT_ID,
	messagesClientOnlyActions,
	messagesServerToClientActions,
} from '@messenger/core/src/Redux/Messages/Actions';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {messageAdapter} from '@messenger/core/src/Redux/Messages/entityAdapter';
import {messagesClientToServerActions} from '@messenger/core/src/Redux/Messages/Actions/messagesClientToServerActions';
import {EnumReservedChannelIds} from '@messenger/core/src/BusinessLogic/EnumReservedChannelIds';

import {SswMessageType} from './Model';

export const initialState: TMessageState = messageAdapter.getInitialState({
	isLoading: 0,
	isError: false,
	mediaUploadUrl: '',
	mediaUploads: {},
	failedMessageSentData: {},
	sentMessageKeys: {},
	requestedTicketsFor: [],
	sentChannelCount: '',
	messagesHistoryStatuses: {},
	unseenChatMessagesCount: 0,
});

const messagesSlice = createSlice({
	name: EnumStore.MESSAGES,
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(messagesClientOnlyActions.removeByIds, messageAdapter.removeMany);
		builder.addCase(messagesClientOnlyActions.appendMessage, messageAdapter.upsertOne);
		builder.addCase(messagesClientOnlyActions.updateChatMessageTimeSeen, messageAdapter.updateOne);
		builder.addCase(messagesClientOnlyActions.sendMessageWithMediaUpload, (state, {payload: {message, attachment}}) => {
			const {messageId} = message;

			if (messageId) {
				state.mediaUploads[messageId] = {
					messageId,
					attachment,
				};
			}

			return messageAdapter.upsertOne(state, message);
		});
		builder.addCase(messagesClientOnlyActions.removeSentMessageKey, (state, action) => {
			state.sentMessageKeys = _.omit(state.sentMessageKeys, action.payload);
		});
		builder.addCase(messagesServerToClientActions.messageSendResult, (state, action) => {
			const messageKey = _.get(action.payload, 'messageKey', '');

			if (action.error) {
				state.failedMessageSentData[messageKey] = action.meta.result;
			} else {
				state.sentChannelCount = action.meta.values.channelCount;

				state.sentMessageKeys[messageKey] = false;
				messageAdapter.updateOne(state, {
					id: _.get(action.payload, 'messageId', messageKey),
					changes: {
						messageKey: action.payload.messageKey,
						messageId: _.get(action.payload, 'messageId', messageKey),
					},
				});
			}
		});

		builder.addCase(messagesClientToServerActions.setReaction, (state, {payload: {messageId, text}}) => {
			messageAdapter.updateOne(state, {
				id: messageId,
				changes: {
					reaction: text,
				},
			});
		});

		builder.addCase(messagesClientToServerActions.resetReaction, (state, {payload: {messageId}}) => {
			messageAdapter.updateOne(state, {
				id: messageId,
				changes: {
					reaction: '',
				},
			});
		});

		builder.addCase(messagesClientOnlyActions.markUploaded, (state, action) => {
			state.mediaUploads = _.omit(state.mediaUploads, action.payload);
		});
		builder.addCase(messagesClientOnlyActions.setUploadError, (state, action) => {
			const {attachment} = state.mediaUploads[action.payload.messageId] || {};

			state.mediaUploads[action.payload.messageId] = {...action.payload, attachment};
			state.failedMessageSentData[action.payload.messageId] = {
				code: parseInt(`${action.payload.errorCode}`, 10),
				reason: `${action.payload.reason}`,
			};
		});
		builder.addCase(messagesClientOnlyActions.retrySaveMedia, (state, {payload: {messageId}}) => {
			const {attachment} = state.mediaUploads[messageId] || {};

			state.mediaUploads[messageId] = {messageId, attachment};
			state.failedMessageSentData = _.omit({...state.failedMessageSentData}, [messageId]);
			messageAdapter.updateOne(state, {
				id: messageId,
				changes: {
					mediaState: EnumMediaState.TRANSCODING,
				},
			});
		});
		builder.addCase(messagesClientOnlyActions.removeMessageById, messageAdapter.removeOne);
		builder.addCase(messagesClientOnlyActions.resetStore, () => initialState);
		builder.addCase(messagesServerToClientActions.messageReceived, messageAdapter.upsertOne);

		builder.addCase(messagesServerToClientActions.messageWithMediaReceived, (state, {payload}) => {
			if (payload) {
				messageAdapter.updateOne(state, payload);
			}
		});

		builder.addCase(messagesServerToClientActions.messagesHistoryReceived, (state, {meta: {params, commands}}) => {
			const messageHistorySubjectId: string = _.get(params, MESSAGE_HISTORY_SUBJECT_ID);
			const lastMessage = _.last(commands);

			if (messageHistorySubjectId) {
				state.messagesHistoryStatuses = {
					...state.messagesHistoryStatuses,
					[messageHistorySubjectId]: {
						searchAfter: lastMessage?.params.messageId
							? {
									searchAfterId: lastMessage.params.messageId,
									searchAfterTime: parseInt(String(lastMessage.params.time), 10),
							  }
							: undefined,
						endReached: _.size(commands) < parseInt(String(params.limit), 10),
						isReady: true,
						isLoading: false,
					},
				};
			}

			state.isLoading = state.isLoading > 0 ? state.isLoading - 1 : 0;
		});
		builder.addCase(messagesClientToServerActions.requestHistory, (state) => ({
			...state,
			isLoading: state.isLoading + 1,
		}));
		builder.addCase(messagesClientOnlyActions.resetIsLoading, (state) => {
			state.isLoading = initialState.isLoading;
		});
		builder.addCase(messagesClientToServerActions.requestMessagesHistory, (state, {payload}) => {
			state.isLoading = state.isLoading + 1;

			if (payload[MESSAGE_HISTORY_SUBJECT_ID]) {
				const subjectId = payload[MESSAGE_HISTORY_SUBJECT_ID];

				const status = state.messagesHistoryStatuses[subjectId] || {};

				if (!status.isLoading) {
					state.messagesHistoryStatuses[subjectId] = {...status, isLoading: true};
				}
			}
		});
		builder.addCase(messagesClientOnlyActions.addIntroductionMessages, (state, {payload}) => {
			return messageAdapter.upsertMany(
				{
					...state,
					messagesHistoryStatuses: {
						...state.messagesHistoryStatuses,
						[EnumReservedChannelIds.CHANNEL_ID_JOHN_DOE]: {
							endReached: true,
							isReady: true,
							isLoading: false,
							searchAfter: {
								searchAfterId: _.last(payload)?.messageId || '',
								searchAfterTime: 1,
							},
						},
					},
				},
				payload,
			);
		});
		builder.addCase(messagesClientOnlyActions.removeIntroductionMessages, messageAdapter.removeMany);
		builder.addCase(messagesClientOnlyActions.updateIntroductionMessage, messageAdapter.updateOne);
		builder.addCase(messagesClientOnlyActions.setMany, messageAdapter.setMany);
		builder.addCase(messagesClientOnlyActions.addMany, messageAdapter.addMany);
		builder.addCase(messagesClientOnlyActions.setMediaUploadUrl, (state, action) => {
			state.mediaUploadUrl = action.payload;
		});
		builder.addCase(messagesClientToServerActions.sendMessage, (state, action) => {
			state.sentMessageKeys[action.payload.messageKey] = true;
		});
		builder.addCase(messagesClientOnlyActions.highlightMessage, (state, action) => {
			state.messageIdToScroll = action.payload.messageId;
			state.highlightMessageId = action.payload.messageId;
		});
		builder.addCase(messagesClientOnlyActions.stopHighlightingMessage, (state) => {
			state.highlightMessageId = undefined;
		});
		builder.addCase(messagesClientOnlyActions.endScrollToMessage, (state) => {
			state.messageIdToScroll = undefined;
		});
		builder.addCase(messagesClientOnlyActions.setSentChannelCount, (state, action) => {
			state.sentChannelCount = `${action.payload}`;
		});

		builder.addCase(messagesClientOnlyActions.updateMessageChannels, (state, {payload: {id, changes}}) => {
			messageAdapter.updateOne(state, {
				id,
				changes,
			});
		});

		builder.addCase(messagesClientOnlyActions.setUnseenChatMessagesCount, (state, {payload}) => {
			state.unseenChatMessagesCount = payload;
		});
	},
});

export type TMessageState = EntityState<SswMessageType> & {
	isLoading: number;
	isError: boolean;
	mediaUploadUrl: string;
	mediaUploads: Dictionary<TMessageMediaUploadStatus>;
	failedMessageSentData: Dictionary<TMessageSentFailed>;
	sentMessageKeys: Record<string, boolean>;
	messageIdToScroll?: string;
	highlightMessageId?: string;
	requestedTicketsFor: string[];
	sentChannelCount: string | undefined;
	messagesHistoryStatuses: Record<
		string,
		{
			searchAfter?: {searchAfterId: string; searchAfterTime: number};
			endReached?: boolean;
			isLoading?: boolean;
			isReady?: boolean;
		}
	>;
	unseenChatMessagesCount: number;
};

export type TMessageSentFailed = {
	code: number;
	reason: string;
};

export default messagesSlice;
