import _ from 'lodash';
import {PayloadAction} from '@reduxjs/toolkit';
import {call, put, select} from 'typed-redux-saga';
import {EnumSetTp} from 'cmd-control-client-lib';

import EnumPreviewType from '@messenger/core/src/BusinessLogic/EnumPreviewType';
import {selectIsStreamStartedFromCurrentInstance} from '@messenger/core/src/Redux/Stream/Selectors/selectIsStreamStartedFromCurrentInstance';
import {previewClientOnlyActions} from '@messenger/core/src/Redux/Preview/Actions/previewClientOnlyActions';
import {TStreamConnectOptions} from '@messenger/core/src/Redux/Stream/Model';
import EnumStreamType from '@messenger/core/src/BusinessLogic/EnumStreamType';
import {EnumLocalStorageKeys} from '@messenger/core/src/BusinessLogic/EnumLocalStorageKeys';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import EnumStoreType from '@messenger/core/src/BusinessLogic/EnumStoreType';
import {mediaDeviceClientOnlyActions} from '@messenger/core/src/Redux/MediaDevice/Actions';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';
import {EnumStartStreamStep} from '@messenger/core/src/Redux/Stream/slice';
import selectSetTp from '@messenger/core/src/Redux/Session/Selectors/selectSetTp';

function* streamingGoOnlineSaga(action: PayloadAction<TStreamConnectOptions>) {
	try {
		const browser = ServiceFactory.uiContainer;
		const webRtcApi = ServiceFactory.webRtcApi;
		const webRtcSupported: boolean = yield* call([webRtcApi, webRtcApi.checkBrowserSupport]);
		const storeApi = ServiceFactory.store;
		const lastSuccessfulStreamType: EnumStreamType = yield* call(
			[storeApi, storeApi.get],
			EnumLocalStorageKeys.LAST_SUCCESSFUL_STREAM_TYPE,
			{storeType: EnumStoreType.LOCAL},
		);

		const isStreamStartedFromCurrentInstance = yield* select(selectIsStreamStartedFromCurrentInstance);
		const onlineStatus = yield* select(selectSetTp);

		if (!isStreamStartedFromCurrentInstance || onlineStatus === EnumSetTp.OFFLINE) {
			return;
		}

		if (
			!_.isUndefined(action.payload.webRTCConfig) &&
			lastSuccessfulStreamType !== EnumStreamType.EXTERNAL_TOOL &&
			!browser.isIE &&
			webRtcSupported
		) {
			yield* put(mediaDeviceClientOnlyActions.setIsMicDisabled(false));
			yield* put(streamClientOnlyActions.setStartStreamStep(EnumStartStreamStep.WEBRTC_STREAM_CONFIG));
			yield* put(previewClientOnlyActions.changePreviewType(EnumPreviewType.LOCAL));
			yield* put(
				mediaDeviceClientOnlyActions.requestMediaDevicePermissions({
					isMicrophoneRequired: true,
				}),
			);
		} else {
			yield* put(streamClientOnlyActions.startExternalToolStream());
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'streamingGoOnlineSaga', action});
	}
}

export default streamingGoOnlineSaga;
