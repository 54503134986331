import {createEntityAdapter} from '@reduxjs/toolkit';

export const chatSummaryEntityAdapter = createEntityAdapter<TChatSummaryEntity>({
	selectId: (entity) => entity.channelId,
});

export type TChatSummaryEntity = {
	channelId: string;
	FIRST?: string;
	LAST?: string;
	CHATS?: number;
};
