import _ from 'lodash';
import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import {selectFilteredGuestChannelCount} from '@messenger/core/src/Redux/Channels/Selectors/selectFilteredGuestChannelCount';
import {selectTargetSystemGroupChannelsCount} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetSystemGroupChannelsCount';
import {selectCurrentMessageInputId} from '@messenger/core/src/Redux/MessageInput/Selectors/selectCurrentMessageInputId';
import selectIsAudioRecording from '@messenger/core/src/Redux/Attachment/Selectors/selectIsAudioRecording';
import {selectReplyMessageVM} from '@messenger/core/src/Redux/Messages/Selectors/selectReplyMessageVM';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';
import {messageInputClientOnlyActions} from '@messenger/core/src/Redux/MessageInput/Actions/messageInputClientOnlyActions';
import {attachmentClientOnlyActions} from '@messenger/core/src/Redux/Attachment/Actions';
import selectCurrentMessageInput from '@messenger/core/src/Redux/MessageInput/Selectors/selectCurrentMessageInput';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';
import {selectCurrentAttachmentVm} from '@messenger/core/src/Redux/Attachment/Selectors/selectCurrentAttachmentVm';

const sendCurrentMessageSaga = function* () {
	try {
		const guestIdentity = yield* select(selectCurrentGuestIdentity);
		const attachment = yield* select(selectCurrentAttachmentVm);
		const targetChannelsCount = yield* select(selectFilteredGuestChannelCount);
		const targetSystemGroupsCount = yield* select(selectTargetSystemGroupChannelsCount);

		if (
			(!_.includes([EnumGuestType.ALL, EnumGuestType.CHAT], guestIdentity?.guestType) &&
				attachment?.hasAttachmentError) ||
			(guestIdentity?.guestType === EnumGuestType.BULK && !targetChannelsCount && !targetSystemGroupsCount)
		) {
			return;
		}

		const editorState = yield* select(selectCurrentMessageInput);
		const message = _.trim(editorState?.value);
		const isAudioRecording = yield* select(selectIsAudioRecording);

		if (_.isEmpty(message) && _.isUndefined(attachment) && !isAudioRecording) {
			return;
		}

		yield* put(messagesClientOnlyActions.sendMessage({message}));

		if (yield* select(selectReplyMessageVM)) {
			yield* put(clientClientOnlyActions.resetReplyMessageId());
		}

		yield* put(messageInputClientOnlyActions.removeOne(yield* select(selectCurrentMessageInputId)));

		if (isAudioRecording) {
			yield* put(attachmentClientOnlyActions.stopRecordingAudio({recordedSuccessfully: true}));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'sendCurrentMessageSaga'});
	}
};

export default sendCurrentMessageSaga;
