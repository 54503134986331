import {createSlice} from '@reduxjs/toolkit';
import {EnumCurrency, ICurrencyDescription, InitialCurrencies} from 'cmd-control-client-lib';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {currencyClientOnlyActions} from '@messenger/core/src/Redux/Currency/Actions/currencyClientOnlyActions';

const initialState: TCurrencySliceState = {
	currencies: {},
	currentCurrencyShortName: EnumCurrency.EURO,
};

const currencySlice = createSlice({
	name: EnumStore.CURRENCY,
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(currencyClientOnlyActions.setCurrencies, (state, action) => {
			state.currencies = action.payload;
		});
	},
});

export type TCurrentCurrency = {
	shortName: EnumCurrency;
} & ICurrencyDescription;

export type TCurrencySliceState = {
	currencies: InitialCurrencies;
	currentCurrencyShortName: EnumCurrency;
};

export default currencySlice;
