import {createSelector} from '@messenger/core/src/Utils/Redux';

import {selectMessageInputEntities} from './defaultSelectors';
import {selectCurrentMessageInputId} from './selectCurrentMessageInputId';

const selectCurrentMessageInput = createSelector(
	[selectCurrentMessageInputId, selectMessageInputEntities],
	(messageInputId, messageInputEntities) => messageInputEntities[messageInputId] || {id: messageInputId},
);

export default selectCurrentMessageInput;
