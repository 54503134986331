import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import selectRunningChatVMsByFilter from '@messenger/core/src/Redux/Chats/Selectors/selectRunningChatVMsByFilter';

const selectRunningChatVMsByFilterIds = createSelector([selectRunningChatVMsByFilter], (runningChatVMs) =>
	_.map(runningChatVMs, (chatVM) => chatVM.chatId),
);

export default selectRunningChatVMsByFilterIds;
