import {createSlice, SliceCaseReducers} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {defaultState, TLoginFormState} from '@messenger/core/src/Redux/LoginForm/defaultState';
import {loginFormClientOnlyActions} from '@messenger/core/src/Redux/LoginForm/Actions/loginFormClientOnlyActions';

const loginFormSlice = createSlice<TLoginFormState, SliceCaseReducers<TLoginFormState>, EnumStore>({
	name: EnumStore.LOGIN_FORM,
	initialState: defaultState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(loginFormClientOnlyActions.submit, (state, action) => {
			state.rememberMe = action.payload.rememberMe;
			state.password = action.payload.password;
			state.login = action.payload.login;
		});
		builder.addCase(loginFormClientOnlyActions.setDefaultCredentials, (state, action) => {
			state.login = action.payload.login;
			state.rememberMe = action.payload.rememberMe;
			state.passwordHash = action.payload.passwordHash;
		});
		builder.addCase(loginFormClientOnlyActions.submitSent, (state) => {
			state.isLoggingIn = true;
		});
		builder.addCase(loginFormClientOnlyActions.submitResponseReceived, (state) => {
			state.isLoggingIn = false;
		});
		builder.addCase(loginFormClientOnlyActions.setError, (state, action) => {
			state.error = action.payload;
		});
		builder.addCase(loginFormClientOnlyActions.resetError, (state) => {
			state.error = '';
		});
		builder.addCase(loginFormClientOnlyActions.disableAutoLogin, (state) => {
			state.autoLogin = false;
		});
		builder.addCase(loginFormClientOnlyActions.enableAutoLogin, (state) => {
			state.autoLogin = true;
		});
		builder.addCase(loginFormClientOnlyActions.cleanUp, (state) => {
			state.rememberMe = false;
			state.login = '';
			state.password = '';
			state.passwordHash = '';
		});
		builder.addCase(loginFormClientOnlyActions.setAutoLoginProcessed, (state, {payload}) => {
			state.isAutoLoginProcessed = payload;
		});
	},
});

export default loginFormSlice;
