import _ from 'lodash';

import {selectChannelIds} from '@messenger/core/src/Redux/Channels/Selectors/defaultSelectors';
import {createSelector} from '@messenger/core/src/Utils/Redux/createSelector';

import getPropsChannelId from './getPropsChannelId';

const getChannelExists = createSelector([selectChannelIds, getPropsChannelId], (channelIds, channelId) =>
	_.includes(channelIds, channelId),
);

export default getChannelExists;
