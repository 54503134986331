import _ from 'lodash';
import {call} from 'typed-redux-saga';
import moment from 'moment';

import changeLanguageOnClientSaga from '@messenger/core/src/Redux/Client/Sagas/changeLanguageOnClientSaga';
import {TSessionUpdatedAction} from '@messenger/core/src/Redux/Session/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';

export const syncServerLanguageOnClientSaga = function* ({payload}: TSessionUpdatedAction) {
	try {
		const i18n = ServiceFactory.i18n;
		const language = _.get(payload, 'language');

		if (!_.isUndefined(language) && (language !== i18n.language || language !== moment.locale())) {
			yield* call(changeLanguageOnClientSaga, clientClientOnlyActions.changeLanguage(language));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'syncServerLanguageOnClientSaga'});
	}
};
