import {createSelector} from '@reduxjs/toolkit';
import _ from 'lodash';

import {selectCustomGroupEntitiesVMs} from '@messenger/core/src/Redux/Groups/Selectors/selectCustomGroupEntitiesVMs';
import {_selectGroupsIds} from '@messenger/core/src/Redux/Groups/Selectors/defaultSelectors';

export const selectCustomGroupVMs = createSelector(
	[selectCustomGroupEntitiesVMs, _selectGroupsIds],
	(groupEntityVMs, groupsIds) =>
		_.chain(groupsIds)
			.map((groupId) => groupEntityVMs[groupId])
			.compact()
			.value(),
);
