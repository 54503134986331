import {put, select} from 'typed-redux-saga';
import _ from 'lodash';

import {channelsMapClientOnlyActions} from '@messenger/core/src/Redux/ChannelsMap/Actions/channelsMapClientOnlyActions';
import {selectCurrentRange} from '@messenger/core/src/Redux/ChannelsMap/Selectors/selectCurrentRange';
import {selectChannelsMapEntities} from '@messenger/core/src/Redux/ChannelsMap/Selectors/defaultSelectors';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {TChannelsMap} from '@messenger/core/src/Redux/ChannelsMap/types';

export const refreshCurrentRangeSaga = function* () {
	try {
		const currentRange = yield* select(selectCurrentRange);

		if (!currentRange) {
			return;
		}

		let firstGapIndex: number = -1;
		const channelsMap = (yield* select(selectChannelsMapEntities)) as Record<string, TChannelsMap>;
		const channelsMapCount = _.size(channelsMap);

		for (let index = 0; index < channelsMapCount; index++) {
			const channelsMapItem = channelsMap[index];

			if (!channelsMapItem) {
				firstGapIndex = index;

				break;
			}
		}

		if ((firstGapIndex === -1 && currentRange.endIndex < channelsMapCount) || currentRange.endIndex < firstGapIndex) {
			return;
		}

		const toRemove = _.chain(channelsMap)
			.reduce((result: number[], channelsMapItem) => {
				if (
					(channelsMapItem.id < currentRange.startIndex || channelsMapItem.id > currentRange.endIndex) &&
					firstGapIndex !== -1 &&
					channelsMapItem.id > firstGapIndex
				) {
					result.push(channelsMapItem.id);
				}

				return result;
			}, [])
			.value();

		if (!_.isEmpty(toRemove)) {
			yield* put(channelsMapClientOnlyActions.removeMany(toRemove));
		}

		yield* put(channelsMapClientOnlyActions.requestChannelsMap(currentRange));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'refreshCurrentRangeSaga'});
	}
};
