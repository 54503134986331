import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectChatVms} from '@messenger/core/src/Redux/Chats/Selectors/defaultSelectors';

const selectActiveChats = createSelector([selectChatVms], (chatVms) =>
	_.filter(chatVms, (chatVm) => !!chatVm && !chatVm.exitCode),
);

export default selectActiveChats;
