import { ICOMMAND, ACTION, IRESPONSE, RESULT, IKeyMaybeValue } from "../cmd-protocol";

export type clientID = { clientID: string; _iid: string };

export enum EnumClientStatusType {
  AUDIO_UNMUTED = "11",
  AUDIO_MUTED = "12",
  STREAM_STATS = "15",
  PLAYER_EVENT = "16",
  PAGE_HIDDEN = "20",
  PAGE_VISIBLE = "21",
  PROXY_STATE_INFO = "22",
  MEDIA_PLAYER_EVENT = "23",
  WEBRTC_STATS = "24",
}

export class CMDP_CSTATUS implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_CSTATUS;
  public params: clientID & {
    statusCode: EnumClientStatusType;
    text: string;
  };
}

export class CMDP_CSTATUS_RESPONSE extends CMDP_CSTATUS implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}

export class CMDP_CINIT implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_CINIT;
  public params: clientID & {
    version: string;
    initData: string;
    messengerProperties: string;
  };
}

export class CMDP_CINIT_RESPONSE extends CMDP_CINIT implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}
