import _ from 'lodash';
import {EnumBooleanStringified, CMDC_CONTACTNOTE} from 'cmd-control-client-lib';

import {parseBoolean} from '@messenger/core/src/Utils/StringUtil';

export enum EnumContactNoteField {
	FIRSTNAME = 'firstName',
	LASTNAME = 'lastName',
	AGE = 'age',
	CITY = 'city',
	NOTES = 'notes',
}

export class ContactNoteViewModel {
	readonly saving: boolean = false;
	readonly regularCustomer: boolean = false;
	readonly firstName: string = '';
	readonly lastName: string = '';
	readonly age: string = '';
	readonly city: string = '';
	readonly notes: string = '';

	constructor(note?: Partial<CMDC_CONTACTNOTE['params']>) {
		this.regularCustomer = parseBoolean(_.get(note, 'regularCustomer', EnumBooleanStringified.FALSE));
		this.firstName = _.get(note, EnumContactNoteField.FIRSTNAME, '');
		this.lastName = _.get(note, EnumContactNoteField.LASTNAME, '');
		this.age = _.get(note, EnumContactNoteField.AGE, '');
		this.city = _.get(note, EnumContactNoteField.CITY, '');
		this.notes = _.get(note, EnumContactNoteField.NOTES, '');
	}
}
