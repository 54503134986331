import _ from 'lodash';
import {call, put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {messagesClientToServerActions} from '@messenger/core/src/Redux/Messages/Actions';
import {selectRunningChatIds} from '@messenger/core/src/Redux/Chats/Selectors/selectRunningChatIds';
import {ALL_CHATS} from '@messenger/core/src/BusinessLogic/Constants';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

export default function* processChatsReceivedSaga() {
	try {
		const chatIds = yield* select(selectRunningChatIds);
		const guestIdentity = yield* select(selectCurrentGuestIdentity);

		if (!_.isEmpty(chatIds)) {
			yield* put(messagesClientToServerActions.requestHistory({chatIDs: chatIds}));
		}

		if (guestIdentity?.guestType === EnumGuestType.CHAT && !_.includes(chatIds, guestIdentity.chatId)) {
			const {goToChatMessages} = yield* getNavigationContext();

			yield* call(goToChatMessages, ALL_CHATS);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processChatsReceivedSaga'});
	}
}
