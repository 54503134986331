import {PayloadAction, Update} from '@reduxjs/toolkit';
import _ from 'lodash';
import {put, select} from 'typed-redux-saga';
import {ChatFlags} from 'cmd-control-client-lib';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {chatsClientOnlyActions} from '@messenger/core/src/Redux/Chats/Actions';
import IChat from '@messenger/core/src/Redux/Chats/Model';
import {selectChatVmById, getChatIdForSelector} from '@messenger/core/src/Redux/Chats/Selectors/defaultSelectors';
import {ChatViewModel} from '@messenger/core/src/Redux/Chats/ViewModel';

export const syncVoyeurShownFlagSaga = function* (action: PayloadAction<Update<IChat>>) {
	try {
		const chat: ChatViewModel | undefined = yield* select(
			selectChatVmById,
			getChatIdForSelector(action.payload.id.toString()),
		);

		if (_.isUndefined(chat) || (action.payload.changes.isVoyeur && chat.isVoyShown)) {
			return;
		}

		yield* put(
			chatsClientOnlyActions.forceFlags({
				chatID: chat.chatId,
				flags: _.uniq([...chat.flags, ChatFlags.VOYSHOWN]).join(','),
			}),
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'syncVoyeurShownFlagSaga'});
	}
};
