import {createSlice} from '@reduxjs/toolkit';
import _ from 'lodash';
import {CMDC_STREAMSTATE} from 'cmd-control-client-lib';

import {EnumChatMode} from '@messenger/core/src/BusinessLogic/EnumChatMode';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {streamClientToServerActions, streamServerToClientActions} from '@messenger/core/src/Redux/Stream/Actions';
import EnumStreamType from '@messenger/core/src/BusinessLogic/EnumStreamType';
import IBrowserCapabilities from '@messenger/core/src/Redux/Stream/IBrowserCapabilities';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';

import {defaultStreamConnectOptions} from './defaultStreamConnectOptions';
import {TStreamConnectOptions} from './Model';

export const initialState: TStartStream = {
	startStreamStep: undefined,
	streamType: undefined,
	streamMode: EnumChatMode.CHAT,
	isStreamStartedFromCurrentInstance: false,
	streamConnectOptions: defaultStreamConnectOptions,
	isWebRtcOutputStreamActive: false,
	browserCapabilities: {},
	state: undefined,
	isLoading: false,
};

const streamSlice = createSlice({
	name: EnumStore.STREAM,
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(streamClientOnlyActions.setStartStreamStep, (state, action) => {
			state.startStreamStep = action.payload;
		});
		builder.addCase(streamClientOnlyActions.resetStartStreamStep, (state) => {
			state.startStreamStep = undefined;
		});
		builder.addCase(streamClientOnlyActions.setStreamMode, (state, action) => {
			state.streamMode = action.payload;
		});
		builder.addCase(streamClientOnlyActions.startExternalToolStream, (state) => {
			state.streamType = EnumStreamType.EXTERNAL_TOOL;
		});
		builder.addCase(streamClientOnlyActions.startWebRTCStream, (state) => {
			state.streamType = EnumStreamType.WEB_RTC;
			state.isWebRtcOutputStreamActive = true;
		});
		builder.addCase(streamClientOnlyActions.stopStream, (state) => {
			state.streamType = undefined;
			state.isWebRtcOutputStreamActive = false;
			state.isLoading = false;
			state.startingShowChannelId = undefined;
		});
		builder.addCase(streamClientOnlyActions.setIsStreamStartedFromCurrentInstance, (state, {payload}) => {
			state.isStreamStartedFromCurrentInstance = payload;
		});
		builder.addCase(streamClientOnlyActions.setBrowserCapabilities, (state, action) => {
			state.browserCapabilities = action.payload;
		});
		builder.addCase(streamClientOnlyActions.resetBrowserCapabilities, (state) => {
			state.browserCapabilities = {};
		});
		builder.addCase(streamClientOnlyActions.resetStateState, (state) => {
			state.state = undefined;
		});
		builder.addCase(streamClientOnlyActions.stopPrivateTicketShowWithError, (state, action) => {
			state.error = action.payload;
			state.isLoading = false;
		});
		builder.addCase(streamClientOnlyActions.resetErrorAndMeta, (state) => {
			state.error = undefined;
			state.isLoading = false;
			state.startingShowChannelId = undefined;
		});
		builder.addCase(streamServerToClientActions.configReceived, (state, action) => {
			state.streamConnectOptions = action.payload;
		});
		builder.addCase(streamServerToClientActions.configUpdated, (state, action) => {
			state.streamConnectOptions = action.payload;
		});
		builder.addCase(streamServerToClientActions.stateChanged, (state, action) => {
			state.state = action.payload;
		});
		builder.addCase(streamServerToClientActions.streamRestartReceived, (state, action) => {
			state.streamConnectOptions = action.payload;
		});
		builder.addCase(streamClientToServerActions.startPrivateTicketShow, (state, action) => {
			state.startingShowChannelId = action.payload.channelId;
			state.isLoading = true;
			state.error = undefined;
		});
		builder.addCase(streamServerToClientActions.startPrivateShowResponseReceived, (state, action) => {
			// set error, if any
			const error = _.get(action, 'error', false);

			if (error) {
				state.error = error;
				state.isLoading = false;
			}
		});
	},
});

export type TStartStream = {
	startStreamStep?: EnumStartStreamStep;
	streamType?: EnumStreamType;
	streamMode: EnumChatMode;
	streamConnectOptions: TStreamConnectOptions;
	isStreamStartedFromCurrentInstance: boolean;
	isWebRtcOutputStreamActive: boolean;
	browserCapabilities: THaveAudioAndVideoCodecs;
	state?: CMDC_STREAMSTATE['params'];
	startingShowChannelId?: string;
	error?: string;
	isLoading: boolean;
};

export enum EnumStartStreamStep {
	START_STREAM_PROMPT = 'START_STREAM_PROMPT',
	WEBRTC_STREAM_CONFIG = 'WEBRTC_STREAM_CONFIG',
	THIRD_PARTY_STREAM_CONFIG = 'THIRD_PARTY_STREAM_CONFIG',
	WAITING_STREAM_ACCEPTED = 'WAITING_STREAM_ACCEPTED',
}

export type THaveAudioAndVideoCodecs = Pick<IBrowserCapabilities, 'videoCodecs' | 'audioCodecs'>;

export default streamSlice;
