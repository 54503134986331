import {Action} from 'redux';

import EnumActions from '@messenger/core/src/Redux/SpeedTest/EnumActions';
import {speedTestServerToClientActions} from '@messenger/core/src/Redux/SpeedTest/Actions/speedTestServerToClientActions';

abstract class ActionCreator {
	static markTestStart = (): IMarkTestStartAction => ({
		type: EnumActions.START,
		time: new Date(),
	});

	static setSpeedTestUrl = ({speedTestUrl}: {speedTestUrl: string}): IMarkSetSpeedTestUrlAction => ({
		type: EnumActions.SET_SPEED_TEST_URL,
		speedTestUrl,
	});

	/**
	 * @note in milliseconds
	 */
	static markProgress = (progress: number, measured: number): IMarkProgressAction => ({
		type: EnumActions.MARK_PROGRESS,
		progress,
		measured,
	});

	static markEnd = (): IMarkTestEndAction => ({
		type: EnumActions.END,
	});

	static markTestSuccess = (): ISpeedTestSuccessAction => ({
		type: EnumActions.SUCCESS,
	});

	static setError = (error: string): ISpeedTestFailedAction => ({
		type: EnumActions.ERROR,
		error,
	});

	static reset = (): ISpeedTestResetAllAction => ({
		type: EnumActions.RESET,
	});

	static resetError = (): ISpeedTestResetErrorAction => ({
		type: EnumActions.RESET_ERROR,
	});
}

export interface ISpeedTestMeta extends Object {
	testID: string;
	seconds: number;
	dataURL: string;
	dataID: string;
}

type IMarkTestEndAction = Action<EnumActions.END>;
type ISpeedTestSuccessAction = Action<EnumActions.SUCCESS>;
type ISpeedTestResetErrorAction = Action<EnumActions.RESET_ERROR>;
type ISpeedTestResetAllAction = Action<EnumActions.RESET>;

interface IMarkTestStartAction extends Action<EnumActions.START> {
	time: Date;
}

interface IMarkProgressAction extends Action<EnumActions.MARK_PROGRESS> {
	progress: number;
	measured: number;
}

interface ISpeedTestFailedAction extends Action<EnumActions.ERROR> {
	error: string;
}

interface ISpeedTestStartReceivedAction extends Action<typeof speedTestServerToClientActions.startTestReceived.type> {
	payload: ISpeedTestMeta;
}

interface IMarkSetSpeedTestUrlAction extends Action<EnumActions.SET_SPEED_TEST_URL> {
	speedTestUrl: string;
}

export type IAnySpeedTestAction =
	| IMarkTestStartAction
	| IMarkProgressAction
	| IMarkTestEndAction
	| ISpeedTestFailedAction
	| ISpeedTestResetAllAction
	| ISpeedTestSuccessAction
	| ISpeedTestResetErrorAction
	| ISpeedTestStartReceivedAction
	| IMarkSetSpeedTestUrlAction;

export default ActionCreator;
