import {ACTION, channelIdType, EnumTicketType, MediaOffer, Ticket} from 'cmd-control-client-lib';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class MediaPricesClientToServerActions extends ClientToServerActions<EnumStore.MEDIA_PRICES> {
	readonly scope = EnumStore.MEDIA_PRICES;

	getByTargetChannelIds = this.createAction(
		ACTION.CMDP_SMEDIAGETPRICES,
		({targetChannelIds}: {targetChannelIds: string[]}) => ({
			payload: {
				channelId: targetChannelIds.join(','),
			},
		}),
	);

	openMediaOffer = this.createAction(ACTION.CMDP_SOPENMEDIAOFFER, (payload: TOpenMediaOffer) => ({
		payload: {
			ticketType: EnumTicketType.SingleC2C,
			isChargeable: true,
			...payload,
		},
	}));
}

type TOpenMediaOffer = channelIdType &
	MediaOffer &
	Ticket & {
		text?: string;
	};

export const mediaPricesClientToServerActions = new MediaPricesClientToServerActions();
