import _ from 'lodash';

import {channelVmRejector} from '@messenger/core/src/BusinessLogic/Channels/channelVmRejector';
import ChannelVM from '@messenger/core/src/Redux/Channels/ChannelVM';
import {selectChannelFilterMatcher} from '@messenger/core/src/Redux/Channels/Selectors/selectChannelFilterMatcher';
import {selectChannelVMs} from '@messenger/core/src/Redux/Channels/Selectors/defaultSelectors';
import selectRunningChatVMsByFilter from '@messenger/core/src/Redux/Chats/Selectors/selectRunningChatVMsByFilter';
import selectGuestTypeFilter from '@messenger/core/src/Redux/Client/Selectors/GuestTypeFilter/selectGuestTypeFilter';
import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectTotalMessagesGroupedByChannelId} from '@messenger/core/src/Redux/Messages/Selectors/defaultSelectors';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

export const selectChannelVMsListByFilter = createSelector(
	[
		selectGuestTypeFilter,
		selectChannelVMs,
		selectChannelFilterMatcher,
		selectRunningChatVMsByFilter,
		selectTotalMessagesGroupedByChannelId,
	],
	(guestTypeFilter, channelVMs, channelFilterMatcher, runningChats, totalMessagesCount) => {
		if (guestTypeFilter === EnumGuestType.CHAT) {
			return [];
		}

		return _.reject(channelVMs, (channelVM: ChannelVM) =>
			channelVmRejector(channelVM, channelFilterMatcher, runningChats, totalMessagesCount),
		);
	},
);
