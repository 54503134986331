import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectMediaEntities} from '@messenger/core/src/Redux/Media/Selectors/defaultSelectors';
import AttachmentVM, {getAttachmentId} from '@messenger/core/src/Redux/Attachment/AttachmentVM';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

import {_selectAttachmentEntities} from './defaultSelectors';

export const selectCurrentAttachmentVm = createSelector(
	[_selectAttachmentEntities, selectMediaEntities, selectCurrentGuestIdentity],
	(attachmentEntities, mediaEntities, guestIdentity) => {
		const attachment = attachmentEntities[getAttachmentId(guestIdentity?.channelId)];

		if (!attachment || _.includes([EnumGuestType.ALL, EnumGuestType.CHAT], guestIdentity?.guestType)) {
			return undefined;
		}

		const mediaMd5 = _.get(attachment, 'mediaMd5');

		const media = mediaMd5 ? mediaEntities[mediaMd5] : undefined;

		return new AttachmentVM(attachment, media);
	},
);
