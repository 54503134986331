import {call, put, select} from 'typed-redux-saga';
import _ from 'lodash';
import {AnyAction} from 'redux';
import {batchActions} from 'redux-batched-actions';

import {channelsClientOnlyActions, channelsClientToServerActions} from '@messenger/core/src/Redux/Channels/Actions';
import {ALL_CHATS, BULK} from '@messenger/core/src/BusinessLogic/Constants';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {chatsClientOnlyActions} from '@messenger/core/src/Redux/Chats/Actions';
import {selectCam2CamPreviewIds} from '@messenger/core/src/Redux/Cam2CamPreview/Selectors/defaultSelectors';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';
import {selectTeamChannelId} from '@messenger/core/src/Redux/Channels/Selectors/selectTeamChannelId';
import {selectIsCam2CamAreaOpen} from '@messenger/core/src/Redux/Client/Selectors/selectIsCam2CamAreaOpen';
import {selectIsLoggedIn} from '@messenger/core/src/Redux/Session/Selectors/selectIsLoggedIn';
import {routingClientOnlyActions} from '@messenger/core/src/Redux/Routing/Actions/routingClientOnlyActions';
import {messageInputClientOnlyActions} from '@messenger/core/src/Redux/MessageInput/Actions/messageInputClientOnlyActions';
import {getAttachmentId} from '@messenger/core/src/Redux/Attachment/AttachmentVM';
import {attachmentClientOnlyActions} from '@messenger/core/src/Redux/Attachment/Actions';
import {channelsMapClientOnlyActions} from '@messenger/core/src/Redux/ChannelsMap/Actions/channelsMapClientOnlyActions';
import {selectSessionUsrKey} from '@messenger/core/src/Redux/Session/Selectors/selectSessionUsrKey';
import {selectSessionUsrId} from '@messenger/core/src/Redux/Session/Selectors/selectSessionUsrId';
import {EnumReservedChannelIds} from '@messenger/core/src/BusinessLogic/EnumReservedChannelIds';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import {mediaPricesClientOnlyActions} from '@messenger/core/src/Redux/MediaPrice/Actions/mediaPricesClientOnlyActions';
import {
	getChannelIdForSelector,
	selectChannelVMById,
} from '@messenger/core/src/Redux/Channels/Selectors/defaultSelectors';
import {channelGroupsClientOnlyActions} from '@messenger/core/src/Redux/ChannelGroups/Actions/channelGroupsClientOnlyActions';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';
import {selectChatVmById, getChatIdForSelector} from '@messenger/core/src/Redux/Chats/Selectors/defaultSelectors';

export const updateStateOnChatNavigationSaga = function* ({
	payload: {channelId, chatId, isBulk = false},
}: ReturnType<typeof routingClientOnlyActions.updateStateAfterNavigation>) {
	try {
		const isLoggedIn = yield* select(selectIsLoggedIn);

		if (!isLoggedIn) {
			return;
		}

		const {goToChatMessages} = yield* getNavigationContext();
		const guestIdentity = yield* select(selectCurrentGuestIdentity);
		let batchedActions: AnyAction[] = [];
		let actions: AnyAction[] = [];

		if (!isBulk && guestIdentity?.guestType === EnumGuestType.BULK) {
			yield* put(messageInputClientOnlyActions.removeOne(BULK));
			yield* put(channelsClientOnlyActions.resetTargetsSelection());
			yield* put(
				attachmentClientOnlyActions.detach({
					attachmentId: getAttachmentId(),
				}),
			);
		}

		if (channelId) {
			const userKey = yield* select(selectSessionUsrKey);
			const userId = yield* select(selectSessionUsrId);

			if (
				channelId !== EnumReservedChannelIds.CHANNEL_ID_JOHN_DOE &&
				userId &&
				userKey &&
				channelId.indexOf(`${userId}.${userKey}`) !== 0
			) {
				yield* call(goToChatMessages, ALL_CHATS, {replace: true});

				return;
			}

			if (guestIdentity?.guestType !== EnumGuestType.CHANNEL || channelId !== guestIdentity.channelId) {
				batchedActions = [
					chatsClientOnlyActions.resetSelected(),
					channelsClientOnlyActions.setSelected(channelId),
					channelsClientOnlyActions.setTargets({channelIds: [channelId]}),
					clientClientOnlyActions.forceDrawer({isOpened: false}),
				];

				actions = [
					channelsMapClientOnlyActions.processChannelSelection({channelId}),
					channelGroupsClientOnlyActions.getChannelGroupsOnce({channelId}),
					mediaPricesClientOnlyActions.requestPrices(),
				];

				if (channelId === (yield* select(selectTeamChannelId))) {
					batchedActions.push(clientClientOnlyActions.closeUserInfoArea());
				} else {
					batchedActions.push(clientClientOnlyActions.setUserAreaChannelId({channelId}));
				}

				if (yield* select(selectIsCam2CamAreaOpen)) {
					batchedActions.push(clientClientOnlyActions.closeCam2CamArea());
				}
			}
		} else if (chatId) {
			if (guestIdentity?.guestType !== EnumGuestType.CHAT || chatId !== guestIdentity.chatId) {
				batchedActions = [
					channelsClientOnlyActions.resetSelected(),
					channelsClientOnlyActions.resetTargetsSelection(),
					messagesClientOnlyActions.setUnseenChatMessagesCount(0),
					clientClientOnlyActions.forceDrawer({isOpened: false}),
				];

				const cam2CamPreviewChatIds = yield* select(selectCam2CamPreviewIds);

				if (chatId === ALL_CHATS) {
					if (!_.isEmpty(cam2CamPreviewChatIds)) {
						batchedActions.push(clientClientOnlyActions.openCam2CamArea({chatId: guestIdentity?.chatId}));
					}

					batchedActions.push(chatsClientOnlyActions.selectChat(ALL_CHATS));
				} else {
					const chatVM = yield* select(selectChatVmById, getChatIdForSelector(chatId));

					if (chatVM) {
						if (
							chatVM.channelId &&
							_.includes(cam2CamPreviewChatIds, chatId) &&
							!ServiceFactory.uiContainer.isMobile(false)
						) {
							batchedActions.push(clientClientOnlyActions.openUserInfoArea({channelId: chatVM.channelId}));
						}

						batchedActions.push(chatsClientOnlyActions.selectChat(chatId));

						if (chatVM.channelId) {
							actions.push(channelGroupsClientOnlyActions.getChannelGroupsOnce({channelId: chatVM.channelId}));
							batchedActions.push(clientClientOnlyActions.setUserAreaChannelId({channelId: chatVM.channelId}));

							const channelVm = yield* select(selectChannelVMById, getChannelIdForSelector(chatVM.channelId));

							if (channelVm?.unseenCount) {
								actions.push(channelsClientToServerActions.markChannelSeen({channelId: chatVM.channelId}));
							}
						}

						const teamChannelId: string | undefined = yield* select(selectTeamChannelId);

						if (chatVM.isVoyeur || chatVM.isAdmin || chatVM.channelId === teamChannelId) {
							batchedActions.push(clientClientOnlyActions.closeUserInfoArea());
						}

						if (chatVM.isGroupChat) {
							actions.push(chatsClientOnlyActions.setIsGroupChatExpanded(true));
						}

						actions.push(channelsMapClientOnlyActions.processChatSelection({chatId}));
					} else {
						yield* call(goToChatMessages, ALL_CHATS, {replace: true});

						return;
					}
				}
			}
		} else {
			if (
				!channelId &&
				!chatId &&
				(guestIdentity?.guestType === EnumGuestType.CHAT ||
					guestIdentity?.guestType === EnumGuestType.CHANNEL ||
					guestIdentity?.guestType === EnumGuestType.ALL)
			) {
				batchedActions = [
					channelsClientOnlyActions.resetSelected(),
					chatsClientOnlyActions.resetSelected(),
					channelsClientOnlyActions.resetTargetsSelection(),
					clientClientOnlyActions.forceDrawer({isOpened: false}),
				];
			}
		}

		if (isBulk !== (guestIdentity?.guestType === EnumGuestType.BULK)) {
			batchedActions.push(clientClientOnlyActions.setIsBulkMessage(isBulk));
		}

		if (!_.isEmpty(batchedActions)) {
			yield* put(batchActions(batchedActions));
		}

		for (const i in actions) {
			yield* put(actions[i]);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'updateStateOnChatNavigationSaga', payload: {channelId, chatId}});
	}
};
