import {put} from 'typed-redux-saga';
import _ from 'lodash';
import {ACTION} from 'cmd-control-client-lib';

import {channelsMapClientOnlyActions} from '@messenger/core/src/Redux/ChannelsMap/Actions/channelsMapClientOnlyActions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {channelsClientOnlyActions} from '@messenger/core/src/Redux/Channels/Actions';
import {chatsClientOnlyActions} from '@messenger/core/src/Redux/Chats/Actions';

export const processChannelsUpdateSaga = function* ({
	type,
	payload,
	meta,
}: ReturnType<typeof channelsClientOnlyActions.upsertMany | typeof chatsClientOnlyActions.upsertMany>) {
	try {
		const channelIds: string[] =
			channelsClientOnlyActions.upsertMany.type === type
				? _.keys(payload)
				: _.chain(meta)
						.get('commands')
						.filter(
							({action, params}) =>
								_.includes([ACTION.CMDC_CINIT, ACTION.CMDC_CEXIT], action) && Boolean(params.channelId),
						)
						.map('params.channelId')
						.uniq()
						.value();

		if (!_.size(channelIds)) {
			return;
		}

		yield* put(channelsMapClientOnlyActions.startChannelsMapUpdate({channelIds}));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processChannelsUpdateSaga'});
	}
};
