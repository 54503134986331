import _ from 'lodash';
import {put} from 'typed-redux-saga';
import {ResultCode} from 'cmd-control-client-lib';

import {toyClientOnlyActions} from '@messenger/core/src/Redux/Toy/Actions/ToyClientOnlyActions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {sessionServerToClientActions} from '@messenger/core/src/Redux/Session/Actions';
import {parseBoolean} from '@messenger/core/src/Utils/StringUtil';

export const toggleToysPollingSaga = function* (action: ReturnType<typeof sessionServerToClientActions.loggedIn>) {
	try {
		// only send on successful login
		if (_.has(action, 'meta.result.code') && action.meta.result.code !== ResultCode.OK) {
			return;
		}

		yield* put(toyClientOnlyActions.markProfileEnabled(parseBoolean(action.payload.showToyTab)));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'toggleToysPollingSaga', action});
	}
};
