import {call} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {MAIN_PREVIEW_ID} from '@messenger/core/src/Services/JpegSocketPreviewApi/AbstractJpegSocketPreviewApi';

function* closeMainPreviewSocketSaga() {
	try {
		const api = ServiceFactory.jpegSocketPreviewApi;

		yield* call([api, api.removePreview], MAIN_PREVIEW_ID);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'closeMainPreviewSocketSaga'});
	}
}

export default closeMainPreviewSocketSaga;
