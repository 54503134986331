import {PayloadAction, Update} from '@reduxjs/toolkit';
import {put} from 'typed-redux-saga';
import {ACTION, CMDP_SUPLOADMEDIA_RESPONSE, ResultCode} from 'cmd-control-client-lib';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import {SswMessageType} from '@messenger/core/src/Redux/Messages/Model';

const updateUploadStatusSaga = function* (
	action: PayloadAction<Update<SswMessageType>, ACTION.CMDP_SUPLOADMEDIA, CMDP_SUPLOADMEDIA_RESPONSE>,
) {
	try {
		if (action.meta.result.code !== ResultCode.OK) {
			const uploadError = {
				messageId: String(action.payload.id),
				errorCode: action.meta.result.code,
				reason: action.meta.result.reason,
			};

			yield* put(messagesClientOnlyActions.setUploadError(uploadError));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'updateUploadStatusSaga'});
	}
};

export default updateUploadStatusSaga;
