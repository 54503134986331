import {createEntityAdapter} from '@reduxjs/toolkit';
import _ from 'lodash';

import {SswMessageType} from '@messenger/core/src/Redux/Messages/Model';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

export const temporaryIdPrefix = 'TEMPORARY-';

export const getTemporaryMessageId = (messageKey?: string) => `${temporaryIdPrefix}${messageKey}`;
export const isTemporaryMessageId = (messageId: string) => messageId.indexOf(temporaryIdPrefix) === 0;

export const getMessageId = (message: SswMessageType) => {
	if (message.messageId) {
		return message.messageId;
	}

	ServiceFactory.logService.error('messageId is not specified for', message);

	return _.uniqueId('FAILED-MESSAGE-ID-');
};

export const sortByTimeAndMessageKey = (
	{time: messageTime1 = '', messageKey: messageKey1 = ''},
	{time: messageTime2 = '', messageKey: messageKey2 = ''},
) => {
	const time1 = parseInt(messageTime1, 10);
	const time2 = parseInt(messageTime2, 10);

	if (time1 !== time2) {
		return time1 > time2 ? 1 : -1;
	}

	if (messageKey1 !== messageKey2) {
		return messageKey1 > messageKey2 ? 1 : -1;
	}

	return 0;
};

export const messageAdapter = createEntityAdapter<SswMessageType>({
	/** selectId - should be unique and always existing field for every entity **/
	selectId: getMessageId,
	/** sortComparer - optional. Could be skipped for most of reducers  **/
	sortComparer: sortByTimeAndMessageKey,
});
