import {MediaFile, ChannelMediaLinkState, EnumCurrency, EnumMediaState, EnumMediaType} from 'cmd-control-client-lib';
import _ from 'lodash';
import moment from 'moment';

import {TChannelMedia} from '@messenger/core/src/Redux/ChannelMedia/entityAdapter';
import {TMessageImageSource} from '@messenger/core/src/Redux/Messages/MessageViewModel';
import {oneHourInSeconds} from '@messenger/core/src/BusinessLogic/TimeIntervals';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

class MediaVM {
	media: MediaFile;
	linkState?: ChannelMediaLinkState;
	mediaType?: EnumMediaType;
	mediaMd5?: string;
	imgSrcSet?: string;
	imgSrc?: string;
	imgHeight: number;
	imgWidth: number;
	videoHeight: number;
	videoWidth: number;
	audioM4a?: string;
	audioMp3?: string;
	audioOgg?: string;
	mediaPrice?: number;
	videoHls?: string;
	videoFileUrl?: string;
	currency?: EnumCurrency;
	privateTicketShowDuration?: number;
	readonly isSent: boolean;
	readonly isPaid: boolean;
	readonly duration: number;
	readonly formattedDuration?: string;
	readonly audioSourcesList: string[];
	readonly imageSource: string;
	readonly isOkMediaState: boolean;
	readonly isPrivateTicketShowOffer: boolean;
	readonly hasTranscodingError: boolean;
	readonly mediaState: EnumMediaState;
	readonly videoUrl?: string;
	readonly isVerticalVideo: boolean = true;
	readonly isTicketMediaType: boolean;
	readonly isDuplicatedMedia: boolean;
	readonly poster?: string;
	readonly imageSourceSet: TMessageImageSource[];

	constructor(media: MediaFile, channelMedia?: TChannelMedia) {
		this.media = media;
		this.mediaType = _.get(media, 'mediaType') as EnumMediaType;
		this.mediaMd5 = media.mediaMd5;
		this.imgSrcSet = _.get(media, 'imgSrcSet');
		this.imgSrc = _.get(media, 'imgSrc');
		this.audioM4a = _.get(media, 'audioM4a');
		this.audioMp3 = _.get(media, 'audioMp3');
		this.audioOgg = _.get(media, 'audioOgg');
		this.imgHeight = _.chain(media).get('imgHeight', '0').parseInt(10).value();
		this.imgWidth = _.chain(media).get('imgWidth', '0').parseInt(10).value();
		this.videoHeight = _.chain(media).get('videoHeight', '0').parseInt(10).value();
		this.videoWidth = _.chain(media).get('videoWidth', '0').parseInt(10).value();
		this.videoHls = _.get(media, 'videoHls');
		this.videoFileUrl = _.get(media, 'videoFileUrl');
		this.privateTicketShowDuration = _.chain(media).get('ticketDuration').parseInt(10).value();

		this.mediaPrice = channelMedia?.mediaPrice ? parseFloat(channelMedia?.mediaPrice) : undefined;
		this.linkState = channelMedia?.linkState;
		this.currency = channelMedia?.currency;

		this.isSent = this.linkState === ChannelMediaLinkState.FREE;
		this.isPaid = this.linkState === ChannelMediaLinkState.PAID;
		this.mediaState = _.get(this, 'media.mediaState', EnumMediaState.OK) as EnumMediaState;
		this.audioSourcesList = _.compact([this.audioM4a, this.audioMp3, this.audioOgg]);
		this.imageSource = this.imgSrc || _.get(this, 'media.imgPixelated');
		this.isOkMediaState = this.mediaState === EnumMediaState.OK;
		this.isPrivateTicketShowOffer = this.mediaType === EnumMediaType.TICKET;
		this.hasTranscodingError = this.mediaState === EnumMediaState.TRANSCODING_ERROR;
		this.videoUrl = this.videoHls ?? this.videoFileUrl;
		this.isTicketMediaType = EnumMediaType.TICKET === this.mediaType;
		this.isDuplicatedMedia = !_.isEmpty(_.omit(this.media, 'mediaMd5'));
		this.poster = this.getPoster();
		this.imageSourceSet = this.getImageSourceSet();

		const duration =
			this.media.mediaType === EnumMediaType.VIDEO
				? _.get(this.media, 'videoDuration')
				: _.get(this.media, 'audioDuration');

		this.duration = duration && _.parseInt(duration);

		this.formattedDuration =
			_.includes([EnumMediaType.VIDEO, EnumMediaType.AUDIO], this.media.mediaType) && !_.isUndefined(this.duration)
				? moment
						.utc(moment.duration(this.duration, 'seconds').as('milliseconds'))
						.format(
							this.duration < oneHourInSeconds
								? ServiceFactory.i18n.t('momentFormat:durationFormat.minutes')
								: ServiceFactory.i18n.t('momentFormat:durationFormat.hours'),
						)
				: undefined;

		if (this.videoHeight && this.videoWidth) {
			this.isVerticalVideo = this.videoHeight > this.videoWidth;
		}
	}

	private getPoster() {
		switch (this.media.mediaType) {
			case EnumMediaType.BITMAP:
				return _.get(this.media, 'imgSrc');
			case EnumMediaType.VIDEO:
				return _.get(this.media, 'videoPoster');
			default:
				return undefined;
		}
	}

	private getImageSourceSet(): TMessageImageSource[] {
		if (!_.isEmpty(this.imgSrcSet)) {
			const aspectRatio = !_.isUndefined(this.imgHeight) && this.imgWidth ? this.imgHeight / this.imgWidth : 1;

			return _.chain(this.imgSrcSet)
				.split(',')
				.map((part: string) => {
					const [uri, width] = _.split(part, ' ');

					return {
						uri,
						width: _.parseInt(width, 10),
						height: _.parseInt(width, 10) * aspectRatio,
					};
				})
				.sortBy('width')
				.value();
		}

		return [
			{
				uri: this.imageSource,
				width: this.imgWidth,
				height: this.imgHeight,
			},
		];
	}

	getAppropriateImage(width: number) {
		return this.imageSourceSet.find((image) => image.width >= width) || _.last(this.imageSourceSet);
	}
}

export default MediaVM;
