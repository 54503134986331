import {takeEvery} from 'typed-redux-saga';

import {previewServerToClientActions} from '@messenger/core/src/Redux/Preview/Actions/previewServerToClientActions';
import {streamServerToClientActions} from '@messenger/core/src/Redux/Stream/Actions';

import openMainPreviewSocketSaga from 'src/Redux/Preview/Sagas/openMainPreviewSocketSaga';
import closeMainPreviewSocketSaga from 'src/Redux/Preview/Sagas/closeMainPreviewSocketSaga';

function* previewSagaWatcher() {
	yield* takeEvery(previewServerToClientActions.previewStarted, openMainPreviewSocketSaga);
	yield* takeEvery(streamServerToClientActions.stopStreamReceived, closeMainPreviewSocketSaga);
}

export default previewSagaWatcher;
