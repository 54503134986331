import _ from 'lodash';

import {selectMediaPricesByChannelId} from '@messenger/core/src/Redux/MediaPrice/Selectors/defaultSelectors';
import selectRootState from '@messenger/core/src/Redux/selectRootState';
import {createSelector} from '@messenger/core/src/Utils/Redux/createSelector';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {selectTargetChannelIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetChannelIds';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

export const selectPricesForTargetChannelIds = createSelector(
	[selectRootState, selectTargetChannelIds, selectCurrentGuestIdentity],
	(rootState, targetChannelIds, guestIdentity) =>
		_.size(targetChannelIds) === 1 && guestIdentity?.guestType !== EnumGuestType.BULK
			? selectMediaPricesByChannelId(rootState, targetChannelIds[0])
			: rootState[EnumStore.MEDIA_PRICES].defaultPrices,
);
