import {put} from 'typed-redux-saga';
import {ResultCode} from 'cmd-control-client-lib';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import ActionCreator from '@messenger/core/src/Redux/SpeedTest/ActionCreator';
import {speedTestServerToClientActions} from '@messenger/core/src/Redux/SpeedTest/Actions/speedTestServerToClientActions';

const processResultsSubmitSaga = function* (
	action: ReturnType<typeof speedTestServerToClientActions.stopTestReceived>,
) {
	try {
		const result =
			action.meta.result.code === ResultCode.OK
				? ActionCreator.markTestSuccess()
				: ActionCreator.setError(action.meta.result.reason);

		yield* put(result);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'ProcessResultsSubmitSaga'});
	}
};

export default processResultsSubmitSaga;
