import {call} from 'typed-redux-saga';
import {ResultCode} from 'cmd-control-client-lib';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import EnumSnackbarNotificationKeys from '@messenger/core/src/BusinessLogic/EnumSnackbarNotificationKeys';
import {sessionServerToClientActions} from '@messenger/core/src/Redux/Session/Actions';

const removeNotificationOnKickedByAdminSaga = function* (
	action: ReturnType<typeof sessionServerToClientActions.loggedIn>,
) {
	try {
		if (action.meta.result.code !== ResultCode.OK) {
			return;
		}

		const key = EnumSnackbarNotificationKeys.KICKED_BY_ADMIN;

		yield* call([ServiceFactory.notifications, ServiceFactory.notifications.close], key);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'removeNotificationOnKickedByAdminSaga'});
	}
};

export default removeNotificationOnKickedByAdminSaga;
