import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux/createSelector';
import {selectChannelVMs} from '@messenger/core/src/Redux/Channels/Selectors/defaultSelectors';

const selectArchivedChannelIds = createSelector([selectChannelVMs], (channelVMs) =>
	_.chain(channelVMs).filter('isArchived').map('channelId').value(),
);

export default selectArchivedChannelIds;
