import _ from 'lodash';
import {CMDC_CHANNELINFO} from 'cmd-control-client-lib';

import {TChannelInfoModel} from '@messenger/core/src/Redux/ChannelInfos/Model';

export const channelInfoEntityProcessor = (channelInfo: CMDC_CHANNELINFO['params'] | Partial<TChannelInfoModel>) => {
	const merged = {
		...channelInfo,
	};

	if (!_.isEmpty(channelInfo.payload)) {
		const parsed = _.attempt(JSON.parse, channelInfo.payload);

		if (!_.isError(parsed)) {
			merged.payload = parsed;
		}
	}

	return _.omit(merged, 'dsid') as TChannelInfoModel;
};
