import {call} from 'typed-redux-saga';
import {PayloadAction} from '@reduxjs/toolkit';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import ICam2CamConnectionConfig from '@messenger/core/src/Redux/Cam2CamPreview/Model';

export const PREVIEW_FORMAT_SUFFIX = '&format=15';

function* openPreviewSocketSaga(action: PayloadAction<ICam2CamConnectionConfig>) {
	try {
		if (action.payload.chatID && action.payload.mediaJpegWsUrl) {
			const api = ServiceFactory.jpegSocketPreviewApi;

			yield* call(
				[api, api.createPreview],
				action.payload.chatID,
				action.payload.mediaJpegWsUrl + PREVIEW_FORMAT_SUFFIX,
			);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'openPreviewSocketSaga'});
	}
}

export default openPreviewSocketSaga;
