import {createEntityAdapter} from '@reduxjs/toolkit';

export const mediaUploadProgressAdapter = createEntityAdapter<TMediaUploadProgressEntity>({
	selectId: (mediaUploadProgress: TMediaUploadProgressEntity) => mediaUploadProgress.messageId,
});

export type TMediaUploadProgressEntity = {
	progress: number;
	messageId: string;
};
