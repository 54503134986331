import {PayloadAction} from '@reduxjs/toolkit';
import {put, select} from 'typed-redux-saga';
import {SystemMessageKey} from 'cmd-control-client-lib';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {SswMessageType} from '@messenger/core/src/Redux/Messages/Model';
import {selectQueriesEntities} from '@messenger/core/src/Redux/QueryUser/Selectors/defaultSelectors';
import {queryClientOnlyActions} from '@messenger/core/src/Redux/QueryUser/Actions/queryClientOnlyActions';

function* rejectQueryUserRequestOnChatExitSaga(action: PayloadAction<SswMessageType>) {
	try {
		if (SystemMessageKey.h_chat_stop === action.payload.messageKey) {
			const chatId = action.payload.chatID;
			const availableQueries = yield* select(selectQueriesEntities);
			const runningQuery = _.find(availableQueries, (query) => query?.chatID === chatId);

			if (runningQuery) {
				yield* put(queryClientOnlyActions.abortQuery(runningQuery));
			}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'rejectQueryUserRequestOnChatExitSaga'});
	}
}

export default rejectQueryUserRequestOnChatExitSaga;
