import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {channelsClientToServerActions} from '@messenger/core/src/Redux/Channels/Actions/channelsClientToServerActions';
import {selectSessionUsrKey} from '@messenger/core/src/Redux/Session/Selectors/selectSessionUsrKey';

const getChannelsSummarySaga = function* () {
	try {
		const usrKey = yield* select(selectSessionUsrKey);

		if (usrKey) {
			yield* put(channelsClientToServerActions.requestSummary({usrKey}));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'getChannelsSummarySaga'});
	}
};

export default getChannelsSummarySaga;
