import _ from 'lodash';

import {channelInfoAdapter} from '@messenger/core/src/Redux/ChannelInfos/entityAdapter';
import IRootState from '@messenger/core/src/Redux/IRootState';
import {TChannelInfoModel} from '@messenger/core/src/Redux/ChannelInfos/Model';
import {TChannelEntityId} from '@messenger/core/src/Redux/Channels/Selectors/defaultSelectors';
import {TDefaultSelectors} from '@messenger/core/src/Redux/TDefaultSelectors';
import {createSelector} from '@messenger/core/src/Utils/Redux';
import getChannelInfoVMCached from '@messenger/core/src/Redux/ChannelInfos/getChannelInfoVMCached';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

const {selectIds, selectEntities, selectById} = channelInfoAdapter.getSelectors<IRootState>(
	(state) => state[EnumStore.CHANNEL_INFOS],
) as TDefaultSelectors<TChannelInfoModel, TChannelEntityId>;

export const selectChannelInfoIds = selectIds;

export const selectChannelInfoEntityVMs = createSelector([selectEntities], (entities) =>
	_.mapValues(entities, (entity) => getChannelInfoVMCached(entity)),
);

export const selectChannelInfoVMById = createSelector([selectById], getChannelInfoVMCached);
