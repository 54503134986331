import {takeEvery, takeLatest, throttle} from 'typed-redux-saga';
import {IS_TYPING_NOTIFICATION_INTERVAL} from 'cmd-control-client-lib';

import {
	channelsClientOnlyActions,
	channelsClientToServerActions,
	channelsServerToClientActions,
} from '@messenger/core/src/Redux/Channels/Actions';
import {updateChannelMetaTriggers} from '@messenger/core/src/Redux/Channels/Actions/updateChannelMetaTriggers';
import {notifyChannelFlagsUpdatedSaga} from '@messenger/core/src/Redux/Channels/Sagas/notifyChannelFlagsUpdatedSaga';
import {processCanLoadMoreFlagSaga} from '@messenger/core/src/Redux/Channels/Sagas/processCanLoadMoreFlagSaga';
import {incrementLoadingCounterSaga} from '@messenger/core/src/Redux/Channels/Sagas/incrementLoadingCounterSaga';
import processChannelsReceivedSaga from '@messenger/core/src/Redux/Channels/Sagas/processChannelsReceivedSaga';
import {decrementLoadingCounterSaga} from '@messenger/core/src/Redux/Channels/Sagas/decrementLoadingCounterSaga';
import processBatchUpsertChannelsSaga from '@messenger/core/src/Redux/Channels/Sagas/processBatchUpsertChannelsSaga';
import {requestChannelsByIdsSaga} from '@messenger/core/src/Redux/Channels/Sagas/requestChannelsByIdsSaga';
import {reverseChannelFlagsSaga} from '@messenger/core/src/Redux/Channels/Sagas/reverseChannelFlagsSaga';
import {
	channelInfosClientToServerActions,
	channelInfosServerToClientActions,
} from '@messenger/core/src/Redux/ChannelInfos/Actions';
import processUpdateChannelInfosSaga from '@messenger/core/src/Redux/ChannelInfos/Sagas/processUpdateChannelInfosSaga';
import requestAllChannelsLastMessageSaga from '@messenger/core/src/Redux/Channels/Sagas/requestAllChannelsLastMessageSaga';
import {checkCanReceiveAttachmentSaga} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/Sagas/checkCanReceiveAttachmentSaga';
import {checkUploadedMediaTargetsChangesSaga} from '@messenger/core/src/Redux/Channels/Sagas/checkUploadedMediaTargetsChangesSaga';

import {requestTicketsIfRequiredSaga} from 'src/Redux/Media/Sagas/requestTicketsIfRequiredSaga';

import {notifyIsTypingSaga} from './Sagas/notifyIsTypingSaga';

const changeTargetChannelsEvents = [
	channelsClientOnlyActions.addToTargets.type,
	channelsClientOnlyActions.removeFromTargets.type,
	channelsClientOnlyActions.setTargets.type,
];

const updateChannelInfosEvents = [
	channelInfosClientToServerActions.get.type,
	channelInfosServerToClientActions.channelInfosRequested,
];

/**
 * @link https://redux-saga.js.org/docs/advanced/FutureActions.html
 * @return {Generator}
 */
function* channelsSagaWatcher() {
	yield* takeEvery(channelsClientToServerActions.requestChannelsByFilter, incrementLoadingCounterSaga);
	yield* takeLatest(channelsServerToClientActions.channelsReceived, processChannelsReceivedSaga);
	yield* takeLatest(channelsServerToClientActions.channelsReceived, requestTicketsIfRequiredSaga);
	yield* takeLatest(channelsServerToClientActions.channelsReceived, decrementLoadingCounterSaga);
	yield* takeLatest(channelsServerToClientActions.channelsReceived, requestAllChannelsLastMessageSaga);
	yield* takeEvery(channelsServerToClientActions.channelsReceived, processCanLoadMoreFlagSaga);
	yield* takeEvery(channelsClientOnlyActions.upsertMany, processBatchUpsertChannelsSaga);
	yield* takeLatest(updateChannelMetaTriggers, notifyChannelFlagsUpdatedSaga);
	yield* takeEvery(channelsClientOnlyActions.requestChannels, requestChannelsByIdsSaga);
	yield* takeEvery(channelsClientOnlyActions.reverseChannelFlags, reverseChannelFlagsSaga);
	yield* throttle(IS_TYPING_NOTIFICATION_INTERVAL, channelsClientOnlyActions.notifyIsTyping, notifyIsTypingSaga);
	yield* takeEvery(changeTargetChannelsEvents, checkCanReceiveAttachmentSaga);
	yield* takeEvery(changeTargetChannelsEvents, checkUploadedMediaTargetsChangesSaga);
	yield* takeEvery(updateChannelInfosEvents, processUpdateChannelInfosSaga);
}

export default channelsSagaWatcher;
