import _ from 'lodash';
import React, {Ref} from 'react';
import {useTranslation} from 'react-i18next';

import PropertyHandler from '@messenger/core/src/Types/PropertyHandler';
import {TCanAccessWindow, TCanPlaySound} from '@messenger/core/src/Services/AbstractUiContainer';
import DialogPopUp from '@messenger/uikit/src/Dialog/DialogPopUp/DialogPopUp';
import EnumChatPageTestIds from '@messenger/uikit/src/TestIds/EnumChatPageTestIds';

import {testSoundBuffer} from 'src/Utils/Sound/testSoundBuffer';

export const testAndEnable = (props: TTestAndEnableProps) => {
	props.markTestStarted();
	testSoundBuffer(props.browser).then(props.markTestSuccess).catch(props.markTestFailed);
};

const EnableSoundsContent = React.forwardRef(
	(
		{
			isSoundsTestRunning,
			disableSounds,
			browser,
			markTestSuccess,
			markTestStarted,
			markTestFailed,
		}: TEnableSoundsContentProps,
		ref: Ref<Element>,
	) => {
		const {t} = useTranslation();
		const onClose = _.debounce(disableSounds, 300, {leading: true});

		return (
			<DialogPopUp
				dataTestId={EnumChatPageTestIds.NOTIFICATION_ABOUT_SOUND}
				body={t('notification:enable-sound-text')}
				ref={ref}
				btn0Text={t('common:no')}
				onClose={onClose}
				btn0DataTestId={EnumChatPageTestIds.NOTIFICATION_ABOUT_SOUND_BUTTON_NO}
				isBtn0Disabled={isSoundsTestRunning}
				btn1Text={t('common:yes')}
				onBtn0Click={onClose}
				onBtn1Click={_.debounce(() => testAndEnable({browser, markTestSuccess, markTestFailed, markTestStarted}), 300, {
					leading: true,
				})}
				btn1DataTestId={EnumChatPageTestIds.NOTIFICATION_ABOUT_SOUND_BUTTON_YES}
				isBtn1Disabled={isSoundsTestRunning}
			/>
		);
	},
);

type TTestAndEnableProps = {
	browser: TCanPlaySound & TCanAccessWindow;
	markTestSuccess: PropertyHandler;
	markTestFailed: PropertyHandler;
	markTestStarted: PropertyHandler;
};
type TEnableSoundsContentProps = {
	browser: TCanPlaySound & TCanAccessWindow;
	markTestSuccess: PropertyHandler;
	markTestFailed: PropertyHandler;
	markTestStarted: PropertyHandler;
	isSoundsTestRunning: boolean;
	disableSounds: PropertyHandler;
};

EnableSoundsContent.displayName = 'EnableSoundsContent';
export default EnableSoundsContent;
