import {put} from 'typed-redux-saga';
import {EnumBooleanStringifiedExtended} from 'cmd-control-client-lib';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {channelsClientToServerActions} from '@messenger/core/src/Redux/Channels/Actions';
import {channelGroupsServerToClientActions} from '@messenger/core/src/Redux/ChannelGroups/Actions/channelGroupsServerToClientActions';

const processChannelGroupsReceivedSaga = function* ({
	meta,
}: ReturnType<typeof channelGroupsServerToClientActions.channelGroupsReceivedMany>) {
	try {
		const isSingleChannelRequest =
			_.chain(meta.commands)
				.map(({params}) => params.channelId)
				.uniq()
				.size()
				.value() === 1;

		if (isSingleChannelRequest) {
			return;
		}

		const groupIds = _.chain(meta.commands)
			.map(({params}) => params.groupId)
			.uniq()
			.value();

		// TODO Change this logic to request channels with pagination
		if (!_.isEmpty(groupIds)) {
			yield* put(
				channelsClientToServerActions.requestChannelsByFilter({
					filterGroupId: groupIds.join(','),
					channelInfo: EnumBooleanStringifiedExtended.TRUE,
					contactNote: EnumBooleanStringifiedExtended.TRUE,
					onlineState: EnumBooleanStringifiedExtended.TRUE,
				}),
			);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processChannelGroupsReceivedSaga'});
	}
};

export default processChannelGroupsReceivedSaga;
