import {createEntityAdapter} from '@reduxjs/toolkit';

export const liveSessionChatsEntityAdapter = createEntityAdapter<TLiveSessionChats>({
	selectId: (entity) => entity.liveSessionId,
});

export type TLiveSessionChats = {
	liveSessionId: string;
	liveChatIds: string[];
	channelIds: string[];
};
