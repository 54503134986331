import {ChannelOnlineStateType} from '@messenger/core/src/Redux/OnlineState/entityAdapter';
import {parseBoolean} from '@messenger/core/src/Utils/StringUtil';

export default class OnlineStateVM {
	readonly isOnline: boolean = false;
	readonly isAvailableForMobileVideoCall: boolean = false; // true - when mobile video call enabled and no running video call

	constructor(private readonly onlineState: ChannelOnlineStateType) {
		this.isOnline = parseBoolean(onlineState.online);
		this.isAvailableForMobileVideoCall = parseBoolean(onlineState.mobileVideoCall);
	}
}
