import {EnumBooleanStringified, SupportedLanguage, ChannelInfoType} from 'cmd-control-client-lib';
import {TFunction} from 'react-i18next';

export enum EnumProfileFieldDataType {
	STRING = 'string',
	RANGE = 'array',
	DATE = 'date',
}

export type TProfileFiledValue = {
	dataType: 'range' | 'string';
	minValue: number;
	maxValue: number;
	minLabel: string;
	maxLabel: string;
	value: number;
};

export enum EnumChatTimes {
	firstChat = 'firstChat',
	lastChat = 'lastChat',
	totalChats = 'totalChats',
}

export type TProfileFields = {
	dataType: EnumProfileFieldDataType;
	key: EnumChatTimes;
	value: TProfileFiledValue[] | string;
};

export const getChatTimeLabelTranslated = (key: EnumChatTimes, t: TFunction) => {
	switch (key) {
		case EnumChatTimes.firstChat:
			return t('user:firstchat');

		case EnumChatTimes.lastChat:
			return t('user:lastchat');

		case EnumChatTimes.totalChats:
			return t('user:numchats');

		default:
			return key;
	}
};

export type TChannelInfoPayload = {
	[key in SupportedLanguage]: TProfileFields[];
};

/**
 * @TODO: Use ChannelInfoType in cmd-control-client-lib
 * **/

export type TChannelInfoModel = {
	canCall0900?: EnumBooleanStringified;
	canSingleSession?: EnumBooleanStringified;
	canPurchaseMedia?: EnumBooleanStringified;
	canBuyShopContent: EnumBooleanStringified;
	canBeDiscounted?: EnumBooleanStringified;
	canSendGifts: EnumBooleanStringified;
	canControlToy: EnumBooleanStringified;
	canSendVideo?: EnumBooleanStringified;
	avs?: EnumBooleanStringified;
	dsid?: string;
	partnerId?: number | string;
	partnerKey?: number | string;
	payload?: TChannelInfoPayload | string; // JSON
	ualias?: string;
	imgSrc?: string;
	imgSrcSet?: string;
} & ChannelInfoType;
