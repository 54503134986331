import _ from 'lodash';
import {CMDC_CONTACTNOTE} from 'cmd-control-client-lib';

import {contactNoteAdapter} from '@messenger/core/src/Redux/ContactNote/entityAdapter';
import IRootState from '@messenger/core/src/Redux/IRootState';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {TDefaultSelectors} from '@messenger/core/src/Redux/TDefaultSelectors';
import {TChannelEntityId} from '@messenger/core/src/Redux/Channels/Selectors/defaultSelectors';
import {createSelector} from '@messenger/core/src/Utils/Redux';
import getContactNoteVMCached from '@messenger/core/src/Redux/ContactNote/getContactNoteVMCached';

const {
	selectIds,
	selectEntities,
	selectById: _selectContactNoteByChannelId,
} = contactNoteAdapter.getSelectors<IRootState>((state) => state[EnumStore.CONTACT_NOTE]) as TDefaultSelectors<
	CMDC_CONTACTNOTE['params'],
	TChannelEntityId
>;

export const selectContactNoteIds = selectIds;

export const selectContactNoteEntityVMs = createSelector([selectEntities], (entities) =>
	_.mapValues(entities, (entity) => getContactNoteVMCached(entity)),
);

export const selectContactNoteVMById = createSelector([_selectContactNoteByChannelId], getContactNoteVMCached);
