import {EntityState} from '@reduxjs/toolkit';

import {channelInfoAdapter} from '@messenger/core/src/Redux/ChannelInfos/entityAdapter';
import {TChannelInfoModel} from '@messenger/core/src/Redux/ChannelInfos/Model';

export type IChannelInfosState = {
	isLoading: boolean;
	isError: boolean;
	requestedIds: string[];
} & EntityState<TChannelInfoModel>;

export const initialState: IChannelInfosState = channelInfoAdapter.getInitialState({
	isLoading: false,
	isError: false,
	requestedIds: [],
});
