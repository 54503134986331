import {put} from 'typed-redux-saga';
import _ from 'lodash';

import {mediaServerToClientActions} from '@messenger/core/src/Redux/Media/Actions/mediaServerToClientActions';
import {mediaClientOnlyActions} from '@messenger/core/src/Redux/Media/Actions/mediaClientOnlyActions';
import {parseBoolean} from '@messenger/core/src/Utils/StringUtil';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

export const processUploadedMediaReceivedSaga = function* ({
	meta,
}: ReturnType<typeof mediaServerToClientActions.uploadedMediaReceived>) {
	try {
		if (
			parseBoolean(meta.params.dropMediaFields) ||
			!parseBoolean(meta.params.countOnly) ||
			_.isUndefined(meta.values.count)
		) {
			return;
		}

		yield* put(mediaClientOnlyActions.setContentCatalogTotal(+meta.values.count));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processUploadedMediaReceivedSaga'});
	}
};
