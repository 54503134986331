import {select, call} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectChannelIds} from '@messenger/core/src/Redux/Channels/Selectors/defaultSelectors';
import selectActiveChatIds from '@messenger/core/src/Redux/Chats/Selectors/selectActiveChatIds';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';
import {routingClientOnlyActions} from '@messenger/core/src/Redux/Routing/Actions/routingClientOnlyActions';

const navigateToChannelOrChatSaga = function* ({
	payload: {chatOrChannelId},
}: ReturnType<typeof routingClientOnlyActions.navigateChatOrChannel>) {
	try {
		const activeChats = yield* select(selectActiveChatIds);
		const {goToChatMessages, goToChannelMessages} = yield* getNavigationContext();

		if (activeChats.includes(chatOrChannelId)) {
			yield* call(goToChatMessages, chatOrChannelId);

			return;
		}

		const channelIds = yield* select(selectChannelIds);

		if (channelIds.includes(chatOrChannelId)) {
			yield* call(goToChannelMessages, chatOrChannelId);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'navigateToChannelOrChatSaga'});
	}
};

export default navigateToChannelOrChatSaga;
