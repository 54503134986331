import {createSlice, EntityState} from '@reduxjs/toolkit';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {chatAdapter} from '@messenger/core/src/Redux/Chats/entityAdapter';
import {ALL_CHATS} from '@messenger/core/src/BusinessLogic/Constants';
import {
	chatsClientOnlyActions,
	chatsClientToServerActions,
	chatsServerToClientActions,
} from '@messenger/core/src/Redux/Chats/Actions';

import IChat from './Model';

export const initialState: TChatState = chatAdapter.getInitialState({
	isLoading: false,
	isError: false,
	selectedId: undefined,
	requested: false,
	unseenChatIds: [],
	shownAdminChats: [],
	typingChats: [],
	visibleChatsLimit: ServiceFactory.env.getInitialLimitOfVisibleChats(),
	ids: [ALL_CHATS],
	entities: {
		[ALL_CHATS]: {
			chatID: ALL_CHATS,
			flags: '',
		},
	},
	videoTimes: {},
	isGroupChatExpanded: false,
});

const chatsSlice = createSlice({
	name: EnumStore.CHATS,
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(chatsClientOnlyActions.resetStore, () => initialState);
		builder.addCase(chatsClientOnlyActions.selectChat, (state, action) => {
			state.selectedId = action.payload;
		});
		builder.addCase(chatsClientOnlyActions.resetSelected, (state) => {
			state.selectedId = undefined;
		});
		builder.addCase(chatsClientOnlyActions.unmuteText, chatAdapter.updateOne);
		builder.addCase(chatsServerToClientActions.setDiscount, (state, {payload}) =>
			chatAdapter.updateOne(state, {
				id: _.toString(payload.chatID),
				changes: {hasDiscount: payload.rate},
			}),
		);
		builder.addCase(chatsClientOnlyActions.markAsPrivate, chatAdapter.updateOne);
		builder.addCase(chatsClientOnlyActions.upsertMany, (state, action) => {
			state.isLoading = false;

			return chatAdapter.upsertMany(state, action);
		});
		builder.addCase(chatsClientOnlyActions.updateChatVideoTimesMany, (state, {payload}) => {
			state.videoTimes = {...state.videoTimes, ...payload};
		});
		builder.addCase(chatsClientOnlyActions.forceFlags, chatAdapter.updateOne);
		builder.addCase(chatsClientOnlyActions.markUnseen, (state, action) => {
			state.unseenChatIds = _.concat(state.unseenChatIds, action.payload);
		});
		builder.addCase(chatsClientOnlyActions.markSeen, (state, action) => {
			state.unseenChatIds = state.unseenChatIds.filter((chatId) => !_.includes(action.payload, chatId));
		});
		builder.addCase(chatsServerToClientActions.guestStartsVoyeur, chatAdapter.updateOne);
		builder.addCase(chatsServerToClientActions.guestStopsVoyeur, chatAdapter.updateOne);
		builder.addCase(chatsClientToServerActions.get, (state) => {
			state.isLoading = true;
		});
		builder.addCase(chatsClientOnlyActions.removeMany, (state, {payload}) => {
			state.videoTimes = _.omit(state.videoTimes, payload);

			return chatAdapter.removeMany(state, payload);
		});
		builder.addCase(chatsServerToClientActions.get, (state) => {
			state.isLoading = false;
		});
		builder.addCase(chatsServerToClientActions.showAdminChat, (state, action) => {
			state.shownAdminChats.push(action.payload);
		});
		builder.addCase(chatsClientOnlyActions.pruneExitedAdminChats, (state, action) => {
			state.shownAdminChats = _.without(state.shownAdminChats, ...action.payload);
		});
		builder.addCase(chatsClientOnlyActions.setVisibleChatsLimit, (state, action) => {
			state.visibleChatsLimit = action.payload;
		});
		builder.addCase(chatsClientOnlyActions.addTypingChat, (state, action) => {
			state.typingChats = _.union(state.typingChats, [action.payload]);
		});
		builder.addCase(chatsClientOnlyActions.removeTypingChat, (state, action) => {
			state.typingChats = _.without(state.typingChats, action.payload);
		});
		builder.addCase(chatsClientOnlyActions.setIsGroupChatExpanded, (state, action) => {
			state.isGroupChatExpanded = action.payload;
		});
	},
});

export type TChatState = EntityState<IChat> & {
	isLoading: boolean;
	isError: boolean;
	selectedId?: string;
	requested: boolean;
	unseenChatIds: string[];
	shownAdminChats: string[];
	typingChats: string[];
	visibleChatsLimit: number;
	isGroupChatExpanded: boolean;
	videoTimes: Record<string, number>;
};

export default chatsSlice;
