import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {EnumChatMode} from '@messenger/core/src/BusinessLogic/EnumChatMode';
import {EnumStartStreamStep, THaveAudioAndVideoCodecs} from '@messenger/core/src/Redux/Stream/slice';
import {EnumVideoFacingMode} from '@messenger/core/src/Types/media';

enum StreamAction {
	SET_START_STREAM_STEP = 'SET_START_STREAM_STEP',
	RESET_START_STREAM_STEP = 'RESET_START_STREAM_STEP',

	SET_STREAM_MODE = 'SET_STREAM_MODE',
	START_EXTERNAL_TOOL_STREAM = 'START_EXTERNAL_TOOL_STREAM',
	START_WEB_RTC_STREAM = 'START_WEB_RTC_STREAM',
	STOP_STREAM = 'STOP_STREAM',

	SET_IS_STREAM_STARTED_FROM_CURRENT_INSTANCE = 'SET_IS_STREAM_STARTED_FROM_CURRENT_INSTANCE',

	SET_BROWSER_CAPABILITIES = 'SET_BROWSER_CAPABILITIES',
	RESET_BROWSER_CAPABILITIES = 'RESET_BROWSER_CAPABILITIES',
	RESET_STATE_STATE = 'RESET_STATE_STATE',
	STOP_PRIVATE_TICKET_SHOW_WITH_ERROR = 'STOP_PRIVATE_TICKET_SHOW_WITH_ERROR',
	RESET_ERROR_END_META = 'RESET_ERROR_END_META',
	GO_ONLINE = 'GO_ONLINE',
	GO_OFFLINE = 'GO_OFFLINE',
	RESTART_GO_ONLINE = 'RESTART_GO_ONLINE',
	SET_VIDEO_FACING_MODE = 'SET_VIDEO_FACING_MODE',
}

class StreamClientOnlyActions extends ClientOnlyActions<EnumStore.STREAM> {
	readonly scope = EnumStore.STREAM;

	setStartStreamStep = this.createAction(
		StreamAction.SET_START_STREAM_STEP,
		this.getPrepareAction<EnumStartStreamStep>(),
	);
	resetStartStreamStep = this.createAction(StreamAction.RESET_START_STREAM_STEP);
	setStreamMode = this.createAction(StreamAction.SET_STREAM_MODE, this.getPrepareAction<EnumChatMode>());
	startExternalToolStream = this.createAction(StreamAction.START_EXTERNAL_TOOL_STREAM);
	startWebRTCStream = this.createAction(StreamAction.START_WEB_RTC_STREAM);
	stopStream = this.createAction(StreamAction.STOP_STREAM);
	setIsStreamStartedFromCurrentInstance = this.createAction(
		StreamAction.SET_IS_STREAM_STARTED_FROM_CURRENT_INSTANCE,
		this.getPrepareAction<boolean>(),
	);
	setBrowserCapabilities = this.createAction(
		StreamAction.SET_BROWSER_CAPABILITIES,
		this.getPrepareAction<THaveAudioAndVideoCodecs>(),
	);
	resetBrowserCapabilities = this.createAction(StreamAction.RESET_BROWSER_CAPABILITIES);
	resetStateState = this.createAction(StreamAction.RESET_STATE_STATE);
	stopPrivateTicketShowWithError = this.createAction(
		StreamAction.STOP_PRIVATE_TICKET_SHOW_WITH_ERROR,
		this.getPrepareAction<string>(),
	);
	resetErrorAndMeta = this.createAction(StreamAction.RESET_ERROR_END_META);
	goOnline = this.createAction(StreamAction.GO_ONLINE);
	goOffline = this.createAction(StreamAction.GO_OFFLINE, this.getPrepareAction<boolean | undefined>());
	restartGoOnline = this.createAction(StreamAction.RESTART_GO_ONLINE);
	setVideoFacingMode = this.createAction(
		StreamAction.SET_VIDEO_FACING_MODE,
		this.getPrepareAction<EnumVideoFacingMode>(),
	);
}

export const streamClientOnlyActions = new StreamClientOnlyActions();
