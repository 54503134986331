import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectSessionVM} from '@messenger/core/src/Redux/Session/Selectors/selectSessionVM';

const selectCanBeLoggedByForeignSID = createSelector(
	[selectSessionVM],
	(sessionVM) => !_.isEmpty(sessionVM.foreignSID),
);

export default selectCanBeLoggedByForeignSID;
