import {
	ACTION,
	CMDP_SGETUPLOADEDMEDIA,
	ChannelMediaLinkState,
	EnumBooleanStringifiedExtended,
	EnumMediaType,
} from 'cmd-control-client-lib';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class MediaClientToServerActions extends ClientToServerActions<EnumStore.MEDIA> {
	readonly scope = EnumStore.MEDIA;

	requestTickets = this.createAction(ACTION.CMDP_SGETTICKETS, (channelIds: string[]) => ({
		payload: {
			channelId: channelIds.join(','),
		},
	}));

	requestMedias = this.createAction(
		ACTION.CMDP_SGETUPLOADEDMEDIA,
		({
			channelIds,
			countOnly,
			limit,
			skip,
			mediaType,
			linkState,
			...payload
		}: Omit<
			CMDP_SGETUPLOADEDMEDIA['params'],
			'channelId' | 'skip' | 'limit' | 'countOnly' | 'mediaType' | 'linkState'
		> & {
			channelIds?: string[];
			limit?: number | string;
			skip?: number | string;
			countOnly?: boolean;
			mediaType?: EnumMediaType[];
			linkState?: ChannelMediaLinkState[];
		}): {payload: CMDP_SGETUPLOADEDMEDIA['params']} => ({
			payload: {
				...payload,
				mediaType: mediaType?.join(','),
				linkState: linkState?.join(','),
				limit: limit?.toString(),
				skip: skip?.toString(),
				...(countOnly ? {countOnly: EnumBooleanStringifiedExtended.TRUE} : {}),
				channelId: channelIds?.join(','),
			},
		}),
	);
}

export const mediaClientToServerActions = new MediaClientToServerActions();
