import {ACTION} from 'cmd-control-client-lib';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class ChannelMediaClientToServerActions extends ClientToServerActions<EnumStore.CHANNEL_MEDIA> {
	readonly scope = EnumStore.CHANNEL_MEDIA;

	checkUploadedMedia = this.createAction(
		ACTION.CMDP_SCHECKUPLOADEDMEDIA,
		({targetChannelIds, mediaMd5}: {targetChannelIds: string[]; mediaMd5?: string}) => ({
			payload: {
				mediaMd5,
				channelId: targetChannelIds.join(','),
			},
		}),
	);
}

export const channelMediaClientToServerActions = new ChannelMediaClientToServerActions();
