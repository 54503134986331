import {call, put, select} from 'typed-redux-saga';

import ActionCreator from '@messenger/core/src/Redux/SpeedTest/ActionCreator';
import {
	selectSpeedTestProgress,
	selectSpeedTestRequests,
	selectSpeedTestTime,
	selectSpeedTestUrl,
} from '@messenger/core/src/Redux/SpeedTest/Selectors';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

const ProcessTestRequest = function* (remainingMs: number) {
	try {
		const speedTestApi = ServiceFactory.speedTestApi;
		const speedTestUrl = yield* select(selectSpeedTestUrl);

		yield* call([speedTestApi, speedTestApi.sendRequest], speedTestUrl);

		// calculate new progress value
		const totalSeconds: number = yield* select(selectSpeedTestTime);
		const totalTimeMs = totalSeconds * 1000;
		const progress: number = yield* select(selectSpeedTestProgress);
		const currentMs = totalTimeMs - remainingMs;
		const newProgress = (currentMs / totalTimeMs) * 100;

		// calculate measured
		const requests: number = yield* select(selectSpeedTestRequests);
		const measured = ((((requests * speedTestApi.payloadSize) / currentMs) * 1000) / 1024) * 8;

		// only change if grows
		if (newProgress > progress) {
			yield* put(ActionCreator.markProgress(newProgress, measured));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'ProcessTestRequest'});
	}
};

export default ProcessTestRequest;
