import {call} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {markChatAsPrivateSaga} from '@messenger/core/src/Redux/Session/Sagas/markChatAsPrivateSaga';
import closeModalsOnSetTpChangesSaga from '@messenger/core/src/Redux/Session/Sagas/closeModalsOnSetTpChangesSaga';
import {TSessionUpdatedAction} from '@messenger/core/src/Redux/Session/Actions';

import {processWebRtcStreamStarted} from 'src/Redux/Session/Sagas/processWebRtcStreamStarted';

export const processSessionUpdateSaga = function* (action: TSessionUpdatedAction) {
	try {
		yield* call(processWebRtcStreamStarted, action);
		yield* call(markChatAsPrivateSaga, action);
		yield* call(closeModalsOnSetTpChangesSaga, action);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processSessionUpdateSaga', action});
	}
};
