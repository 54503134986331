import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectMediaEntities} from '@messenger/core/src/Redux/Media/Selectors/defaultSelectors';
import {selectAttachmentById} from '@messenger/core/src/Redux/Attachment/Selectors/selectAttachmentById';
import AttachmentVM from '@messenger/core/src/Redux/Attachment/AttachmentVM';

export const selectAttachmentVMById = createSelector(
	[selectAttachmentById, selectMediaEntities],
	(attachment, mediaEntities) => {
		if (_.isUndefined(attachment)) {
			return undefined;
		}

		const mediaMd5 = _.get(attachment, 'mediaMd5');

		const media = mediaMd5 ? mediaEntities[mediaMd5] : undefined;

		return new AttachmentVM(attachment, media);
	},
);
