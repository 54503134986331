import {Update} from '@reduxjs/toolkit';
import _ from 'lodash';
import {ChannelType, channelIdFilter, CMDC_CHANNEL, getChannelsParamsType} from 'cmd-control-client-lib';

import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {TUpdateChannelFlagsPayload} from '@messenger/core/src/Redux/Channels/Actions/channelsClientToServerActions';
import AttachmentVM from '@messenger/core/src/Redux/Attachment/AttachmentVM';
import IAttachment from '@messenger/core/src/Redux/Attachment/Model';
import {EnumMediaSource} from '@messenger/core/src/BusinessLogic/Media/EnumMediaSource';

enum EnumChannelsActions {
	APPEND = 'APPEND_ONE',
	DECREMENT_LOADING = 'DECREMENT_LOADING',
	INCREMENT_LOADING = 'INCREMENT_LOADING',
	REMOVE = 'CHANNEL_REMOVE',
	REQUEST_CONTACT_NOTE = 'REQUEST_CONTACT_NOTE',
	REQUEST_EXTRA_HISTORY = 'REQUEST_EXTRA_HISTORY',
	RESET_ALL = 'RESET_ALL',
	RESET_FILTER = 'RESET_FILTER',
	RESET_SELECTED = 'RESET_SELECTED',
	CHANGE_FILTERS = 'CHANGE_FILTERS',
	SET_FILTERS = 'SET_FILTERS',
	SET_FILTER_SKIP = 'SET_FILTER_SKIP',
	SET_FILTER_CONTACT_NAME = 'SET_FILTER_CONTACT_NAME',
	SET_USER_POSSIBILITIES_FILTER = 'SET_USER_POSSIBILITIES_FILTER',
	SET_SELECTED = 'SET_SELECTED',
	SET_TEAM_CHANNEL_ID = 'SET_TEAM_ID',
	TOGGLE_NOTE_EXPANDED = 'TOGGLE_NOTE_EXPANDED',
	UPDATE_CHANNEL = 'UPDATE_CHANNEL',
	UPSERT_MANY = 'UPSERT_MANY',
	ADD_MANY = 'ADD_MANY',
	SET_VISIBLE_CHANNELS_LIMIT = 'SET_VISIBLE_CHANNELS_LIMIT',
	SET_CHANNELS_WITH_HISTORY = 'SET_CHANNELS_WITH_HISTORY',
	BULK_SET_TARGETS = 'BULK_SET_TARGETS',
	BULK_SELECT = 'BULK_SELECT',
	BULK_DESELECT = 'BULK_DESELECT',
	BULK_RESET = 'BULK_RESET',
	BULK_FILTER = 'BULK_FILTER',
	BULK_MODE_TOGGLE = 'BULK_MODE_TOGGLE',
	SET_CAN_LOAD_MORE = 'SET_CAN_LOAD_MORE',
	REVERSE_CHANNEL_FLAGS = 'REVERSE_CHANNEL_FLAGS',
	REQUEST_CHANNELS = 'REQUEST_CHANNELS',
	ADD_TYPING_CHANNEL = 'ADD_TYPING_CHANNEL',
	REMOVE_TYPING_CHANNEL = 'REMOVE_TYPING_CHANNEL',
	CHECK_TARGETS_FOR_BULK = 'CHECK_TARGETS_FOR_BULK',
	GET_SYSTEM_GROUPS_CHANNELS_COUNT = 'GET_SYSTEM_GROUPS_CHANNELS_COUNT',
	NOTIFY_IS_TYPING = 'NOTIFY_IS_TYPING',
	TARGET_SELECT_MODE_RESET = 'TARGET_SELECT_MODE_RESET',
}

class ChannelsClientOnlyActions extends ClientOnlyActions<EnumStore.CHANNELS> {
	readonly scope = EnumStore.CHANNELS;

	resetAll = this.createAction(EnumChannelsActions.RESET_ALL);
	append = this.createAction(EnumChannelsActions.APPEND, this.getPrepareAction<ChannelType>());
	remove = this.createAction(EnumChannelsActions.REMOVE, this.getPrepareAction<string>());
	setTeamChannelId = this.createAction(EnumChannelsActions.SET_TEAM_CHANNEL_ID, this.getPrepareAction<string>());
	setSelected = this.createAction(EnumChannelsActions.SET_SELECTED, this.getPrepareAction<string | undefined>());
	resetSelected = this.createAction(EnumChannelsActions.RESET_SELECTED);
	decrementLoading = this.createAction(EnumChannelsActions.DECREMENT_LOADING);
	incrementLoading = this.createAction(EnumChannelsActions.INCREMENT_LOADING);
	toggleNoteExpanded = this.createAction(
		EnumChannelsActions.TOGGLE_NOTE_EXPANDED,
		this.getPrepareAction<string | undefined>(),
	);
	requestContactNote = this.createAction(
		EnumChannelsActions.REQUEST_CONTACT_NOTE,
		({channelId}: {channelId: string}) => ({payload: channelId}),
	);
	requestExtraHistory = this.createAction(
		EnumChannelsActions.REQUEST_EXTRA_HISTORY,
		this.getPrepareAction<{channelId: string}>(),
	);
	setFilters = this.createAction(
		EnumChannelsActions.SET_FILTERS,
		this.getPrepareAction<Partial<getChannelsParamsType & channelIdFilter>>(),
	);
	changeFilters = this.createAction(
		EnumChannelsActions.CHANGE_FILTERS,
		this.getPrepareAction<Partial<getChannelsParamsType & channelIdFilter>>(),
	);
	resetFilter = this.createAction(
		EnumChannelsActions.RESET_FILTER,
		this.getPrepareAction<TResetFilterPayload | undefined>(),
	);
	setUserPossibilitiesFilter = this.createAction(
		EnumChannelsActions.SET_USER_POSSIBILITIES_FILTER,
		this.getPrepareAction<Pick<AttachmentVM, 'mediaType' | 'price' | 'isFree'>>(),
	);
	setFilterSkip = this.createAction(EnumChannelsActions.SET_FILTER_SKIP, (payload: number) => ({
		payload: payload.toString(),
	}));
	setFilterContactName = this.createAction(
		EnumChannelsActions.SET_FILTER_CONTACT_NAME,
		this.getPrepareAction<string | undefined>(),
	);

	updateChannel = this.createAction(EnumChannelsActions.UPDATE_CHANNEL, this.getPrepareAction<Update<ChannelType>>());

	upsertMany = this.createAction(EnumChannelsActions.UPSERT_MANY, (payload: CMDC_CHANNEL['params'][]) => ({
		payload: _.chain(payload)
			// if no flags - set empty to reset previous in store
			.map((channel) => _.assign({flags: ''}, channel))
			// group by channel IDs to apply updated one-by-one
			.groupBy('channelId')
			.value(),
	}));
	addMany = this.createAction(EnumChannelsActions.ADD_MANY, this.getPrepareAction<CMDC_CHANNEL['params'][]>());
	setVisibleChannelsLimit = this.createAction(
		EnumChannelsActions.SET_VISIBLE_CHANNELS_LIMIT,
		this.getPrepareAction<number>(),
	);
	setChannelsWithHistory = this.createAction(
		EnumChannelsActions.SET_CHANNELS_WITH_HISTORY,
		this.getPrepareAction<{channelIds: string[]}>(),
	);

	setTargets = this.createAction(
		EnumChannelsActions.BULK_SET_TARGETS,
		this.getPrepareAction<{
			channelIds: string[];
			groupIds?: string[];
			attachmentId?: string;
		}>(),
	);

	addToTargets = this.createAction(
		EnumChannelsActions.BULK_SELECT,
		this.getPrepareAction<{
			channelIds: string[];
			groupIds?: string[];
			attachmentId?: string;
		}>(),
	);

	removeFromTargets = this.createAction(
		EnumChannelsActions.BULK_DESELECT,
		this.getPrepareAction<{
			channelIds: string[];
			groupIds?: string[];
			attachmentId?: string;
			permanentSelectMode?: boolean;
		}>(),
	);

	filterTargets = this.createAction(
		EnumChannelsActions.BULK_FILTER,
		this.getPrepareAction<{channelsToDelete: string[]}>(),
	);

	resetTargetsSelection = this.createAction(EnumChannelsActions.BULK_RESET);
	toggleTargetsSelectMode = this.createAction(EnumChannelsActions.BULK_MODE_TOGGLE);
	resetTargetsSelectMode = this.createAction(EnumChannelsActions.TARGET_SELECT_MODE_RESET);

	checkTargetsForBulkMessage = this.createAction(
		EnumChannelsActions.CHECK_TARGETS_FOR_BULK,
		this.getPrepareAction<{
			attachment?: IAttachment;
			mediaSource?: EnumMediaSource;
		}>(),
	);

	setCanLoadMore = this.createAction(EnumChannelsActions.SET_CAN_LOAD_MORE, this.getPrepareAction<boolean>());

	reverseChannelFlags = this.createAction(
		EnumChannelsActions.REVERSE_CHANNEL_FLAGS,
		this.getPrepareAction<TUpdateChannelFlagsPayload>(),
	);

	requestChannels = this.createAction(
		EnumChannelsActions.REQUEST_CHANNELS,
		this.getPrepareAction<{channelIds: string[]; requestUuid?: string}>(),
	);

	addTypingChannel = this.createAction(EnumChannelsActions.ADD_TYPING_CHANNEL, this.getPrepareAction<string>());

	removeTypingChannel = this.createAction(EnumChannelsActions.REMOVE_TYPING_CHANNEL, this.getPrepareAction<string>());

	getSystemGroupsChannelsCount = this.createAction(EnumChannelsActions.GET_SYSTEM_GROUPS_CHANNELS_COUNT);

	notifyIsTyping = this.createAction(EnumChannelsActions.NOTIFY_IS_TYPING);
}

export type TResetFilterPayload = {
	keepUserAbilitiesFilter: boolean;
};

export const channelsClientOnlyActions = new ChannelsClientOnlyActions();
