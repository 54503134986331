import IRootState from '@messenger/core/src/Redux/IRootState';
import {TDefaultSelectors} from '@messenger/core/src/Redux/TDefaultSelectors';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {
	liveSessionChatsEntityAdapter,
	TLiveSessionChats,
} from '@messenger/core/src/Redux/LiveSessionChats/liveSessionChatsEntityAdapter';
import {TLiveSessionEntityId} from '@messenger/core/src/Redux/LiveSessions/Selectors/defaultSelectors';

const {selectById} = liveSessionChatsEntityAdapter.getSelectors<IRootState>(
	(state) => state[EnumStore.LIVE_SESSION_CHATS],
) as TDefaultSelectors<TLiveSessionChats, TLiveSessionEntityId>;

export const selectLiveSessionChatsById = selectById;
