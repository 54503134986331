import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import {ALL_CHATS} from '@messenger/core/src/BusinessLogic/Constants';
import {selectRegularChatVms} from '@messenger/core/src/Redux/Chats/Selectors/selectRegularChatVms';
import {selectAdminChatsToShow} from '@messenger/core/src/Redux/Chats/Selectors/selectAdminChatsToShow';

export const selectRunningNonVoyeurChatIds = createSelector(
	[selectRegularChatVms, selectAdminChatsToShow],
	(chats, adminChatsToShowIds) =>
		_.chain(chats)
			.map((chatVm) =>
				(chatVm.isAdmin && !_.includes(adminChatsToShowIds, chatVm.chatId)) ||
				chatVm.chatId === ALL_CHATS ||
				chatVm?.isExited ||
				chatVm.isVoyeur
					? undefined
					: chatVm.chatId,
			)
			.compact()
			.value(),
);
