import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import {getChannelMediaState} from '@messenger/core/src/Redux/ChannelMedia/Selectors/getChannelMediaState';
import getPropsTargetChannelIds from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/getPropsTargetChannelIds';

export const selectAreSomeChannelMediaForTargetChannelIdsChecking = createSelector(
	[getPropsTargetChannelIds, getChannelMediaState],
	(targetChannelIds, channelMediaState) =>
		_.some(targetChannelIds, (channelId) => _.includes(channelMediaState.checkingChannelIds, channelId)),
);
