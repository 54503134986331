import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux/createSelector';
import getPropsChannelId from '@messenger/core/src/Redux/Channels/Selectors/getPropsChannelId';
import {selectChatVms} from '@messenger/core/src/Redux/Chats/Selectors/defaultSelectors';

const selectRunningChatVmByChannelId = createSelector([selectChatVms, getPropsChannelId], (chatVMs, channelId) =>
	_.find(chatVMs, (chatVm) => !chatVm.isExited && chatVm.channelId === channelId),
);

export default selectRunningChatVmByChannelId;
