import {fromJS, Map} from 'immutable';
import {ResultCode} from 'cmd-control-client-lib';
import {AnyAction} from '@reduxjs/toolkit';

import EnumPreviewType from '@messenger/core/src/BusinessLogic/EnumPreviewType';
import {previewClientOnlyActions} from '@messenger/core/src/Redux/Preview/Actions/previewClientOnlyActions';
import {previewServerToClientActions} from '@messenger/core/src/Redux/Preview/Actions/previewServerToClientActions';
import {streamServerToClientActions} from '@messenger/core/src/Redux/Stream/Actions';

const defaultState = Map<string, any>().set('sources', {}).set('streamPreviewType', EnumPreviewType.LOCAL);

const PreviewReducer = (state: Map<string, any> = defaultState, {type, payload, meta}: AnyAction) => {
	switch (type) {
		case previewClientOnlyActions.resetStore.type:
			return defaultState;

		case streamServerToClientActions.stopStreamReceived.type:
			return state.set('sources', Map());

		case previewClientOnlyActions.changePreviewType.type:
			return state.set('streamPreviewType', payload);

		case previewServerToClientActions.previewStarted.type: {
			if (meta.result.code !== ResultCode.OK) {
				return state;
			}

			const sources: {
				[key: string]: any;
			} = {};

			if (payload.dataURL) {
				sources.dataUrl = payload.dataURL;
			}

			if (payload.mediaHlsUrl) {
				sources.mediaHlsUrl = payload.mediaHlsUrl;
			}

			if (payload.mediaHlsMobileUrl) {
				sources.mediaHlsMobileUrl = payload.mediaHlsMobileUrl;
			}

			if (payload.mediaRtmpUrl) {
				sources.mediaRtmpUrl = payload.mediaRtmpUrl;
			}

			if (payload.mediaJpegUrl) {
				sources.mediaJpegUrl = payload.mediaJpegUrl;
			}

			if (payload.mediaJpegWsUrl) {
				sources.mediaJpegWsUrl = payload.mediaJpegWsUrl;
			}

			if (payload.mediaRtmp) {
				sources.mediaRtmp = payload.mediaRtmp;
			}

			if (payload.mediaWebRtc) {
				sources.mediaWebRtc = payload.mediaWebRtc;
			}

			if (payload.mediaH5Live) {
				const mediaH5Live = JSON.parse(payload.mediaH5Live);

				mediaH5Live.rtmp.url = mediaH5Live.rtmp.appUrl;
				mediaH5Live.rtmp.streamname = mediaH5Live.rtmp.streamName;
				mediaH5Live.token = mediaH5Live.securityToken;
				sources.mediaH5Live = mediaH5Live;
			}

			if (payload.width) {
				sources.mediaWidth = payload.width;
			}

			if (payload.height) {
				sources.mediaHeight = payload.height;
			}

			return state.set('sources', fromJS(sources));
		}

		default:
			return state;
	}
};

export default PreviewReducer;
