import {createSlice, SliceCaseReducers} from '@reduxjs/toolkit';
import _ from 'lodash';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {IChannelInfosState, initialState} from '@messenger/core/src/Redux/ChannelInfos/initialState';

import {channelInfoAdapter} from './entityAdapter';
import {channelInfosClientOnlyActions} from './Actions/channelInfosClientOnlyActions';

export const channelInfosSlice = createSlice<IChannelInfosState, SliceCaseReducers<IChannelInfosState>, EnumStore>({
	name: EnumStore.CHANNEL_INFOS,
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(channelInfosClientOnlyActions.resetStore, () => initialState);
		builder.addCase(channelInfosClientOnlyActions.upsertMany, channelInfoAdapter.upsertMany);
		builder.addCase(channelInfosClientOnlyActions.addMany, channelInfoAdapter.addMany);
		builder.addCase(channelInfosClientOnlyActions.appendOne, channelInfoAdapter.upsertOne);
		builder.addCase(channelInfosClientOnlyActions.removeOne, channelInfoAdapter.removeOne);
		builder.addCase(
			channelInfosClientOnlyActions.updateStore,
			(state, {payload: {isLoading, requestedIds, channelInfos}}) => {
				state.requestedIds = requestedIds;
				state.isLoading = isLoading;

				if (!_.isUndefined(channelInfos) && !_.isEmpty(channelInfos)) {
					channelInfoAdapter.upsertMany(state, channelInfos);
				}
			},
		);
	},
});
