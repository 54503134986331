import {call} from 'typed-redux-saga';
import {EnumBooleanStringified, ResultCode} from 'cmd-control-client-lib';

import getChannelsSummarySaga from '@messenger/core/src/Redux/Session/Sagas/getChannelsSummarySaga';
import requestTeamChannelSaga from '@messenger/core/src/Redux/Session/Sagas/requestTeamChannelSaga';
import {EnumLocalStorageKeys} from '@messenger/core/src/BusinessLogic/EnumLocalStorageKeys';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import EnumStoreType from '@messenger/core/src/BusinessLogic/EnumStoreType';
import {requestMediaPricesForTargetChannelsSaga} from '@messenger/core/src/Redux/MediaPrice/Sagas/requestMediaPricesForTargetChannelsSaga';
import {sessionServerToClientActions} from '@messenger/core/src/Redux/Session/Actions';

export const processLoginResultSaga = function* (action: ReturnType<typeof sessionServerToClientActions.loggedIn>) {
	try {
		// only send on successful login
		if (action.meta.result.code !== ResultCode.OK) {
			return;
		}

		yield* call(requestTeamChannelSaga);
		yield* call(getChannelsSummarySaga);

		//Request default prices
		yield* call(requestMediaPricesForTargetChannelsSaga, {isDefault: true});

		yield* call(
			[ServiceFactory.store, ServiceFactory.store.set],
			EnumLocalStorageKeys.TOS_ACCEPTED,
			EnumBooleanStringified.TRUE,
			{storeType: EnumStoreType.LOCAL},
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processLoginResultSaga'});
	}
};
