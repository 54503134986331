import {
	ACTION,
	ChannelMediaLinkState,
	CMDC_MEDIAPURCHASED,
	CMDP_SCHECKUPLOADEDMEDIA_RESPONSE,
} from 'cmd-control-client-lib';

import {ServerToClientActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class ChannelMediaServerToClientActions extends ServerToClientActions<EnumStore.CHANNEL_MEDIA> {
	readonly scope = EnumStore.CHANNEL_MEDIA;

	uploadedMediaChecked = this.createAction(
		ACTION.CMDP_SCHECKUPLOADEDMEDIA,
		this.getPrepareAction<CMDP_SCHECKUPLOADEDMEDIA_RESPONSE['values'], CMDP_SCHECKUPLOADEDMEDIA_RESPONSE, false>(),
	);

	mediaPurchased = this.createAction(
		ACTION.CMDC_MEDIAPURCHASED,
		(
			{mediaMd5, linkState, currency, mediaPrice, channelId}: CMDC_MEDIAPURCHASED['params'],
			meta: CMDC_MEDIAPURCHASED,
		) => ({
			payload: {
				mediaMd5: String(mediaMd5),
				linkState: linkState || ChannelMediaLinkState.NONE,
				currency,
				mediaPrice,
				channelId: String(channelId),
			},
			meta,
		}),
	);
}

export const channelMediaServerToClientActions = new ChannelMediaServerToClientActions();
