import {Channel} from '@redux-saga/types';
import {AnyAction} from 'redux';
import {channel, END} from 'redux-saga';
import {call, put, select, take} from 'typed-redux-saga';
import {EnumSetTp} from 'cmd-control-client-lib';

import {TStreamConnectOptions, TWebRTCConfig} from '@messenger/core/src/Redux/Stream/Model';
import EnumPreviewType from '@messenger/core/src/BusinessLogic/EnumPreviewType';
import {previewClientOnlyActions} from '@messenger/core/src/Redux/Preview/Actions/previewClientOnlyActions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectStreamConnectOptions} from '@messenger/core/src/Redux/Stream/Selectors/selectStreamConnectOptions';
import WebRtcConfigVM from '@messenger/core/src/Redux/Stream/WebRtcConfigVM';
import {EnumMediaStreamTrackKind} from '@messenger/core/src/Services/WebRtc/CommonConnectionTypes';
import {selectShouldRestartStream} from '@messenger/core/src/Redux/MediaDevice/Selectors/selectShouldRestartStream';
import {selectBrowserSupportedVideoCodec} from '@messenger/core/src/Redux/MediaDevice/Selectors/selectBrowserSupportedVideoCodec';
import {selectBrowserSupportedAudioCodec} from '@messenger/core/src/Redux/MediaDevice/Selectors/selectBrowserSupportedAudioCodec';
import selectSetTp from '@messenger/core/src/Redux/Session/Selectors/selectSetTp';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';
import {EnumStartStreamStep} from '@messenger/core/src/Redux/Stream/slice';

import stopWebRtcOutputStreamSaga from 'src/Redux/Stream/Sagas/stopWebRtcOutputStreamSaga';
import {setStreamingDeviceStatusSaga} from 'src/Redux/MediaDevice/Sagas/setStreamingDeviceStatusSaga';

const RTC_ERROR_ACTION_TYPE = 'RTC_ERROR_ACTION_TYPE';
const RTC_STOP_ACTION_TYPE = 'RTC_STOP_ACTION_TYPE';

function* startWebRtcOutputStreamSaga() {
	let isFailed = false;

	try {
		const setTpValue = yield* select(selectSetTp);

		if (setTpValue === EnumSetTp.OFFLINE) {
			return;
		}

		yield* put(streamClientOnlyActions.setStartStreamStep(EnumStartStreamStep.WAITING_STREAM_ACCEPTED));
		const isSeverWaitsStream = setTpValue === EnumSetTp.GOING_ONLINE;
		const shouldRestartStream = yield* select(selectShouldRestartStream);

		if (!isSeverWaitsStream && !shouldRestartStream) {
			throw new Error('Invalid state or race with rtmp software');
		}

		const api = ServiceFactory.webRtcApi;
		const streamConnectionProps: TStreamConnectOptions = yield* select(selectStreamConnectOptions);
		const videoCodec = yield* select(selectBrowserSupportedVideoCodec);
		const audioCodec = yield* select(selectBrowserSupportedAudioCodec);
		const chan = (yield* call(channel)) as Channel<AnyAction | END>;

		if (!api.mediaStream) {
			throw new Error('Unreachable empty MediaStream');
		}

		const tracks = yield* call([api.mediaStream, api.mediaStream.getTracks]);
		const endedListenersEventHandler = () => {
			chan.put({type: RTC_ERROR_ACTION_TYPE, error: new Error('Stream track has been ended')});
		};

		for (const track of tracks) {
			if (track.kind === EnumMediaStreamTrackKind.VIDEO) {
				track.addEventListener('ended', endedListenersEventHandler);
			}
		}

		yield* call([api, api.startOutputStream], {
			webRTCConfig: new WebRtcConfigVM(streamConnectionProps.webRTCConfig as TWebRTCConfig),
			onStop: () => {
				chan.put({type: RTC_STOP_ACTION_TYPE});
				chan.put(END);
			},
			onError: (error: Error) => {
				chan.put({type: RTC_ERROR_ACTION_TYPE, error});
			},
			videoCodec,
			audioCodec,
		});

		if (shouldRestartStream) {
			yield* call(setStreamingDeviceStatusSaga);
		}

		yield* put(previewClientOnlyActions.changePreviewType(EnumPreviewType.LOCAL));

		const action = yield* take(chan);

		for (const track of tracks) {
			if (track.kind === EnumMediaStreamTrackKind.VIDEO) {
				track.removeEventListener('ended', endedListenersEventHandler);
			}
		}

		if (action && action.type === RTC_ERROR_ACTION_TYPE) {
			throw action.error;
		}
	} catch (e) {
		isFailed = true;
		ServiceFactory.logService.error(e as Error, {saga: 'WebRtcOutputStreamSagaFailed'});
	} finally {
		yield* call(stopWebRtcOutputStreamSaga, isFailed);
	}
}

export default startWebRtcOutputStreamSaga;
