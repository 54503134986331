import _ from 'lodash';
import {PayloadAction} from '@reduxjs/toolkit';
import {delay, put, race, select, take} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {toyClientOnlyActions} from '@messenger/core/src/Redux/Toy/Actions/ToyClientOnlyActions';
import {IVibrationEvent} from '@messenger/core/src/Redux/Toy/IVibrationEvent';
import {selectIsToysEnabled} from '@messenger/core/src/Redux/Toy/Selectors/selectIsToysEnabled';
import {selectCurrentVibration} from '@messenger/core/src/Redux/Toy/Selectors/selectCurrentVibration';
import {selectNextVibration} from '@messenger/core/src/Redux/Toy/Selectors/selectNextVibration';
import {selectMaxIntensity} from '@messenger/core/src/Redux/Toy/Selectors/selectMaxIntensity';

const processVibrateQueueSaga = function* (action: PayloadAction<IVibrationEvent | undefined>) {
	try {
		const isEnabled: boolean = yield* select(selectIsToysEnabled);
		const vibration: IVibrationEvent | undefined = yield* select(selectCurrentVibration);
		let nextVibration: IVibrationEvent | undefined = action.payload;

		if (!isEnabled) {
			return;
		}

		if (vibration && nextVibration) {
			// if vibration running adding to queue
			if (!_.isUndefined(nextVibration) && nextVibration.duration > 0) {
				yield* put(toyClientOnlyActions.addToQueue(nextVibration));
			}
		} else {
			// if run without params then needs select from queue
			if (!nextVibration) {
				nextVibration = yield* select(selectNextVibration);
				yield* put(toyClientOnlyActions.removeFromQueue());
			}

			nextVibration = nextVibration ? {...nextVibration} : nextVibration;

			if (!_.isUndefined(nextVibration) && nextVibration.duration > 0) {
				nextVibration = nextVibration ? {...nextVibration} : nextVibration;

				// apply max intensity
				const maxIntensity: number = yield* select(selectMaxIntensity);

				nextVibration.intensity = Math.min(nextVibration.intensity, maxIntensity);

				yield* put(toyClientOnlyActions.startVibrate(nextVibration));
				const delayEffect = delay(nextVibration.duration);

				if (nextVibration.isTest) {
					yield* race({delayEffect, stop: take(toyClientOnlyActions.stopTestVibration)});
				} else {
					yield* delayEffect;
				}

				yield* put(toyClientOnlyActions.stopVibrate());
			}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processVibrateQueueSaga'});
	}
};

export default processVibrateQueueSaga;
