import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux/createSelector';
import {selectChatEntityVms} from '@messenger/core/src/Redux/Chats/Selectors/defaultSelectors';

import selectRunningChatsChannelIds from './selectRunningChatsChannelIds';

export const selectChatForSendingMessageIds = createSelector(
	[selectChatEntityVms, selectRunningChatsChannelIds],
	(chatVMEntities, activeChannelIds) =>
		_.reduce(
			chatVMEntities,
			(arr: string[], value, key) => {
				if (
					(!value?.exitCode && !value?.isAllChatsAggregate && !value?.isVoyeur) ||
					(value?.exitCode && value?.channelId && !_.includes(activeChannelIds, value?.channelId))
				) {
					arr.push(key);
				}

				return arr;
			},
			[],
		),
);
