import {CMDC_CMEDIA, ChannelMediaLinkState, EnumMediaType, UploadedMediaDirection} from 'cmd-control-client-lib';
import _ from 'lodash';

import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {TChannelMedia} from '@messenger/core/src/Redux/ChannelMedia/entityAdapter';
import {TAnyMedia} from '@messenger/core/src/Redux/Media/entityAdapter';

enum MediaAction {
	SET_UPLOAD_URL = 'SET_UPLOAD_URL',
	PRIVATE_SHOW_START = 'PRIVATE_SHOW_START',
	RESET_STORE = 'RESET_STORE',
	UPSERT_MANY = 'UPSERT_MANY',
	RESET_CONTENT_CATALOG = 'RESET_CONTENT_CATALOG',
	SET_CONTENT_CATALOG_IDS = 'SET_CONTENT_CATALOG_IDS',
	REQUEST_MEDIA_FOR_CONTENT_CATALOG = 'REQUEST_MEDIA_FOR_CONTENT_CATALOG',
	SET_CONTENT_CATALOG_TOTAL = 'SET_CONTENT_CATALOG_TOTAL',
}

class MediaClientOnlyActions extends ClientOnlyActions<EnumStore.MEDIA> {
	readonly scope = EnumStore.MEDIA;

	setMediaUploadUrl = this.createAction(MediaAction.SET_UPLOAD_URL, this.getPrepareAction<string>());

	resetStore = this.createAction(MediaAction.RESET_STORE);

	upsertMany = this.createAction(MediaAction.UPSERT_MANY, (_payload: CMDC_CMEDIA['params'][]) => {
		const channelMedias: TChannelMedia[] = [];

		const payload = _payload.map(
			({linkState, linkDate, channelId, mediaPrice, currency, direction, ...mediaPayload}: CMDC_CMEDIA['params']) => {
				if (
					!_.isUndefined(channelId) &&
					!_.isUndefined(linkState) &&
					!_.isUndefined(linkDate) &&
					!_.isUndefined(mediaPayload.mediaMd5) &&
					!_.isUndefined(mediaPayload.mediaType)
				) {
					channelMedias.push({
						linkDate,
						linkState,
						mediaType: mediaPayload.mediaType,
						mediaMd5: mediaPayload.mediaMd5,
						channelId,
						mediaPrice,
						currency,
						direction,
					});
				}

				return mediaPayload.mediaType === EnumMediaType.TICKET ? {...mediaPayload, channelId} : mediaPayload;
			},
		);

		return {
			payload: payload as TAnyMedia[], // @TODO type mismatch, need to get rid of 'as' (VXLIV-857)
			meta: {channelMedias},
		};
	});

	resetContentCatalog = this.createAction(MediaAction.RESET_CONTENT_CATALOG);

	requestMediaForContentCatalog = this.createAction(
		MediaAction.REQUEST_MEDIA_FOR_CONTENT_CATALOG,
		this.getPrepareAction<
			| {
					channelId?: string;
					limit?: number;
					direction?: UploadedMediaDirection;
					mediaType?: EnumMediaType[];
					linkState?: ChannelMediaLinkState[];
					isMediaForSingleUser?: boolean;
			  }
			| undefined
		>(),
	);

	setContentCatalogIds = this.createAction(MediaAction.SET_CONTENT_CATALOG_IDS, this.getPrepareAction<string[]>());

	setContentCatalogTotal = this.createAction(
		MediaAction.SET_CONTENT_CATALOG_TOTAL,
		this.getPrepareAction<number | null>(),
	);
}

export const mediaClientOnlyActions = new MediaClientOnlyActions();
