import {get} from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux/createSelector';
import {onceUponATimeInAGalaxyFarFarAway} from '@messenger/core/src/Utils/DateTimeUtil';
import SpeedTestResultAdapter from '@messenger/core/src/Services/SpeedTest/SpeedTestResultAdapter';
import IRootState from '@messenger/core/src/Redux/IRootState';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

const getSpeedTest = (state: IRootState) => state[EnumStore.SPEED_TEST];

export const selectSpeedTestTime = createSelector([getSpeedTest], (speedTest) =>
	get(speedTest, ['meta', 'seconds'], -1),
);

export const selectSpeedTestStartTime = createSelector([getSpeedTest], (speedTest) =>
	get(speedTest, ['startTime'], onceUponATimeInAGalaxyFarFarAway),
);

export const selectSpeedTestProgress = createSelector([getSpeedTest], (speedTest) => get(speedTest, ['progress'], 0));

export const selectSpeedTestRequests = createSelector([getSpeedTest], (speedTest) => get(speedTest, ['requests'], 0));

export const selectSpeedTestMeasured = createSelector([getSpeedTest], (speedTest) => get(speedTest, ['measured'], 0));

export const selectSpeedTestReport = createSelector([getSpeedTest], (speedTest) => {
	if (speedTest.meta === undefined) {
		throw new Error('Test was not started');
	}

	return new SpeedTestResultAdapter(
		speedTest.meta.testID,
		speedTest.meta.dataID,
		speedTest.meta.seconds,
		speedTest.measured * 1024,
	);
});

export const selectSpeedTestUrl = createSelector([getSpeedTest], (speedTest) => get(speedTest, 'speedTestUrl', ''));

export const selectIsSpeedTestStarted = createSelector([getSpeedTest], (speedTest) =>
	get(speedTest, 'isStarted', false),
);

export const selectIsSpeedTestFinished = createSelector([getSpeedTest], (speedTest) =>
	get(speedTest, 'isFinished', false),
);

export const selectSpeedTestError = createSelector([getSpeedTest], (speedTest) => get(speedTest, ['error'], ''));

export default selectSpeedTestTime;
