import _ from 'lodash';
import {EnumCurrency, EnumMediaType, InitialCurrencies, PriceInstruction} from 'cmd-control-client-lib';

import {IMediaPrice} from '@messenger/core/src/Redux/MediaPrice/IMediaPrice';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import AbstractMediaViewModel, {IPredefinedPrice} from '@messenger/core/src/Redux/MediaPrice/AbstractMediaViewModel';
import formatCurrency from '@messenger/core/src/Utils/Numbers/formatCurrency';

class MediaPricesViewModel extends AbstractMediaViewModel {
	protected mediaPrice: Record<EnumMediaType, PriceInstruction | undefined>;
	readonly currency: EnumCurrency;
	readonly currencySymbol: string;

	constructor(
		protected mediaInfo: Partial<IMediaPrice>,
		protected currenciesList: InitialCurrencies,
		protected mediaType?: EnumMediaType,
	) {
		super();
		this.mediaPrice = {
			[EnumMediaType.AUDIO]: mediaInfo.audioPrices,
			[EnumMediaType.VIDEO]: mediaInfo.videoPrices,
			[EnumMediaType.BITMAP]: mediaInfo.imagePrices,
			[EnumMediaType.TICKET]: mediaInfo.ticketPrices?.singleC2C.price,
			[EnumMediaType.TICKET_SHOW]: mediaInfo.ticketPrices?.ticketShow.price,
		};

		this.currency = mediaInfo.currency ?? EnumCurrency.EURO;
		this.currencySymbol = _.get(currenciesList, [`${this.currency}`, 'symbol'], '');
	}

	format(price: number) {
		return formatCurrency(this.currenciesList, ServiceFactory.i18n.language, this.currency, price);
	}

	getMin(mediaType: EnumMediaType, fallback = 1): number {
		const value = this.mediaPrice[mediaType]?.min || fallback;

		return _.isString(value) ? parseFloat(value) : value;
	}

	getMax(mediaType: EnumMediaType, fallback = 150): number {
		const value = this.mediaPrice[mediaType]?.max || fallback;

		return _.isString(value) ? parseFloat(value) : value;
	}

	getMinFormatted(mediaType: EnumMediaType, fallback = 1) {
		return this.format(this.getMin(mediaType, fallback));
	}

	getMaxFormatted(mediaType: EnumMediaType, fallback = 150) {
		return this.format(this.getMax(mediaType, fallback));
	}

	getDefault(mediaType: EnumMediaType, fallback = 0) {
		const value = this.mediaPrice[mediaType]?.default || fallback;

		return _.isString(value) ? parseFloat(value) : value;
	}

	getPredefinedList(mediaType: EnumMediaType): Array<IPredefinedPrice> {
		return (
			this.mediaPrice[mediaType]?.pick?.map(
				(price: string): IPredefinedPrice => ({
					value: _.toNumber(price),
					label: this.format(_.toNumber(price)),
				}),
			) || []
		);
	}

	isValid(price: number, mediaType: EnumMediaType = EnumMediaType.BITMAP) {
		if (_.isNaN(price) || !_.isFinite(price)) {
			return false;
		}

		const isInRage = price >= this.getMin(mediaType) && price <= this.getMax(mediaType);

		return mediaType === EnumMediaType.TICKET ? isInRage : isInRage || price === 0;
	}
}

export default MediaPricesViewModel;
