import {put} from 'typed-redux-saga';

import {channelsClientOnlyActions} from '@messenger/core/src/Redux/Channels/Actions';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

export const resetGuestFiltersSaga = function* () {
	yield* put(clientClientOnlyActions.setGuestTypeFilter(EnumGuestType.ALL));
	yield* put(channelsClientOnlyActions.resetFilter());
};
