import {call} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {showGlobalErrorSaga} from '@messenger/core/src/Redux/Client/Sagas/showGlobalErrorSaga';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';

import streamingGoOfflineSaga from 'src/Redux/Stream/Sagas/streamingGoOfflineSaga';

export const processGlobalErrorSaga = function* (action: ReturnType<typeof clientClientOnlyActions.globalError>) {
	try {
		if (ServiceFactory.webRtcApi.mediaStream) {
			yield* call(streamingGoOfflineSaga);
		}

		yield* call(showGlobalErrorSaga, action);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processGlobalErrorSaga'});
	}
};
