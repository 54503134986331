import cuid from 'cuid';
import _ from 'lodash';
import {call, put, select} from 'typed-redux-saga';
import {PayloadAction} from '@reduxjs/toolkit';

import {ALL_CHATS} from '@messenger/core/src/BusinessLogic/Constants';
import {messageInputClientOnlyActions} from '@messenger/core/src/Redux/MessageInput/Actions/messageInputClientOnlyActions';
import selectRunningChatsCount from '@messenger/core/src/Redux/Chats/Selectors/selectRunningChatsCount';
import {
	clientClientOnlyActions,
	TShortcutAppliedPayload,
} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import {selectCurrentChatChannelId} from '@messenger/core/src/Redux/Messages/Selectors/selectCurrentChatChannelId';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectTeamChannelId} from '@messenger/core/src/Redux/Channels/Selectors/selectTeamChannelId';
import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';
import selectIsAudioRecording from '@messenger/core/src/Redux/Attachment/Selectors/selectIsAudioRecording';
import {selectCurrentAttachmentVm} from '@messenger/core/src/Redux/Attachment/Selectors/selectCurrentAttachmentVm';

export const processShortcutAppliedSaga = function* (action: PayloadAction<TShortcutAppliedPayload>) {
	try {
		const selectedChannelId: string | undefined = yield* select(selectCurrentChatChannelId);
		const runningChatsCount: number = yield* select(selectRunningChatsCount);
		const teamChannelId: string | undefined = yield* select(selectTeamChannelId);

		const canSend =
			(_.isUndefined(selectedChannelId) && runningChatsCount > 0) ||
			(!_.isUndefined(selectedChannelId) && selectedChannelId !== teamChannelId);

		if (!canSend) {
			yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], {
				text: ServiceFactory.i18n.t('shortcuts:apply-failed-notification'),
				variant: EnumAbstractNotificationVariant.WARNING,
				key: cuid(),
			});

			return;
		}

		if (action.payload.autoSend) {
			if (yield* select(selectIsAudioRecording)) {
				yield* put(clientClientOnlyActions.setShortcutWhileRecordingModalOpen(true));

				return;
			} else {
				const attachment = yield* select(selectCurrentAttachmentVm);

				if (attachment && attachment?.hasAttachmentError) {
					return;
				}

				yield* put(messagesClientOnlyActions.sendMessage({message: action.payload.text}));
			}
		} else {
			yield* put(
				messageInputClientOnlyActions.upsertOne({
					id: !_.isUndefined(selectedChannelId) ? selectedChannelId : ALL_CHATS,
					value: action.payload.text,
				}),
			);
		}

		const autoSendLabel = action.payload.autoSend ? ServiceFactory.i18n.t('shortcuts:auto-send') : '';

		yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], {
			text: ServiceFactory.i18n.t('shortcuts:applied-notification', {
				label: action.payload.label,
				autoSend: autoSendLabel,
			}),
			variant: EnumAbstractNotificationVariant.INFO,
			key: cuid(),
		});
	} catch (err) {
		ServiceFactory.logService.error(err as Error, {saga: 'processShortcutAppliedSaga'});
	}
};
