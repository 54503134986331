import {EnumMediaType} from 'cmd-control-client-lib';

abstract class AbstractMediaViewModel<T extends [EnumMediaType] | [] = [EnumMediaType]> {
	abstract format(value: number): string;

	abstract getMin(...args: [...T, number] | T): number;

	abstract getMax(...args: [...T, number] | T): number;

	abstract getMinFormatted(...args: [...T, number] | T): string;

	abstract getMaxFormatted(...args: [...T, number] | T): string;

	abstract getPredefinedList(...args: T): Array<IPredefinedPrice>;

	abstract isValid(value: number, mediaType?: EnumMediaType): boolean;
}

export interface IPredefinedPrice {
	value: number;
	label: string;
}

export default AbstractMediaViewModel;
