import {createSelector} from '@reduxjs/toolkit';
import _ from 'lodash';

import IRootState from '@messenger/core/src/Redux/IRootState';
import {selectChannelGroupVMs} from '@messenger/core/src/Redux/ChannelGroups/Selectors/selectChannelGroupVMs';

const getGroupIds = (_state: IRootState, groupIds: string[]) => groupIds;

export const selectChannelIdsByGroupIds = createSelector(
	[selectChannelGroupVMs, getGroupIds],
	(channelGroupVMs, groupIds) => {
		return _.reduce(
			channelGroupVMs,
			(result, channelGroupVM) =>
				channelGroupVM && !channelGroupVM.isDeleted && _.includes(groupIds, channelGroupVM.groupId)
					? [...result, channelGroupVM.channelId]
					: result,
			[] as string[],
		);
	},
);
