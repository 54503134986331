import {ACTION, CMDC_MSETTINGS, CMDP_SGETMSETTINGS_RESPONSE, CMDP_SSETMSETTING_RESPONSE} from 'cmd-control-client-lib';

import {ServerToClientActions, TSingleEntityActionMeta} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class ModelSettingsServerToClientActions extends ServerToClientActions<EnumStore.MODEL_SETTINGS> {
	readonly scope = EnumStore.MODEL_SETTINGS;

	getModelSettings = this.createAction(
		ACTION.CMDP_SGETMSETTINGS,
		this.getPrepareAction<CMDP_SGETMSETTINGS_RESPONSE['values'], CMDP_SGETMSETTINGS_RESPONSE, false>(),
	);
	modelSettingsReceived = this.createAction(
		ACTION.CMDC_MSETTINGS,
		this.getPrepareAction<CMDC_MSETTINGS['params'], TSingleEntityActionMeta<CMDC_MSETTINGS>, false>(),
	);

	setModelSettingResponseReceived = this.createAction(
		ACTION.CMDP_SSETMSETTING,
		this.getPrepareAction<CMDP_SSETMSETTING_RESPONSE['values'], CMDP_SSETMSETTING_RESPONSE, false>(),
	);
}

export const modelSettingsServerToClientActions = new ModelSettingsServerToClientActions();
