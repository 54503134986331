import {createSelector} from '@messenger/core/src/Utils/Redux';
import selectRunningChatVMsByFilter from '@messenger/core/src/Redux/Chats/Selectors/selectRunningChatVMsByFilter';
import selectVisibleChatsLimit from '@messenger/core/src/Redux/Chats/Selectors/selectVisibleChatsLimit';
import selectRunningChatsCount from '@messenger/core/src/Redux/Chats/Selectors/selectRunningChatsCount';

const selectRunningChatsPaginated = createSelector(
	[selectRunningChatVMsByFilter, selectVisibleChatsLimit, selectRunningChatsCount],
	(chats, visibleChatsLimit, runningChatsCount) => chats.slice(0, Math.min(visibleChatsLimit, runningChatsCount)),
);

export default selectRunningChatsPaginated;
