import {ResultCode, SupportedLanguage} from 'cmd-control-client-lib';

import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {EnumKeyboardNavigate} from '@messenger/core/src/Redux/Client/EnumKeyboardNavigate';
import EnumNotifications from '@messenger/core/src/BusinessLogic/EnumNotifications';
import EnumChatFontSize from '@messenger/core/src/BusinessLogic/EnumChatFontSize';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';
import {TBackendConnectionStatus} from '@messenger/core/src/Redux/Client/slice';

export enum EnumClientOnlyActions {
	SET_CLIENT_READY = 'SET_CLIENT_READY',
	SET_IS_CONNECTED = 'SET_IS_CONNECTED',
	SET_IS_DISCONNECTED = 'SET_IS_DISCONNECTED',
	TOGGLE_MARCO_MODAL = 'TOGGLE_MARCO_MODAL',
	OPEN_USER_INFO_AREA = 'OPEN_USER_INFO_AREA',
	OPEN_CAM2CAM_AREA = 'OPEN_CAM2CAM_AREA',
	CLOSE_CAM2CAM_AREA = 'CLOSE_CAM2CAM_AREA',
	FORCE_DRAWER = 'FORCE_DRAWER',
	TOGGLE_DRAWER = 'TOGGLE_DRAWER',
	SET_USER_INFO_AREA_CHANNEL = 'SET_USER_INFO_AREA_CHANNEL',
	RESET_USER_INFO_AREA_CHANNEL = 'RESET_USER_INFO_AREA_CHANNEL',
	CLOSE_USER_INFO_AREA = 'CLOSE_USER_INFO_AREA',
	TOGGLE_USER_INFO_AREA = 'TOGGLE_USER_INFO_AREA',
	TOGGLE_SOUND_MUTED = 'TOGGLE_SOUND_MUTED',
	SHOW_LOGOUT_WARNING = 'SHOW_LOGOUT_WARNING',
	RESET_STORE = 'RESET_STORE',
	HIDE_LOGOUT_WARNING = 'HIDE_LOGOUT_WARNING',
	SET_REPLY_MESSAGE_ID = 'SET_REPLY_MESSAGE_ID',
	RESET_REPLY_MESSAGE_ID = 'RESET_REPLY_MESSAGE_ID',
	DISABLE_SERVER = 'DISABLE_SERVER',
	ENABLE_SERVER = 'ENABLE_SERVER',
	TOGGLE_SOFT_CHAT_AVAILABILITY = 'TOGGLE_SOFT_CHAT_AVAILABILITY',
	PLAY_SOUND = 'PLAY_SOUND',
	CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
	COPIED_TO_CLIPBOARD = 'COPIED_TO_CLIPBOARD',
	GLOBAL_ERROR = 'GLOBAL_ERROR',

	SOUNDS_ACCEPTED = 'SOUNDS_ACCEPTED',
	SOUNDS_DECLINED = 'SOUNDS_DECLINED',
	SOUNDS_TEST_RESULT = 'SOUNDS_TEST_RESULT',
	NAVIGATE = 'NAVIGATE',
	SET_CHAT_FONT_SIZE = 'SET_CHAT_FONT_SIZE',
	LOAD_CHAT_FONT_SIZE_FROM_STORE = 'LOAD_CHAT_FONT_SIZE_FROM_STORE',
	APPLY_SHORTCUT = 'APPLY_SHORTCUT',
	DISABLE_SHORTCUTS = 'DISABLE_SHORTCUTS',
	ENABLE_SHORTCUTS = 'ENABLE_SHORTCUTS',
	CHAT_SCREEN_RENDERED = 'CHAT_SCREEN_RENDERED',
	NOTIFY_USER_MENU_OPEN = 'USER_MENU_OPEN',
	SHOW_NOTIFICATION = 'SHOW_NOTIFICATION',
	SET_SHORTCUT_WHILE_RECORDING_MODAL_OPEN = 'SET_SHORTCUT_WHILE_RECORDING_MODAL_OPEN',
	UPDATE_NETWORK_STATUS = 'UPDATE_NETWORK_STATUS',
	UPDATE_BACKEND_CONNECTION_STATUS = 'UPDATE_BACKEND_CONNECTION_STATUS',
	SET_GUEST_TYPE_FILTER = 'SET_GUEST_TYPE_FILTER',
	RESET_GUEST_FILTERS = 'RESET_GUEST_FILTERS',
	FILTER_CHANNELS_ONLY = 'FILTER_CHANNELS_ONLY',
	SET_APP_VERSION_INFO = 'SET_APP_VERSION_INFO',
	UPDATE_APP = 'UPDATE_APP',
	SET_SKIPPED_VERSION = 'SET_SKIPPED_VERSION',
	SET_CACHE_SIZE = 'SET_CACHE_SIZE',
	CHECK_CACHE_SIZE = 'CHECK_CACHE_SIZE',
	CLEAR_CACHE = 'CLEAR_CACHE',
	SET_IS_BULK_MESSAGE = 'SET_IS_BULK_MESSAGE',
}

/**
 * @NOTE Most of the actions are in src/Actions/Client/* files
 */
class ClientClientOnlyActions extends ClientOnlyActions<EnumStore.CLIENT> {
	readonly scope = EnumStore.CLIENT;

	setClientReady = this.createAction(EnumClientOnlyActions.SET_CLIENT_READY);
	setIsConnected = this.createAction(EnumClientOnlyActions.SET_IS_CONNECTED);
	setIsDisconnected = this.createAction(EnumClientOnlyActions.SET_IS_DISCONNECTED);
	setIsBulkMessage = this.createAction(EnumClientOnlyActions.SET_IS_BULK_MESSAGE, this.getPrepareAction<boolean>());
	toggleMacroModal = this.createAction(EnumClientOnlyActions.TOGGLE_MARCO_MODAL);
	openCam2CamArea = this.createAction(
		EnumClientOnlyActions.OPEN_CAM2CAM_AREA,
		this.getPrepareAction<{chatId: string | undefined}>(),
	);
	closeCam2CamArea = this.createAction(EnumClientOnlyActions.CLOSE_CAM2CAM_AREA);
	forceDrawer = this.createAction(EnumClientOnlyActions.FORCE_DRAWER, this.getPrepareAction<{isOpened: boolean}>());
	toggleDrawer = this.createAction(EnumClientOnlyActions.TOGGLE_DRAWER);
	setUserAreaChannelId = this.createAction(
		EnumClientOnlyActions.SET_USER_INFO_AREA_CHANNEL,
		this.getPrepareAction<{channelId: string}>(),
	);
	resetUserAreaChannelId = this.createAction(EnumClientOnlyActions.RESET_USER_INFO_AREA_CHANNEL);
	closeUserInfoArea = this.createAction(EnumClientOnlyActions.CLOSE_USER_INFO_AREA);
	openUserInfoArea = this.createAction(
		EnumClientOnlyActions.OPEN_USER_INFO_AREA,
		this.getPrepareAction<{channelId: string}>(),
	);
	toggleUserInfoArea = this.createAction(EnumClientOnlyActions.TOGGLE_USER_INFO_AREA);
	toggleSoundMuted = this.createAction(EnumClientOnlyActions.TOGGLE_SOUND_MUTED);
	showLogoutWarning = this.createAction(
		EnumClientOnlyActions.SHOW_LOGOUT_WARNING,
		this.getPrepareAction<EnumStopStreamWarningAction | undefined>(),
	);
	resetStore = this.createAction(EnumClientOnlyActions.RESET_STORE);
	hideLogoutWarning = this.createAction(EnumClientOnlyActions.HIDE_LOGOUT_WARNING);
	setReplyMessageId = this.createAction(
		EnumClientOnlyActions.SET_REPLY_MESSAGE_ID,
		this.getPrepareAction<{messageId: string}>(),
	);
	resetReplyMessageId = this.createAction(EnumClientOnlyActions.RESET_REPLY_MESSAGE_ID);
	disableServer = this.createAction(EnumClientOnlyActions.DISABLE_SERVER);
	enableServer = this.createAction(EnumClientOnlyActions.ENABLE_SERVER);
	toggleSoftChatAvailability = this.createAction(EnumClientOnlyActions.TOGGLE_SOFT_CHAT_AVAILABILITY);
	playSound = this.createAction(EnumClientOnlyActions.PLAY_SOUND, this.getPrepareAction<string>());
	changeLanguage = this.createAction(EnumClientOnlyActions.CHANGE_LANGUAGE, this.getPrepareAction<SupportedLanguage>());
	copiedToClipboard = this.createAction(EnumClientOnlyActions.COPIED_TO_CLIPBOARD, this.getPrepareAction<string>());
	globalError = this.createAction(
		EnumClientOnlyActions.GLOBAL_ERROR,
		this.getPrepareAction<{code: ResultCode; reason: string}>(),
	);

	disableShortcuts = this.createAction(EnumClientOnlyActions.DISABLE_SHORTCUTS);
	enableShortcuts = this.createAction(EnumClientOnlyActions.ENABLE_SHORTCUTS);
	soundsAccepted = this.createAction(EnumClientOnlyActions.SOUNDS_ACCEPTED);
	soundsDeclined = this.createAction(EnumClientOnlyActions.SOUNDS_DECLINED);
	soundsTestResult = this.createAction(EnumClientOnlyActions.SOUNDS_TEST_RESULT, this.getPrepareAction<boolean>());
	setChatFontSize = this.createAction(
		EnumClientOnlyActions.SET_CHAT_FONT_SIZE,
		this.getPrepareAction<EnumChatFontSize>(),
	);
	navigate = this.createAction(EnumClientOnlyActions.NAVIGATE, this.getPrepareAction<EnumKeyboardNavigate>());
	loadChatFontSizeFromStore = this.createAction(
		EnumClientOnlyActions.LOAD_CHAT_FONT_SIZE_FROM_STORE,
		this.getPrepareAction<EnumChatFontSize>(),
	);
	applyShortcut = this.createAction(
		EnumClientOnlyActions.APPLY_SHORTCUT,
		this.getPrepareAction<TShortcutAppliedPayload>(),
	);
	notifyChatScreenRendered = this.createAction(EnumClientOnlyActions.CHAT_SCREEN_RENDERED);
	notifyUserMenuOpened = this.createAction(EnumClientOnlyActions.NOTIFY_USER_MENU_OPEN);
	showNotification = this.createAction(
		EnumClientOnlyActions.SHOW_NOTIFICATION,
		this.getPrepareAction<EnumNotifications>(),
	);
	setShortcutWhileRecordingModalOpen = this.createAction(
		EnumClientOnlyActions.SET_SHORTCUT_WHILE_RECORDING_MODAL_OPEN,
		this.getPrepareAction<boolean>(),
	);
	updateNetworkStatus = this.createAction(
		EnumClientOnlyActions.UPDATE_NETWORK_STATUS,
		this.getPrepareAction<{isOnline: boolean; isResumable?: boolean}>(),
	);
	updateBackendConnectionStatus = this.createAction(
		EnumClientOnlyActions.UPDATE_BACKEND_CONNECTION_STATUS,
		this.getPrepareAction<TBackendConnectionStatus>(),
	);
	resetGuestFilters = this.createAction(EnumClientOnlyActions.RESET_GUEST_FILTERS);
	filterChannelsOnly = this.createAction(EnumClientOnlyActions.FILTER_CHANNELS_ONLY);
	setGuestTypeFilter = this.createAction(
		EnumClientOnlyActions.SET_GUEST_TYPE_FILTER,
		this.getPrepareAction<EnumGuestType>(),
	);
	setAppVersionInfo = this.createAction(
		EnumClientOnlyActions.SET_APP_VERSION_INFO,
		this.getPrepareAction<TAppVersionInfo>(),
	);
	updateApp = this.createAction(EnumClientOnlyActions.UPDATE_APP);
	setSkippedVersion = this.createAction(EnumClientOnlyActions.SET_SKIPPED_VERSION, this.getPrepareAction<string>());
	clearCache = this.createAction(EnumClientOnlyActions.CLEAR_CACHE);
	checkCacheSize = this.createAction(EnumClientOnlyActions.CHECK_CACHE_SIZE);
	setCacheSize = this.createAction(EnumClientOnlyActions.SET_CACHE_SIZE, this.getPrepareAction<number>());
}

export enum EnumStopStreamWarningAction {
	LOGOUT,
	STOP_STREAM,
}

export type TShortcutAppliedPayload = {
	autoSend: boolean;
	label: string;
	text: string;
};

export type TAppVersionInfo = {
	shouldUpdate: boolean;
	appStoreVersion: string;
	isVersionSkipped: boolean;
};

export const clientClientOnlyActions = new ClientClientOnlyActions();
