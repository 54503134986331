import {ChannelType} from 'cmd-control-client-lib';
import _ from 'lodash';
import {Dictionary} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import IRootState from '@messenger/core/src/Redux/IRootState';
import {channelAdapter} from '@messenger/core/src/Redux/Channels/entityAdapter';
import {createSelector} from '@messenger/core/src/Utils/Redux';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectTeamChannelId} from '@messenger/core/src/Redux/Channels/Selectors/selectTeamChannelId';
import ChannelHelper from '@messenger/core/src/Redux/Channels/ChannelHelper';
import {TDefaultSelectors} from '@messenger/core/src/Redux/TDefaultSelectors';
import {
	selectContactNoteVMById,
	selectContactNoteEntityVMs,
} from '@messenger/core/src/Redux/ContactNote/Selectors/defaultSelectors';
import {
	selectChannelInfoVMById,
	selectChannelInfoEntityVMs,
} from '@messenger/core/src/Redux/ChannelInfos/Selectors/defaultSelectors';
import {selectOnlineStateEntityVMs} from '@messenger/core/src/Redux/OnlineState/Selectors/defaultSelectors';
import getChannelVMCached from '@messenger/core/src/Redux/Channels/getChannelVMCached';
import ChannelVM from '@messenger/core/src/Redux/Channels/ChannelVM';

// see typescript nominal typing https://www.typescriptlang.org/play#example/nominal-typing
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TChannelEntityId = string & {__channelEntityIdBrand: any};

export const getChannelIdForSelector = (channelId?: string) => channelId as TChannelEntityId;

const {selectEntities, selectIds, selectById, selectAll} = channelAdapter.getSelectors<IRootState>(
	(state) => state[EnumStore.CHANNELS],
) as TDefaultSelectors<ChannelType, TChannelEntityId>;

const isTvChannelAllowed = ServiceFactory.env.shouldShowTVChannel();
const isInfoChannelAllowed = ServiceFactory.env.shouldShowInfoChannel();

const isChannelAllowed = (channelId?: string, teamChannelId?: string) =>
	(isTvChannelAllowed || !ChannelHelper.isTVChannel(channelId)) &&
	(isInfoChannelAllowed || teamChannelId !== channelId);

const _selectEntities = createSelector([selectEntities, selectTeamChannelId], (channelEntities, teamChannelId) =>
	_.reduce<Dictionary<ChannelType>, Dictionary<ChannelType>>(
		channelEntities,
		(result, channelEntity) => {
			if (channelEntity && isChannelAllowed(channelEntity.channelId, teamChannelId)) {
				result[channelEntity.channelId] = channelEntity;
			}

			return result;
		},
		{},
	),
);

const _selectChannelById = createSelector([selectById, selectTeamChannelId], (channelEntity, teamChannelId) =>
	channelEntity && isChannelAllowed(channelEntity.channelId, teamChannelId) ? channelEntity : undefined,
);

const _selectAll = createSelector([selectAll, selectTeamChannelId], (channelEntities, teamChannelId) =>
	_.isUndefined(channelEntities)
		? []
		: _.filter(channelEntities, (channelEntity) => isChannelAllowed(channelEntity.channelId, teamChannelId)),
);

export const selectTotalChannels = createSelector([selectIds], (channelIds) => _.size(channelIds));

export const selectChannelIds = createSelector([selectIds, selectTeamChannelId], (channelIds, teamChannelId) =>
	_.filter(channelIds, (channelId) => isChannelAllowed(channelId, teamChannelId)),
);

export const selectChannelVMById = createSelector(
	[
		_selectChannelById,
		selectContactNoteVMById,
		selectChannelInfoVMById,
		selectTeamChannelId,
		selectOnlineStateEntityVMs,
	],
	(entity, contactNoteVM, channelInfoVM, teamChannelId, onlineStateVMs) =>
		getChannelVMCached(entity, [
			contactNoteVM,
			channelInfoVM,
			teamChannelId,
			_.get(onlineStateVMs, `${entity?.channelId}`),
		]),
);

export const selectChannelEntityVMs = createSelector(
	[
		_selectEntities,
		selectContactNoteEntityVMs,
		selectChannelInfoEntityVMs,
		selectTeamChannelId,
		selectOnlineStateEntityVMs,
	],
	(entities, contactNoteVMEntities, channelInfoVMEntities, teamChannelId, onlineStateVMs) =>
		_.reduce(
			entities,
			(result: Record<string, ChannelVM>, entity) => {
				if (!_.isUndefined(entity)) {
					const vm = getChannelVMCached(entity, [
						_.get(contactNoteVMEntities, entity.channelId),
						_.get(channelInfoVMEntities, entity.channelId),
						teamChannelId,
						_.get(onlineStateVMs, entity.channelId),
					]);

					if (!_.isUndefined(vm)) {
						result[entity.channelId] = vm;
					}
				}

				return result;
			},
			{},
		),
);

export const selectChannelVMs = createSelector(
	[_selectAll, selectContactNoteEntityVMs, selectChannelInfoEntityVMs, selectTeamChannelId, selectOnlineStateEntityVMs],
	(entities, contactNoteVMEntities, channelInfoVMEntities, teamChannelId, onlineStateVMs) =>
		_.compact(
			_.map(entities, (entity) =>
				getChannelVMCached(entity, [
					_.get(contactNoteVMEntities, entity.channelId),
					_.get(channelInfoVMEntities, entity.channelId),
					teamChannelId,
					_.get(onlineStateVMs, entity.channelId),
				]),
			),
		),
);
