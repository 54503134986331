import {END} from 'redux-saga';
import {take} from 'typed-redux-saga';
import {EventChannel} from '@redux-saga/core';
import {PayloadAction} from '@reduxjs/toolkit';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {TShortcutAppliedPayload} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';
import {sessionServerToClientActions} from '@messenger/core/src/Redux/Session/Actions';

const closeShortcutsChannelSaga = function* (
	shortcutsChannel: EventChannel<PayloadAction<TShortcutAppliedPayload> | END>,
) {
	try {
		yield* take([sessionServerToClientActions.loggedOut, sessionServerToClientActions.sessionRemotelyTerminated]);

		// close the channel
		shortcutsChannel.close();
	} catch (err) {
		ServiceFactory.logService.error(err, {saga: 'keyboardShortcutsSaga'});
	}
};

export default closeShortcutsChannelSaga;
