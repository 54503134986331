import _ from 'lodash';
import {select, put, call} from 'typed-redux-saga';
import {EnumMessageDirection, EnumMessageType} from 'cmd-control-client-lib';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import {selectUnseenChatMessagesCount} from '@messenger/core/src/Redux/Messages/Selectors/selectUnseenChatMessagesCount';
import {selectMessageIds} from '@messenger/core/src/Redux/Messages/Selectors/defaultSelectors';
import processBatchUpsertSaga from '@messenger/core/src/Redux/Messages/Sagas/processBatchUpsertSaga';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

import processBatchUpsertWebSaga from 'src/Redux/Messages/Sagas/processBatchUpsertWebSaga';

const processManyMessagesSaga = function* (action: ReturnType<typeof messagesClientOnlyActions.processMany>) {
	try {
		const guestIdentity = yield* select(selectCurrentGuestIdentity);

		if (guestIdentity?.guestType === EnumGuestType.ALL || guestIdentity?.guestType === EnumGuestType.CHAT) {
			const chatIncomingMessageIds = _.chain(action.payload)
				.map(
					({msgType, messageId, direction, chatID}) =>
						direction === EnumMessageDirection.IN &&
						msgType === EnumMessageType.CHAT &&
						(guestIdentity?.guestType === EnumGuestType.ALL || chatID === guestIdentity.chatId) &&
						messageId,
				)
				.compact()
				.uniq()
				.value();

			if (!_.isEmpty(chatIncomingMessageIds)) {
				const unseenChatMessagesCount = yield* select(selectUnseenChatMessagesCount);
				const messagesIds = yield* select(selectMessageIds);
				const newMessagesIds = _.difference(chatIncomingMessageIds, messagesIds);

				if (!_.isEmpty(newMessagesIds)) {
					yield* put(
						messagesClientOnlyActions.setUnseenChatMessagesCount(newMessagesIds.length + unseenChatMessagesCount),
					);
				}
			}
		}

		const setManyAction = messagesClientOnlyActions.setMany(action.payload);

		yield* put(setManyAction);
		yield* call(processBatchUpsertSaga, setManyAction);
		yield* call(processBatchUpsertWebSaga, ServiceFactory.uiContainer, setManyAction);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processManyMessagesSaga', action});
	}
};

export default processManyMessagesSaga;
