import _ from 'lodash';
import {call, put, select} from 'typed-redux-saga';
import {isMobile} from 'react-device-detect';
import moment from 'moment/moment';

import {EnumLocalStorageKeys} from '@messenger/core/src/BusinessLogic/EnumLocalStorageKeys';
import EnumStoreType from '@messenger/core/src/BusinessLogic/EnumStoreType';
import EnumStreamType from '@messenger/core/src/BusinessLogic/EnumStreamType';
import {selectStreamType} from '@messenger/core/src/Redux/Stream/Selectors/selectStreamType';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumChatMode} from '@messenger/core/src/BusinessLogic/EnumChatMode';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';
import {chatsClientOnlyActions} from '@messenger/core/src/Redux/Chats/Actions';
import {ticketShowClientOnlyActions} from '@messenger/core/src/Redux/TicketShow/Actions/ticketShowClientOnlyActions';
import {selectIsScheduledAfterTicketShowModal} from '@messenger/core/src/Redux/TicketShow/Selectors/selectIsScheduledAfterTicketShowModal';
import {selectAllGuestChatWithVoyeurIds} from '@messenger/core/src/Redux/Chats/Selectors/selectAllGuestChatWithVoyeurIds';
import {messageInputClientOnlyActions} from '@messenger/core/src/Redux/MessageInput/Actions/messageInputClientOnlyActions';
import {ALL_CHATS} from '@messenger/core/src/BusinessLogic/Constants';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';
import {liveSessionsClientOnlyActions} from '@messenger/core/src/Redux/LiveSessions/Actions/liveSessionsClientOnlyActions';

import stopWebRtcOutputStreamSaga from 'src/Redux/Stream/Sagas/stopWebRtcOutputStreamSaga';

function* streamingGoOfflineSaga() {
	try {
		const currentStreamType: EnumStreamType | undefined = yield* select(selectStreamType);

		if (!_.isUndefined(currentStreamType)) {
			const api = ServiceFactory.store;

			yield* call([api, api.set], EnumLocalStorageKeys.LAST_SUCCESSFUL_STREAM_TYPE, currentStreamType, {
				storeType: EnumStoreType.LOCAL,
			});
		}

		yield* put(streamClientOnlyActions.setStreamMode(EnumChatMode.CHAT));
		yield* call(stopWebRtcOutputStreamSaga, false);
		yield* put(messageInputClientOnlyActions.removeOne(ALL_CHATS));
		yield* put(streamClientOnlyActions.resetStateState());

		const allGuestChatWithVoyeurIds = yield* select(selectAllGuestChatWithVoyeurIds);

		if (!_.isEmpty(allGuestChatWithVoyeurIds)) {
			yield* put(chatsClientOnlyActions.removeMany(allGuestChatWithVoyeurIds));
		}

		const isShowModalAfterTicketShowModal: boolean = yield* select(selectIsScheduledAfterTicketShowModal);

		if (isShowModalAfterTicketShowModal) {
			yield* put(
				ticketShowClientOnlyActions.setModalAfterTicketShow({
					isShown: true,
				}),
			);
		}

		if (isMobile && ServiceFactory.env.isAppMobileEnabled()) {
			yield* put(liveSessionsClientOnlyActions.loadByDate({date: moment()}));
			const {goHome, isChatPage} = yield* getNavigationContext();

			if (isChatPage(ALL_CHATS)) {
				yield* call(goHome);
			}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'streamingGoOfflineSaga'});
	}
}

export default streamingGoOfflineSaga;
