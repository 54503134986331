enum EnumActions {
	START = 'CLIENT_SPEED_TEST_START',
	MARK_PROGRESS = 'CLIENT_SPEED_TEST_PROGRESS',
	END = 'CLIENT_SPEED_TEST_END',
	SUCCESS = 'CLIENT_SPEED_TEST_SUCCESS',
	ERROR = 'CLIENT_SPEED_TEST_ERROR',
	RESET = 'CLIENT_SPEED_TEST_RESET',
	RESET_ERROR = 'CLIENT_SPEED_TEST_RESET_ERROR',
	SET_SPEED_TEST_URL = 'SET_SPEED_TEST_URL',
}

export default EnumActions;
