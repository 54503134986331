import * as ccuid from 'cuid';
import _ from 'lodash';
import {
	CMDC_CMSG,
	EnumBooleanStringified,
	EnumCurrency,
	EnumMediaState,
	EnumMediaType,
	EnumMessageDirection,
	EnumMessageType,
	MessageId,
} from 'cmd-control-client-lib';
import {EntityId} from '@reduxjs/toolkit';

import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {getTemporaryMessageId} from '@messenger/core/src/Redux/Messages/entityAdapter';
import {SswMessageType} from '@messenger/core/src/Redux/Messages/Model';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import getDefaultMessageTime from '@messenger/core/src/Utils/Messages/getMessageCreationTime';
import getMessageKey from '@messenger/core/src/Utils/Messages/getMessageKey';
import AttachmentVM from '@messenger/core/src/Redux/Attachment/AttachmentVM';
import EnumKeyInMessage from '@messenger/core/src/BusinessLogic/EnumKeyInMessage';
import {TMessageMediaUploadStatus} from '@messenger/core/src/Redux/Messages/MessageMediaUploadStatus';
import {MessageViewModel} from '@messenger/core/src/Redux/Messages/MessageViewModel';
import {EnumMessageHistorySubject} from '@messenger/core/src/Redux/Messages/Actions/messagesClientToServerActions';
import getMediaType from '@messenger/core/src/Utils/Media/getMediaType';

/**
 * @WARNING: Shouldn't be exported.
 * Use MessagesClientOnlyActions.<action>.type instead
 */
export enum EnumClientActions {
	APPEND_MESSAGE = 'APPEND_MESSAGE',
	SET_MANY = 'SET_MANY',
	PROCESS_MANY = 'PROCESS_MANY',
	SEND_MESSAGE_WITH_MEDIA_UPLOAD = 'SEND_MESSAGE_WITH_MEDIA_UPLOAD',
	MARK_UPLOADED = 'MARK_UPLOADED',
	SET_UPLOAD_ERROR = 'SET_UPLOAD_ERROR',
	ADD_INTRO_MESSAGES = 'ADD_INTRO_MESSAGES',
	REMOVE_INTRO_MESSAGES = 'REMOVE_INTRO_MESSAGES',
	UPDATE_INTRO_MESSAGE = 'UPDATE_INTRO_MESSAGE',
	DELETE_MESSAGE = 'DELETE_MESSAGE',
	REMOVE_MESSAGE_BY_ID = 'REMOVE_MESSAGE_BY_ID',
	REMOVE_MANY_BY_IDS = 'REMOVE_MANY_BY_IDS',
	RESET_STORE = 'RESET_STORE',
	SET_MEDIA_UPLOAD_URL = 'SET_MEDIA_UPLOAD_URL',
	UPDATE_MESSAGE_TIME_SEEN = 'UPDATE_MESSAGE_TIME_SEEN',
	SEND_MESSAGE = 'SEND_MESSAGE',
	SEND_CURRENT_MESSAGE = 'SEND_CURRENT_MESSAGE',
	NAVIGATE_TO_MESSAGE = 'NAVIGATE_TO_MESSAGE',
	HIGHLIGHT_MESSAGE = 'HIGHLIGHT_MESSAGE',
	END_SCROLL_TO_MESSAGE = 'END_SCROLL_TO_MESSAGE',
	STOP_MESSAGE_HIGHLIGHT = 'STOP_MESSAGE_HIGHLIGHT',
	REMOVE_SENT_MESSAGE_KEY = 'REMOVE_SENT_MESSAGE_KEY',
	NAVIGATE_TO_CAMERA = 'NAVIGATE_TO_CAMERA',
	SCROLLED_TO_MESSAGE = 'SCROLLED_TO_MESSAGE',
	RETRY_SAVE_MEDIA = 'RETRY_SAVE_MEDIA',
	ADD_MANY = 'ADD_MANY',
	SET_SENT_CHANNEL_COUNT = 'SET_SENT_CHANNEL_COUNT',
	DELETE_MESSAGES = 'DELETE_MESSAGES',
	RESET_IS_LOADING = 'RESET_IS_LOADING',
	UPDATE_MESSAGE_CHANNELS = 'UPDATE_MESSAGE_CHANNELS',
	VIRTUOSO_CHANGE_FIRST_ITEM_INDEX = 'VIRTUOSO_CHANGE_FIRST_ITEM_INDEX',
	NOTIFY_MODEL_ABOUT_SENDING_REPLY = 'NOTIFY_MODEL_ABOUT_SENDING_REPLY',
	SET_UNSEEN_CHAT_MESSAGES_COUNT = 'SET_UNSEEN_CHAT_MESSAGES_COUNT',
	MARK_CHAT_MESSAGE_SEEN = 'MARK_CHAT_MESSAGE_SEEN',
	REQUEST_MESSAGES_HISTORY = 'REQUEST_MESSAGES_HISTORY',
}

class MessagesClientOnlyActions extends ClientOnlyActions<EnumStore.MESSAGES> {
	readonly scope = EnumStore.MESSAGES;

	appendMessage = this.createAction(EnumClientActions.APPEND_MESSAGE, (message: Partial<SswMessageType>) => {
		const messageKey = _.get(message, 'messageKey', ccuid.slug());
		const extraProps = {
			time: message.time ?? getDefaultMessageTime(message),
			direction: message.direction ?? EnumMessageDirection.OUT,
		};

		if (!message.chatID && message.chatIDs) {
			const chatIDs = message.chatIDs.split(',');

			if (chatIDs.length === 1) {
				//we need this to allow appended message to bypass filter core/src/BusinessLogic/MessagesDisplayFilters/ExactChatFilter.ts
				_.assign(extraProps, {
					chatID: chatIDs[0],
					key: EnumKeyInMessage.SINGLE,
				});
			}
		}

		return {
			payload: {
				messageId: getTemporaryMessageId(messageKey),
				msgType: message.chatID || message.chatIDs ? EnumMessageType.CHAT : EnumMessageType.MESSENGER,
				// do not override messageId if present
				...message,
				...extraProps,
			},
		};
	});

	processMany = this.createAction(EnumClientActions.PROCESS_MANY, this.getPrepareAction<CMDC_CMSG['params'][]>());
	setMany = this.createAction(EnumClientActions.SET_MANY, (payload: CMDC_CMSG['params'][]) => {
		return {
			payload: _.chain(payload)
				.filter((message) => !message.isCopy)
				.map((message) => {
					return {
						...message,
						time: message.time ?? String(new Date().getTime()),
					};
				})
				.value(),
		};
	});

	sendMessageWithMediaUpload = this.createAction(
		EnumClientActions.SEND_MESSAGE_WITH_MEDIA_UPLOAD,
		({
			attachment,
			text,
			channelId,
			messageKey = getMessageKey(),
			messageId = getTemporaryMessageId(messageKey),
			isBulk = false,
		}: {
			attachment: AttachmentVM;
			text?: string;
			channelId: string;
			messageId?: string;
			messageKey?: string;
			isBulk?: boolean;
		}) => {
			const mediaType = getMediaType(attachment.file);
			let audioMp3: string | undefined = undefined;
			let videoFileUrl: string | undefined = undefined;
			let videoPoster: string | undefined = undefined;
			let imgPixelated: string | undefined = undefined;
			let isChargeable = EnumBooleanStringified.FALSE;
			let mediaPrice: string | undefined = undefined;
			let currency: EnumCurrency | undefined = undefined;

			switch (mediaType) {
				case EnumMediaType.AUDIO:
					audioMp3 = _.get(attachment.file, 'uri', 'Blob');

					break;
				case EnumMediaType.VIDEO:
					videoFileUrl =
						attachment.file instanceof File
							? ServiceFactory.uiContainer.createObjectURL(attachment.file)
							: attachment.file?.uri;
					videoPoster = attachment.thumbnail;

					break;
				case EnumMediaType.BITMAP:
					imgPixelated =
						attachment.file instanceof File
							? ServiceFactory.uiContainer.createObjectURL(attachment.file)
							: attachment.file?.uri;
			}

			if (!_.isUndefined(attachment.price) && attachment.price !== 0) {
				isChargeable = EnumBooleanStringified.TRUE;
				mediaPrice = attachment.price.toString();
				currency = attachment.currency;
			}

			const message = {
				messageId,
				messageKey,
				text,
				channelId,
				time: getDefaultMessageTime(),
				direction: EnumMessageDirection.OUT,
				msgType: EnumMessageType.MESSENGER,
				timeSeen: '0',
				isBulk: isBulk ? EnumBooleanStringified.TRUE : EnumBooleanStringified.FALSE,

				mediaState: EnumMediaState.UPLOAD,
				mediaType,
				audioMp3,
				videoFileUrl,
				videoPoster,
				imgPixelated,

				isChargeable,
				mediaPrice,
				currency,
			};

			return {
				payload: {
					message,
					attachment,
				},
			};
		},
	);

	markUploaded = this.createAction(EnumClientActions.MARK_UPLOADED, this.getPrepareAction<string>());

	setUploadError = this.createAction(
		EnumClientActions.SET_UPLOAD_ERROR,
		this.getPrepareAction<TMessageMediaUploadStatus>(),
	);

	removeMessageById = this.createAction(EnumClientActions.REMOVE_MESSAGE_BY_ID, this.getPrepareAction<string>());

	removeSentMessageKey = this.createAction(EnumClientActions.REMOVE_SENT_MESSAGE_KEY, this.getPrepareAction<string>());

	removeByIds = this.createAction(EnumClientActions.REMOVE_MANY_BY_IDS, this.getPrepareAction<EntityId[]>());

	resetStore = this.createAction(EnumClientActions.RESET_STORE);

	addIntroductionMessages = this.createAction(
		EnumClientActions.ADD_INTRO_MESSAGES,
		this.getPrepareAction<Partial<SswMessageType>[]>(),
	);

	removeIntroductionMessages = this.createAction(
		EnumClientActions.REMOVE_INTRO_MESSAGES,
		this.getPrepareAction<EntityId[]>(),
	);

	updateIntroductionMessage = this.createAction(
		EnumClientActions.UPDATE_INTRO_MESSAGE,
		({messageId, ...changes}: MessageId & SswMessageType) => ({
			payload: {
				id: messageId,
				changes: changes,
			},
		}),
	);

	setMediaUploadUrl = this.createAction(EnumClientActions.SET_MEDIA_UPLOAD_URL, this.getPrepareAction<string>());

	updateChatMessageTimeSeen = this.createAction(EnumClientActions.UPDATE_MESSAGE_TIME_SEEN, (payload: string) => {
		return {
			payload: {
				id: payload,
				changes: {
					timeSeen: `${new Date().getTime()}`,
				},
			},
		};
	});

	sendMessage = this.createAction(
		EnumClientActions.SEND_MESSAGE,
		this.getPrepareAction<{message?: string; channelId?: string}>(),
	);

	sendCurrentMessage = this.createAction(EnumClientActions.SEND_CURRENT_MESSAGE);

	navigateToMessage = this.createAction(
		EnumClientActions.NAVIGATE_TO_MESSAGE,
		this.getPrepareAction<TCommonMessageActionPayload & {objectId?: string}>(),
	);

	highlightMessage = this.createAction(
		EnumClientActions.HIGHLIGHT_MESSAGE,
		this.getPrepareAction<TCommonMessageActionPayload>(),
	);
	endScrollToMessage = this.createAction(EnumClientActions.END_SCROLL_TO_MESSAGE);
	stopHighlightingMessage = this.createAction(EnumClientActions.STOP_MESSAGE_HIGHLIGHT);
	navigateToCamera = this.createAction(
		EnumClientActions.NAVIGATE_TO_CAMERA,
		this.getPrepareAction<TNavigateToCameraPayload>(),
	);
	scrolledToMessage = this.createAction(
		EnumClientActions.SCROLLED_TO_MESSAGE,
		this.getPrepareAction<TCommonMessageActionPayload>(),
	);
	retrySaveMedia = this.createAction(EnumClientActions.RETRY_SAVE_MEDIA, this.getPrepareAction<{messageId: string}>());
	deleteMessage = this.createAction(
		EnumClientActions.DELETE_MESSAGE,
		this.getPrepareAction<TCommonMessageActionPayload>(),
	);
	deleteMessages = this.createAction(EnumClientActions.DELETE_MESSAGES, this.getPrepareAction<{messageKey: string}>());
	addMany = this.createAction(EnumClientActions.ADD_MANY, this.getPrepareAction<CMDC_CMSG['params'][]>());
	setSentChannelCount = this.createAction(EnumClientActions.SET_SENT_CHANNEL_COUNT, this.getPrepareAction<number>());
	resetIsLoading = this.createAction(EnumClientActions.RESET_IS_LOADING);
	updateMessageChannels = this.createAction(
		EnumClientActions.UPDATE_MESSAGE_CHANNELS,
		(payload: {messageId: string; channelIds: string[]}) => {
			return {
				payload: {
					id: payload.messageId,
					changes: {
						channelId: _.join(payload.channelIds, ','),
					},
				},
			};
		},
	);
	virtuosoChangeFirstItemIndex = this.createAction(
		EnumClientActions.VIRTUOSO_CHANGE_FIRST_ITEM_INDEX,
		this.getPrepareAction<string>(),
	);
	notifyModelAboutSendingReply = this.createAction(
		EnumClientActions.NOTIFY_MODEL_ABOUT_SENDING_REPLY,
		this.getPrepareAction<MessageViewModel>(),
	);
	setUnseenChatMessagesCount = this.createAction(
		EnumClientActions.SET_UNSEEN_CHAT_MESSAGES_COUNT,
		this.getPrepareAction<number>(),
	);
	markChatMessageSeen = this.createAction(EnumClientActions.MARK_CHAT_MESSAGE_SEEN, this.getPrepareAction<string>());
	requestMessagesHistory = this.createAction(
		EnumClientActions.REQUEST_MESSAGES_HISTORY,
		this.getPrepareAction<{subjectType: EnumMessageHistorySubject; subjectId: string}>(),
	);
}

type TCommonMessageActionPayload = {
	messageId: string;
	channelId?: string;
};

type TNavigateToCameraPayload = {
	attachmentId: string;
	channelId?: string;
	isBulkMessage: boolean;
};

export const messagesClientOnlyActions = new MessagesClientOnlyActions();
