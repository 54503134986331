import {takeEvery} from 'typed-redux-saga';

import {attachmentClientOnlyActions} from '@messenger/core/src/Redux/Attachment/Actions/attachmentClientOnlyActions';
import {voiceRecordingSaga} from '@messenger/core/src/Redux/Attachment/Sagas/voiceRecordingSaga';
import {processDeleteAttachmentSaga} from '@messenger/core/src/Redux/Attachment/Sagas/processDeleteAttachmentSaga';
import releaseMediaDevicePermissionsSaga from '@messenger/core/src/Redux/MediaDevice/Sagas/releaseMediaDevicePermissionsSaga';
import {checkCanReceiveAttachmentSaga} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/Sagas/checkCanReceiveAttachmentSaga';
import {updateAttachmentPriceSaga} from '@messenger/core/src/Redux/Attachment/Sagas/updateAttachmentPriceSaga';

import processAddFileToAttachmentSaga from './Sagas/processAddFileToAttachmentSaga';
import {setDefaultAudioPriceSaga} from './Sagas/setDefaultAudioPriceSaga';

function* attachmentSagaWatcher() {
	yield* takeEvery(attachmentClientOnlyActions.requestRecordingAudio, voiceRecordingSaga);
	yield* takeEvery(attachmentClientOnlyActions.addFileToAttachment, processAddFileToAttachmentSaga);
	yield* takeEvery(attachmentClientOnlyActions.attach, checkCanReceiveAttachmentSaga);
	yield* takeEvery(attachmentClientOnlyActions.updateAttachmentPrice, updateAttachmentPriceSaga);
	yield* takeEvery(attachmentClientOnlyActions.detach.type, processDeleteAttachmentSaga);
	yield* takeEvery(attachmentClientOnlyActions.stopRecordingAudio.type, releaseMediaDevicePermissionsSaga);
	yield* takeEvery(attachmentClientOnlyActions.setIsAudioPreviewCollapsed.type, setDefaultAudioPriceSaga);
}

export default attachmentSagaWatcher;
