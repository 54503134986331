import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

enum EnumClientActions {
	RESET_STORE = 'RESET_STORE',
}

class OnlineSummaryClientOnlyActions extends ClientOnlyActions<EnumStore.ONLINE_SUMMARY> {
	readonly scope = EnumStore.ONLINE_SUMMARY;

	resetStore = this.createAction(EnumClientActions.RESET_STORE);
}

export const onlineSummaryClientOnlyActions = new OnlineSummaryClientOnlyActions();
