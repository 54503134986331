import {EnumSetTp} from 'cmd-control-client-lib';
import _ from 'lodash';
import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {TSessionUpdatedAction} from '@messenger/core/src/Redux/Session/Actions';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';
import {selectStartStreamStep} from '@messenger/core/src/Redux/Stream/Selectors/selectStartStreamStep';
import {EnumStartStreamStep} from '@messenger/core/src/Redux/Stream/slice';

function* closeModalsOnSetTpChangesSaga(action: TSessionUpdatedAction) {
	try {
		const status = _.get(action.payload, 'setTp', EnumSetTp.GOING_ONLINE);

		if (status !== EnumSetTp.GOING_ONLINE) {
			const startStreamStep = yield* select(selectStartStreamStep);

			if (startStreamStep !== EnumStartStreamStep.START_STREAM_PROMPT) {
				yield* put(streamClientOnlyActions.resetStartStreamStep());
			}

			if (status === EnumSetTp.OFFLINE) {
				yield* put(streamClientOnlyActions.setIsStreamStartedFromCurrentInstance(false));
			}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'closeModalsOnSetTpChangesSaga'});
	}
}

export default closeModalsOnSetTpChangesSaga;
