import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {introductionClientOnlyActions} from '@messenger/core/src/Redux/Introduction/Action/IntroductionClientOnlyActions';
import EnumIntroductionStep from '@messenger/core/src/Redux/Introduction/EnumIntroductionStep';
import {getIntroductionStep, getIsIntroRunning} from '@messenger/core/src/Redux/Introduction/Selectors';

const manipulateUIOnUserInteractionsSaga = function* () {
	try {
		const isRunning = yield* select(getIsIntroRunning);

		if (!isRunning) {
			return;
		}

		const current = yield* select(getIntroductionStep);

		if (current === EnumIntroductionStep.USER_MENU_AREA) {
			yield* put(introductionClientOnlyActions.goToStep(EnumIntroductionStep.USER_MENU_AREA_OPENED));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'manipulateUIOnUserInteractionsSaga'});
	}
};

export default manipulateUIOnUserInteractionsSaga;
