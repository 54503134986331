import {put, select} from 'typed-redux-saga';
import _ from 'lodash';

import {channelsMapClientOnlyActions} from '@messenger/core/src/Redux/ChannelsMap/Actions/channelsMapClientOnlyActions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';
import selectRunningChatVMsByFilterIds from '@messenger/core/src/Redux/Chats/Selectors/selectRunningChatVMsByFilterIds';

export const processChatSelectionSaga = function* ({
	payload: {chatId},
}: ReturnType<typeof channelsMapClientOnlyActions.processChatSelection>) {
	try {
		if (!chatId) {
			return;
		}

		const chatIds = yield* select(selectRunningChatVMsByFilterIds);
		const chatIndex = _.indexOf(chatIds, chatId);

		if (chatIndex > -1) {
			yield* put(
				channelsMapClientOnlyActions.setSelectedIndex({
					index: chatIndex,
					type: EnumGuestType.CHAT,
				}),
			);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processChannelSelectionSaga'});
	}
};
