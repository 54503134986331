import _ from 'lodash';
import {ParametricSelector} from '@reduxjs/toolkit';

import IRootState from '@messenger/core/src/Redux/IRootState';

const getPropsMessageKey: ParametricSelector<IRootState, {messageKey?: string}, string | undefined> = (
	_state: IRootState,
	props?: {messageKey?: string},
) => (_.isUndefined(props) ? undefined : props.messageKey);

export default getPropsMessageKey;
