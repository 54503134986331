import {ACTION, CMDP_START_STREAM_PREVIEW_RESPONSE} from 'cmd-control-client-lib';

import {ServerToClientActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class PreviewServerToClientActions extends ServerToClientActions<EnumStore.STREAM_PREVIEW> {
	readonly scope = EnumStore.STREAM_PREVIEW;

	previewStarted = this.createAction(
		ACTION.CMDP_START_STREAM_PREVIEW,
		this.getPrepareAction<CMDP_START_STREAM_PREVIEW_RESPONSE['values'], CMDP_START_STREAM_PREVIEW_RESPONSE, false>(),
	);
}

export const previewServerToClientActions = new PreviewServerToClientActions();
