import {EnumCurrency, EnumMediaType} from 'cmd-control-client-lib';

import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import IAttachment from '@messenger/core/src/Redux/Attachment/Model';
import ILocalFile from '@messenger/core/src/Redux/Media/ILocalFile';
import {EnumMediaSource, TDeviceStorageMediaSource} from '@messenger/core/src/BusinessLogic/Media/EnumMediaSource';

enum EnumActions {
	ADD_FILE_TO_ATTACHMENT = 'ADD_FILE_TO_ATTACHMENT',
	ATTACH = 'ATTACH',
	UPDATE = 'UPDATE',
	DETACH = 'DETACH',
	RESET_STORE = 'RESET_STORE',
	REQUEST_RECORDING = 'REQUEST_RECORDING',
	START_RECORDING = 'START_RECORDING',
	STOP_RECORDING = 'STOP_RECORDING',
	IMAGE_GALLERY_SHOW = 'IMAGE_GALLERY_SHOW',
	AUDIO_RECORDING_FAILED = 'AUDIO_RECORDING_FAILED',
	CONTENT_CATALOG_SHOW = 'CONTENT_CATALOG_SHOW',
	UPDATE_ATTACHMENT_PRICE = 'UPDATE_ATTACHMENT_PRICE',
	SET_IS_HOLDING_RECORD = 'SET_IS_HOLDING_RECORD',
	SHOW_SELECT_MEDIA_ERROR = 'SHOW_SELECT_MEDIA_ERROR',
	SET_IS_AUDIO_PREVIEW_COLLAPSED = 'SET_IS_AUDIO_PREVIEW_COLLAPSED',
}

class AttachmentClientOnlyActions extends ClientOnlyActions<EnumStore.ATTACHMENT> {
	readonly scope = EnumStore.ATTACHMENT;

	requestRecordingAudio = this.createAction(EnumActions.REQUEST_RECORDING);

	setIsHoldingRecord = this.createAction(
		EnumActions.SET_IS_HOLDING_RECORD,
		this.getPrepareAction<{isHoldingRecord: boolean}>(),
	);

	setIsAudioPreviewCollapsed = this.createAction(
		EnumActions.SET_IS_AUDIO_PREVIEW_COLLAPSED,
		this.getPrepareAction<{isAudioPreviewCollapsed: boolean}>(),
	);

	startRecordingAudio = this.createAction(EnumActions.START_RECORDING, this.getPrepareAction<{attachmentId: string}>());

	stopRecordingAudio = this.createAction(
		EnumActions.STOP_RECORDING,
		this.getPrepareAction<{recordedSuccessfully: boolean; autoSend?: boolean} | undefined>(),
	);

	audioRecordingFailed = this.createAction(EnumActions.AUDIO_RECORDING_FAILED);

	addFileToAttachment = this.createAction(
		EnumActions.ADD_FILE_TO_ATTACHMENT,
		this.getPrepareAction<
			IAttachment & {
				mediaSource?: EnumMediaSource;
				isBulkMessage?: boolean;
				mediaType?: EnumMediaType;
				autoSend?: boolean;
				isFromMediaPreview?: boolean;
				channelId?: string;
			}
		>(),
	);

	attach = this.createAction(
		EnumActions.ATTACH,
		this.getPrepareAction<{
			attachmentId: string;
			mediaMd5?: string;
			isTicketShow?: boolean;
			duration?: number;
			file?: File | ILocalFile;
			price?: number;
			currency?: EnumCurrency;
		}>(),
	);

	update = this.createAction(
		EnumActions.UPDATE,
		({attachmentId, ...changes}: Pick<IAttachment, 'attachmentId'> & Partial<IAttachment>) => ({
			payload: {
				id: attachmentId,
				changes,
			},
		}),
	);

	detach = this.createAction(EnumActions.DETACH, ({attachmentId}: {attachmentId: string}) => ({payload: attachmentId}));

	resetStore = this.createAction(EnumActions.RESET_STORE);

	selectMediaFromStorage = this.createAction(
		EnumActions.IMAGE_GALLERY_SHOW,
		this.getPrepareAction<{
			isBulkMessage?: boolean;
			channelId?: string;
			attachmentId: string;
			isFromMediaPreview?: boolean;
			mediaSource: TDeviceStorageMediaSource;
		}>(),
	);

	updateAttachmentPrice = this.createAction(
		EnumActions.UPDATE_ATTACHMENT_PRICE,
		this.getPrepareAction<{attachmentId: string; price: number}>(),
	);

	showSelectMediaErrorNotification = this.createAction(
		EnumActions.SHOW_SELECT_MEDIA_ERROR,
		this.getPrepareAction<{isBulkMessage?: boolean}>(),
	);
}

export const attachmentClientOnlyActions = new AttachmentClientOnlyActions();
