import {takeEvery} from 'typed-redux-saga';

import {chatsClientOnlyActions, chatsServerToClientActions} from '@messenger/core/src/Redux/Chats/Actions';

import processChatsReceivedSaga from 'src/Redux/Chat/Sagas/processChatsReceivedSaga';
import {processGroupChatToggleSaga} from 'src/Redux/Chat/Sagas/processGroupChatToggleSaga';
import {navigateToAllChatsOnFirstGuestEnterSaga} from 'src/Redux/Chat/Sagas/navigateToAllChatsOnFirstGuestEnterSaga';
import {navigateToAllChatsOnFirstAdminEnterSaga} from 'src/Redux/Chat/Sagas/navigateToAllChatsOnFirstAdminEnterSaga';

function* chatSagaWatcher() {
	yield* takeEvery(chatsServerToClientActions.get, processChatsReceivedSaga);
	yield* takeEvery(chatsClientOnlyActions.setIsGroupChatExpanded, processGroupChatToggleSaga);
	yield* takeEvery(chatsClientOnlyActions.upsertMany, navigateToAllChatsOnFirstGuestEnterSaga);
	yield* takeEvery(chatsServerToClientActions.showAdminChat, navigateToAllChatsOnFirstAdminEnterSaga);
}

export default chatSagaWatcher;
