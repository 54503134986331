import {call, put} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {streamClientToServerActions} from '@messenger/core/src/Redux/Stream/Actions';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';

import streamingGoOfflineSaga from 'src/Redux/Stream/Sagas/streamingGoOfflineSaga';

const processGoOfflineSaga = function* ({
	payload: isStreamError,
}: ReturnType<typeof streamClientOnlyActions.goOffline>) {
	try {
		yield* put(streamClientOnlyActions.resetStartStreamStep());
		yield* put(streamClientToServerActions.stopSend(isStreamError));
		yield* call(streamingGoOfflineSaga);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processGoOnlineSaga'});
	}
};

export default processGoOfflineSaga;
