import {EnumChannelFilterValues, channelIdFilter, getChannelsParamsType} from 'cmd-control-client-lib';

import ChannelVM from '@messenger/core/src/Redux/Channels/ChannelVM';

export default class ChatsFilterMatcher {
	private readonly filter: getChannelsParamsType & channelIdFilter;

	constructor(filter: getChannelsParamsType & channelIdFilter) {
		this.filter = filter;
	}

	isMatches(channelVM: ChannelVM, isViewingAllChannels: boolean) {
		const isArchived = channelVM.isArchived;
		const isRegular = channelVM.isRegular;
		const isAdvertised = channelVM.isAdvertised;
		const isMuted = channelVM.isMuted;
		const isVIP = channelVM.isVIP;
		const isBanned = channelVM.isBanned;

		return (
			(!isViewingAllChannels && this.filter.filterArchived === EnumChannelFilterValues.EXCLUDE && isArchived) ||
			(!isViewingAllChannels && this.filter.filterArchived === EnumChannelFilterValues.ONLY && !isArchived) ||
			(this.filter.filterRegularCustomer === EnumChannelFilterValues.EXCLUDE && isRegular) ||
			(this.filter.filterRegularCustomer === EnumChannelFilterValues.ONLY && !isRegular) ||
			(this.filter.filterVIP === EnumChannelFilterValues.EXCLUDE && isVIP) ||
			(this.filter.filterVIP === EnumChannelFilterValues.ONLY && !isVIP) ||
			(this.filter.filterMuted === EnumChannelFilterValues.EXCLUDE && isMuted) ||
			(this.filter.filterMuted === EnumChannelFilterValues.ONLY && !isMuted) ||
			(this.filter.filterAdvertised === EnumChannelFilterValues.EXCLUDE && isAdvertised) ||
			(this.filter.filterAdvertised === EnumChannelFilterValues.ONLY && !isAdvertised) ||
			(this.filter.filterUnseen === EnumChannelFilterValues.EXCLUDE && channelVM.unseenCount > 0) ||
			(this.filter.filterUnseen === EnumChannelFilterValues.ONLY && channelVM.unseenCount === 0) ||
			(!isViewingAllChannels && this.filter.filterBanned === EnumChannelFilterValues.EXCLUDE && isBanned) ||
			(!isViewingAllChannels && this.filter.filterBanned === EnumChannelFilterValues.ONLY && !isBanned)
		);
	}
}
