import {chain} from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectChatVms} from '@messenger/core/src/Redux/Chats/Selectors/defaultSelectors';

const selectAllGuestChatIds = createSelector([selectChatVms], (chatVms) =>
	chain(chatVms)
		.filter((chatVm) => !chatVm.isAllChatsAggregate && !chatVm.isVoyeur)
		.map('chatId')
		.value(),
);

export default selectAllGuestChatIds;
