import {PayloadAction} from '@reduxjs/toolkit';
import {RESULT, ResultCode} from 'cmd-control-client-lib';
import {call, put, select, take} from 'typed-redux-saga';

import {removeStoredCredentialsSaga} from '@messenger/core/src/Redux/Session/Sagas/removeStoredCredentialsSaga';
import {loginFormClientOnlyActions} from '@messenger/core/src/Redux/LoginForm/Actions/loginFormClientOnlyActions';
import selectLoginFormState from '@messenger/core/src/Redux/LoginForm/Selectors/selectLoginFormState';
import {preventNextScreenAutoLogin} from '@messenger/core/src/Redux/Session/Sagas/preventNextScreenAutoLogin';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {notifyIfPinnedLimitReachedSaga} from '@messenger/core/src/Redux/Channels/Sagas/notifyIfPinnedLimitReachedSaga';
import {channelsServerToClientActions} from '@messenger/core/src/Redux/Channels/Actions';
import {groupsClientToServerActions} from '@messenger/core/src/Redux/Groups/Actions/groupsClientToServerActions';
import {selectSessionUsrKey} from '@messenger/core/src/Redux/Session/Selectors/selectSessionUsrKey';
import {selectSessionUsrId} from '@messenger/core/src/Redux/Session/Selectors/selectSessionUsrId';
import selectSessionId from '@messenger/core/src/Redux/Session/Selectors/selectSessionId';
import selectShouldAutoLogin from '@messenger/core/src/Redux/LoginForm/Selectors/selectShouldAutoLogin';

/**
 * @param {Object} action
 * @return {Generator|void}
 */
const processLoginResult = function* (action: PayloadAction<RESULT>) {
	try {
		const loginForm = yield* select(selectLoginFormState);
		const {code} = action.payload;

		if (!code) {
			return;
		}

		if (code === ResultCode.OK) {
			// let thought & enable auto-login
			yield* put(loginFormClientOnlyActions.resetError());
			const isAutoLogin = yield* select(selectShouldAutoLogin);

			if (!isAutoLogin) {
				yield* put(loginFormClientOnlyActions.submitSuccess());
			}

			// reset stored credentials if remember me is not ticked
			if (loginForm.rememberMe) {
				yield* put(loginFormClientOnlyActions.enableAutoLogin());
			} else {
				yield* call(preventNextScreenAutoLogin);
			}

			yield* take(channelsServerToClientActions.channelSummaryReceived);
			yield* call(notifyIfPinnedLimitReachedSaga);
			yield* put(groupsClientToServerActions.getGroups());

			const userKey = yield* select(selectSessionUsrKey);
			const userId = yield* select(selectSessionUsrId);
			const sessionId = yield* select(selectSessionId);

			ServiceFactory.logService.setModelInfo({login: loginForm.login, sessionId, userKey, userId});

			return;
		}

		if (code === ResultCode.WRONG_USER_NAME_OR_PASSWORD) {
			yield* call(removeStoredCredentialsSaga);
			yield* put(loginFormClientOnlyActions.setError(action.payload.reason));
		}

		// show error
		yield* put(loginFormClientOnlyActions.setError(action.payload.reason));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processLoginResult'});
	}
};

export default processLoginResult;
