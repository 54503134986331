import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

enum EnumClientActions {
	OPEN = 'OPEN',
	CLOSE = 'CLOSE',
}

class TestVideoChatClientOnlyActions extends ClientOnlyActions<EnumStore.TEST_VIDEO_CHAT> {
	readonly scope = EnumStore.TEST_VIDEO_CHAT;

	open = this.createAction(EnumClientActions.OPEN);

	close = this.createAction(EnumClientActions.CLOSE);
}

const testVideoChatClientOnlyActions = new TestVideoChatClientOnlyActions();

export default testVideoChatClientOnlyActions;
