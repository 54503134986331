import 'reflect-metadata';
import {container, inject, singleton} from 'tsyringe';
import i18next from 'i18next';
import _ from 'lodash';
import {
	CmdClientAgent,
	CmdControlSession,
	ConnectionConfig,
	EnumInitDataFormat,
	ICOMMAND,
	IRESPONSE,
	ReconnectionError,
	ResultCode,
	SupportedLanguage,
	UploadMediaTus,
} from 'cmd-control-client-lib';
import type {Dispatch} from '@reduxjs/toolkit';

import EnvInterfaceService from '@messenger/core/src/Services/EnvInterfaceService';
import AbstractVControlApi, {TSendMediaParams} from '@messenger/core/src/Services/VControl/AbstractVControlApi';
import type ILogService from '@messenger/core/src/Services/ILogService';
import DIToken from '@messenger/core/src/BusinessLogic/DIToken';
import AbstractUINotificationService from '@messenger/core/src/Services/AbstractUINotificationService';
import ILocalFile from '@messenger/core/src/Redux/Media/ILocalFile';
import createActionsFromResponse from '@messenger/core/src/Actions/createActionsFromResponse';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';
import EnumSnackbarNotificationKeys from '@messenger/core/src/BusinessLogic/EnumSnackbarNotificationKeys';
import AbstractUiContainer from '@messenger/core/src/Services/AbstractUiContainer';

@singleton()
class VControlApi extends AbstractVControlApi {
	private readonly STALE_CONNECTION_TIMEOUT = 20000;
	private staleConnectionTimeoutId?: ReturnType<typeof setTimeout>;
	private isConnectionStale = false;
	private session?: CmdControlSession;
	private readonly config: ConnectionConfig;
	private readonly globalErrorCodes = [
		ResultCode.NETWORK_ERROR,
		ResultCode.NO_CONNECTION,
		ResultCode.SCRIPT_ERROR,
		ResultCode.SESSION_ERROR,
		ResultCode.SESSION_NOT_FOUND,
		ResultCode.TIMEOUT,
	];

	constructor(
		@inject(DIToken.reduxDispatch) dispatch: Dispatch,
		@inject(DIToken.currentLanguage) language: SupportedLanguage,
		@inject(DIToken.I18n) protected i18n: typeof i18next,
		@inject(DIToken.EnvInterfaceService) private env: EnvInterfaceService,
		@inject(DIToken.LogService) protected logService: ILogService,
		@inject(DIToken.UINotificationService) private notifications: AbstractUINotificationService,
		@inject(DIToken.UiContainer) protected uiContainer: AbstractUiContainer,
	) {
		super(dispatch);
		// build config
		this.config = new ConnectionConfig();
		this.config.agent = CmdClientAgent.WEB;
		this.config.useWS = true;
		this.config.version = this.env.getVersion();
		this.config.language = language;
		this.config.initdata = EnumInitDataFormat.LIVE2;
		this.config.commandHandler = this.dispatchResponse.bind(this);
		this.config.connectionRetryInterval = 15000;
		this.config.firstConnectionRetryInterval = 3000;
		this.config.onError = this.onSocketError.bind(this);
		this.config.onOpen = this.onSocketOpen.bind(this);

		this.config.https = this.env.getWsHttpsEnabled();
		this.config.host = this.env.getWsHost();
		this.config.wssport = this.env.getWsPort();
		this.config.wsspath = this.env.getWsPath();

		if (this.env.isVControlDebugEnabled()) {
			this.config.logger = this.logService;
		}

		this.connect = this.connect.bind(this);
		this.resume = this.resume.bind(this);
		this.sendMedia = this.sendMedia.bind(this);
	}

	setDeviceId(deviceId: string) {
		this.config.deviceId = deviceId;
	}

	async connect() {
		this.session = new CmdControlSession(this.config);
		this.logService.debug('VControl connected');

		return this;
	}

	resume() {
		this.notifications.close(EnumSnackbarNotificationKeys.SOCKET_ERROR_NOTIFICATION_KEY);
		this.isConnectionStale = false;

		if (!this.session?.resume()) {
			this.closeConnection();
			this.connect();
		}

		this.logService.debug('VControl resumed connection');
	}

	pause(isResumable = true) {
		clearTimeout(this.staleConnectionTimeoutId);
		this.session?.pause(isResumable);
		this.logService.debug('VControl reconnection paused');
	}

	isConnected(): boolean {
		return !!this.session;
	}

	onSocketOpen() {
		this.dispatch(clientClientOnlyActions.updateBackendConnectionStatus({isConnected: true}));
	}

	onSocketError(error: ReconnectionError) {
		this.logService.debug('VControl socket error', {event: error});

		if (_.get(error, 'isFatal', false)) {
			this.dispatch(clientClientOnlyActions.updateBackendConnectionStatus({isConnected: false}));
		} else {
			const reConnectionTimeout = _.get(error, 'reConnectionTimeout');

			this.dispatch(
				clientClientOnlyActions.updateBackendConnectionStatus({
					isConnected: false,
					reConnectionTimeout: reConnectionTimeout,
				}),
			);
		}
	}

	dispatchResponse(response: IRESPONSE): void {
		const {
			result: {code, reason},
		} = response;

		this.checkNetworkStatus(response);

		if (this.globalErrorCodes.includes(code)) {
			this.dispatch(clientClientOnlyActions.globalError({code, reason}));
		} else {
			createActionsFromResponse(response).forEach((action) => {
				this.dispatch(action);
			});
		}
	}

	private checkNetworkStatus(response: IRESPONSE): void {
		clearTimeout(this.staleConnectionTimeoutId);

		if (!_.get(response, 'params.sessionID')) {
			return;
		}

		if (this.isConnectionStale) {
			this.isConnectionStale = false;

			this.dispatch(clientClientOnlyActions.updateNetworkStatus({isOnline: true, isResumable: false}));
		} else {
			this.staleConnectionTimeoutId = setTimeout(() => {
				this.isConnectionStale = true;
				this.dispatch(clientClientOnlyActions.updateNetworkStatus({isOnline: false, isResumable: false}));
			}, this.STALE_CONNECTION_TIMEOUT);
		}
	}

	send(command: ICOMMAND) {
		if (this.session === undefined) {
			this.connect();
		}

		if (!_.isEmpty(this.appendData?.toString())) {
			command.params = {
				...command.params,
			};
		}

		!!this.session && this.session.sendCommand(command);

		return this;
	}

	get language(): SupportedLanguage {
		return this.config.language;
	}

	changeLanguage(language: SupportedLanguage) {
		if (this.config.language !== language) {
			// set new in config
			this.config.language = language;
		}
	}

	closeConnection() {
		if (this.session) {
			this.session.close();
			this.logService.debug('VControl connection closed');
		}
	}

	sendMedia(uploadUrl: string, file: File | ILocalFile, params: TSendMediaParams, tusConfig?: UploadMediaTus): void {
		new Error('Not implemented here.');
	}

	submitFeedbackForm(uploadFeedbackUrl: string, formData: FormData): Promise<{success?: boolean; error?: unknown}> {
		return fetch(uploadFeedbackUrl, {
			method: 'POST',
			body: formData,
		})
			.then((response) => response.text())
			.then((text) => ({success: text.includes(`${ResultCode.OK}`)}))
			.catch((error) => ({error}));
	}
}

container.register(DIToken.VControlApi, {useToken: VControlApi});

export default VControlApi;
