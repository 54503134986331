import _ from 'lodash';
import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectMediaPricesVmForTargetChannelIds} from '@messenger/core/src/Redux/MediaPrice/Selectors/selectMediaPricesVmForTargetChannelIds';
import getMediaType from '@messenger/core/src/Utils/Media/getMediaType';
import {attachmentClientOnlyActions} from '@messenger/core/src/Redux/Attachment/Actions/attachmentClientOnlyActions';
import {selectCurrentAttachmentVm} from '@messenger/core/src/Redux/Attachment/Selectors/selectCurrentAttachmentVm';

export function* setDefaultAudioPriceSaga({
	payload: {isAudioPreviewCollapsed},
}: ReturnType<typeof attachmentClientOnlyActions.setIsAudioPreviewCollapsed>) {
	try {
		const attachmentVM = yield* select(selectCurrentAttachmentVm);

		if (isAudioPreviewCollapsed || _.isUndefined(attachmentVM)) {
			return;
		}

		const mediaType = getMediaType(attachmentVM.file);

		if (!_.isUndefined(mediaType) && !attachmentVM.price) {
			const prices = yield* select(selectMediaPricesVmForTargetChannelIds);
			const defaultPrice = prices?.getDefault(mediaType);

			if (!_.isUndefined(defaultPrice)) {
				yield* put(
					attachmentClientOnlyActions.updateAttachmentPrice({
						price: defaultPrice,
						attachmentId: attachmentVM.attachmentId,
					}),
				);
			}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'setDefaultAudioPriceSaga'});
	}
}
