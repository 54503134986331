import {createSlice} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

import {messageInputClientOnlyActions} from './Actions/messageInputClientOnlyActions';
import {messageInputEntityAdapter} from './messageInputEntityAdapter';

export const messageInputSlice = createSlice({
	name: EnumStore.MESSAGE_INPUT,
	initialState: messageInputEntityAdapter.getInitialState(),
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(messageInputClientOnlyActions.upsertOne, messageInputEntityAdapter.upsertOne);
		builder.addCase(messageInputClientOnlyActions.removeOne, messageInputEntityAdapter.removeOne);
	},
});
