import {put, select} from 'typed-redux-saga';

import selectIsUserInfoOpen from '@messenger/core/src/Redux/Client/Selectors/selectIsUserInfoOpen';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';
import selectUserInfoChannelId from '@messenger/core/src/Redux/Client/Selectors/selectUserInfoChannelId';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {selectCurrentGuestHasUserInfo} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestHasUserInfo';

export const toggleUserInfoAreaSaga = function* () {
	const hasUserInfo = yield* select(selectCurrentGuestHasUserInfo);
	const guestIdentity = yield* select(selectCurrentGuestIdentity);

	if (!hasUserInfo || !guestIdentity?.channelId) {
		return;
	}

	const {channelId} = guestIdentity;
	const isUserInfoOpen = yield* select(selectIsUserInfoOpen);
	const userInfoChannelId = yield* select(selectUserInfoChannelId);

	if (isUserInfoOpen && userInfoChannelId === channelId) {
		yield* put(clientClientOnlyActions.closeUserInfoArea());
	} else {
		yield* put(clientClientOnlyActions.openUserInfoArea({channelId}));
	}
};
