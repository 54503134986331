import {
	ACTION,
	CMDC_SSTATUS,
	CMDC_STREAMSTATE,
	CMDP_SQUERYSTARTSINGLEC2C_RESPONSE,
	CMDP_STARTSEND_RESPONSE,
	CMDP_STOPSEND_RESPONSE,
	CMDP_SGETSTREAMPARAMS_RESPONSE,
	ResultCode,
} from 'cmd-control-client-lib';
import _ from 'lodash';

import {ServerToClientActions, TSingleEntityActionMeta} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {TStreamConfig, TStreamConnectOptions} from '@messenger/core/src/Redux/Stream/Model';
import {defaultStreamConnectOptions} from '@messenger/core/src/Redux/Stream/defaultStreamConnectOptions';

/**
 * @WARNING
 * After adding new Server Action add it to "serverToClientActionsMap" object
 * @see serverToClientActionsMap
 */
class StreamServerToClientActions extends ServerToClientActions<EnumStore.STREAM> {
	readonly scope = EnumStore.STREAM;

	configReceived = this.createAction(
		ACTION.CMDC_CSTARTSTREAMPUSH,
		prepareWebRtcConfigAction<TSingleEntityActionMeta<unknown>>(),
	);
	configUpdated = this.createAction(
		ACTION.CMDP_SGETSTREAMPARAMS,
		prepareWebRtcConfigAction<CMDP_SGETSTREAMPARAMS_RESPONSE>(),
	);

	streamRestartReceived = this.createAction(
		ACTION.CMDC_CSTREAMRESTART,
		prepareWebRtcConfigAction<TSingleEntityActionMeta<unknown>>(),
	);

	stateChanged = this.createAction(ACTION.CMDC_STREAMSTATE, (payload: CMDC_STREAMSTATE['params']) => ({
		payload: {
			..._.omit(payload, ['sessionID', 'time', 'commandID']),
		},
	}));

	startPrivateShowResponseReceived = this.createAction(
		ACTION.CMDP_SQUERYSTARTSINGLEC2C,
		(payload: CMDP_SQUERYSTARTSINGLEC2C_RESPONSE['values'], meta: CMDP_SQUERYSTARTSINGLEC2C_RESPONSE) => ({
			payload,
			error: meta.result.code !== ResultCode.OK ? meta.result.reason : false,
		}),
	);
	stopSendResponseReceived = this.createAction(
		ACTION.CMDP_STOPSEND,
		this.getPrepareAction<CMDP_STOPSEND_RESPONSE['values'], CMDP_STOPSEND_RESPONSE, false>(),
	);
	startSendResponseReceived = this.createAction(
		ACTION.CMDP_STARTSEND,
		this.getPrepareAction<CMDP_STARTSEND_RESPONSE['values'], CMDP_STARTSEND_RESPONSE, false>(),
	);
	stopStreamReceived = this.createAction(ACTION.CMDC_CSTOPSTREAMPUSH, this.getPrepareAction<unknown, object, false>());
	statusReceived = this.createAction(ACTION.CMDC_SSTATUS, this.getPrepareAction<CMDC_SSTATUS['params']>());
}

const prepareWebRtcConfigAction =
	<M>() =>
	(payload: TStreamConfig, meta: M, error?: boolean | string) => {
		const options: TStreamConnectOptions = {
			rtmpURL: _.get(payload, 'rtmpURL', defaultStreamConnectOptions.rtmpURL),
			streamName: _.get(payload, 'streamName', defaultStreamConnectOptions.streamName),
			powerEstimation: _.get(payload, 'powerEstimation', defaultStreamConnectOptions.powerEstimation),
		};

		const webRTCOptions = _.attempt(JSON.parse, payload.webRtc);

		if (!_.isError(webRTCOptions)) {
			options.webRTCConfig = webRTCOptions;
		}

		return {
			payload: options,
			meta,
			error: !_.isUndefined(payload.webRtc) && _.isError(webRTCOptions) ? webRTCOptions : error,
		};
	};

export const streamServerToClientActions = new StreamServerToClientActions();
