import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import EnumPreviewType from '@messenger/core/src/BusinessLogic/EnumPreviewType';

enum EnumClientOnlyActions {
	RESET = 'RESET',
	CHANGE_PREVIEW_TYPE = 'CHANGE_PREVIEW_TYPE',
}

/**
 * @NOTE Most of the actions are in src/Actions/Client/* files
 */
class PreviewClientOnlyActions extends ClientOnlyActions<EnumStore.STREAM_PREVIEW> {
	readonly scope = EnumStore.STREAM_PREVIEW;

	resetStore = this.createAction(EnumClientOnlyActions.RESET);

	changePreviewType = this.createAction(
		EnumClientOnlyActions.CHANGE_PREVIEW_TYPE,
		this.getPrepareAction<EnumPreviewType>(),
	);
}

export const previewClientOnlyActions = new PreviewClientOnlyActions();
