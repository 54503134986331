import {channelIdFilter, getChannelsParamsType} from 'cmd-control-client-lib';

import selectChannelsState from '@messenger/core/src/Redux/Channels/Selectors/selectChannelsState';
import {createSelector} from '@messenger/core/src/Utils/Redux';

const selectChannelsFilter = createSelector(
	[selectChannelsState],
	(channelsState): getChannelsParamsType & channelIdFilter => channelsState.filter,
);

export default selectChannelsFilter;
