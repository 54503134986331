import {put, select} from 'typed-redux-saga';

import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectMessageMediaUploads} from '@messenger/core/src/Redux/Messages/Selectors/selectMessageMediaUploads';
import {
	getMessageIdForSelector,
	selectMessageVMById,
} from '@messenger/core/src/Redux/Messages/Selectors/defaultSelectors';

export default function* retrySaveMediaSaga({
	payload: {messageId},
}: ReturnType<typeof messagesClientOnlyActions.retrySaveMedia>) {
	try {
		const messageVm = yield* select(selectMessageVMById, getMessageIdForSelector(messageId));
		const {[messageId]: mediaUpload} = yield* select(selectMessageMediaUploads);

		if (!messageVm?.channelId || !mediaUpload?.attachment) {
			return;
		}

		yield* put(
			messagesClientOnlyActions.sendMessageWithMediaUpload({
				messageId: messageVm.messageId,
				text: messageVm.text,
				channelId: messageVm.channelId,
				messageKey: messageVm.messageKey,
				isBulk: messageVm.getIsBulk(),
				attachment: mediaUpload.attachment,
			}),
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'retrySaveMediaSaga'});
	}
}
