import {put, call} from 'typed-redux-saga';

import {removeStoredCredentialsSaga} from '@messenger/core/src/Redux/Session/Sagas/removeStoredCredentialsSaga';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';
import EnumSnackbarNotificationKeys from '@messenger/core/src/BusinessLogic/EnumSnackbarNotificationKeys';
import {loginFormClientOnlyActions} from '@messenger/core/src/Redux/LoginForm/Actions/loginFormClientOnlyActions';
import {cleanUpStoresSaga} from '@messenger/core/src/Redux/Session/Sagas/cleanUpStoresSaga';
import notificationsClientOnlyActions from '@messenger/core/src/Redux/Notifications/Actions/notificationsClientOnlyActions';
import {sessionServerToClientActions} from '@messenger/core/src/Redux/Session/Actions';

export const processRemoteSessionTermination = function* (
	action: ReturnType<typeof sessionServerToClientActions.sessionRemotelyTerminated>,
) {
	try {
		const {t} = ServiceFactory.i18n;
		let shortReason;
		let fullReason;

		switch (action.payload.exitCode) {
			case '301':
				shortReason = t('login:logout_reason.301');

				if (action.payload.exitMessage) {
					fullReason = t('login:logout_reason.custom', {message: action.payload.exitMessage});
				}

				break;

			default:
				shortReason = action.payload.exitMessage || action.payload.exitCode;

				break;
		}

		yield* put(notificationsClientOnlyActions.closeNonErrorNotifications());
		yield* call(removeStoredCredentialsSaga);
		yield* put(loginFormClientOnlyActions.disableAutoLogin());
		yield* call(cleanUpStoresSaga);
		yield* put(loginFormClientOnlyActions.setError(shortReason));
		yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueuePersistent], {
			text: fullReason || shortReason,
			variant: EnumAbstractNotificationVariant.ERROR,
			key: EnumSnackbarNotificationKeys.KICKED_BY_ADMIN,
		});
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processInitResultsSaga'});
	}
};
