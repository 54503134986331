import React from 'react';
import _ from 'lodash';
import {put} from '@redux-saga/core/effects';
import cuid from 'cuid';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import notificationsClientOnlyActions from '@messenger/core/src/Redux/Notifications/Actions/notificationsClientOnlyActions';
import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';
import {channelMediaServerToClientActions} from '@messenger/core/src/Redux/ChannelMedia/Actions';

import MediaPurchasedContentContainer from 'src/Components/Notifications/MediaPurchased/MediaPurchasedContentContainer';

const showMediaPurchasedNotificationSaga = function* ({
	meta,
}: ReturnType<typeof channelMediaServerToClientActions.mediaPurchased>) {
	try {
		const notificationKey = cuid();

		const channelId = _.get(meta, 'params.channelId', '');
		const messageId = _.get(meta, 'params.relationId', '');
		const text = _.get(meta, 'params.text', '');

		yield put(
			notificationsClientOnlyActions.enqueuePersistent(
				'', // @note this message will not be used
				EnumAbstractNotificationVariant.INFO,
				{
					key: notificationKey,
					content: (
						<MediaPurchasedContentContainer
							message={text}
							messageId={messageId}
							channelId={channelId}
							notificationKey={notificationKey}
						/>
					),
				},
			),
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'showMediaPurchasedNotificationSaga', meta});
	}
};

export default showMediaPurchasedNotificationSaga;
