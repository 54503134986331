import {ACTION, MSettingNameEnum, LanguageText, RequestStateEnum} from 'cmd-control-client-lib';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

enum EnumActionAliasesActions {
	SET_VIDEO_CHAT_PRICE = 'SET_VIDEO_CHAT_PRICE',
	SET_HEATMAP_ENABLED = 'SET_HEATMAP_ENABLED',
	SET_HEATMAP_DESCRIPTION = 'SET_HEATMAP_DESCRIPTION',
	SET_VOYEUR_CHAT_ENABLED = 'SET_VOYEUR_CHAT_ENABLED',
	SET_VOYEUR_CHAT_PRIVATE_AUDIO_ENABLED = 'SET_VOYEUR_CHAT_PRIVATE_AUDIO_ENABLED',
	REQUEST_TOY_ACCESS = 'REQUEST_TOY_ACCESS',
	REQUEST_TICKET_SHOW_ACCESS = 'REQUEST_TICKET_SHOW_ACCESS',
}

class ModelSettingsClientToServerActions extends ClientToServerActions<EnumStore.MODEL_SETTINGS> {
	readonly scope = EnumStore.MODEL_SETTINGS;

	getModelSettings = this.createAction(ACTION.CMDP_SGETMSETTINGS);

	setVideoChatPrice = this.createActionWithAlias(
		ACTION.CMDP_SSETMSETTING,
		EnumActionAliasesActions.SET_VIDEO_CHAT_PRICE,
		(name: MSettingNameEnum, value: number) => ({
			payload: {
				settingName: name,
				settingValue: value,
			},
		}),
	);

	setHeatmapEnabled = this.createActionWithAlias(
		ACTION.CMDP_SSETMSETTING,
		EnumActionAliasesActions.SET_HEATMAP_ENABLED,
		(value: boolean) => ({
			payload: {
				settingName: MSettingNameEnum.HEATMAP_ENABLED,
				settingValue: value,
			},
		}),
	);

	setHeatmapDescription = this.createActionWithAlias(
		ACTION.CMDP_SSETMSETTING,
		EnumActionAliasesActions.SET_HEATMAP_DESCRIPTION,
		(value: LanguageText[]) => ({
			payload: {
				settingName: MSettingNameEnum.HEATMAP_DESCRIPTION,
				settingValue: JSON.stringify(value),
			},
		}),
	);

	setVoyeurChatEnabled = this.createActionWithAlias(
		ACTION.CMDP_SSETMSETTING,
		EnumActionAliasesActions.SET_VOYEUR_CHAT_ENABLED,
		(value: boolean) => ({
			payload: {
				settingName: MSettingNameEnum.VOYEUR_CHAT_ENABLED,
				settingValue: value,
			},
		}),
	);

	setVoyeurChatPrivateAudioEnabled = this.createActionWithAlias(
		ACTION.CMDP_SSETMSETTING,
		EnumActionAliasesActions.SET_VOYEUR_CHAT_PRIVATE_AUDIO_ENABLED,
		(value: boolean) => ({
			payload: {
				settingName: MSettingNameEnum.VOYEUR_CHAT_PRIVATE_AUDIO,
				settingValue: value,
			},
		}),
	);
	requestLovenseToyAccess = this.createActionWithAlias(
		ACTION.CMDP_SSETMSETTING,
		EnumActionAliasesActions.REQUEST_TOY_ACCESS,
		() => ({
			payload: {
				settingName: MSettingNameEnum.LOVENSE_TOY_REQUEST,
				settingValue: RequestStateEnum.REQUESTED,
			},
		}),
	);

	requestTicketShowAccess = this.createActionWithAlias(
		ACTION.CMDP_SSETMSETTING,
		EnumActionAliasesActions.REQUEST_TICKET_SHOW_ACCESS,
		() => ({
			payload: {
				settingName: MSettingNameEnum.TICKET_SHOW_REQUEST,
				settingValue: RequestStateEnum.REQUESTED,
			},
		}),
	);
}

export const modelSettingsClientToServerActions = new ModelSettingsClientToServerActions();
