import {ACTION, CMDP_SGETTICKETS_RESPONSE, CMDP_SGETUPLOADEDMEDIA_RESPONSE} from 'cmd-control-client-lib';

import {ServerToClientActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';

class MediaServerToClientActions extends ServerToClientActions<EnumStore.MEDIA> {
	readonly scope = EnumStore.MEDIA;

	ticketsReceived = this.createAction(
		ACTION.CMDP_SGETTICKETS,
		(payload: CMDP_SGETTICKETS_RESPONSE['values'], meta: CMDP_SGETTICKETS_RESPONSE, error?: string | boolean) => ({
			payload,
			meta,
			error,
		}),
	);
	uploadedMediaReceived = this.createAction(
		ACTION.CMDP_SGETUPLOADEDMEDIA,
		this.getPrepareAction<CMDP_SGETUPLOADEDMEDIA_RESPONSE['values'], CMDP_SGETUPLOADEDMEDIA_RESPONSE>(),
	);
}

export const mediaServerToClientActions = new MediaServerToClientActions();
