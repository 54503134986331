import { EnumCurrency, ICurrencyDescription, IEmoji, IReaction, JSONString } from "../@types";
import { IGift } from "./gifts";
import { IBACKENDPAYLOAD } from "./backend";
import { ACTION } from "./command/action";
import { baseParamsType, SupportedLanguage } from "./command/baseparams";
import { ICOMMAND, IKeyValue, IRESPONSE, RESULT } from "./command/icommand";

export interface IInitBackendPayload {
  giftBundles: any;
  giftCategories: any;
  gifts: any;
  emoticons: any;
  currency: any;
  toyControls: any;
  icons: any;
}

export type UploadMediaTus = {
  endPoint: string;
  chunkSize: number;
  parallelUploads: number;
  authHeader: string;
};

/**
 * the initial command
 */
export class CMDP_INIT implements ICOMMAND {
  public action: ACTION = ACTION.CMDP_INIT;
  public params: baseParamsType & {
    login?: string;
    initData?: string;
  };
}

/**
 * Response for CMDP_INIT
 */
export class CMDP_INIT_RESPONSE extends CMDP_INIT implements IRESPONSE, IBACKENDPAYLOAD {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: {
    /** minimal version info */
    versionRequired?: string;
    /** version last */
    versionLast?: string;
    /** update url */
    versionUpdateUrl?: string;
    /** where to send message media */
    uploadMediaUrl?: string;
    speedTestUrl?: string;
    uploadFeedbackUrl?: string;
    translations?: JSONString<InitialTranslations>;
    gifts?: JSONString<IGift[]>;
    emojiList?: JSONString<IEmoji[]>;
    currency?: JSONString<InitialCurrencies>;
    reactions?: JSONString<IReaction[]>;
  };
  /** backendpayload */
  public payload?: IInitBackendPayload;
}

export type InitialTranslations = {
  [key in SupportedLanguage]?: IKeyValue;
};

export type InitialCurrencies = {
  [key in EnumCurrency]?: ICurrencyDescription;
};

/**
 * the initial command, 8320 only
 */
export class CMDP_SINIT extends CMDP_INIT {
  public action: ACTION = ACTION.CMDP_SINIT;
}

/** Response for CMDP_SINIT, 8320 only */
export class CMDP_SINIT_RESPONSE extends CMDP_INIT_RESPONSE {
  public action: ACTION = ACTION.CMDP_SINIT;
}
