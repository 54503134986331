import cuid from 'cuid';
import _ from 'lodash';
import {call, put, select} from 'typed-redux-saga';
import {ResultCode} from 'cmd-control-client-lib';

import {
	EnumAbstractNotificationVariant,
	TAbstractNotificationOptions,
} from '@messenger/core/src/Services/UINotification';
import {loginFormClientOnlyActions} from '@messenger/core/src/Redux/LoginForm/Actions/loginFormClientOnlyActions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {cleanUpStoresSaga} from '@messenger/core/src/Redux/Session/Sagas/cleanUpStoresSaga';
import selectShouldAutoLogin from '@messenger/core/src/Redux/LoginForm/Selectors/selectShouldAutoLogin';
import {reInitVControlSaga} from '@messenger/core/src/Redux/Session/Sagas/reInitVControlSaga';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';
import {closeAllNotificationsSaga} from '@messenger/core/src/Redux/Notifications/Sagas/closeAllNotificationsSaga';

export const showGlobalErrorSaga = function* ({
	payload: {code, reason},
}: ReturnType<typeof clientClientOnlyActions.globalError>) {
	const shouldStayLoggedIn = yield* select(selectShouldAutoLogin);

	if (code === ResultCode.SESSION_NOT_FOUND && shouldStayLoggedIn) {
		yield* call(closeAllNotificationsSaga);
		yield* call(reInitVControlSaga);

		return;
	}

	yield* put(loginFormClientOnlyActions.disableAutoLogin());

	yield* call(cleanUpStoresSaga);

	const notification: TAbstractNotificationOptions = {
		text: reason,
		buttonText: 'OK',
		variant: EnumAbstractNotificationVariant.ERROR,
		key: cuid.slug(),
		onClose: _.noop,
	};

	yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], notification);
};
