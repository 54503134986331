import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {toyClientOnlyActions} from '@messenger/core/src/Redux/Toy/Actions/ToyClientOnlyActions';
import {selectSelectedToy} from '@messenger/core/src/Redux/Toy/Selectors/selectSelectedToy';

/**
 * @param {object} action
 * @return {Generator|void}
 */
const deselectMissingToySaga = function* (action: ReturnType<typeof toyClientOnlyActions.receiveToys>) {
	try {
		// check selected device still in list
		const selectedToyId = yield* select(selectSelectedToy);
		const toyIds = Object.keys(action.payload);

		// we have a toy selected & it's still in list
		if (undefined !== selectedToyId && !toyIds.includes(selectedToyId)) {
			yield* put(toyClientOnlyActions.deselectToy());
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'deselectMissingToySaga', action});
	}
};

export default deselectMissingToySaga;
