import {createSelector} from '@messenger/core/src/Utils/Redux/createSelector';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import IRootState from '@messenger/core/src/Redux/IRootState';
import {introductionStepsAdapter} from '@messenger/core/src/Redux/Introduction/entityAdapter';
import EnumIntroductionStep from '@messenger/core/src/Redux/Introduction/EnumIntroductionStep';
import {selectIsOnline} from '@messenger/core/src/Redux/Session/Selectors/selectIsOnline';
import {TIntroductionState} from '@messenger/core/src/Redux/Introduction/slice';

const getIntroduction = (state: IRootState): TIntroductionState => state[EnumStore.INTRODUCTION];

export const getIsIntroRunning = createSelector([getIntroduction], (introduction): boolean => introduction.isRunning);

export const getShouldShowIntroModal = createSelector(
	[getIntroduction, selectIsOnline],
	(introduction, isOnline): boolean => introduction.shouldShowModal && !isOnline,
);

export const getIsIntroLoading = createSelector([getIntroduction], (introduction): boolean => introduction.isLoading);

export const getReplyMessageIds = createSelector(
	[getIntroduction],
	(introduction): string[] => introduction.replyMessageIds,
);

export const getChannelsScrollDisabled = createSelector(
	[getIntroduction],
	(introduction): boolean => introduction.isChannelScrollDisabled,
);

export const getIsReadyToRedirect = createSelector(
	[getIntroduction],
	(introduction): boolean => introduction.isReadyToRedirect,
);

export const getIntroductionStep = createSelector(
	[getIntroduction],
	(introduction): EnumIntroductionStep => introduction.step,
);

export const shouldShowButtons = createSelector([getIntroduction], (introduction): boolean => introduction.showButtons);

export const {selectAll: getIntroductionSteps} = introductionStepsAdapter.getSelectors<IRootState>(
	(state) => state[EnumStore.INTRODUCTION],
);

export const selectIconsMeaningModalShown = createSelector(
	getIntroduction,
	(introduction): boolean => introduction.iconsMeaningModalShown,
);
