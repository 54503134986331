import _ from 'lodash';
import {EntityId} from '@reduxjs/toolkit';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import getPropsChannelId from '@messenger/core/src/Redux/Channels/Selectors/getPropsChannelId';
import {selectArePricesLoadingByChannelId} from '@messenger/core/src/Redux/MediaPrice/Selectors/selectArePricesLoadingByChannelId';

import {selectAllMediaPricesChannelIds} from './defaultSelectors';
import {selectArePricesErroredByChannelId} from './selectArePricesErroredByChannelId';

export const selectAreMediaPricesRequestedByChannelId = createSelector(
	[
		selectArePricesLoadingByChannelId,
		selectArePricesErroredByChannelId,
		selectAllMediaPricesChannelIds,
		getPropsChannelId,
	],
	(isLoading: boolean, isErrored: boolean, allIds: EntityId[], channelId) =>
		!_.isUndefined(channelId) && (isLoading || isErrored || _.includes(allIds, channelId)),
);
