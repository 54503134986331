import {TCanAccessWindow, TCanPlaySound} from '@messenger/core/src/Services/AbstractUiContainer';

export const testSoundBuffer = (browser: TCanPlaySound & TCanAccessWindow): Promise<boolean> =>
	new Promise((resolve, reject) => {
		const timers: number[] = [];
		const source = browser.playDummySound();
		const window = browser.getWindow();

		const checkResult = (isLastCall = false) => {
			if (source.playbackState === source.PLAYING_STATE || source.playbackState === source.FINISHED_STATE) {
				timers.map((timer) => window.clearTimeout(timer));
				resolve(true);
			} else if (isLastCall) {
				timers.map((timer) => window.clearTimeout(timer));
				reject(false);
			}
		};

		// by checking the play state after some time, we know if we're really unlocked
		timers.push(window.setTimeout(checkResult, 0));
		timers.push(window.setTimeout(checkResult, 1));
		timers.push(window.setTimeout(checkResult, 2));
		timers.push(window.setTimeout(checkResult.bind(null, true), 1000));
	});
