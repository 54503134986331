import _ from 'lodash';
import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {chatSummaryClientToServerActions} from '@messenger/core/src/Redux/ChatSummary/Actions/chatSummaryClientToServerActions';
import {chatSummaryClientOnlyActions} from '@messenger/core/src/Redux/ChatSummary/Actions/chatSummaryClinetOnlyActions';
import {selectCurrentChatChannelId} from '@messenger/core/src/Redux/Messages/Selectors/selectCurrentChatChannelId';

export const updateChatSummarySaga = function* (channelIds?: string[]) {
	try {
		const channelId = yield* select(selectCurrentChatChannelId);

		if (!_.isUndefined(channelIds) && channelIds.length > 0) {
			yield* put(chatSummaryClientOnlyActions.removeMany(channelIds));
		}

		if (!_.isUndefined(channelId) && _.includes(channelIds, channelId)) {
			yield* put(chatSummaryClientToServerActions.fetchChatSummary({channelId}));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'updateChatSummarySaga'});
	}
};
