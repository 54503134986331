import _ from 'lodash';
import {EnumMediaState, EnumMediaType, EnumTicketType} from 'cmd-control-client-lib';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectAllMedia} from '@messenger/core/src/Redux/Media/Selectors/defaultSelectors';
import getPropsChannelId from '@messenger/core/src/Redux/Channels/Selectors/getPropsChannelId';

export const selectTicketByChannelId = createSelector([selectAllMedia, getPropsChannelId], (allMedia, channelId) =>
	_.find(allMedia, {
		channelId,
		mediaType: EnumMediaType.TICKET,
		mediaState: EnumMediaState.OK,
		ticketType: EnumTicketType.SingleC2C,
	}),
);
