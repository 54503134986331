import _ from 'lodash';
import {EnumMediaType} from 'cmd-control-client-lib';

import ILocalFile from '@messenger/core/src/Redux/Media/ILocalFile';

const getMediaType = (file?: File | ILocalFile): EnumMediaType | undefined => {
	let mediaType = undefined;

	if (_.isUndefined(file)) {
		return mediaType;
	}

	if (_.startsWith(file.type, 'image')) {
		mediaType = EnumMediaType.BITMAP;
	}

	if (_.startsWith(file.type, 'video')) {
		mediaType = EnumMediaType.VIDEO;
	}

	if (_.startsWith(file.type, 'audio')) {
		mediaType = EnumMediaType.AUDIO;
	}

	return mediaType;
};

export default getMediaType;
