import {ClientOnlyActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import type {TMessageInputEntity} from '@messenger/core/src/Redux/MessageInput/TMessageInputEntity';

enum EnumActions {
	UPSERT_ONE = 'UPSERT_ONE',
	REMOVE_ONE = 'REMOVE_ONE',
	SAVE_CURRENT_CARET_POSITION = 'SAVE_CURRENT_CARET_POSITION',
	INSERT_TO_CURRENT_CARET_POSITION = 'INSERT_TO_CURRENT_CARET_POSITION',
	FOCUS_CURRENT_MESSAGE_INPUT = 'FOCUS_CURRENT_MESSAGE_INPUT',
}

class MessageInputClientOnlyActions extends ClientOnlyActions {
	readonly scope = EnumStore.MESSAGE_INPUT;

	upsertOne = this.createAction(EnumActions.UPSERT_ONE, this.getPrepareAction<TMessageInputEntity>());
	removeOne = this.createAction(EnumActions.REMOVE_ONE, this.getPrepareAction<string>());
	saveCurrentCaretPosition = this.createAction(EnumActions.SAVE_CURRENT_CARET_POSITION);
	insertToCurrentCaretPosition = this.createAction(
		EnumActions.INSERT_TO_CURRENT_CARET_POSITION,
		this.getPrepareAction<string>(),
	);
	focusCurrentMessageInput = this.createAction(EnumActions.FOCUS_CURRENT_MESSAGE_INPUT);
}

export const messageInputClientOnlyActions = new MessageInputClientOnlyActions();
