import {call, put} from 'typed-redux-saga';
import {EnumBooleanStringified} from 'cmd-control-client-lib';

import {EnumLocalStorageKeys} from '@messenger/core/src/BusinessLogic/EnumLocalStorageKeys';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import EnumStoreType from '@messenger/core/src/BusinessLogic/EnumStoreType';
import {loginFormClientOnlyActions} from '@messenger/core/src/Redux/LoginForm/Actions/loginFormClientOnlyActions';
import {sessionClientOnlyActions} from '@messenger/core/src/Redux/Session/Actions/sessionClientOnlyActions';

const setDefaultCredentialsSaga = function* () {
	try {
		const api = ServiceFactory.store;

		const rememberMe = yield* call([api, api.get], EnumLocalStorageKeys.REMEMBER_ME, {
			storeType: EnumStoreType.LOCAL,
		});
		const storeType = rememberMe ? EnumStoreType.LOCAL : EnumStoreType.SESSION;
		const login = yield* call([api, api.get], EnumLocalStorageKeys.LOGIN, {storeType});
		const passwordHash = yield* call([api, api.get], EnumLocalStorageKeys.PASSWORD_HASH, {storeType});

		if (!login || !passwordHash) {
			yield* put(sessionClientOnlyActions.setIsLoggedIn(EnumBooleanStringified.FALSE));
		}

		yield* put(loginFormClientOnlyActions.setDefaultCredentials({login, passwordHash, rememberMe}));

		if (rememberMe) {
			yield* put(loginFormClientOnlyActions.enableAutoLogin());
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'setDefaultCredentialsSaga'});
	}
};

export default setDefaultCredentialsSaga;
