import cuid from 'cuid';
import {
	ChannelInfoType,
	ChannelType,
	ComaSeparatedValues,
	EnumBooleanStringified,
	EnumChannelFlags,
	EnumProductId,
} from 'cmd-control-client-lib';

import NumberUtil from '@messenger/core/src/Utils/NumberUtil';
import {onceUponATimeInAGalaxyFarFarAway} from '@messenger/core/src/Utils/DateTimeUtil';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumReservedChannelIds} from '@messenger/core/src/BusinessLogic/EnumReservedChannelIds';

import JohnDoeAvatar from 'src/Assets/Images/channel-john-doe.jpeg';

export const JohnDoeUserId = NumberUtil.random(10000, 1000000);
export const JohnDoePartnerId = NumberUtil.random(10000, 1000000);
export const JohnDoePartnerKey = cuid.slug();
export const JohnDoeUserKey = cuid.slug();
export const JohnDoeChannelsMapId = 0;
export const JohnDoeChannelWeight = 'JOHN_DOE_WEIGHT';

export default class ChannelHelper {
	static getJohnDoeChannel = (): ChannelType => ({
		channelId: EnumReservedChannelIds.CHANNEL_ID_JOHN_DOE,
		weight: JohnDoeChannelWeight,
		imgSrc: JohnDoeAvatar,
		usrId: `${JohnDoeUserId}`,
		usrKey: JohnDoeUserKey,
		partnerId: `${JohnDoePartnerId}`,
		partnerKey: JohnDoePartnerKey,
		unseen: '1',
		serial: cuid.slug(),
		lastTimeIn: new Date().getTime().toString(),
		lastTimeOut: onceUponATimeInAGalaxyFarFarAway.getTime().toString(),
		flags:
			`${EnumChannelFlags.UNANSWERED},${EnumChannelFlags.PINNED}` as unknown as ComaSeparatedValues<EnumChannelFlags>,
	});

	static getJohnDoeContactInfo = (): ChannelInfoType => ({
		channelId: EnumReservedChannelIds.CHANNEL_ID_JOHN_DOE,
		partnerId: `${JohnDoePartnerId}`,
		partnerKey: JohnDoePartnerKey,
		ualias: ServiceFactory.i18n.t('intro:channels_john_doe_username') as string,
		productId: EnumProductId.Default,
		imgSrc: JohnDoeAvatar,
		followMe: EnumBooleanStringified.FALSE,
	});
}
