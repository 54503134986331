import _ from 'lodash';
import {IMayHaveChannelId} from 'cmd-control-client-lib';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import IRootState from '@messenger/core/src/Redux/IRootState';
import {selectChannelMediaEntities} from '@messenger/core/src/Redux/ChannelMedia/Selectors/defaultSelectors';

export const getChannelIdFromProps = (_state: IRootState, props: IMayHaveChannelId) => props?.channelId;

const selectMediaByChannelId = createSelector(
	[selectChannelMediaEntities, getChannelIdFromProps],
	(channelMediaEntities, channelId) => {
		return _.filter(channelMediaEntities, (entity) => {
			return entity?.channelId === channelId;
		});
	},
);

export default selectMediaByChannelId;
