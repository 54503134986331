import _ from 'lodash';

import {selectAdminChatsToShow} from '@messenger/core/src/Redux/Chats/Selectors/selectAdminChatsToShow';
import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectRegularChatVms} from '@messenger/core/src/Redux/Chats/Selectors/selectRegularChatVms';
import selectAreAllChannelsShown from '@messenger/core/src/Redux/Channels/Selectors/Filter/selectAreAllChannelsShown';
import ChannelVM from '@messenger/core/src/Redux/Channels/ChannelVM';
import selectGuestTypeFilter from '@messenger/core/src/Redux/Client/Selectors/GuestTypeFilter/selectGuestTypeFilter';
import {selectChannelEntityVMs} from '@messenger/core/src/Redux/Channels/Selectors/defaultSelectors';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

import selectChatsFilterMatcher from './selectChatsFilterMatcher';

const selectRunningChatVMsByFilter = createSelector(
	[
		selectGuestTypeFilter,
		selectRegularChatVms,
		selectAdminChatsToShow,
		selectAreAllChannelsShown,
		selectChatsFilterMatcher,
		selectChannelEntityVMs,
	],
	(guestTypeFilter, chatVms, adminChatsToShowIds, areAllChannelsShow, chatsFilterMatcher, channelVMEntities) => {
		if (guestTypeFilter === EnumGuestType.CHANNEL) {
			return [];
		}

		return _.filter(chatVms, (chatVm) => {
			const hasChannel = chatVm.channelId && _.has(channelVMEntities, `${chatVm.channelId}`);
			const hideAdmins = chatVm.isAdmin && !_.includes(adminChatsToShowIds, chatVm.chatId);
			let matchingFilter = false;

			if (hasChannel) {
				matchingFilter = chatsFilterMatcher.isMatches(
					_.get(channelVMEntities, `${chatVm.channelId}`) as ChannelVM,
					guestTypeFilter === EnumGuestType.ALL && areAllChannelsShow,
				);
			}

			return !(
				chatVm.exitCode ||
				(hasChannel && matchingFilter) ||
				hideAdmins ||
				chatVm.isAllChatsAggregate ||
				chatVm.isGroupChat ||
				chatVm.isPrivateTicketShow ||
				chatVm.isLobbyChat
			);
		});
	},
);

export default selectRunningChatVMsByFilter;
