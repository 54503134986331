import {createSlice, EntityState} from '@reduxjs/toolkit';
import {groupType} from 'cmd-control-client-lib';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {groupAdapter} from '@messenger/core/src/Redux/Groups/groupAdapter';
import {groupsClientToServerActions} from '@messenger/core/src/Redux/Groups/Actions/groupsClientToServerActions';
import {groupsClientOnlyActions} from '@messenger/core/src/Redux/Groups/Actions/groupsClientOnlyActions';
import {groupServerToClientActions} from '@messenger/core/src/Redux/Groups/Actions/groupsServerToClientActions';

export const initialExtraState = {
	isSending: false,
	errorText: '',
};

export const initialState: TGroupState = groupAdapter.getInitialState(initialExtraState);

const groupsSlice = createSlice({
	name: EnumStore.GROUPS,
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(groupsClientOnlyActions.upsertMany, groupAdapter.upsertMany);
		builder.addCase(groupsClientOnlyActions.resetStore, () => initialState);
		builder.addCase(groupsClientOnlyActions.resetError, (state) => {
			state.errorText = '';
		});
		builder.addCase(groupsClientToServerActions.createGroup, (state) => {
			state.isSending = true;
			state.errorText = '';
		});
		builder.addCase(groupsClientToServerActions.editGroup, (state) => {
			state.isSending = true;
			state.errorText = '';
		});
		builder.addCase(groupServerToClientActions.groupAdded, (state, {error}) => {
			state.isSending = false;

			if (error) {
				state.errorText = error;

				return;
			}
		});
		builder.addCase(groupServerToClientActions.groupUpdated, (state, {error}) => {
			state.isSending = false;

			if (error) {
				state.errorText = error;
			}
		});
	},
});

export type TGroupState = EntityState<groupType> & {
	isSending: boolean;
	errorText: string;
};

export default groupsSlice;
