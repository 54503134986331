import {createSelector} from '@reduxjs/toolkit';
import _ from 'lodash';

import {selectTargetChannelIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetChannelIds';
import {selectAreMediaPricesRequestedByChannelId} from '@messenger/core/src/Redux/MediaPrice/Selectors/selectAreMediaPricesRequestedByChannelId';
import selectRootState from '@messenger/core/src/Redux/selectRootState';

export const selectTargetChannelIdsWithNoPrices = createSelector(
	[selectRootState, selectTargetChannelIds],
	(rootState, targetChannelIds) =>
		_.reject(targetChannelIds, (channelId) => selectAreMediaPricesRequestedByChannelId(rootState, {channelId})),
);
