import {put, call} from 'typed-redux-saga';
import _ from 'lodash';
import {EntityId} from '@reduxjs/toolkit';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import notificationsClientOnlyActions from '@messenger/core/src/Redux/Notifications/Actions/notificationsClientOnlyActions';
import {closeAllNotificationsSaga} from '@messenger/core/src/Redux/Notifications/Sagas/closeAllNotificationsSaga';

const closeNotificationsSaga = function* ({
	payload: notificationKey,
}: ReturnType<typeof notificationsClientOnlyActions['closeSnackbar']>) {
	try {
		if (!_.isEmpty(notificationKey)) {
			yield* put(notificationsClientOnlyActions.dismissMany([notificationKey as EntityId]));
		} else {
			yield* call(closeAllNotificationsSaga);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'closeNotificationsSaga'});
	}
};

export default closeNotificationsSaga;
