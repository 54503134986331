import {delay, put, select, call} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {toyClientOnlyActions} from '@messenger/core/src/Redux/Toy/Actions/ToyClientOnlyActions';
import {selectIsToysEnabled} from '@messenger/core/src/Redux/Toy/Selectors/selectIsToysEnabled';
import {selectIsLoggedIn} from '@messenger/core/src/Redux/Session/Selectors/selectIsLoggedIn';

const checkDeviceStatusSaga = function* () {
	try {
		const timeInterval: number = yield* call([ServiceFactory.env, ServiceFactory.env.getDeviceTimerInterval]);

		while (true) {
			const isToysEnabled: boolean = yield* select(selectIsToysEnabled);
			const isLoggedIn = yield* select(selectIsLoggedIn);

			if (!isToysEnabled || !isLoggedIn) {
				break;
			}

			yield* put(toyClientOnlyActions.requestToys());
			yield* delay(timeInterval);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'checkDeviceStatusSaga'});
	}
};

export default checkDeviceStatusSaga;
