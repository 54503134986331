import cuid from 'cuid';
import {call, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {
	selectChannelVMById,
	getChannelIdForSelector,
} from '@messenger/core/src/Redux/Channels/Selectors/defaultSelectors';
import {streamClientToServerActions} from '@messenger/core/src/Redux/Stream/Actions';
import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';
import {selectIsOnline} from '@messenger/core/src/Redux/Session/Selectors/selectIsOnline';

export const notifyOnGuestAvsSaga = function* (
	action: ReturnType<typeof streamClientToServerActions.startPrivateShow>,
) {
	try {
		const channelVM = yield* select(selectChannelVMById, getChannelIdForSelector(action.payload.channelId));
		const isModelStreaming = yield* select(selectIsOnline);

		if (channelVM?.isAvs === false && isModelStreaming) {
			yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], {
				text: ServiceFactory.i18n.t('privateShow:notification-avs'),
				variant: EnumAbstractNotificationVariant.WARNING,
				key: cuid(),
				duration: 2000,
			});
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'notifyOnGuestAvsSaga'});
	}
};
