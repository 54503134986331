import _ from 'lodash';
import 'reflect-metadata';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectGuestChannelVMs} from '@messenger/core/src/Redux/Channels/Selectors/selectGuestChannelVMs';
import {selectAllGroupsVMs} from '@messenger/core/src/Redux/Groups/Selectors/selectAllGroupsVMs';
import ChannelOrGroupVM from '@messenger/core/src/Redux/Channels/ChannelOrGroupVM';

export const selectGuestChannelAndGroupVMs = createSelector(
	[selectGuestChannelVMs, selectAllGroupsVMs],
	(channelVMs, groupsVMs) => {
		return _.concat(
			_.map(channelVMs, (item) => new ChannelOrGroupVM(item)),
			_.map(groupsVMs, (item) => new ChannelOrGroupVM(undefined, item)),
		);
	},
);
