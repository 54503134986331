import _ from 'lodash';
import {createSelectorCreator, defaultMemoize} from 'reselect';

import {selectChannelIds} from '@messenger/core/src/Redux/Channels/Selectors/defaultSelectors';
import selectChannelIdsWithHistory from '@messenger/core/src/Redux/Channels/Selectors/selectChannelIdsWithHistory';

const selectChannelIdsWithoutHistoryCreator = createSelectorCreator(defaultMemoize, _.isEqual);

const selectChannelIdsWithoutHistory = selectChannelIdsWithoutHistoryCreator(
	[selectChannelIdsWithHistory, selectChannelIds],
	(channelIdsWithHistory, channelIds) => _.difference(channelIds, channelIdsWithHistory),
);

export default selectChannelIdsWithoutHistory;
