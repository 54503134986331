import {delay, put, select} from 'typed-redux-saga';

import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import selectHighlightedMessageId from '@messenger/core/src/Redux/Messages/Selectors/selectHighlightedMessageId';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

export function* stopHighlightingMessageSaga(action: ReturnType<typeof messagesClientOnlyActions.highlightMessage>) {
	try {
		yield* delay(ServiceFactory.env.getHighlightingMessageTimeout());

		const currentlyHighlighting = yield* select(selectHighlightedMessageId);

		if (currentlyHighlighting === action.payload.messageId) {
			yield* put(messagesClientOnlyActions.stopHighlightingMessage());
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'stopHighlightingMessageSaga'});
	}
}
