import {call, put, select, take} from 'typed-redux-saga';
import {eventChannel} from 'redux-saga';

import {sipClientOnlyActions} from '@messenger/core/src/Redux/Sip/Actions/sipClientOnlyActions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import selectSessionLogin from '@messenger/core/src/Redux/Session/Selectors/selectSessionLogin';
import {
	AbstractSipService,
	EnumSipServiceAction,
	TSipServiceActions,
} from '@messenger/core/src/Services/AbstractSipService';

export const createSipChannel = (sip: AbstractSipService) =>
	eventChannel<TSipServiceActions>((emitter) => {
		sip.setEmitter(emitter);

		return () => sip.unsetEmitter();
	});

export const initSipSaga = function* ({payload}: ReturnType<typeof sipClientOnlyActions.init>) {
	try {
		const modelName = yield* select(selectSessionLogin);
		const sipChannel = yield* call(createSipChannel, ServiceFactory.sip);

		yield* call([ServiceFactory.sip, ServiceFactory.sip.init], payload.login, payload.password, modelName);

		while (true) {
			const sipAction = yield* take(sipChannel);

			switch (sipAction.type) {
				case EnumSipServiceAction.INITIALIZED:
					yield* put(sipClientOnlyActions.setIsInitialized(sipAction.payload));

					if (!sipAction.payload) {
						sipChannel.close();
					}

					break;

				case EnumSipServiceAction.INCOMING_CALL:
					yield* put(sipClientOnlyActions.setCall(sipAction.payload));
					break;

				case EnumSipServiceAction.OUTGOING_CALL:
					yield* put(sipClientOnlyActions.setCall(sipAction.payload));
					break;

				case EnumSipServiceAction.STATE_CHANGED:
					yield* put(sipClientOnlyActions.changeCallState(sipAction.payload));
					break;
			}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'initSipSaga'});
	}
};
