import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux/createSelector';
import {selectAllNotifications} from '@messenger/core/src/Redux/Notifications/Selectors/defaultSelectors';
import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';

export const selectNonErrorNotificationIds = createSelector([selectAllNotifications], (notifications) =>
	_.chain(notifications)
		.reject({options: {variant: EnumAbstractNotificationVariant.ERROR}})
		.map('key')
		.value(),
);
