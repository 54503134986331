import {Channel} from '@redux-saga/types';
import {channel, END} from 'redux-saga';
import {call, select, take} from 'typed-redux-saga';
import {EntityId, PayloadAction} from '@reduxjs/toolkit';
import _ from 'lodash';
import {ResultCode, EnumChannelFlags} from 'cmd-control-client-lib';

import {parseBoolean} from '@messenger/core/src/Utils/StringUtil';
import {
	TBooleanChannelFlags,
	channelsClientOnlyActions,
	channelsServerToClientActions,
} from '@messenger/core/src/Redux/Channels/Actions';
import {channelsClientToServerActions} from '@messenger/core/src/Redux/Channels/Actions/channelsClientToServerActions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectActiveNotificationsByChannelIdCount} from '@messenger/core/src/Redux/Notifications/Selectors/selectActiveNotificationsByChannelIdCount';
import {
	EnumAbstractNotificationCloseReason,
	EnumAbstractNotificationVariant,
	TAbstractNotificationOptions,
} from '@messenger/core/src/Services/UINotification';
import ChannelFlagsActionHelper from '@messenger/core/src/Redux/Channels/ChannelFlagsHelper';
import ChannelVM from '@messenger/core/src/Redux/Channels/ChannelVM';
import {selectChannelEntityVMs} from '@messenger/core/src/Redux/Channels/Selectors/defaultSelectors';
import {EnumLocaleNamespace, LocaleSeparator} from '@messenger/core/src/Locale/EnumLocaleNamespace';
import notificationsClientOnlyActions from '@messenger/core/src/Redux/Notifications/Actions/notificationsClientOnlyActions';

enum EnumToastEvents {
	CLOSED = 'toast-closed',
}

const getFlagsDiff = (vm: ChannelVM, flags: TBooleanChannelFlags) => {
	const vmFlags = ChannelFlagsActionHelper.getFlagsFromChannelVM(vm);

	return _.pickBy(flags, (val, key) => val !== vmFlags[key]);
};

const ERROR_FROM_SERVER = 'errorFromServer';

export const notifyChannelFlagsUpdatedSaga = function* (
	clientAction: ReturnType<typeof channelsClientToServerActions.setChannelFlags>,
) {
	const i18n = ServiceFactory.i18n;
	const isNsLoaded: boolean = yield* call([i18n, 'hasLoadedNamespace'], EnumLocaleNamespace.CHANNEL_FLAGS);

	if (!isNsLoaded) {
		yield* call([i18n, 'loadNamespaces'], EnumLocaleNamespace.CHANNEL_FLAGS);
	}

	if (clientAction.meta.withoutNotification) {
		return;
	}

	// wait for server to reply on action
	const {meta} = yield* take(channelsServerToClientActions.channelFlagsUpdated);

	if (meta.result.code !== ResultCode.OK && meta.result.reason) {
		yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], {
			text: meta.result.reason,
			variant: EnumAbstractNotificationVariant.ERROR,
			key: ERROR_FROM_SERVER,
		});

		return;
	}

	const vmEntities = yield* select(selectChannelEntityVMs);

	const update = _.chain(meta.params).pick(Object.values(EnumChannelFlags)).mapValues(parseBoolean).value();

	const toastChannel = (yield* call(channel)) as Channel<
		PayloadAction<TNotificationClosePayload, EnumToastEvents.CLOSED> | END
	>;
	// get changed channel's id, username, flags difference
	const changedChannels = _.chain(meta.params.channelId)
		.split(',')
		.map((channelId) => {
			const vm = vmEntities[channelId];

			// assume that all flags really changed if VM doesn't exists
			if (!vm) {
				return {channelId, diff: update};
			}

			return {channelId, username: vm.username, diff: getFlagsDiff(vm, update)};
		})
		.filter((channel) => !_.isEmpty(channel.diff))
		.value();

	// don't trigger any notification if flags not really changed
	if (changedChannels.length <= 0) {
		return;
	}

	// generate translation for first channel
	// this logic should be changed if actions with multiple channels AND multiple flags will be added
	const firstChannel = changedChannels[0];
	const helper = new ChannelFlagsActionHelper(firstChannel.diff);
	const translation = helper.actionTranslation;
	const additionalText = {
		count: changedChannels.length,
		flags: helper.flagsString,
		username: firstChannel.username,
	};

	if (!ServiceFactory.i18n.exists(translation, additionalText)) {
		ServiceFactory.logService.error({name: `Missing translation for channel flag update notification`} as Error, {
			translation,
			additionalText,
			changedChannels,
		});
	}

	const notificationKey = changedChannels.map(({channelId}) => channelId).join(',');
	const {t: translateFlag} = ServiceFactory.i18n;

	const toastConfig: TAbstractNotificationOptions = {
		text: translateFlag([translation, `channelFlags${LocaleSeparator}unknown`], additionalText),
		variant: EnumAbstractNotificationVariant.SUCCESS,
		duration: yield* call([ServiceFactory.env, ServiceFactory.env.getSnackTimeout]),
		key: notificationKey,
	};

	toastChannel.take((message) => {
		if (message === END || !('payload' in message)) {
			return;
		}

		const reason = message.payload.reason;
		const key = message.payload.key;

		if (reason === EnumAbstractNotificationCloseReason.USER && key === notificationKey) {
			ServiceFactory.notifications.triggerUndo(
				_.chain(changedChannels)
					// create helper
					.map((ch) => ({...ch, helper: new ChannelFlagsActionHelper(ch.diff)}))
					// group by flags
					.groupBy((ch) => ch.helper.flagsString)
					// generate actions for each set of flags
					.map((channelsGroup) =>
						channelsClientOnlyActions.reverseChannelFlags({
							channelIds: channelsGroup.map((ch) => ch.channelId),
							// flags is equal for group, so just pick first
							...channelsGroup[0].helper.reverseFlags,
						}),
					)
					.value(),
			);
		}
	});

	toastConfig.buttonText = ServiceFactory.i18n.t('channel:meta-update-undo');

	toastConfig.onClose = (_, reason, key) => {
		toastChannel.put({type: EnumToastEvents.CLOSED, payload: {reason, key}});
		toastChannel.put(END);
	};

	// count all current notifications for this channel
	const allNotificationsCount = yield* select(selectActiveNotificationsByChannelIdCount, {key: notificationKey});

	if (allNotificationsCount > 0) {
		// close previous one if still displayed
		yield* call([ServiceFactory.notifications, ServiceFactory.notifications.close], notificationKey);

		// wait until fully removed
		yield* take(notificationsClientOnlyActions.removeSnackbar.type);
	}

	// show new one
	yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], toastConfig);
};

type TNotificationClosePayload = {
	reason: EnumAbstractNotificationCloseReason;
	key?: EntityId;
};
