import {EnumBooleanDigitized} from 'cmd-control-client-lib';

import {IHavingChatID} from '@messenger/core/src/Redux/Messages/Model';

export enum QueryUserAnswerChoice {
	BUTTON_0 = 'btn0',
	BUTTON_1 = 'btn1',
}

export interface IHavingQueryIds {
	queryId?: string;
	messageId?: string;
}

/**
 * @see CMDC_QUERYUSER
 */
export interface IQueryUserModel extends IHavingQueryIds, IHavingChatID {
	key: string;
	caption: string;
	text: string;
	btn0: string;
	btn1: string;
	timeout: string;
	sound: string;
	btnDefault: EnumBooleanDigitized;
	btndef: QueryUserAnswerChoice;
}
