import {put, select} from 'typed-redux-saga';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {channelInfosServerToClientActions} from '@messenger/core/src/Redux/ChannelInfos/Actions/channelInfosServerToClientActions';
import {channelInfosClientToServerActions} from '@messenger/core/src/Redux/ChannelInfos/Actions/channelInfosClientToServerActions';
import {channelInfosClientOnlyActions} from '@messenger/core/src/Redux/ChannelInfos/Actions/channelInfosClientOnlyActions';
import {selectCurrentlyRequestingIds} from '@messenger/core/src/Redux/ChannelInfos/Selectors/selectCurrentlyRequestingIds';

type TUpdateChannelInfosActions =
	| ReturnType<typeof channelInfosClientToServerActions.get>
	| ReturnType<typeof channelInfosServerToClientActions.channelInfosRequested>;

const processUpdateChannelInfosSaga = function* (action: TUpdateChannelInfosActions) {
	try {
		let requestedIds: string[] = [];
		const currentRequestedIds = yield* select(selectCurrentlyRequestingIds);

		switch (action.type) {
			case channelInfosClientToServerActions.get.type:
				requestedIds = _.filter(
					_.uniq([...currentRequestedIds, ..._.split(_.get(action, 'payload.channelId'), ',')]),
					(id) => !_.isEmpty(id),
				);

				break;

			case channelInfosServerToClientActions.channelInfosRequested.type:
				requestedIds = _.difference(currentRequestedIds, _.split(_.get(action, 'meta.params.channelId'), ','));

				break;
		}

		yield* put(
			channelInfosClientOnlyActions.updateStore({
				isLoading: !_.isEmpty(requestedIds),
				requestedIds,
			}),
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processUpdateChannelInfosSaga'});
	}
};

export default processUpdateChannelInfosSaga;
