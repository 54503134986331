import {createEntityAdapter} from '@reduxjs/toolkit';

import {VStep} from '@messenger/core/src/Redux/Introduction/index';

export const introductionStepsAdapter = createEntityAdapter<VStep>({
	selectId: (step) => step.step,
	sortComparer: (step1, step2) => {
		return step1.step > step2.step ? 1 : -1;
	},
});
