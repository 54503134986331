import IRootState from '@messenger/core/src/Redux/IRootState';
import {channelMediaEntityAdapter} from '@messenger/core/src/Redux/ChannelMedia/entityAdapter';
import {getChannelMediaState} from '@messenger/core/src/Redux/ChannelMedia/Selectors/getChannelMediaState';

export const {
	selectEntities: selectChannelMediaEntities,
	selectAll: selectAllChannelMedia,
	selectIds: selectChannelMediaIds,
	selectById: selectChannelMediaById,
} = channelMediaEntityAdapter.getSelectors<IRootState>((state) => getChannelMediaState(state));
