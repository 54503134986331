import {createSlice, SliceCaseReducers} from '@reduxjs/toolkit';
import {EnumBooleanStringified, EnumSetTp, ResultCode} from 'cmd-control-client-lib';
import _ from 'lodash';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {
	sessionClientOnlyActions,
	sessionClientToServerActions,
	sessionServerToClientActions,
} from '@messenger/core/src/Redux/Session/Actions';

import {TSessionState} from './Model';

export const defaultState: TSessionState = {
	userId: '',
	usrKey: '',
	login: '',
	instanceId: '',
	sessionID: '',
	startTime: '',
	isNetworkAvailable: EnumBooleanStringified.FALSE,
	setTp: EnumSetTp.OFFLINE,
	isLoginSent: EnumBooleanStringified.FALSE,
	isInitialized: EnumBooleanStringified.FALSE,
	isLoggedIn: undefined,
	showToyTab: EnumBooleanStringified.FALSE,
	showMobileVideoCallTab: EnumBooleanStringified.TRUE,
	mobileVideoCallState: EnumBooleanStringified.FALSE,
	isLobbyModeEnabling: EnumBooleanStringified.FALSE,
	userLinks: '',
	foreignSID: undefined,
	isTooltipStartOnlineShown: EnumBooleanStringified.FALSE,
};

const getDefaultState = (currentState: TSessionState) => ({
	...defaultState,
	isNetworkAvailable: currentState.isNetworkAvailable,
});

const sessionSlice = createSlice<TSessionState, SliceCaseReducers<TSessionState>, EnumStore>({
	name: EnumStore.CHATS,
	initialState: defaultState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(sessionClientOnlyActions.setIsLoggedIn, (state, action) => {
			state.isLoggedIn = action.payload;
		});
		builder.addCase(sessionClientToServerActions.logIn, (state, action) => {
			state.isLoginSent = EnumBooleanStringified.TRUE;

			if (_.has(action.payload, 'foreignSID')) {
				state.foreignSID = _.get(action.payload, 'foreignSID');
			}
		});
		builder.addCase(sessionServerToClientActions.initialized, (state) => {
			state.isInitialized = EnumBooleanStringified.TRUE;
		});
		builder.addCase(sessionServerToClientActions.loggedIn, (state, action) => {
			if (action.meta.result.code === ResultCode.OK) {
				_.assign(state, action.payload, {
					isLoggedIn: EnumBooleanStringified.TRUE,
					instanceId: action.meta.params._iid,
				});
			}
		});
		builder.addCase(sessionServerToClientActions.loggedOut, (state, action) =>
			action.meta.result.code === ResultCode.OK ? getDefaultState(state) : state,
		);
		builder.addCase(sessionServerToClientActions.sessionRemotelyTerminated, getDefaultState);
		builder.addCase(sessionServerToClientActions.fsk16Applied, (state, action) => {
			if (_.get(action, 'meta.result.code') === ResultCode.OK) {
				state.fsk16 = action.payload.fsk16;
			}
		});
		builder.addCase(sessionServerToClientActions.service0900StatusChanged, (state, action) => {
			if (action.meta.result.code === ResultCode.OK) {
				state.service0900State = action.payload.service0900State;
			}
		});
		builder.addCase(sessionServerToClientActions.sessionUpdated, (state, action) => {
			_.assign(state, action.payload);
		});
		builder.addCase(sessionClientOnlyActions.resetStore, getDefaultState);
		builder.addCase(sessionClientOnlyActions.setTp, (state, action) => {
			state.setTp = action.payload;
		});
		builder.addCase(sessionClientOnlyActions.setForeignSID, (state, action) => {
			state.foreignSID = action.payload;
		});
		builder.addCase(sessionClientToServerActions.enableLobbyMode, (state) => {
			state.isLobbyModeEnabling = EnumBooleanStringified.TRUE;
		});
		builder.addCase(sessionServerToClientActions.enableLobbyModeResult, (state) => {
			state.isLobbyModeEnabling = EnumBooleanStringified.FALSE;
		});
		builder.addCase(sessionClientOnlyActions.setTooltipStartOnlineVisibility, (state, action) => {
			state.isTooltipStartOnlineShown = action.payload;
		});
	},
});

export default sessionSlice;
