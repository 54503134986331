import {container, singleton} from 'tsyringe';
import {ACTION, ResultCode, UploadMediaTus, EnumFormatValues} from 'cmd-control-client-lib';
import _ from 'lodash';
import {Upload} from 'tus-js-client';
import axios from 'axios';

import {default as CoreVControlApi} from '@messenger/core/src/Services/VControl/VControlApi';
import ILocalFile from '@messenger/core/src/Redux/Media/ILocalFile';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import DIToken from '@messenger/core/src/BusinessLogic/DIToken';
import {TSendMediaParams} from '@messenger/core/src/Services/VControl/AbstractVControlApi';
import {mediaUploadProgressClientOnlyActions} from '@messenger/core/src/Redux/MediaUploadProgress/Actions/mediaUploadProgressClientOnlyActions';

@singleton()
class VControlApi extends CoreVControlApi {
	sendMedia(uploadUrl: string, file: File | ILocalFile, params: TSendMediaParams, tusConfig?: UploadMediaTus): void {
		if (!(file instanceof File)) {
			return;
		}

		const {messageId, channelId, sessionID} = params;
		const commonParams = _.chain(params)
			.pick(['text', 'messageKey', 'isBulk', 'isChargeable', 'mediaPrice', 'currency'])
			.omitBy(_.isNil)
			.value();

		if (tusConfig) {
			const metadata = {...commonParams, channelId, filename: file.name, filetype: file.type};
			const upload = new Upload(file, {
				endpoint: tusConfig.endPoint,
				chunkSize: tusConfig.chunkSize,
				removeFingerprintOnSuccess: true,
				parallelUploads: tusConfig.parallelUploads,
				headers: {Authorization: tusConfig.authHeader},
				retryDelays: [5, 15000],
				metadata,
				onError: (error) => {
					this.dispatch(
						messagesClientOnlyActions.setUploadError({
							messageId,
							errorCode: ResultCode.NETWORK_ERROR,
							reason: _.get(error, 'message', error.toString()),
						}),
					);
				},
				onProgress: (bytesUploaded, bytesTotal) => {
					const progress = Math.round((bytesUploaded / bytesTotal) * 100);

					this.dispatch(
						mediaUploadProgressClientOnlyActions.setMediaUploadProgress({
							messageId,
							progress,
						}),
					);
				},
			});

			upload.findPreviousUploads().then((previousUploads) => {
				const uploadToResume = _.findLast(previousUploads, (previousUpload) =>
					_.isEqual(previousUpload.metadata, metadata),
				);

				if (uploadToResume) {
					upload.resumeFromPreviousUpload(uploadToResume);
				}

				upload.start();
			});
		} else {
			const formData = new FormData();

			formData.append('file', file as File, file.name);

			if (channelId) {
				formData.append('channelId', channelId);
			}

			axios
				.request({
					url: uploadUrl,
					method: 'POST',
					params: {
						...commonParams,
						sessionID,
						format: EnumFormatValues.JSON,
						action: ACTION.CMDP_SUPLOADMEDIA,
					},
					data: formData,
					responseType: 'json',
					onUploadProgress: (e) => {
						const progress = (e.loaded / e.total) * 100;

						if (progress) {
							this.dispatch(
								mediaUploadProgressClientOnlyActions.setMediaUploadProgress({
									messageId,
									progress,
								}),
							);
						}
					},
				})
				.then((response) => {
					return this.dispatchResponse(response.data);
				})
				.catch((error) => {
					this.dispatch(
						messagesClientOnlyActions.setUploadError({
							messageId,
							errorCode: ResultCode.NETWORK_ERROR,
							reason: _.get(error, 'message', error.toString()),
						}),
					);
				});
		}
	}
}

container.register(DIToken.VControlApi, {useToken: VControlApi});

export default VControlApi;
