import {ACTION, CMDC_QUERYANSWERED, CMDC_QUERYUSER, CMDP_QUERYUSER_RESPONSE, ResultCode} from 'cmd-control-client-lib';

import {ServerToClientActions, TSingleEntityActionMeta} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {IQueryUserModel} from '@messenger/core/src/Redux/QueryUser/IQueryUserModel';
import {getQueryId} from '@messenger/core/src/Redux/QueryUser/entityAdapter';

/**
 * @WARNING
 * After adding new Server Action add it to "serverToClientActionsMap" object
 * @see serverToClientActionsMap
 */
class QueryServerToClientActions extends ServerToClientActions<EnumStore.QUERY_USER> {
	readonly scope = EnumStore.QUERY_USER;

	queryReceived = this.createAction(
		ACTION.CMDC_QUERYUSER,
		this.getPrepareAction<IQueryUserModel, TSingleEntityActionMeta<CMDC_QUERYUSER>, false>(),
	);
	queryAnswered = this.createAction(
		ACTION.CMDC_QUERYANSWERED,
		(payload: CMDC_QUERYANSWERED['params'], meta: TSingleEntityActionMeta<CMDC_QUERYANSWERED>) => ({
			payload: getQueryId(payload),
			meta,
		}),
	);

	queryAnswerResultReceived = this.createAction(
		ACTION.CMDP_QUERYUSER,
		(payload: CMDP_QUERYUSER_RESPONSE['values'], meta: CMDP_QUERYUSER_RESPONSE, error?: string | boolean) => ({
			payload: meta.result.code !== ResultCode.OK ? meta.result.reason : getQueryId(meta.params),
			meta: payload,
			error,
		}),
	);
}

export const queryServerToClientActions = new QueryServerToClientActions();
