import _ from 'lodash';
import * as twemoji from 'twemoji-parser';
import {IGiftTranslation, SupportedLanguage} from 'cmd-control-client-lib';

import GiftVM from '@messenger/core/src/Redux/Gifts/GiftVM';
import {replaceLegacySmileys} from '@messenger/core/src/BusinessLogic/replaceLegacySmileys';
import EnumMessageTypePart from '@messenger/core/src/BusinessLogic/EnumMessagePartType';
import IMessagePart from '@messenger/core/src/BusinessLogic/IMessagePart';
import getMessageGiftPlaceholder from '@messenger/core/src/Utils/Messages/GetMessageGiftPlaceholder';
import {EnumMessageHearts} from '@messenger/core/src/BusinessLogic/EnumMessageHearts';
import EnumSystemEmoji from '@messenger/core/src/BusinessLogic/EnumSystemEmoji';

const parseSmiliesToParts = (text: string): IMessagePart[] => {
	let message: IMessagePart[] = [];
	const lines = text.split('\n');

	_.forEach(lines, (line: string, idx: number) => {
		const isLastLine = idx === lines.length - 1;
		let offset = 0;
		/**
		 * has to be PNG as SVG are not supported by react-native Image :(
		 * @link https://reactnative.dev/docs/image#source
		 */
		const matches = twemoji.parse(line, {assetType: 'png'});

		if (_.isEmpty(matches) && line.length > 0) {
			message = message.concat({
				type: EnumMessageTypePart.TEXT,
				value: line,
			});

			if (!isLastLine) {
				message = message.concat({
					type: EnumMessageTypePart.TEXT,
					isNewLine: true,
					value: '',
				});
			}

			return;
		}

		let afterSmileText = line;

		while (!_.isEmpty(matches)) {
			const emojiEntity = matches.shift() as twemoji.EmojiEntity;
			const beforeSmileText = line.substring(offset, emojiEntity.indices[0]);

			afterSmileText = afterSmileText.substring(emojiEntity.indices[1] - offset);

			if (beforeSmileText.length > 0) {
				message.push({
					type: EnumMessageTypePart.TEXT,
					value: beforeSmileText,
				});
			}

			if (!_.isEmpty(emojiEntity.url)) {
				message.push({
					type: EnumMessageTypePart.SMILE,
					value: emojiEntity.url,
					label: emojiEntity.text,
				});
			}

			offset = offset + beforeSmileText.length + emojiEntity.text.length;
		}

		// remaining part of the input
		if (afterSmileText.length > 0) {
			message.push({
				type: EnumMessageTypePart.TEXT,
				value: afterSmileText,
			});
		}

		// append line break (if not last one)
		if (!isLastLine) {
			message = message.concat({
				type: EnumMessageTypePart.TEXT,
				isNewLine: true,
				value: '',
			});
		}
	});

	return message;
};

const replaceHeart = (text: string, heart: EnumMessageHearts): IMessagePart[] => {
	return [
		{
			type: EnumMessageTypePart.TEXT,
			value: text.substring(0, text.indexOf(heart)),
		},
		{
			type: EnumMessageTypePart.FAVORITE,
			value: heart === EnumMessageHearts.RED ? EnumSystemEmoji.RED_HEART : EnumSystemEmoji.BLACK_HEART,
		},
		{
			type: EnumMessageTypePart.TEXT,
			value: text.substring(text.indexOf(heart) + heart.length),
		},
	];
};

const getMessagePartByGift = (gift: GiftVM, currentLanguage: SupportedLanguage | string) => {
	const transIndex = _.findIndex(gift.translations, (t: IGiftTranslation) => t.language === currentLanguage);
	const germanIndex = _.findIndex(gift.translations, (t: IGiftTranslation) => t.language === SupportedLanguage.DE);

	return {
		type: EnumMessageTypePart.GIFT,
		value: gift.imageUrl,
		label: _.get(gift, ['translations', transIndex, 'value'], _.get(gift, ['translations', germanIndex, 'value'])),
		id: gift.id,
		price: gift.price,
	};
};

/**
 * @todo refactor this properly
 */
const buildMessageStructure = (
	input: string,
	messageGifts: GiftVM[],
	currentLanguage: SupportedLanguage | string = SupportedLanguage.DE,
	isDeleted = false,
	maxPartLength?: number,
): IMessagePart[] => {
	let message: IMessagePart[] = [];

	// simplified render for deleted - no need to parse smilies, etc.
	if (isDeleted) {
		_.forEach(messageGifts, (gift) => message.push(getMessagePartByGift(gift, currentLanguage)));

		return [
			...message,
			{
				type: EnumMessageTypePart.TEXT,
				value: '',
				isDeleted: true,
			},
		].map((part, partId) => ({...part, partId}));
	}

	let text = replaceLegacySmileys(input);
	const hasHeartRed = text.indexOf(EnumMessageHearts.RED) > -1;
	const hasHeartBlack = text.indexOf(EnumMessageHearts.BLACK) > -1;

	// this should only be the case for system messages
	if (hasHeartRed) {
		message = replaceHeart(text, EnumMessageHearts.RED);
		text = !_.isEmpty(messageGifts) ? _.get(message.pop(), 'value', '') : '';
	}

	if (hasHeartBlack) {
		message = replaceHeart(text, EnumMessageHearts.BLACK);
		text = !_.isEmpty(messageGifts) ? _.get(message.pop(), 'value', '') : '';
	}

	_.forEach(messageGifts, (gift) => {
		const placeholder = getMessageGiftPlaceholder(text, gift);
		const placeholderStartIndex = text.indexOf(placeholder);

		if (placeholderStartIndex > -1) {
			if (placeholderStartIndex > 0) {
				const tmpText = text.substr(0, placeholderStartIndex);

				if (tmpText.length > 0) {
					message = message.concat(parseSmiliesToParts(tmpText));
				}
			}

			message.push(getMessagePartByGift(gift, currentLanguage));
			text = text.substr(placeholderStartIndex + placeholder.length, text.length);
		}
	});

	const messageParts = (text.length > 0 ? message.concat(parseSmiliesToParts(text)) : message).map((part, partId) => ({
		...part,
		partId,
	}));

	if (_.isUndefined(maxPartLength)) {
		return messageParts;
	}

	let weight = 0;

	return _.chain(messageParts)
		.map((part: IMessagePart) => {
			if (weight >= maxPartLength) {
				return undefined;
			}

			if (part.type === EnumMessageTypePart.TEXT) {
				weight += part.value.length;
			} else {
				weight += 5;
			}

			if (weight >= maxPartLength) {
				part.value = _.truncate(part.value, {length: maxPartLength});
			}

			return part;
		})
		.compact()
		.value();
};

export default buildMessageStructure;
