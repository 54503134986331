import {createSlice} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import testVideoChatClientOnlyActions from '@messenger/core/src/Redux/TestVideoChat/Actions/testVideoChatClientOnlyActions';
import testVideoChatServerToClientActions from '@messenger/core/src/Redux/TestVideoChat/Actions/testVideoChatServerToClientActions';

export const initialState: ITestVideoChatState = {
	isOpen: false,
	clientID: undefined,
	server: undefined,
};

const testVideoChatSlice = createSlice({
	name: EnumStore.TEST_VIDEO_CHAT,
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(testVideoChatClientOnlyActions.open, (state) => {
			state.isOpen = true;
		});
		builder.addCase(testVideoChatClientOnlyActions.close, (state) => {
			state.isOpen = false;
			state.clientID = undefined;
			state.server = undefined;
		});
		builder.addCase(testVideoChatServerToClientActions.testVideoChatStarted, (state, action) => {
			state.clientID = action.payload.clientID;
			state.server = action.payload.server;
		});
	},
});

export interface ITestVideoChatState {
	isOpen: boolean;
	clientID: string | undefined;
	server: string | undefined;
}

export default testVideoChatSlice;
