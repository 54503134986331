import _ from 'lodash';
import React, {LegacyRef, Ref, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {EnumBooleanDigitized} from 'cmd-control-client-lib';

import QueryUserViewModel from '@messenger/core/src/Redux/QueryUser/QueryUserViewModel';
import {queryClientToServerActions} from '@messenger/core/src/Redux/QueryUser/Actions/queryClientToServerActions';
import {getQueryId} from '@messenger/core/src/Redux/QueryUser/entityAdapter';
import getMessageChatIds from '@messenger/core/src/Utils/Messages/getMessageChatIds';
import {selectQueriesIds} from '@messenger/core/src/Redux/QueryUser/Selectors/defaultSelectors';
import {selectQueryError} from '@messenger/core/src/Redux/QueryUser/Selectors/selectQueryError';
import {selectIsQueryLoading} from '@messenger/core/src/Redux/QueryUser/Selectors/selectIsQueryLoading';
import {useStoreSelector} from '@messenger/core/src/Hooks/useStoreSelector';

import ContentWithTimer from 'src/Components/Notifications/QueryUser/ContentWithTimer';

const ContentWithTimerContainer = React.forwardRef(({query}: TContentWithTimerContainerProps, ref: Ref<Element>) => {
	const dispatch = useDispatch();
	const isLoading = useStoreSelector(selectIsQueryLoading);
	const error = useStoreSelector(selectQueryError);
	const queryExists = _.includes(useStoreSelector(selectQueriesIds), getQueryId(query));
	const queryReply = useCallback(
		(buttonNumber: EnumBooleanDigitized, isTimeout: EnumBooleanDigitized) =>
			dispatch(
				queryClientToServerActions.queryReply(
					query.key,
					buttonNumber,
					getMessageChatIds(query),
					getQueryId(query),
					isTimeout,
					_.get(query, 'channelId'),
				),
			),
		[dispatch, query],
	);

	if (!queryExists) {
		return <span ref={ref as LegacyRef<HTMLSpanElement>} />;
	}

	return <ContentWithTimer ref={ref} query={query} error={error} queryReply={queryReply} isLoading={isLoading} />;
});

type TContentWithTimerContainerProps = {
	query: QueryUserViewModel;
};
ContentWithTimerContainer.displayName = 'ContentWithTimerContainer';
export default ContentWithTimerContainer;
