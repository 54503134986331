import {takeEvery} from 'typed-redux-saga';

import {groupsClientToServerActions} from '@messenger/core/src/Redux/Groups/Actions/groupsClientToServerActions';
import processGroupCreatedSaga from '@messenger/core/src/Redux/Groups/Sagas/processGroupCreatedSaga';
import {groupServerToClientActions} from '@messenger/core/src/Redux/Groups/Actions/groupsServerToClientActions';
import {channelGroupsServerToClientActions} from '@messenger/core/src/Redux/ChannelGroups/Actions/channelGroupsServerToClientActions';
import {checkCanReceiveAttachmentSaga} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/Sagas/checkCanReceiveAttachmentSaga';

const groupUpdatedEvents = [
	groupServerToClientActions.groupAdded,
	groupServerToClientActions.groupUpdated,
	channelGroupsServerToClientActions.removedChannelsFromGroup,
	channelGroupsServerToClientActions.addedChannelsToGroup,
];

function* groupsSagaWatcher() {
	yield* takeEvery(groupsClientToServerActions.createGroup, processGroupCreatedSaga);
	yield* takeEvery(groupUpdatedEvents, checkCanReceiveAttachmentSaga);
}

export default groupsSagaWatcher;
