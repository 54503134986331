import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import selectLoginFormState from '@messenger/core/src/Redux/LoginForm/Selectors/selectLoginFormState';
import selectShouldAutoLogin from '@messenger/core/src/Redux/LoginForm/Selectors/selectShouldAutoLogin';
import selectCanBeLoggedByCredentials from '@messenger/core/src/Redux/Session/Selectors/selectCanBeLoggedByCredentials';
import selectCanBeLoggedByForeignSID from '@messenger/core/src/Redux/Session/Selectors/selectCanBeLoggedByForeignSID';

const selectIsAutoLoginRequired = createSelector(
	[selectShouldAutoLogin, selectLoginFormState, selectCanBeLoggedByCredentials, selectCanBeLoggedByForeignSID],
	(shouldAutoLogin, loginForm, canBeLoggedByCredentials, canBeLoggedByForeignSID) =>
		_.isEmpty(loginForm.error) && shouldAutoLogin && (canBeLoggedByCredentials || canBeLoggedByForeignSID),
);

export default selectIsAutoLoginRequired;
