import {put, select} from 'typed-redux-saga';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {channelGroupsPurchaseAbilityServerToClientActions} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/Actions/channelGroupsPurchaseAbilityServerToClientActions';
import {EnumCanPurchase} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/slice';
import {selectTargetChannelIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetChannelIds';
import {channelGroupsPurchaseAbilityClientOnlyActions} from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/Actions/channelGroupsPurchaseAbilityClientOnlyActions';
import {selectTargetChannelInGroupIds} from '@messenger/core/src/Redux/Channels/Selectors/TargetChannelsSelection/selectTargetChannelInGroupIds';

const getCanPurchase = (totalCount: number, canPurchaseCount: number) => {
	switch (canPurchaseCount) {
		case 0:
			return EnumCanPurchase.NO_ONE;
		case totalCount:
			return EnumCanPurchase.ALL;
		default:
			return EnumCanPurchase.SOME;
	}
};

const processChannelGroupsPurchaseAbilitySaga = function* ({
	payload,
}: ReturnType<typeof channelGroupsPurchaseAbilityServerToClientActions.checkCanReceiveAttachmentReceived>) {
	try {
		const canPurchaseChannels = payload.canPurchaseChannels;
		const canReceiveChannels = payload.canReceiveChannels;
		const channelCount = Number(payload.channelCount);
		const canReceiveCount = _.size(canReceiveChannels);
		const canPurchaseCount = _.size(canPurchaseChannels);

		const targetChannelIds = yield* select(selectTargetChannelIds);
		const targetChannelInGroupIds = yield* select(selectTargetChannelInGroupIds);
		const allTargetChannelIds = _.union(targetChannelIds, targetChannelInGroupIds);

		const canNotPurchaseChannels = _.difference(allTargetChannelIds, canPurchaseChannels);
		const canNotReceiveChannels = _.difference(allTargetChannelIds, canReceiveChannels);

		const hasPurchased = getCanPurchase(channelCount, Number(payload.hasPurchasedCount));
		const canPurchase = getCanPurchase(channelCount, canPurchaseCount);
		const canReceiveAttachment = getCanPurchase(channelCount, canReceiveCount);

		yield* put(
			channelGroupsPurchaseAbilityClientOnlyActions.change({
				canNotPurchaseChannels,
				canNotReceiveChannels,
				hasPurchased,
				canPurchase,
				canReceiveAttachment,
				channelCount,
				canReceiveChannels,
				canPurchaseChannels,
			}),
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processChannelGroupsPurchaseAbilitySaga'});
	}
};

export default processChannelGroupsPurchaseAbilitySaga;
