import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectOnlyAdminOrVoyeurInChat} from '@messenger/core/src/Redux/Chats/Selectors/selectOnlyAdminOrVoyeurInChat';
import {selectCurrentMessageInputLength} from '@messenger/core/src/Redux/MessageInput/Selectors/selectCurrentMessageInputLength';
import selectReplyMessageId from '@messenger/core/src/Redux/Client/Selectors/selectReplyMessageId';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

import {selectCurrentGuestIdentity} from './selectCurrentGuestIdentity';

export const selectCurrentGuestIsEmptyAllChats = createSelector(
	[selectCurrentGuestIdentity, selectCurrentMessageInputLength, selectOnlyAdminOrVoyeurInChat, selectReplyMessageId],
	(guestIdentity, currentMessageInputLength, isOnlyAdminOrVoyeurInChat, replyMessageId) =>
		guestIdentity?.guestType === EnumGuestType.ALL &&
		Boolean(isOnlyAdminOrVoyeurInChat) &&
		!replyMessageId &&
		currentMessageInputLength === 0,
);
