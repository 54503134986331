import React, {Ref} from 'react';
import {batch, useDispatch} from 'react-redux';

import notificationsClientOnlyActions from '@messenger/core/src/Redux/Notifications/Actions/notificationsClientOnlyActions';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';

import MediaPurchasedContent from 'src/Components/Notifications/MediaPurchased/MediaPurchasedContent';

const MediaPurchasedContentContainer = React.forwardRef(
	(props: IMediaPurchasedContentContainerProps, ref: Ref<any>) => {
		const dispatch = useDispatch();

		const onReply = () =>
			batch(() => {
				dispatch(
					messagesClientOnlyActions.navigateToMessage({
						messageId: props.messageId,
						channelId: props.channelId,
					}),
				);

				dispatch(notificationsClientOnlyActions.closeSnackbar(props.notificationKey));
			});

		const onClose = () => {
			dispatch(notificationsClientOnlyActions.closeSnackbar(props.notificationKey));
		};

		return <MediaPurchasedContent ref={ref} onReply={onReply} message={props.message} onClose={onClose} />;
	},
);

interface IMediaPurchasedContentContainerProps {
	message: string;
	messageId: string;
	channelId: string;
	notificationKey: string;
}

export default MediaPurchasedContentContainer;
