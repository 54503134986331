import _ from 'lodash';

import {isTemporaryMessageId, messageAdapter} from '@messenger/core/src/Redux/Messages/entityAdapter';
import IRootState from '@messenger/core/src/Redux/IRootState';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {TDefaultSelectors} from '@messenger/core/src/Redux/TDefaultSelectors';
import {SswMessageType} from '@messenger/core/src/Redux/Messages/Model';
import {createSelector} from '@messenger/core/src/Utils/Redux';
import {getMessageVMCached, getMessageVMDependencies} from '@messenger/core/src/Redux/Messages/getMessageVMCached';
import {selectGiftEntityVMs} from '@messenger/core/src/Redux/Gifts/Selectors/defaultSelectors';
import {selectMessageMediaUploads} from '@messenger/core/src/Redux/Messages/Selectors/selectMessageMediaUploads';
import {selectFailedToSentMessagesData} from '@messenger/core/src/Redux/Messages/Selectors/selectFailedToSentMessagesData';
import {selectAdminChats} from '@messenger/core/src/Redux/Chats/Selectors/selectAdminChats';
import {selectChannelEntityVMs} from '@messenger/core/src/Redux/Channels/Selectors/defaultSelectors';
import {selectRunningChatVMs} from '@messenger/core/src/Redux/Chats/Selectors/selectRunningChatVMs';
import {selectSentMessageKeys} from '@messenger/core/src/Redux/Messages/Selectors/selectSentMessageKeys';
import MessageHelper from '@messenger/core/src/BusinessLogic/MessagesDisplayFilters/MessageHelper';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TMessageEntityId = string & {__messageEntityIdBrand: any};

export const getMessageIdForSelector = (messageId?: string) => messageId as TMessageEntityId;

const {selectIds, selectEntities, selectAll, selectById} = messageAdapter.getSelectors<IRootState>(
	(state) => state[EnumStore.MESSAGES],
) as TDefaultSelectors<SswMessageType, TMessageEntityId>;

export const selectMessageIds = selectIds;
export const selectTotalMessagesGroupedByChannelId = createSelector(selectEntities, (messages) =>
	_.countBy(messages, 'channelId'),
);

export const selectMessageKeyToIds = createSelector(selectAll, (messages) =>
	_.chain(messages)
		.reduce((result: Record<string, string[]>, message) => {
			const messageKey = _.get(message, 'messageKey', '');
			const messageId = _.get(message, 'messageId', '');

			if (
				!MessageHelper.isSystem(message) &&
				!_.isEmpty(messageId) &&
				!_.isEmpty(messageKey) &&
				!isTemporaryMessageId(messageId)
			) {
				if (!_.has(result, messageKey)) {
					result[messageKey] = [];
				}

				result[messageKey].push(messageId);
			}

			return result;
		}, {})
		.pickBy((messageIdList) => _.size(messageIdList) > 1)
		.value(),
);

export const selectMessageVMById = createSelector(
	[
		selectById,
		selectGiftEntityVMs,
		selectMessageMediaUploads,
		selectFailedToSentMessagesData,
		selectChannelEntityVMs,
		selectSentMessageKeys,
		selectMessageKeyToIds,
		selectRunningChatVMs,
		selectAdminChats,
	],
	(
		message,
		giftEntities,
		mediaUploads,
		failedToSentMessagesData,
		channelVMs,
		sentMessageKeys,
		messageKeyToIds,
		runningChatVMs,
		adminChatVMs,
	) =>
		message
			? getMessageVMCached(
					message,
					getMessageVMDependencies(
						message,
						giftEntities,
						mediaUploads,
						failedToSentMessagesData,
						channelVMs,
						sentMessageKeys,
						messageKeyToIds,
						runningChatVMs,
						adminChatVMs,
					),
			  )
			: undefined,
);

export const selectMessageVMs = createSelector(
	[
		selectAll,
		selectGiftEntityVMs,
		selectMessageMediaUploads,
		selectFailedToSentMessagesData,
		selectChannelEntityVMs,
		selectSentMessageKeys,
		selectMessageKeyToIds,
		selectRunningChatVMs,
		selectAdminChats,
	],
	(
		messages,
		giftEntities,
		mediaUploads,
		failedToSentMessagesData,
		channelVMs,
		sentMessageKeys,
		messageKeyToIds,
		runningChatVMs,
		adminChatVMs,
	) =>
		_.chain(messages)
			.map((message) =>
				getMessageVMCached(
					message,
					getMessageVMDependencies(
						message,
						giftEntities,
						mediaUploads,
						failedToSentMessagesData,
						channelVMs,
						sentMessageKeys,
						messageKeyToIds,
						runningChatVMs,
						adminChatVMs,
					),
				),
			)
			.compact()
			.value(),
);
