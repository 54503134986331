/**
 * command names that CmdControl can handle
 */
export enum ACTION {
  CMDP_INIT = "CMDP_INIT",
  CMDP_SINIT = "CMDP_SINIT",
  CMDP_LOGIN = "CMDP_LOGIN",
  CMDP_SLOGIN = "CMDP_SLOGIN",
  CMDP_LOGOUT = "CMDP_LOGOUT",
  CMDP_SLOGOUT = "CMDP_SLOGOUT",
  CMDC_USERINFO = "CMDC_USERINFO",
  CMDP_SETUSERINFO = "CMDP_SETUSERINFO",
  CMDP_GETUSERINFO = "CMDP_GETUSERINFO",
  CMDC_CONTACTNOTE = "CMDC_CONTACTNOTE",
  CMDP_SETCONTACTNOTE = "CMDP_SETCONTACTNOTE",
  CMDP_SSETCONTACTNOTE = "CMDP_SSETCONTACTNOTE",
  CMDP_GETCONTACTNOTE = "CMDP_GETCONTACTNOTE",
  CMDP_SGETCONTACTNOTE = "CMDP_SGETCONTACTNOTE",
  CMDC_CONTACTINFO = "CMDC_CONTACTINFO",
  CMDP_SETCONTACTINFO = "CMDP_SETCONTACTINFO",
  CMDP_SGETCONTACTINFO = "CMDP_SGETCONTACTINFO",
  CMDP_GETCONTACTINFO = "CMDP_GETCONTACTINFO",
  CMDC_CHANNELINFO = "CMDC_CHANNELINFO",
  CMDP_GETCHANNELINFO = "CMDP_GETCHANNELINFO",
  CMDP_SETCHANNELINFO = "CMDP_SETCHANNELINFO",
  CMDC_CHANNEL = "CMDC_CHANNEL",
  CMDP_NEWCHANNEL = "CMDP_NEWCHANNEL",
  CMDP_GETCHANNELS = "CMDP_GETCHANNELS",
  CMDP_SGETCHANNELS = "CMDP_SGETCHANNELS",
  CMDP_GETCHANNEL = "CMDP_GETCHANNEL",
  CMDP_SGETCHANNEL = "CMDP_SGETCHANNEL",
  CMDP_CHANNELSEEN = "CMDP_CHANNELSEEN",
  CMDP_SCHANNELSEEN = "CMDP_SCHANNELSEEN",
  CMDP_UPDATECHANNEL = "CMDP_UPDATECHANNEL",
  CMDC_CHANNELSSUMMARY = "CMDC_CHANNELSSUMMARY",
  CMDP_CHANNELSSUMMARY = "CMDP_CHANNELSSUMMARY",
  CMDP_SUPLOADPIC = "CMDP_SUPLOADPIC",
  CMDC_CMSG = "CMDC_CMSG",
  CMDP_GETHISTORY = "CMDP_GETHISTORY",
  CMDP_MSG = "CMDP_MSG",
  CMDP_MSGDELETE = "CMDP_MSGDELETE",
  CMDC_ONLINESTATE = "CMDC_ONLINESTATE",
  CMDP_GETONLINESTATE = "CMDP_GETONLINESTATE",
  CMDP_GETONLINESTATES = "CMDP_GETONLINESTATES",
  CMDC_VALUE = "CMDC_VALUE",

  /* LIVE, VideoSession commandsv*/
  CMDP_SET_DISCOUNT = "CMDP_SET_DISCOUNT",
  CMDP_SQUERY_DISCOUNT = "CMDP_SQUERY_DISCOUNT",
  CMDP_QUERYUSER = "CMDP_QUERYUSER",
  CMDC_QUERYANSWERED = "CMDC_QUERYANSWERED",
  CMDP_SQUERY_FORMAT = "CMDP_SQUERY_FORMAT",
  CMDP_STOPLOOPBACK9 = "CMDP_STOPLOOPBACK9",
  CMDP_STARTLOOPBACK9 = "CMDP_STARTLOOPBACK9",
  CMDP_STOPBANDWIDTHTEST = "CMDP_STOPBANDWIDTHTEST",
  CMDP_STARTBANDWIDTHTEST = "CMDP_STARTBANDWIDTHTEST",
  CMDP_STOP_STREAM_PREVIEW = "CMDP_STOP_STREAM_PREVIEW",
  CMDP_START_STREAM_PREVIEW = "CMDP_START_STREAM_PREVIEW",
  CMDP_UPDATE = "CMDP_UPDATE",
  CMDP_SGETCHATS = "CMDP_SGETCHATS",
  CMDP_FSK16 = "CMDP_FSK16",
  CMDP_KICK = "CMDP_KICK",
  CMDP_TRACERT_REPLY = "CMDP_TRACERT_REPLY",
  CMDP_STOPSEND = "CMDP_STOPSEND",
  CMDP_STARTSEND = "CMDP_STARTSEND",
  CMDP_SERVICE0900 = "CMDP_SERVICE0900",
  /*special heartbeat command*/
  CMDP_NOOP = "CMDP_NOOP",
  CMDP_QUERYBACKEND = "CMDP_QUERYBACKEND",
  CMDP_UNDEFINED = "CMDP_UNDEFINED",
  /*VideoChat server commands*/
  CMDC_CINIT = "CMDC_CINIT",
  CMDC_FEEDBACK = "CMDC_FEEDBACK",
  CMDC_DSRELOAD = "CMDC_DSRELOAD",
  CMDC_UPDATE = "CMDC_UPDATE",
  CMDC_CEXIT = "CMDC_CEXIT",
  CMDC_CSTARTSTREAMPUSH = "CMDC_CSTARTSTREAMPUSH",
  CMDC_CSTOPVIDEOPUSH = "CMDC_CSTOPVIDEOPUSH",
  CMDC_QUERYUSER = "CMDC_QUERYUSER",
  CMDC_CQUERYUSER = "CMDC_CQUERYUSER",
  CMDC_UPDATE_CHAT = "CMDC_UPDATE_CHAT",
  CMDC_BACKENDPAYLOAD = "CMDC_BACKENDPAYLOAD",
  CMDP_BACKENDPAYLOAD = "CMDP_BACKENDPAYLOAD",
  CMDP_TOYSTATE = "CMDP_TOYSTATE",
  CMDP_TOYACTION = "CMDP_TOYACTION",
  CMDC_TOYACTION = "CMDC_TOYACTION",
  CMDC_CSTREAMRESTART = "CMDC_CSTREAMRESTART",
  CMDP_SUPLOADMEDIA = "CMDP_SUPLOADMEDIA",
  /** Commands used on Live but not mentioned here **/
  CMDC_CSTOPAUDIOPUSH = "CMDC_CSTOPAUDIOPUSH",
  CMDC_CSTARTAUDIOPUSH = "CMDC_CSTARTAUDIOPUSH",
  CMDP_CSTOPTEXT = "CMDP_CSTOPTEXT",
  CMDC_CSTARTVIDEOPULL = "CMDC_CSTARTVIDEOPULL",
  CMDC_CSTARTVOYEUR = "CMDC_CSTARTVOYEUR",
  CMDC_CSTOPVIDEOPULL = "CMDC_CSTOPVIDEOPULL",
  CMDC_CSTOPVOYEUR = "CMDC_CSTOPVOYEUR",
  CMDP_SUPDATE = "CMDP_SUPDATE",
  CMDC_CSHOWADMIN = "CMDC_CSHOWADMIN",
  CMDC_CSTOPSTREAMPUSH = "CMDC_CSTOPSTREAMPUSH",
  CMDP_SSTATUS = "CMDP_SSTATUS",
  CMDC_CSTARTTEXT = "CMDC_CSTARTTEXT",
  CMDC_CSTARTVIDEOPUSH = "CMDC_CSTARTVIDEOPUSH",
  CMDC_SLOGOUT = "CMDC_SLOGOUT",
  CMDP_SDEVICESTATUS = "CMDP_SDEVICESTATUS",
  CMDC_STREAMSTATE = "CMDC_STREAMSTATE",
  CMDP_GETONLINESUMMARY = "CMDP_GETONLINESUMMARY",
  CMDP_SMEDIAGETPRICES = "CMDP_SMEDIAGETPRICES",
  CMDP_SMEDIAPURCHASE = "CMDP_SMEDIAPURCHASE",
  CMDP_OPEN_LOBBY = "CMDP_OPEN_LOBBY",
  CMDP_CLOSE_LOBBY = "CMDP_CLOSE_LOBBY",
  CMDP_SMEDIAOFFERCREATE = "CMDP_SMEDIAOFFERCREATE",
  CMDP_SINFORMMEDIAPURCHASED = "CMDP_SINFORMMEDIAPURCHASED",
  CMDP_SETEXPOTOKEN = "CMDP_SETEXPOTOKEN",
  CMDP_SGETCHATHISTORY = "CMDP_SGETCHATHISTORY",
  CMDP_SETSETTINGS = "CMDP_SETSETTINGS",
  CMDP_GETSETTINGS = "CMDP_GETSETTINGS",
  CMDC_MEDIAPURCHASED = "CMDC_MEDIAPURCHASED",
  CMDP_SGETMEDIAINFO = "CMDP_SGETMEDIAINFO",
  CMDP_SIGNUP = "CMDP_SIGNUP",
  CMDP_RESETPASSWORD = "CMDP_RESETPASSWORD",
  CMDP_SQUERYSTARTSINGLEC2C = "CMDP_SQUERYSTARTSINGLEC2C",
  CMDP_SOPENMEDIAOFFER = "CMDP_SOPENMEDIAOFFER",
  CMDP_SCLOSEMEDIAOFFER = "CMDP_SCLOSEMEDIAOFFER",
  CMDP_SSTARTTESTVIDEOCHAT = "CMDP_SSTARTTESTVIDEOCHAT",
  CMDP_SGETTICKETS = "CMDP_SGETTICKETS",
  CMDP_SCHECKUPLOADEDMEDIA = "CMDP_SCHECKUPLOADEDMEDIA",
  CMDP_SGETUPLOADEDMEDIA = "CMDP_SGETUPLOADEDMEDIA",
  CMDC_CMEDIA = "CMDC_CMEDIA",
  CMDP_SCLOSETICKET = "CMDP_SCLOSETICKET",
  CMDP_SGETMSETTINGS = "CMDP_SGETMSETTINGS",
  CMDP_SSETMSETTING = "CMDP_SSETMSETTING",
  CMDC_MSETTINGS = "CMDC_MSETTINGS",
  CMDP_SDELETE_USER = "CMDP_SDELETE_USER",
  CMDC_GROUP = "CMDC_GROUP",
  CMDC_CHANNELGROUP = "CMDC_CHANNELGROUP",
  CMDP_SGETGROUPS = "CMDP_SGETGROUPS",
  CMDP_SADDGROUP = "CMDP_SADDGROUP",
  CMDP_SUPDATEGROUP = "CMDP_SUPDATEGROUP",
  CMDP_SGETGROUPCHANNELS = "CMDP_SGETGROUPCHANNELS",
  CMDP_SGETCHANNELGROUPS = "CMDP_SGETCHANNELGROUPS",
  CMDP_SADDCHANNELTOGROUP = "CMDP_SADDCHANNELTOGROUP",
  CMDP_SDELCHANNELFROMGROUP = "CMDP_SDELCHANNELFROMGROUP",
  CMDC_CTICKETSHOW = "CMDC_CTICKETSHOW",
  CMDP_SGETTICKETSHOWS = "CMDP_SGETTICKETSHOWS",
  CMDP_SUPSERTTICKETSHOW = "CMDP_SUPSERTTICKETSHOW",
  CMDP_SDELETETICKETSHOW = "CMDP_SDELETETICKETSHOW",
  CMDP_SSTARTTICKETSHOW = "CMDP_SSTARTTICKETSHOW",
  CMDP_SSTOPTICKETSHOW = "CMDP_SSTOPTICKETSHOW",
  CMDP_SGETCHATSSUMMARY = "CMDP_SGETCHATSSUMMARY",
  CMDP_GETLIVEHISTORY = "CMDP_GETLIVEHISTORY",
  CMDC_LIVESESSION = "CMDC_LIVESESSION",
  CMDC_LIVECHAT = "CMDC_LIVECHAT",
  CMDC_CHANNELMEDIASUMMARY = "CMDC_CHANNELMEDIASUMMARY",
  CMDP_SGETCHANNELDMEDIASUMMARY = "CMDP_SGETCHANNELDMEDIASUMMARY",
  CMDP_SGETSEDCARDPICTURES = "CMDP_SGETSEDCARDPICTURES",
  CMDC_SEDCARDPICTURE = "CMDC_SEDCARDPICTURE",
  CMDP_CSTATUS = "CMDP_CSTATUS",
  CMDP_CINIT = "CMDP_CINIT",
  CMDP_SMSGREACTION = "CMDP_SMSGREACTION",
  CMDC_SSTATUS = "CMDC_SSTATUS",
  CMDP_SGETMESSAGEHISTORY = "CMDP_SGETMESSAGEHISTORY",
  CMDP_SMSGESTIMATE = "CMDP_SMSGESTIMATE",
  CMDP_SETMOBILETOKEN = "CMDP_SETMOBILETOKEN",
  CMDP_STARTMOBILEVIDEOCALL = "CMDP_STARTMOBILEVIDEOCALL",
  CMDC_STARTMOBILEVIDEOCALL = "CMDC_STARTMOBILEVIDEOCALL",
  CMDP_UPDATEMOBILEVIDEOCALL = "CMDP_UPDATEMOBILEVIDEOCALL",
  CMDC_UPDATEMOBILEVIDEOCALL = "CMDC_UPDATEMOBILEVIDEOCALL",
  CMDC_STARTWEBSIP = "CMDC_STARTWEBSIP",
  CMDC_STOPWEBSIP = "CMDC_STOPWEBSIP",
  CMDP_WSA_ADDUPDATEUSER = "CMDP_WSA_ADDUPDATEUSER",
  CMDP_WSA_DELETEUSER = "CMDP_WSA_DELETEUSER",
  CMDP_WSA_GET_USERS = "CMDP_WSA_GET_USERS",
  CMDC_WSA_USER = "CMDC_WSA_USER",
  CMDP_WSA_GET_CLIENTS = "CMDP_WSA_GET_CLIENTS",
  CMDC_WSA_CLIENT = "CMDC_WSA_CLIENT",
  CMDP_WSA_HEARTBEAT = "CMDP_WSA_HEARTBEAT",
  CMDP_DEEPL = "CMDP_DEEPL",
  CMDP_SGETSTREAMPARAMS = "CMDP_SGETSTREAMPARAMS",
  CMDP_LOGOUTOTHERMOBILE = "CMDP_LOGOUTOTHERMOBILE",
  CMDC_MVCSCHEDULE = "CMDC_MVCSCHEDULE",
  CMDP_SGETMVCSCHEDULE = "CMDP_SGETMVCSCHEDULE",
  CMDP_SUPSERTMVCSCHEDULE = "CMDP_SUPSERTMVCSCHEDULE",
  CMDP_SDELETEMVCSCHEDULE = "CMDP_SDELETEMVCSCHEDULE",
}
