import {call} from 'typed-redux-saga';
import {PayloadAction} from '@reduxjs/toolkit';

import {SswMessageType} from '@messenger/core/src/Redux/Messages/Model';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {cancelTicketShowSaga} from '@messenger/web/src/Redux/TicketShow/Sagas/cancelTicketShowSaga';

const h_ticket_show_cancelled = 'h_ticket_show_cancelled';

export const checkCanceledTicketShowSaga = function* (action: PayloadAction<SswMessageType>) {
	try {
		const message = action.payload;

		if (message.messageKey === h_ticket_show_cancelled) {
			yield* call(cancelTicketShowSaga);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'checkCanceledTicketShowSaga', action});
	}
};
