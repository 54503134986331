import {takeEvery} from 'typed-redux-saga';

import {abuseReportClientOnlyActions} from '@messenger/core/src/Redux/AbuseReport/Actions/abusereportClientOnlyActions';
import {SendAbuseReportSaga} from '@messenger/core/src/Redux/AbuseReport/Sagas/SendAbuseReportSaga';

function* feedbackSagaWatcher() {
	yield* takeEvery(abuseReportClientOnlyActions.send.type, SendAbuseReportSaga);
}

export default feedbackSagaWatcher;
