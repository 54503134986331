import _ from 'lodash';
import {ParametricSelector} from '@reduxjs/toolkit';

import IRootState from '@messenger/core/src/Redux/IRootState';

const getPropsTargetChannelIds: ParametricSelector<IRootState, {targetChannelIds?: string[]}, string[] | undefined> = (
	_state: IRootState,
	props?: {targetChannelIds?: string[]},
) => (_.isUndefined(props) ? undefined : props.targetChannelIds);

export default getPropsTargetChannelIds;
