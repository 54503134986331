import {ACTION, EnumMediaType, EnumQueryBackendSubAction, EnumStreamStatusType} from 'cmd-control-client-lib';

import {ClientToServerActions} from '@messenger/core/src/Actions/ActionCreator';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import IBrowserCapabilities from '@messenger/core/src/Redux/Stream/IBrowserCapabilities';

class StreamClientToServerActions extends ClientToServerActions<EnumStore.STREAM> {
	readonly scope = EnumStore.STREAM;

	startSend = this.createAction(ACTION.CMDP_STARTSEND, (capabilities?: IBrowserCapabilities) => ({
		payload: {
			capabilities: JSON.stringify(capabilities),
		},
	}));

	stopSend = this.createAction(ACTION.CMDP_STOPSEND, (streamError?: boolean) => ({
		payload: {
			streamError,
		},
	}));

	startPrivateTicketShow = this.createAction(ACTION.CMDP_SQUERYSTARTSINGLEC2C, (channelId: string) => ({
		payload: {
			channelId,
		},
	}));

	stopPrivateTicketShow = this.createAction(ACTION.CMDP_SCLOSEMEDIAOFFER, (channelId: string) => ({
		payload: {
			channelId,
			mediaType: EnumMediaType.TICKET,
		},
	}));

	startPrivateShow = this.createAction(ACTION.CMDP_SQUERYSTARTSINGLEC2C, (channelId: string, messageId: string) => ({
		payload: {
			channelId: channelId,
			messageId: messageId,
			subactiion: EnumQueryBackendSubAction.START_PRIVATE_SHOW,
		},
	}));

	sendStreamStats = this.createAction(ACTION.CMDP_SSTATUS, (stats: RTCStatsReport) => {
		const result: RTCStats[] = [];

		stats.forEach((report: RTCStats) => {
			result.push(report);
		});

		return {
			payload: {
				type: EnumStreamStatusType.WEBRTC_STATS,
				value: JSON.stringify(result),
			},
		};
	});

	sendPowerEstimation = this.createAction(ACTION.CMDP_SGETSTREAMPARAMS, (powerEstimation: string) => ({
		payload: {
			powerEstimation,
		},
	}));
}

export const streamClientToServerActions = new StreamClientToServerActions();
