import _ from 'lodash';
import {ACTION, EnumIntentionString} from 'cmd-control-client-lib';
import {call, put, select} from 'typed-redux-saga';

import selectVisibleChatsLimit from '@messenger/core/src/Redux/Chats/Selectors/selectVisibleChatsLimit';
import selectRunningChatsCount from '@messenger/core/src/Redux/Chats/Selectors/selectRunningChatsCount';
import {selectAllExitedChatsVms} from '@messenger/core/src/Redux/Chats/Selectors/selectAllExitedChatsVms';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {chatsClientOnlyActions} from '@messenger/core/src/Redux/Chats/Actions';
import {updateChatSummarySaga} from '@messenger/core/src/Redux/ChatSummary/Sagas/updateChatSummarySaga';
import {ALL_CHATS} from '@messenger/core/src/BusinessLogic/Constants';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';
import {selectCurrentGuestIdentity} from '@messenger/core/src/Redux/Client/Selectors/CurrentGuest/selectCurrentGuestIdentity';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';
import {selectMessageInputById} from '@messenger/core/src/Redux/MessageInput/Selectors/defaultSelectors';

export function* processBatchUpsertSaga(action: ReturnType<typeof chatsClientOnlyActions.upsertMany>) {
	try {
		const exitedChatIds = _.chain(action.meta.commands)
			.filter({action: ACTION.CMDC_CEXIT})
			.map('params.chatID')
			.value();

		const unseenChatIds = _.chain(action.meta.commands)
			.filter({action: ACTION.CMDC_CINIT})
			.map('params.chatID')
			.value();

		const exitedChannelIds = _.chain(action.meta.commands)
			.filter(
				(command) =>
					command.action === ACTION.CMDC_CEXIT && _.get(command, 'params.chattype') !== EnumIntentionString.VOYEUR,
			)
			.map('params.channelId')
			.value();

		const guestIdentity = yield* select(selectCurrentGuestIdentity);
		const messageInput = yield* select(selectMessageInputById, ALL_CHATS);

		if (messageInput?.value) {
			const initChatChannelIds = _.chain(action.meta.commands)
				.filter({action: ACTION.CMDC_CINIT})
				.map('params.channelId')
				.compact()
				.value();

			if (!_.isEmpty(initChatChannelIds)) {
				const allExitedChatsVMs = yield* select(selectAllExitedChatsVms);

				const chatIdsToRemove = _.reduce(
					allExitedChatsVMs,
					(arr: string[], chat) => {
						if (_.includes(initChatChannelIds, chat.channelId)) {
							arr.push(chat.chatId);
						}

						return arr;
					},
					[],
				);

				if (!_.isEmpty(chatIdsToRemove)) {
					yield* put(chatsClientOnlyActions.removeMany(chatIdsToRemove));
				}
			}
		} else {
			yield* put(chatsClientOnlyActions.removeMany(exitedChatIds));
		}

		const visibleChatsLimit = yield* select(selectVisibleChatsLimit);
		const runningChatsCount = yield* select(selectRunningChatsCount);

		/**
		 * @note: new guest should be processed only when quests counter lower than initial
		 * visibility limit. Other cases are processed in component, using visibility sensor
		 */
		const guestEnteredChat =
			visibleChatsLimit < runningChatsCount && visibleChatsLimit < ServiceFactory.env.getInitialLimitOfVisibleChats();
		const guestLeftChat = visibleChatsLimit > runningChatsCount;

		if (guestLeftChat || guestEnteredChat) {
			yield* put(
				chatsClientOnlyActions.setVisibleChatsLimit(
					Math.min(runningChatsCount, ServiceFactory.env.getInitialLimitOfVisibleChats()),
				),
			);
		}

		if (guestIdentity?.guestType === EnumGuestType.CHAT && _.includes(exitedChatIds, guestIdentity.chatId)) {
			const {goToChatMessages} = yield* getNavigationContext();

			yield* call(goToChatMessages, ALL_CHATS);
		}

		if (!_.isEmpty(unseenChatIds)) {
			yield* put(chatsClientOnlyActions.markUnseen(unseenChatIds));
		}

		if (!_.isEmpty(exitedChatIds)) {
			yield* put(chatsClientOnlyActions.markSeen(exitedChatIds));
			yield* put(chatsClientOnlyActions.pruneExitedAdminChats(exitedChatIds));
		}

		yield* call(updateChatSummarySaga, exitedChannelIds);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processBatchUpsertSaga'});
	}
}
