import {SupportedLanguage} from 'cmd-control-client-lib';

export const getLocaleByLanguage = (language: SupportedLanguage | string) => {
	switch (language) {
		case SupportedLanguage.EN:
			return 'en-US';

		case SupportedLanguage.ES:
			return 'es-ES';

		default:
		case SupportedLanguage.DE:
			return 'de-DE';
	}
};
