import {put, select} from 'typed-redux-saga';
import _ from 'lodash';

import {attachmentClientOnlyActions} from '@messenger/core/src/Redux/Attachment/Actions';
import {selectMediaPricesVmForTargetChannelIds} from '@messenger/core/src/Redux/MediaPrice/Selectors/selectMediaPricesVmForTargetChannelIds';
import {selectAttachmentVMById} from '@messenger/core/src/Redux/Attachment/Selectors/selectAttachmentVMById';

export const updateAttachmentPriceSaga = function* ({
	payload: {attachmentId, price},
}: ReturnType<typeof attachmentClientOnlyActions.updateAttachmentPrice>) {
	const attachment = yield* select(selectAttachmentVMById, {attachmentId});
	const prices = yield* select(selectMediaPricesVmForTargetChannelIds);

	if (_.isUndefined(attachment) || attachment.price === price) {
		return;
	}

	const {mediaType} = attachment;

	if (!_.isUndefined(prices)) {
		yield* put(
			attachmentClientOnlyActions.update({
				isPriceValid: prices.isValid(price, mediaType),
				price,
				currency: prices.currency,
				attachmentId,
			}),
		);
	}
};
