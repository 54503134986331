import {createSlice, EntityState} from '@reduxjs/toolkit';
import _ from 'lodash';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {channelMediaEntityAdapter, TChannelMedia} from '@messenger/core/src/Redux/ChannelMedia/entityAdapter';
import {channelMediaClientOnlyActions} from '@messenger/core/src/Redux/ChannelMedia/Actions/channelMediaClientOnlyActions';
import {channelMediaClientToServerActions} from '@messenger/core/src/Redux/ChannelMedia/Actions/channelMediaClientToServerActions';
import {channelMediaServerToClientActions} from '@messenger/core/src/Redux/ChannelMedia/Actions';

export type TChannelMediaState = EntityState<TChannelMedia> & {
	checkingChannelIds: string[];
};

export const initialChannelMediaState = channelMediaEntityAdapter.getInitialState({
	checkingChannelIds: [] as string[],
});

const channelMediaSlice = createSlice({
	name: EnumStore.CHANNEL_MEDIA,
	initialState: initialChannelMediaState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(channelMediaClientOnlyActions.addChannelMedia, channelMediaEntityAdapter.upsertMany);
		builder.addCase(channelMediaServerToClientActions.mediaPurchased, channelMediaEntityAdapter.upsertOne);
		builder.addCase(channelMediaClientOnlyActions.deleteChannelMedia, channelMediaEntityAdapter.removeMany);
		builder.addCase(channelMediaClientToServerActions.checkUploadedMedia, (state, action) => {
			state.checkingChannelIds = _.uniq([...state.checkingChannelIds, ..._.split(action.payload.channelId, ',')]);
		});
		builder.addCase(channelMediaServerToClientActions.uploadedMediaChecked, (state, action) => {
			state.checkingChannelIds = _.difference(state.checkingChannelIds, _.split(action.meta.params.channelId, ','));
		});
		builder.addCase(channelMediaClientOnlyActions.resetStore, () => initialChannelMediaState);
	},
});

export default channelMediaSlice;
