import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import IRootState from '@messenger/core/src/Redux/IRootState';
import {mediaEntityAdapter} from '@messenger/core/src/Redux/Media/entityAdapter';

export const {
	selectIds: selectMediaIds,
	selectEntities: selectMediaEntities,
	selectAll: selectAllMedia,
	selectById: selectMediaById,
} = mediaEntityAdapter.getSelectors<IRootState>((state) => state[EnumStore.MEDIA]);
