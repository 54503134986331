import {channelIdFilter} from 'cmd-control-client-lib';

const userPossibilitiesFilters: Array<keyof channelIdFilter> = [
	'filterCanReceiveImage',
	'filterCanReceiveVideo',
	'filterCanReceiveAudio',
	'filterCanReceiveTicket',
	'filterCanPurchaseMedia',
];

export default userPossibilitiesFilters;
