import {call, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumLocalStorageKeys} from '@messenger/core/src/BusinessLogic/EnumLocalStorageKeys';
import EnumStoreType from '@messenger/core/src/BusinessLogic/EnumStoreType';
import {selectSessionUsrKey} from '@messenger/core/src/Redux/Session/Selectors/selectSessionUsrKey';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';

function* storeFontSizeSaga(action: ReturnType<typeof clientClientOnlyActions.setChatFontSize>) {
	try {
		const modelId = yield* select(selectSessionUsrKey);
		const storageKey = `${EnumLocalStorageKeys.CHAT_FONT_SIZE}:${modelId}`;
		const api = ServiceFactory.store;

		yield* call([api, api.set], storageKey, action.payload, {storeType: EnumStoreType.LOCAL});
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'storeFontSizeSaga'});
	}
}

export default storeFontSizeSaga;
