import {takeEvery} from 'typed-redux-saga';

import {introductionClientOnlyActions} from '@messenger/core/src/Redux/Introduction/Action/IntroductionClientOnlyActions';
import {messagesClientOnlyActions} from '@messenger/core/src/Redux/Messages/Actions';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';

import {beforeIntroStartedSaga} from 'src/Redux/Introduction/Saga/beforeIntroStartedSaga';
import appendDataToStoreForStepSaga from 'src/Redux/Introduction/Saga/appendDataToStoreForStepSaga';
import {appendMessagesStepsSaga} from 'src/Redux/Introduction/Saga/appendMessagesStepsSaga';
import manipulateUIOnUserInteractionsSaga from 'src/Redux/Introduction/Saga/manipulateUIOnUserInteractionsSaga';
import appendJohnDoeChannelStepSaga from 'src/Redux/Introduction/Saga/appendJohnDoeChannelStepSaga';
import appendIntroStepToReplyMessage from 'src/Redux/Introduction/Saga/appendIntroStepToReplyMessageSaga';
import switchStepToReplyMessage from 'src/Redux/Introduction/Saga/switchStepToReplyMessageSaga';
import cleanUpStepsAndNotifyOtherStores from 'src/Redux/Introduction/Saga/cleanUpStepsAndNotifyOtherStoresSaga';
import beforeStepSaga from 'src/Redux/Introduction/Saga/beforeStepSaga';

function* introductionSagaWatcher() {
	yield* takeEvery(introductionClientOnlyActions.notifyStarted.type, beforeIntroStartedSaga);
	yield* takeEvery(introductionClientOnlyActions.goToStep.type, appendDataToStoreForStepSaga);
	yield* takeEvery(introductionClientOnlyActions.goToStep.type, appendMessagesStepsSaga);
	yield* takeEvery(clientClientOnlyActions.notifyUserMenuOpened.type, manipulateUIOnUserInteractionsSaga);
	yield* takeEvery(introductionClientOnlyActions.notifyChannelRendered.type, appendJohnDoeChannelStepSaga);
	yield* takeEvery(messagesClientOnlyActions.appendMessage.type, appendIntroStepToReplyMessage);
	yield* takeEvery(introductionClientOnlyActions.appendStep.type, switchStepToReplyMessage);
	yield* takeEvery(introductionClientOnlyActions.notifyFinished.type, cleanUpStepsAndNotifyOtherStores);
	yield* takeEvery(introductionClientOnlyActions.beforeStep.type, beforeStepSaga);
}

export default introductionSagaWatcher;
