import { ACTION, baseParamsType, ICOMMAND, IKeyMaybeValue, IRESPONSE, RESULT } from "../cmd-protocol";
import { channelIdType } from "./channel";
import { Ticket, MessageId } from "./message";

/**
 * frontend command to start video cam2cam call
 */
export class CMDP_SQUERYSTARTSINGLEC2C implements ICOMMAND {
  public action = ACTION.CMDP_SQUERYSTARTSINGLEC2C;
  public params: baseParamsType &
    channelIdType &
    /** the message id of related media offer */
    MessageId &
    /** MediaFile is added to proxy call from Cmdontrol to B2B backend */
    Ticket;
}

/**
 * Response command for CMDP_SQUERYSTARTSINGLEC2C.
 */
export class CMDP_SQUERYSTARTSINGLEC2C_RESPONSE extends CMDP_SQUERYSTARTSINGLEC2C implements IRESPONSE {
  public result: RESULT;
  public commands: ICOMMAND[];
  public values: IKeyMaybeValue;
}
