import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import selectLoginFormState from '@messenger/core/src/Redux/LoginForm/Selectors/selectLoginFormState';

const selectCanBeLoggedByCredentials = createSelector(
	[selectLoginFormState],
	(loginForm) => loginForm.rememberMe && (!_.isEmpty(loginForm.password) || !_.isEmpty(loginForm.passwordHash)),
);

export default selectCanBeLoggedByCredentials;
