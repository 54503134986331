import 'reflect-metadata';
import _ from 'lodash';
import {inject, singleton} from 'tsyringe';
import type {AnyAction, Dispatch, EntityId} from '@reduxjs/toolkit';

import notificationsClientOnlyActions from '@messenger/core/src/Redux/Notifications/Actions/notificationsClientOnlyActions';
import DIToken from '@messenger/core/src/BusinessLogic/DIToken';
import EnvInterfaceService from '@messenger/core/src/Services/EnvInterfaceService';
import {
	EnumAbstractNotificationVariant,
	TAbstractNotificationOptions,
} from '@messenger/core/src/Services/UINotification';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
@((singleton as any)())
abstract class AbstractUINotificationService {
	constructor(
		@inject(DIToken.reduxDispatch) protected dispatch: Dispatch,
		@inject(DIToken.EnvInterfaceService) protected env: EnvInterfaceService,
	) {
		this.close = this.close.bind(this);
		this.prepareOptions = this.prepareOptions.bind(this);
	}

	/**
	 * this method should transform abstract options to concrete impl options
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	protected abstract prepareOptions(options: TAbstractNotificationOptions): any;

	enqueuePersistent(options: TAbstractNotificationOptions) {
		return this.dispatch(
			notificationsClientOnlyActions.enqueuePersistent(
				options.text,
				_.get(options, 'variant', EnumAbstractNotificationVariant.DEFAULT),
				this.prepareOptions(options),
				options.isPositionBottom,
				options.icon,
				options.isOnlyDesktop,
			),
		);
	}

	/**
	 * @note Please, don't use this method to close notification. Use .close(key) instead.
	 */
	dequeue(key: EntityId) {
		return this.dispatch(notificationsClientOnlyActions.removeSnackbar(key));
	}

	enqueue(options: TAbstractNotificationOptions) {
		return this.dispatch(
			notificationsClientOnlyActions.enqueueType(
				options.text,
				options.variant,
				this.prepareOptions(options),
				options.notificationTitle,
				options.isOnlyMobile,
				options.isPositionBottom,
				options.icon,
				options.isOnlyDesktop,
			),
		);
	}

	close(key?: EntityId) {
		return this.dispatch(notificationsClientOnlyActions.closeSnackbar(key));
	}

	/**
	 * @note this is a hack because of saga/channels issue.
	 * @note please do not use this function for anything other then "Undo"
	 */
	triggerUndo(actions: AnyAction[]) {
		actions.forEach((action) => this.dispatch(action));
	}
}

export default AbstractUINotificationService;
