import {createSlice, SliceCaseReducers} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {
	feedbackClientOnlyActions,
	TFeedbackFormSavePayload,
} from '@messenger/core/src/Redux/Feedback/Actions/feedbackClientOnlyActions';

export type TFeedbackState = {
	isSending: boolean;
	error?: string;
	formFields?: TFeedbackFormSavePayload;
	uploadFeedbackUrl?: string;
};

export const defaultState: TFeedbackState = {
	isSending: false,
	error: undefined,
	formFields: undefined,
};

const feedbackSlice = createSlice<TFeedbackState, SliceCaseReducers<TFeedbackState>, EnumStore>({
	name: EnumStore.FEEDBACK,
	initialState: defaultState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(feedbackClientOnlyActions.resetError, (state) => {
			state.error = undefined;
		});
		builder.addCase(feedbackClientOnlyActions.sent, (state) => {
			state.error = undefined;
			state.isSending = true;
		});
		builder.addCase(feedbackClientOnlyActions.success, (state) => {
			state.isSending = false;
		});
		builder.addCase(feedbackClientOnlyActions.failed, (state, action) => {
			state.error = action.payload.error;
			state.isSending = false;
		});
		builder.addCase(feedbackClientOnlyActions.saveFormFields, (state, action) => {
			state.formFields = action.payload;
		});
		builder.addCase(feedbackClientOnlyActions.setUploadFeedbackUrl, (state, action) => {
			state.uploadFeedbackUrl = action.payload.uploadFeedbackUrl;
		});
	},
});

export default feedbackSlice;
