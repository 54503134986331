import cuid from 'cuid';
import {call} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumAbstractNotificationVariant} from '@messenger/core/src/Services/UINotification';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';

export const showCopiedToClipboardNotification = function* ({
	payload,
}: ReturnType<typeof clientClientOnlyActions.copiedToClipboard>) {
	yield* call([ServiceFactory.notifications, ServiceFactory.notifications.enqueue], {
		text: payload,
		variant: EnumAbstractNotificationVariant.SUCCESS,
		key: cuid(),
	});
};
