import {select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {ObjectStoreService} from '@messenger/core/src/Services/ObjectStore/ObjectStoreService';
import selectCurrentMessageInput from '@messenger/core/src/Redux/MessageInput/Selectors/selectCurrentMessageInput';
import {selectIsMessageInputDisabled} from '@messenger/core/src/Redux/MessageInput/Selectors/selectIsMessageInputDisabled';

import {MESSAGE_INPUT_REF_ID} from 'src/Components/Chat/MessageArea/MessageInput/TextInput/TextInput';

export const focusCurrentMessageInputSaga = function* () {
	try {
		const isMessageInputDisabled = yield* select(selectIsMessageInputDisabled);

		if (isMessageInputDisabled) {
			return;
		}

		const textAreaEl = ObjectStoreService.get<HTMLTextAreaElement>(MESSAGE_INPUT_REF_ID);

		if (!textAreaEl) {
			return;
		}

		textAreaEl.focus();

		const messageInput = yield* select(selectCurrentMessageInput);

		if (textAreaEl.selectionStart !== messageInput?.caretPosition && messageInput?.caretPosition) {
			textAreaEl?.setSelectionRange(messageInput?.caretPosition, messageInput?.caretPosition);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'focusCurrentMessageInputSaga'});
	}
};
