import 'moment/locale/es';
import 'moment/locale/de';
import moment from 'moment';
import {call} from 'typed-redux-saga';
import {SupportedLanguage} from 'cmd-control-client-lib';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {EnumLocalStorageKeys} from '@messenger/core/src/BusinessLogic/EnumLocalStorageKeys';
import momentDurationLabelsDe from '@messenger/core/src/Locale/de/momentDurationLabels.json';
import momentDurationLabelsEs from '@messenger/core/src/Locale/es/momentDurationLabels.json';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';

const changeLanguageOnClientSaga = function* ({payload}: ReturnType<typeof clientClientOnlyActions.changeLanguage>) {
	try {
		const vControlApi = ServiceFactory.getVControlApi();
		const api = ServiceFactory.store;
		const i18n = ServiceFactory.i18n;

		yield* call([vControlApi, vControlApi.changeLanguage], payload);
		yield* call([api, api.set], EnumLocalStorageKeys.I_18_NEXT_LNG, payload);
		yield* call([moment, moment.locale], payload);

		switch (payload) {
			case SupportedLanguage.ES:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				yield* call([moment, moment.updateLocale], SupportedLanguage.ES, {
					durationLabelsStandard: momentDurationLabelsEs,
				});
				break;

			case SupportedLanguage.DE:
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				yield* call([moment, moment.updateLocale], SupportedLanguage.DE, {
					durationLabelsStandard: momentDurationLabelsDe,
				});
				break;
		}

		/**
		 * @note: i18n.changeLanguage should be called after moment.updateLocale to
		 * propagate changes to DOM
		 **/
		yield* call([i18n, i18n.changeLanguage], payload);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'changeLanguageOnClientSaga'});
	}
};

export default changeLanguageOnClientSaga;
