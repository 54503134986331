import {call} from 'typed-redux-saga';
import {PayloadAction} from '@reduxjs/toolkit';
import {CMDP_SSTATUS, EnumStreamStatusType} from 'cmd-control-client-lib';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

const muteWebRTCAudioSaga = function* (action: PayloadAction<CMDP_SSTATUS['params']>) {
	try {
		const webRTC = ServiceFactory.webRtcApi;

		switch (action.payload.type) {
			case EnumStreamStatusType.AUDIO_DEVICE_MUTED:
				yield* call([webRTC, webRTC.muteAudioTrack]);
				break;

			case EnumStreamStatusType.AUDIO_DEVICE_UNMUTED:
				yield* call([webRTC, webRTC.unMuteAudioTrack]);
				break;
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'muteWebRTCAudioSaga'});
	}
};

export default muteWebRTCAudioSaga;
