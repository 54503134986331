import {createSelector} from '@messenger/core/src/Utils/Redux';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import selectRootState from '@messenger/core/src/Redux/selectRootState';
import {TChannelsState} from '@messenger/core/src/Redux/Channels/initialState';

const selectChannelsState = createSelector(
	[selectRootState],
	(rootState): TChannelsState => rootState[EnumStore.CHANNELS],
);

export default selectChannelsState;
