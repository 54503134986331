import _ from 'lodash';
import {put, select, call} from 'typed-redux-saga';
import {ACTION, CMDC_CHANNEL, EnumChannelFlags} from 'cmd-control-client-lib';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectRequestedTicketsFor} from '@messenger/core/src/Redux/Media/Selectors/selectRequestedTicketsFor';
import {selectTicketByChannelId} from '@messenger/core/src/Redux/Media/Selectors/selectTicketByChannelId';
import {mediaClientToServerActions} from '@messenger/core/src/Redux/Media/Actions/mediaClientToServerActions';
import {channelsServerToClientActions} from '@messenger/core/src/Redux/Channels/Actions';

export const requestTicketsIfRequiredSaga = function* ({
	meta,
}: ReturnType<typeof channelsServerToClientActions.channelsReceived>) {
	const logService = ServiceFactory.logService;

	try {
		const withTickets = _.chain(meta.commands)
			.filter(
				(command) =>
					command.action === ACTION.CMDC_CHANNEL && _.includes(command.params.flags, EnumChannelFlags.TICKET),
			)
			.value() as Array<CMDC_CHANNEL>;

		if (_.isEmpty(withTickets)) {
			yield* call([logService, 'debug'], 'No channels with tickets, exiting ...');

			return;
		}

		const alreadyRequested = yield* select(selectRequestedTicketsFor);
		const withTicketChannelIds = _.map(withTickets, (channel) => channel.params.channelId);
		let needTicketsFor = _.difference(withTicketChannelIds, alreadyRequested);

		if (_.isEmpty(needTicketsFor)) {
			yield* call([logService, 'debug'], 'All tickets already requested, exiting ...');

			return;
		}

		for (const channelId of needTicketsFor) {
			const ticket = yield* select(selectTicketByChannelId, {channelId});

			if (!_.isUndefined(ticket)) {
				yield* call([logService, 'debug'], `Channel ${channelId} already has a loaded ticket, skipping ...`);
				needTicketsFor = _.without(needTicketsFor, channelId);
			}
		}

		yield* put(mediaClientToServerActions.requestTickets(needTicketsFor));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'requestTicketsIfRequiredSaga'});
	}
};
