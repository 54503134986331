import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectChannelVMsListByFilter} from '@messenger/core/src/Redux/Channels/Selectors/selectChannelVMsListByFilter';

const selectChannelVMsListByFilterIds = createSelector([selectChannelVMsListByFilter], (channelVMs) =>
	_.map(channelVMs, (channelVM) => channelVM.channelId),
);

export default selectChannelVMsListByFilterIds;
