import {call, put} from 'typed-redux-saga';
import {batchActions} from 'redux-batched-actions';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {sessionClientOnlyActions} from '@messenger/core/src/Redux/Session/Actions';
import {reInitVControlSaga} from '@messenger/core/src/Redux/Session/Sagas/reInitVControlSaga';
import {resetAllStores} from '@messenger/core/src/Redux/Reducers';
import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';
import {closeAllNotificationsSaga} from '@messenger/core/src/Redux/Notifications/Sagas/closeAllNotificationsSaga';

/**
 * @note might need to re-consider back to reducer as next login and init goes faster then clean-up
 */
export const cleanUpStoresSaga = function* () {
	try {
		yield* call(closeAllNotificationsSaga);
		yield* put(batchActions([clientClientOnlyActions.resetStore(), resetAllStores()]));

		yield* call(reInitVControlSaga);
		yield* put(sessionClientOnlyActions.resetStoreFinished());
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'cleanUpStoresSaga'});
	}
};
