import {put} from 'typed-redux-saga';
import md5 from 'md5';

import {sessionClientToServerActions} from '@messenger/core/src/Redux/Session/Actions';
import {loginFormClientOnlyActions} from '@messenger/core/src/Redux/LoginForm/Actions/loginFormClientOnlyActions';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

const processLoginSubmit = function* (action: ReturnType<typeof loginFormClientOnlyActions.submit>) {
	try {
		// disable form
		yield* put(loginFormClientOnlyActions.submitSent());
		yield* put(loginFormClientOnlyActions.disableAutoLogin());

		// reset previous errors
		yield* put(loginFormClientOnlyActions.resetError());

		yield* put(
			sessionClientToServerActions.logIn({
				credentials: {login: action.payload.login, password: md5(action.payload.password)},
				language: ServiceFactory.i18n.language,
			}),
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processLoginSubmit'});
	}
};

export default processLoginSubmit;
