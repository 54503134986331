import {put, select, call} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {
	messagesClientOnlyActions,
	messagesClientToServerActions,
	messagesServerToClientActions,
} from '@messenger/core/src/Redux/Messages/Actions';
import {isTemporaryMessageId} from '@messenger/core/src/Redux/Messages/entityAdapter';
import processRemovedMessageSaga from '@messenger/core/src/Redux/Messages/Sagas/processRemovedMessageSaga';
import {
	getMessageIdForSelector,
	selectMessageVMById,
} from '@messenger/core/src/Redux/Messages/Selectors/defaultSelectors';

function* deleteMessageSaga({
	payload: {messageId, channelId},
}: ReturnType<typeof messagesClientOnlyActions.deleteMessage>) {
	try {
		if (isTemporaryMessageId(messageId)) {
			const message = yield* select(selectMessageVMById, getMessageIdForSelector(messageId));

			if (message) {
				yield* call(
					processRemovedMessageSaga,
					messagesServerToClientActions.messageReceived({messageKey: message.messageKey}),
				);
			}
		} else {
			yield* put(messagesClientToServerActions.deleteMessage(channelId, messageId));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'deleteMessageSaga'});
	}
}

export default deleteMessageSaga;
