export const MESSAGES_RESET_VISIBLE_MESSAGES_THRESHOLD = 200;
export const GO_TO_RECENT_BOUNDARY_DISTANCE = 100;
export const ALL_CHATS = 'all';
export const BULK = 'bulk';
export const GROUP_CHAT = 'group_chat';
export const EDITOR_MAX_LENGTH = 1000;
export const UPLOAD_FILESIZE_LIMIT = 500 * 1024 * 1024;

export const TV_ADMIN_CHANNEL_ID_PARTIAL = '8083.552542';

export const USER_MENU_ONLINE_SUMMARY_REFRESH_INTERVAL = 60000;

export const DOWNLOAD_MEDIA_COUNT_LIMIT = 72;

export const MAX_PART_LENGTH_REPLY_MESSAGE = 400;

// used for mobile only
export const MAX_RECENT_EMOJI = 40;

export const LIVE_SESSIONS_PAGE_SIZE = 25;

export const PRICES_PRECISION = 2;
