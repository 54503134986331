import _ from 'lodash';
import {combineReducers} from 'redux';
import {enableBatching} from 'redux-batched-actions';
import {createAction, Reducer} from '@reduxjs/toolkit';
import {PayloadAction} from '@reduxjs/toolkit/src/createAction';

import {channelsSlice} from '@messenger/core/src/Redux/Channels/slice';
import introductionSlice from '@messenger/core/src/Redux/Introduction/slice';
import clientSlice from '@messenger/core/src/Redux/Client/slice';
import queryUserSlice from '@messenger/core/src/Redux/QueryUser/slice';
import PreviewReducer from '@messenger/core/src/Redux/Preview/PreviewReducer';
import cam2CamPreviewSlice from '@messenger/core/src/Redux/Cam2CamPreview/slice';
import giftsSlice from '@messenger/core/src/Redux/Gifts/giftsSlice';
import SpeedTestReducer from '@messenger/core/src/Redux/SpeedTest/Reducer';
import toySlice from '@messenger/core/src/Redux/Toy/slice';
import messagesSlice, {initialState} from '@messenger/core/src/Redux/Messages/slice';
import streamSlice from '@messenger/core/src/Redux/Stream/slice';
import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import chatsSlice from '@messenger/core/src/Redux/Chats/slice';
import mediaDeviceSlice from '@messenger/core/src/Redux/MediaDevice/slice';
import {contactInfoNote} from '@messenger/core/src/Redux/ContactNote/slice';
import {channelInfosSlice} from '@messenger/core/src/Redux/ChannelInfos/slice';
import sessionSlice from '@messenger/core/src/Redux/Session/slice';
import {messageInputSlice} from '@messenger/core/src/Redux/MessageInput/messageInputSlice';
import onlineSummarySlice from '@messenger/core/src/Redux/OnlineSummary/onlineSummarySlice';
import currencySlice from '@messenger/core/src/Redux/Currency/currencySlice';
import loginFormSlice from '@messenger/core/src/Redux/LoginForm/slice';
import {attachmentSlice} from '@messenger/core/src/Redux/Attachment/slice';
import {mediaPricesSlice} from '@messenger/core/src/Redux/MediaPrice/mediaPricesSlice';
import feedbackSlice from '@messenger/core/src/Redux/Feedback/slice';
import {reactionsSlice} from '@messenger/core/src/Redux/Reactions/slice';
import {pushNotificationsSlice} from '@messenger/core/src/Redux/PushNotifications/slice';
import {featureShowcaseSlice} from '@messenger/core/src/Redux/FeatureShowcase/slice';
import testVideoChatSlice from '@messenger/core/src/Redux/TestVideoChat/slice';
import onlineStateSlice from '@messenger/core/src/Redux/OnlineState/slice';
import mediaSlice from '@messenger/core/src/Redux/Media/slice';
import channelMediaSlice from '@messenger/core/src/Redux/ChannelMedia/slice';
import modelSettingsSlice from '@messenger/core/src/Redux/ModelSettings/slice';
import notificationsSlice from '@messenger/core/src/Redux/Notifications/slice';
import abuseReportSlice from '@messenger/core/src/Redux/AbuseReport/slice';
import ticketShowSlice from '@messenger/core/src/Redux/TicketShow/slice';
import groupsSlice from '@messenger/core/src/Redux/Groups/slice';
import channelGroupsSlice from '@messenger/core/src/Redux/ChannelGroups/slice';
import {chatSummarySlice} from '@messenger/core/src/Redux/ChatSummary/chatSummarySlice';
import {channelMediaSummarySlice} from '@messenger/core/src/Redux/ChannelMediaSummary/channelMediaSummarySlice';
import sedCardPictureSlice from '@messenger/core/src/Redux/SedCardPicture/sedCardPictureSlice';
import liveSessionsSlice from '@messenger/core/src/Redux/LiveSessions/liveSessionsSlice';
import liveSessionChatsSlice from '@messenger/core/src/Redux/LiveSessionChats/liveSessionChatsSlice';
import {webNotificationSlice} from '@messenger/core/src/Redux/WebNotification/webNotificationSlice';
import channelGroupsPurchaseAbilitySlice from '@messenger/core/src/Redux/ChannelGroupsPurchaseAbility/slice';
import liveChatsSlice from '@messenger/core/src/Redux/LiveChats/liveChatsSlice';
import {sipSlice} from '@messenger/core/src/Redux/Sip/sipSlice';
import videoCallSlice from '@messenger/core/src/Redux/VideoCall/slice';
import {initialState as initialChannelsState} from '@messenger/core/src/Redux/Channels/initialState';
import {channelsMapSlice} from '@messenger/core/src/Redux/ChannelsMap/channelsMapSlice';
import {mediaUploadProgressSlice} from '@messenger/core/src/Redux/MediaUploadProgress/slice';
import {messageAdapter, isTemporaryMessageId} from '@messenger/core/src/Redux/Messages/entityAdapter';

const reducers = {
	[EnumStore.ABUSEREPORT]: abuseReportSlice.reducer,
	[EnumStore.ATTACHMENT]: attachmentSlice.reducer,
	[EnumStore.CAM_2_CAM_PREVIEW]: cam2CamPreviewSlice.reducer,
	[EnumStore.CHATS]: chatsSlice.reducer,
	[EnumStore.CHAT_SUMMARY]: chatSummarySlice.reducer,
	[EnumStore.CHANNELS]: channelsSlice.reducer,
	[EnumStore.MESSAGE_INPUT]: messageInputSlice.reducer,
	[EnumStore.CHANNEL_INFOS]: channelInfosSlice.reducer,
	[EnumStore.CHANNEL_MEDIA]: channelMediaSlice.reducer,
	[EnumStore.CHANNEL_MEDIA_SUMMARY]: channelMediaSummarySlice.reducer,
	[EnumStore.CHANNEL_GROUPS]: channelGroupsSlice.reducer,
	[EnumStore.CLIENT]: clientSlice.reducer,
	[EnumStore.CONTACT_NOTE]: contactInfoNote.reducer,
	[EnumStore.CURRENCY]: currencySlice.reducer,
	[EnumStore.FEATURE_SHOWCASE]: featureShowcaseSlice.reducer,
	[EnumStore.FEEDBACK]: feedbackSlice.reducer,
	[EnumStore.INTRODUCTION]: introductionSlice.reducer,
	[EnumStore.LOGIN_FORM]: loginFormSlice.reducer,
	[EnumStore.GIFTS]: giftsSlice.reducer,
	[EnumStore.SESSION]: sessionSlice.reducer,
	[EnumStore.STREAM]: streamSlice.reducer,
	[EnumStore.LIVE_CHATS]: liveChatsSlice.reducer,
	[EnumStore.LIVE_SESSIONS]: liveSessionsSlice.reducer,
	[EnumStore.LIVE_SESSION_CHATS]: liveSessionChatsSlice.reducer,
	[EnumStore.MEDIA]: mediaSlice.reducer,
	[EnumStore.MEDIA_DEVICE]: mediaDeviceSlice.reducer,
	[EnumStore.MEDIA_PRICES]: mediaPricesSlice.reducer,
	[EnumStore.MESSAGES]: messagesSlice.reducer,
	[EnumStore.MODEL_SETTINGS]: modelSettingsSlice.reducer,
	[EnumStore.NOTIFICATIONS]: notificationsSlice.reducer, // notistack part of the store
	[EnumStore.ONLINE_STATE]: onlineStateSlice.reducer,
	[EnumStore.ONLINE_SUMMARY]: onlineSummarySlice.reducer,
	[EnumStore.PUSH_NOTIFICATIONS]: pushNotificationsSlice.reducer,
	[EnumStore.REACTIONS]: reactionsSlice.reducer,
	[EnumStore.QUERY_USER]: queryUserSlice.reducer,
	[EnumStore.STREAM_PREVIEW]: PreviewReducer,
	[EnumStore.SPEED_TEST]: SpeedTestReducer,
	[EnumStore.GROUPS]: groupsSlice.reducer,
	[EnumStore.TEST_VIDEO_CHAT]: testVideoChatSlice.reducer,
	[EnumStore.TICKET_SHOW]: ticketShowSlice.reducer,
	[EnumStore.TOY]: toySlice.reducer,
	[EnumStore.WEB_NOTIFICATION]: webNotificationSlice.reducer,
	[EnumStore.SED_CARD_PICTURE]: sedCardPictureSlice.reducer,
	[EnumStore.CHANNEL_GROUPS_PURCHASE_ABILITY]: channelGroupsPurchaseAbilitySlice.reducer,
	[EnumStore.SIP]: sipSlice.reducer,
	[EnumStore.VIDEO_CALL]: videoCallSlice.reducer,
	[EnumStore.CHANNELS_MAP]: channelsMapSlice.reducer,
	[EnumStore.MEDIA_UPLOAD_PROGRESS]: mediaUploadProgressSlice.reducer,
};

export const resetAllStores = createAction<{keepLocalStores: boolean} | undefined>('app/reset_all_stores');

const combinedReducers = combineReducers(reducers);

const rootReducer: Reducer<ReturnType<typeof combinedReducers>> = (state, action) => {
	const newState = combinedReducers(action.type === resetAllStores.type ? undefined : state, action);

	if (action.type === resetAllStores.type && !_.isUndefined(state)) {
		newState[EnumStore.CURRENCY] = state[EnumStore.CURRENCY];
		newState[EnumStore.GIFTS] = state[EnumStore.GIFTS];
		newState[EnumStore.CLIENT] = state[EnumStore.CLIENT];
		const resetStoreAction = action as PayloadAction<{keepLocalStores: boolean} | undefined>;

		if (resetStoreAction.payload?.keepLocalStores) {
			newState[EnumStore.CHANNELS] = {
				...initialChannelsState,
				..._.pick(state[EnumStore.CHANNELS], 'filter', 'targetIds', 'targetGroupIds', 'isTargetsSelectEnabled'),
			};
			newState[EnumStore.ATTACHMENT] = state[EnumStore.ATTACHMENT];
			newState[EnumStore.MESSAGE_INPUT] = state[EnumStore.MESSAGE_INPUT];
			newState[EnumStore.FEEDBACK] = state[EnumStore.FEEDBACK];
			newState[EnumStore.LOGIN_FORM] = state[EnumStore.LOGIN_FORM];
			newState[EnumStore.INTRODUCTION] = state[EnumStore.INTRODUCTION];

			const tmpOldMessageState = state[EnumStore.MESSAGES];

			const newMessageState = messageAdapter.removeMany(
				tmpOldMessageState,
				_.filter(tmpOldMessageState.ids, (item) => !isTemporaryMessageId(item.toString())),
			);

			//need to save mediaUploads too, if uploading temporary message with media in progress
			newState[EnumStore.MESSAGES] = {
				...initialState,
				ids: newMessageState.ids,
				entities: newMessageState.entities,
				mediaUploads: tmpOldMessageState.mediaUploads,
			};
		}
	}

	return newState;
};

export default enableBatching(rootReducer);
