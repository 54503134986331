import {PayloadAction} from '@reduxjs/toolkit';
import _ from 'lodash';
import {ResultCode} from 'cmd-control-client-lib';
import {call} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import EnumActionRole from '@messenger/core/src/BusinessLogic/EnumActionRole';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';
import {ALL_CHATS} from '@messenger/core/src/BusinessLogic/Constants';

export const handleNotFoundSaga = function* (action: PayloadAction) {
	try {
		if (_.get(action, 'meta.role') !== EnumActionRole.SERVER_TO_CLIENT) {
			return;
		}

		const resultCode = _.get(action, 'meta.result.code');
		const params = _.get(action, 'meta.params');

		switch (resultCode) {
			case ResultCode.CHAT_NOT_FOUND:
				if (params?.channelId) {
					const {goToChatMessages, isChannelPage} = yield* getNavigationContext();

					if (isChannelPage(params?.channelId)) {
						yield* call(goToChatMessages, ALL_CHATS);
					}
				}

				break;

			default:
				return;
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'handleNotFoundSaga', action});
	}
};
