import _ from 'lodash';
import {put} from 'typed-redux-saga';

import {mediaPricesClientToServerActions} from '@messenger/core/src/Redux/MediaPrice/Actions/mediaPricesClientToServerActions';
import AttachmentVM from '@messenger/core/src/Redux/Attachment/AttachmentVM';

export const publishTicketToPrivateShowSaga = function* (
	channelId: string,
	attachment: AttachmentVM,
	messageText?: string,
) {
	const {currency, price: mediaPrice, mediaType, duration: ticketDuration} = attachment;
	const text = _.isEmpty(messageText) ? undefined : messageText;

	yield* put(
		mediaPricesClientToServerActions.openMediaOffer({
			channelId,
			currency,
			mediaPrice: _.isUndefined(mediaPrice) ? undefined : mediaPrice?.toString(),
			mediaType,
			ticketDuration: _.isUndefined(ticketDuration) ? undefined : ticketDuration?.toString(),
			text,
		}),
	);
};
