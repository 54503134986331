import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectChannelEntityVMs} from '@messenger/core/src/Redux/Channels/Selectors/defaultSelectors';
import {bulkGuest, IGuest} from '@messenger/core/src/Types/IGuest';
import {_getSelectedChannelId} from '@messenger/core/src/Redux/Channels/Selectors/_getSelectedChannelId';
import {_selectSelectedChatId} from '@messenger/core/src/Redux/Chats/Selectors/_selectSelectedChatId';
import {_selectIsBulkMessage} from '@messenger/core/src/Redux/Client/Selectors/_selectIsBulkMessage';
import {selectChatEntityVms} from '@messenger/core/src/Redux/Chats/Selectors/defaultSelectors';

export const _selectCurrentGuest = createSelector(
	[selectChatEntityVms, selectChannelEntityVMs, _getSelectedChannelId, _selectSelectedChatId, _selectIsBulkMessage],
	(chatVmEntities, channelEntityVMs, channelId, chatId, isBulk): IGuest | undefined => {
		if (chatId) {
			return chatVmEntities[chatId];
		} else if (channelId) {
			return channelEntityVMs[channelId];
		} else if (isBulk) {
			return bulkGuest;
		}

		return undefined;
	},
);
