import {put, select, take} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectSessionVM} from '@messenger/core/src/Redux/Session/Selectors/selectSessionVM';
import selectLoginFormState from '@messenger/core/src/Redux/LoginForm/Selectors/selectLoginFormState';
import {sessionClientToServerActions, sessionServerToClientActions} from '@messenger/core/src/Redux/Session/Actions';
import selectIsAutoLoginRequired from '@messenger/core/src/Redux/Session/Selectors/selectIsAutoLoginRequired';
import selectCanBeLoggedByForeignSID from '@messenger/core/src/Redux/Session/Selectors/selectCanBeLoggedByForeignSID';
import selectCanBeLoggedByCredentials from '@messenger/core/src/Redux/Session/Selectors/selectCanBeLoggedByCredentials';
import {loginFormClientOnlyActions} from '@messenger/core/src/Redux/LoginForm/Actions/loginFormClientOnlyActions';

const processAutoLoginSaga = function* () {
	try {
		const sessionVM = yield* select(selectSessionVM);
		const {login, passwordHash: password} = yield* select(selectLoginFormState);
		const isAutoLoginRequired = yield* select(selectIsAutoLoginRequired);
		const canBeLoggedByForeignSID = yield* select(selectCanBeLoggedByForeignSID);
		const canBeLoggedByCredentials = yield* select(selectCanBeLoggedByCredentials);
		const shouldPerformAutoLogin = isAutoLoginRequired && (canBeLoggedByForeignSID || canBeLoggedByCredentials);

		if (shouldPerformAutoLogin) {
			yield* put(
				sessionClientToServerActions.logIn({
					credentials: canBeLoggedByForeignSID ? {foreignSID: sessionVM.foreignSID as string} : {login, password},
					language: sessionVM.language,
				}),
			);

			yield* take(sessionServerToClientActions.loggedIn);
		}

		yield* put(loginFormClientOnlyActions.setAutoLoginProcessed(true));
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processAutoLoginSaga'});
	}
};

export default processAutoLoginSaga;
