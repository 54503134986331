import _ from 'lodash';
import moment, {Moment} from 'moment';
import {ChatFlags, EnumBooleanDigitized, EnumProductId} from 'cmd-control-client-lib';

import {ALL_CHATS} from '@messenger/core/src/BusinessLogic/Constants';
import ChannelVM from '@messenger/core/src/Redux/Channels/ChannelVM';
import EnumChatType from '@messenger/core/src/BusinessLogic/EnumChatType';
import {parseBoolean} from '@messenger/core/src/Utils/StringUtil';
import {onceUponATimeInAGalaxyFarFarAway} from '@messenger/core/src/Utils/DateTimeUtil';
import {USERNAME_FALLBACK} from '@messenger/core/src/Redux/ChannelInfos/ChannelInfoVM';
import {IGuest} from '@messenger/core/src/Types/IGuest';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

import IChat from './Model';

export type TChatSummary = {
	FIRST?: Moment;
	LAST?: Moment;
	CHATS?: number;
};

export class ChatViewModel implements IGuest {
	chatId!: string;
	dataId?: string;
	exitCode?: number;
	exitMessage?: string;
	flags: ChatFlags[] = [];
	chatStats: TChatSummary = {};
	userKey?: string;
	login: string = USERNAME_FALLBACK;
	lang?: string;
	sessionID?: string;
	stopTime?: Date;
	startTime: Date = onceUponATimeInAGalaxyFarFarAway;
	paidTime?: number;
	type?: EnumChatType;

	readonly isGroupChat: boolean = false;
	readonly isAllChatsAggregate: boolean = false;
	readonly hasDiscount: boolean = false;
	readonly currentDiscount: number = 0;
	readonly isVoyShown: boolean = false;
	readonly isVoyeur: boolean = false;
	readonly isPrivate: boolean = false;
	readonly cam2cam: boolean = false;
	readonly isPreview: boolean = false;
	readonly isFreeChatAfterPreview: boolean = false;
	readonly isPrivateTicketShow: boolean = false;
	readonly canBan: boolean = false;
	readonly canDiscount: boolean = false;
	readonly isAdmin: boolean = false;
	readonly isTestChat: boolean = false;
	readonly channelId?: string;
	readonly channelViewModel?: ChannelVM;
	readonly isLobbyChat: boolean = false;
	readonly lobbyUsersCount: number = 0;
	readonly username: string = 'Voyeur';
	readonly isArchived: boolean = false;
	readonly isAvs?: boolean;
	readonly isAdvertised: boolean = false;
	readonly unseen: number = 0;
	readonly isRegular: boolean = false;
	readonly isPartner: boolean = false;
	readonly isExited: boolean = false;
	readonly unAnsweredCount: number = 0;
	readonly isUnanswered: boolean = false;
	readonly isTvAdmin: boolean = false;
	readonly isMuted: boolean = false;
	readonly isPinned: boolean = false;
	readonly isBanned: boolean = false;
	readonly avatar?: string;
	readonly userNote: string = '';
	readonly productId: EnumProductId = EnumProductId.Default;
	readonly isOnline: boolean = false;

	/**
	 * IGuest implementation
	 */
	readonly guestType: EnumGuestType = EnumGuestType.CHAT;
	readonly isTeamChannel = false;
	readonly canPurchaseMedia = false;
	readonly canReceiveAudio = false;
	readonly canReceiveVideo = false;
	readonly canReceiveImage = false;
	readonly isPrivateChat: boolean = false;

	constructor(
		public readonly chat: Partial<IChat>,
		modelAvatar: string | undefined,
		channelVm: ChannelVM | undefined = undefined,
	) {
		if (!chat) {
			return;
		}

		this.chatId = _.get(chat, 'chatID', '') as string;
		this.guestType = this.chatId === ALL_CHATS ? EnumGuestType.ALL : EnumGuestType.CHAT;

		if (chat.isPublicText) {
			this.isGroupChat = parseInt(chat.isPublicText, 10) === 1;
		}

		if (chat.dataID) {
			this.dataId = chat.dataID;
		}

		if (chat.startTime) {
			this.startTime = new Date(parseInt(chat.startTime, 10));
		}

		if (chat.stopTime) {
			this.stopTime = new Date(parseInt(chat.stopTime, 10));
		}

		if (chat.paidTime) {
			this.paidTime = parseInt(chat.paidTime, 10);
		}

		if (chat.login) {
			this.login = chat.login;
		}

		if (chat.lang) {
			this.lang = chat.lang.toLowerCase();
		}

		if (chat.hasDiscount) {
			this.currentDiscount = parseInt(chat.hasDiscount, 10);
		}

		if (chat.userKey) {
			this.userKey = chat.userKey;
		}

		if (chat.jsonStats) {
			const parsedChatStats = JSON.parse(chat.jsonStats);

			if (parsedChatStats.FIRST) {
				this.chatStats.FIRST = moment(parsedChatStats.FIRST);
			}

			if (parsedChatStats.LAST) {
				this.chatStats.LAST = moment(parsedChatStats.LAST);
			}

			if (parsedChatStats.CHATS) {
				this.chatStats.CHATS = parsedChatStats.CHATS;
			}
		}

		if (chat.exitCode) {
			this.exitCode = parseInt(chat.exitCode, 10);
		}

		if (chat.flags) {
			this.flags = chat.flags.toString().split(',') as ChatFlags[];
		}

		// Redux-renamed prop
		if (chat.type) {
			this.type = chat.type;
		}

		if (chat.lobbyUsers) {
			this.lobbyUsersCount = parseInt(chat.lobbyUsers, 10);
		}

		this.isVoyShown = _.includes(this.flags, ChatFlags.VOYSHOWN);
		this.isVoyeur = _.includes(this.flags, ChatFlags.VOYEUR) && !this.isVoyShown;

		if (!this.isVoyeur) {
			this.username = _.get(this, 'login', _.get(this, 'channelVM.username', USERNAME_FALLBACK));
		}

		this.channelViewModel = channelVm;

		this.isAllChatsAggregate = this.chatId === ALL_CHATS;
		this.hasDiscount = this.currentDiscount > 0;
		this.isPrivate = parseBoolean(_.get(this, 'chat.isSingle', EnumBooleanDigitized.FALSE));
		this.isPrivateChat = this.isPrivate;
		this.cam2cam = _.includes(this.flags, ChatFlags.CAM2CAM);
		this.isPreview =
			_.includes(this.flags, ChatFlags.PREVIEW) && !_.includes(this.flags, ChatFlags.VIDEO) && !this.isPrivate;
		this.isFreeChatAfterPreview =
			this.isPreview && !parseBoolean(_.get(this, 'chat.previewRun', EnumBooleanDigitized.FALSE));
		this.isPrivateTicketShow = _.includes(this.flags, ChatFlags.SINGLEC2C);
		this.canBan = parseBoolean(_.get(this, 'chat.canBan', EnumBooleanDigitized.FALSE));
		this.canDiscount = parseBoolean(_.get(this, 'chat.canDiscount', EnumBooleanDigitized.FALSE));
		this.isAdmin = (this.chat.admin && parseBoolean(this.chat.admin)) || this.type === EnumChatType.ADMIN_CHAT;
		this.isTestChat = _.includes(this.flags, ChatFlags.TESTCHAT);
		this.channelId = _.get(this, 'chat.channelId');
		this.isLobbyChat = _.get(this, 'chat.isLobby') === EnumBooleanDigitized.TRUE || this.lobbyUsersCount > 0;
		this.isArchived = _.get(this, 'channelViewModel.isArchived', false);
		this.isAvs = this.channelViewModel?.isAvs;
		this.isAdvertised = _.get(this, 'channelViewModel.isAdvertised', false);
		this.unseen = _.get(this, 'channelViewModel.unseen', 0);
		this.isRegular = _.get(this, 'channelViewModel.isRegular', false);
		this.isPartner = _.get(this, 'channelViewModel.isPartner', false);
		this.isExited = !_.isUndefined(this.exitCode);
		this.unAnsweredCount = _.get(this, 'channelViewModel.unAnsweredCount', 0);
		this.isUnanswered = _.get(this, 'channelViewModel.isUnanswered', 0);
		this.isTvAdmin = _.get(this, 'channelViewModel.isTvAdmin', false);
		this.isMuted = _.get(this, 'channelViewModel.isMuted', false);
		this.isPinned = _.get(this, 'channelViewModel.isPinned', false);
		this.isBanned = _.get(this, 'channelViewModel.isBanned', false);
		this.userNote = _.get(this, 'channelViewModel.userNote', '');
		this.productId = _.get(this, 'channelViewModel.productId');
		this.avatar = this.isTestChat ? modelAvatar : _.get(this, 'channelViewModel.avatar');
		this.isOnline = _.isUndefined(this.exitCode) || _.get(this, 'channelViewModel.isOnline', false);
	}
}
