import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import {selectChatVms} from '@messenger/core/src/Redux/Chats/Selectors/defaultSelectors';

const selectActiveVoyeurChatIds = createSelector([selectChatVms], (chatVms) =>
	_.chain(chatVms)
		.filter({
			isExited: false,
			isVoyeur: true,
		})
		.map('chatId')
		.value(),
);

export default selectActiveVoyeurChatIds;
