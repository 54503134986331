import {put} from 'typed-redux-saga';
import {PayloadAction} from '@reduxjs/toolkit';
import {EnumSetTp} from 'cmd-control-client-lib';
import _ from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {previewClientToServerActions} from '@messenger/core/src/Redux/Preview/Actions/previewClientToServerActions';

function* requestPreviewOnSetTpChanges(action: PayloadAction<TSessionWithSetTp>) {
	try {
		if (_.get(action.payload, 'setTp') === EnumSetTp.ONLINE) {
			yield* put(previewClientToServerActions.requestPreview());
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'requestPreviewOnSetTpChanges'});
	}
}

type TSessionWithSetTp = {
	setTp?: EnumSetTp;
};

export default requestPreviewOnSetTpChanges;
