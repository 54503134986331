import _ from 'lodash';

import {IHavingChatID, SswMessageType} from '@messenger/core/src/Redux/Messages/Model';

const getMessageChatIds = (message: Partial<SswMessageType> | IHavingChatID) => {
	if (!!message.chatID && !_.isEmpty(message.chatID.trim())) {
		return message.chatID.split(',');
	}

	if (!!message.chatIDs && !_.isEmpty(message.chatIDs.trim())) {
		return message.chatIDs.split(',');
	}

	return [];
};

export default getMessageChatIds;
