/* eslint-disable sonarjs/cognitive-complexity */
import { decode } from "xmlentities";
import { Logger, logger, setLogger } from "./logger";
import { VERSION } from "./version";
import * as cmdcontrol from "./cmd-protocol";
import { ReconnectionError } from "./@types";

export enum EnumInitDataFormat {
  /** deprecated*/
  LIVE = "live",
  /** new translations */
  LIVE2 = "live2",
  MESSENGER = "messenger",
  AVS = "avs",
  mc = "mc",
}

function nothing(): void {
  /* do nothing */
}

interface IQEntry {
  queued: boolean;
  command: cmdcontrol.ICOMMAND;
  timeoutId: number;

  callback(response: cmdcontrol.IRESPONSE): any;
}

const INACTIVE_SESSION_TIMEOUT = 120; //seconds

export class ConnectionConfig {
  public useWS = true; // use websocket
  public host = "";
  public wssport = "";
  public wsspath = "";
  public https = true;
  public agent: cmdcontrol.CmdClientAgent = cmdcontrol.CmdClientAgent.WEB;
  public language: cmdcontrol.SupportedLanguage;
  public version = "?";
  public commandHandler: (response: cmdcontrol.IRESPONSE) => any;
  public logger: Logger = undefined;
  public webtoken = "";
  public jsonp = false; // Allow usage von JSONP for fallback from websocket
  public firstConnectionRetryInterval = 0;
  public connectionRetryInterval = 2000;
  public initdata: EnumInitDataFormat = EnumInitDataFormat.LIVE;
  public onError?: (error: ReconnectionError) => unknown;
  public onOpen?: (event: Event) => unknown;
  public deviceId?: string;

  public toString(): string {
    // tslint:disable-next-line:max-line-length
    return (
      "[ useWS=" +
      this.useWS +
      " host=" +
      this.host +
      " wssport=" +
      this.wssport +
      " wsspath=" +
      this.wsspath +
      " agent=" +
      this.agent +
      " language=" +
      this.language +
      " version=" +
      this.version +
      " logger=" +
      this.logger +
      " commandHandler= " +
      this.commandHandler +
      " ]"
    );
  }
}

export class CmdConnection {
  // tslint:disable-next-line:member-ordering
  private static _commandTimeout = {
    CMDP_CLOGOUT: 4000,
    CMDP_LOGOUT: 4000,
    CMDP_NOOP: 30_000,
  };
  private _reConnectionTimeoutId?: ReturnType<typeof setTimeout>;
  private _isReConnectionPaused = false;

  private static _timeoutByAction(entry: IQEntry): number {
    return this._commandTimeout[entry.command.action] || 120_000;
  }

  private static _fetchJsonp(urlBase: string, entry: IQEntry, callback: (response: cmdcontrol.IRESPONSE) => any): void {
    let url = `${urlBase}?action=${entry.command.action}`;
    for (const key in entry.command.params) {
      if (Object.prototype.hasOwnProperty.call(entry.command.params, key)) {
        url += "&" + encodeURIComponent(key) + "=" + encodeURIComponent(entry.command.params[key].toString());
      }
    }
    url = url.replace("??", "?");
    const callbackFn = `_jsonp_${Date.now()}_${Math.ceil(Math.random() * 0x1_00_00).toString(16)}`;
    const jsonpScript = document.createElement("script");
    const parent = document.querySelectorAll("head")[0];
    const cleanup = (): void => {
      if (entry.timeoutId) {
        clearTimeout(entry.timeoutId);
        entry.timeoutId = undefined;
      }
      jsonpScript.remove();
      try {
        delete window[callbackFn];
      } catch {
        logger.log("send over jsonp", entry);
        window[callbackFn] = undefined;
      }
    };
    window[callbackFn] = (response: any): void => {
      cleanup();
      if (entry.command.params.format === "json") {
        callback(Object.assign(entry.command, response));
      } else {
        callback(Object.assign(entry.command, CmdConnection._parsePlainResponse(response)));
      }
    };
    jsonpScript.src = `${url}${url.includes("?") ? "&" : "?"}callback=${callbackFn}`;
    jsonpScript.onerror = (e): void => {
      cleanup();
      callback(
        Object.assign(
          entry.command,
          CmdConnection._getErrorResponse(cmdcontrol.ResultCode.NETWORK_ERROR, (e as ErrorEvent).error),
        ),
      );
    };
    parent.append(jsonpScript);
    entry.timeoutId = window.setTimeout(() => {
      cleanup();
      window[callbackFn] = nothing;
      callback(Object.assign(entry.command, CmdConnection._getErrorResponse(cmdcontrol.ResultCode.TIMEOUT, "timeout")));
    }, this._timeoutByAction(entry));
  }

  private static _parsePlainResponse(responseStr: string): any {
    const response: cmdcontrol.IRESPONSE = {
      action: cmdcontrol.ACTION.CMDP_UNDEFINED,
      params: {},
      result: new cmdcontrol.RESULT(),
      commands: [],
      values: {},
    };

    const plusPattern = /\+/g;
    const commandPattern = /^CMDC_(\w+)/;
    const valuePattern = /(\w+)=(\S+)/g;

    let strings: string[];

    for (const line of responseStr.split("\n")) {
      // tslint:disable-next-line:no-conditional-assignment
      if (!(strings = commandPattern.exec(line))) {
        continue;
      }
      const cmdStr: string = strings[1];
      const values: any = {};
      valuePattern.lastIndex = 0;
      // tslint:disable-next-line:no-conditional-assignment
      while ((strings = valuePattern.exec(line))) {
        const key: string = strings[1];
        const value: string = decode(decodeURIComponent(strings[2]));
        values[key] =
          key === "text" && value.slice(0, 7) !== "<a+href" && cmdStr !== "CQUERYUSER" && !line.includes(" from=system")
            ? value
            : value.replace(plusPattern, " ");
      }
      switch (cmdStr) {
        case "RESULT":
          response.result.code = Number.parseInt(values.code, 10);
          response.result.reason = values.reason;
          break;
        case "VALUE":
          for (const key in values) {
            if (Object.prototype.hasOwnProperty.call(values, key)) {
              response.values[key] = values[key];
            }
          }
          break;
        default:
          response.commands.push({
            action: cmdcontrol.ACTION["CMDC_" + cmdStr],
            params: values,
          });
      }
    }
    return response;
  }

  private static _getErrorResponse(code: number, reason: string): any {
    return { result: { code, reason }, commands: undefined, values: undefined };
  }

  private readonly _version: string = VERSION;
  private _settings: ConnectionConfig;
  private readonly _instanceId: string;
  private _servletUrl: string;
  private _platformUrl: string; // from DS_RELOAD
  private _socket: WebSocket;
  private _closing = false;
  private _counter = 1;
  private _reConnectAttemptsCount = 0;
  private _initProcessed = false;
  private _noop = { active: false, lastId: undefined, lastTime: undefined };
  private _dsreload: boolean;
  private _queue: IQEntry[] = [];
  private readonly _jumpTable: {
    [key: string]: (command: cmdcontrol.ICOMMAND) => void;
  };
  private _sessionID = "";
  private _logPrefix = "CMDP.";

  public constructor(settings: ConnectionConfig) {
    this._instanceId = Math.ceil(Math.random() * 0x1_00_00_00).toString(16);
    while (this._instanceId.length < 6) {
      this._instanceId = "0" + this._instanceId;
    }
    this._settings = settings;
    if (settings.logger) {
      setLogger(settings.logger);
    }
    logger.log(this._logPrefix + " version=" + VERSION + " settings: " + settings);
    this._onopen = this._onopen.bind(this);
    this._onmessage = this._onmessage.bind(this);
    this._onclose = this._onclose.bind(this);
    this._onerror = this._onerror.bind(this);
    this.connect = this.connect.bind(this);
    window.onunload = this.close.bind(this);

    this._jumpTable = {
      CMDP_INIT: this._processCmpdInit.bind(this),
      CMDP_SINIT: this._processCmpdInit.bind(this),
      CMDP_SLOGIN: this._processCmdpLogin.bind(this),
      // tslint:disable-next-line:object-literal-sort-keys
      CMDP_LOGIN: this._processCmdpLogin.bind(this),
      CMDP_NOOP: this._processCmdpNoop.bind(this),
      CMDC_DSRELOAD: this._processCmdcDsReload.bind(this),
    };
  }

  public connect(): void {
    // tslint:disable-next-line:max-line-length
    this._servletUrl = this._platformUrl
      ? this._platformUrl
      : `${this._settings.https ? "https" : "http"}://${this._settings.host}:${this._settings.wssport}${
          this._settings.wsspath
        }`;
    if (this._settings.useWS) {
      this._openSocket();
    }
    if (!this._initProcessed) {
      const command = Object.assign(new cmdcontrol.CMDP_INIT(), {
        params: { initData: this._settings.initdata },
      });
      this._send(command);
    }
  }

  public send(command: cmdcontrol.ICOMMAND): void {
    if (command.action === cmdcontrol.ACTION.CMDP_NOOP) {
      logger.error(this._logPrefix + " dont send the CMDP_NOOP active ", command);
      throw new Error("don't send the CMDP_NOOP.");
    }
    this._send(command);
  }

  public close(): void {
    this._closing = true;
    this._noop.active = false;
    if (this._socket) {
      this._socket.close();
    }
  }

  private _send(command: cmdcontrol.ICOMMAND): cmdcontrol.ICOMMAND {
    command = Object.assign(command, {
      params: Object.assign(this._getDefaultParams(), command.params),
    });
    logger.log(this._logPrefix + "_enqueue", command);
    const entry: IQEntry = {
      callback: this._processReply.bind(this),
      command,
      timeoutId: 0,
      queued: true,
    };
    this._queue.push(entry);
    this._dequeue();

    return command;
  }

  private _openSocket(): void {
    const url = `${this._settings.https ? "wss" : "ws"}://${this._settings.host}:${this._settings.wssport}${
      this._settings.wsspath
    }`;
    logger.log(this._logPrefix + "_openSocket", url);
    let socket: WebSocket;
    try {
      socket = new WebSocket(url);
    } catch (error) {
      // @MDN SECURITY_ERR The port to which the connection is being attempted is being blocked.
      logger.warn(this._logPrefix + "_openSocket SECURITY_ERR", error);
      this.reconnect();

      return;
    }

    socket.onopen = this._onopen;
    socket.onmessage = this._onmessage;
    socket.onerror = this._onerror;
    socket.onclose = this._onclose;

    this._socket = socket;
  }

  private _onopen(event: Event): void {
    logger.log(this._logPrefix + "socket.onopen", event);
    this._dequeue();

    if (this._socket?.readyState === WebSocket.OPEN) {
      this._reConnectAttemptsCount = 0;

      this._sendNoop();

      if (this._settings.onOpen) {
        this._settings.onOpen(event);
      }
    }
  }

  private _onmessage(event: MessageEvent): void {
    logger.log(this._logPrefix + "socket.onmessage", event);
    const data = JSON.parse(event.data);
    this._processReply(data);
  }

  private _onerror(event: Event): void {
    logger.warn(this._logPrefix + "socket.onerror", event);

    if (this._settings.onError) {
      const isFatal = this._reConnectAttemptsCount === 6;
      let event: ReconnectionError;

      if (isFatal) {
        event = { isFatal: true };
      } else {
        const reConnectionTimeout = this._getNextConnectionRetryTimeout(this._reConnectAttemptsCount);
        event = { reConnectionTimeout };
      }
      this._settings.onError(event);
    }
  }

  private _onclose(event: CloseEvent): void {
    logger.log(this._logPrefix + "socket.onclose", event);

    if (!this._closing && !this._dsreload && this._settings.useWS) {
      this.reconnect();
    }

    if (this._dsreload) {
      this._dsreload = false;
    }
  }

  private _getDefaultParams(): any {
    return {
      _uniq: (this._counter++).toString(),
      _iid: this._instanceId,
      strip: true,
      format: cmdcontrol.EnumFormatValues.JSON,
      agent: this._settings.agent,
      version: this._settings.version + "/" + this._version,
      language: this._settings.language,
      sessionID: this._sessionID,
      webtoken: this._settings.webtoken,
      deviceId: this._settings.deviceId,
    };
  }

  private _processReply(response: cmdcontrol.IRESPONSE): void {
    logger.log(this._logPrefix + "_processReply", response);
    const _uniq = response.params._uniq;
    let entry: IQEntry;
    for (let i = 0, l = this._queue.length; i < l; i++) {
      entry = this._queue[i];
      if (entry.command.params._uniq === _uniq) {
        if (entry.timeoutId) {
          clearTimeout(entry.timeoutId);
        }
        this._queue.splice(i, 1);
        break;
      }
    }
    if (!entry) {
      logger.warn(this._logPrefix + "_processReply. Entry not found _uniq=" + _uniq, response);
      return;
    }
    /*network issue*/
    let emit = true;
    if (response.result.code === cmdcontrol.ResultCode.NETWORK_ERROR) {
      const retry = Number.parseInt(entry.command.params._retry.toString() || "0", 10);
      if (retry < 10) {
        emit = false;
        setTimeout(() => {
          logger.log(this._logPrefix + ".re-enqueue ", entry.command);
          entry.command.params._retry = entry.command.params._retry ? (retry + 1).toString() : "1";
          this._send(entry.command);
        }, 3000);
      }
    }
    if (emit) {
      this._processCommand(response);
      if (response.commands && response.commands.length > 0) {
        response.commands.forEach(this._processCommand.bind(this));
      }
      this._settings.commandHandler(response);
    }
    this._dequeue();
  }

  private _dequeue(): void {
    if (!this._closing) {
      for (let i = 0, l = this._queue.length; i < l; i++) {
        const entry = this._queue[i];
        if (entry.queued) {
          this._sendEntry(entry);
          break;
        }
      }
    }
  }

  private _sendEntry(entry: IQEntry): void {
    if (this._settings.useWS) {
      if (this._socket?.readyState === WebSocket.OPEN) {
        entry.queued = false;
        const str = JSON.stringify(entry.command);
        this._socket.send(str);
      }
    } else if (this._settings.jsonp) {
      entry.queued = false;
      CmdConnection._fetchJsonp(this._servletUrl, entry, this._processReply.bind(this));
    }
  }

  private _getNextConnectionRetryTimeout(attempt: number): number {
    return this._settings.connectionRetryInterval * attempt || this._settings.firstConnectionRetryInterval;
  }

  public reconnect(): void {
    if (this._isReConnectionPaused) {
      return;
    }

    this._socket = undefined;
    this._markAllQueueEntriesAsQueued();

    if (this._settings.jsonp) {
      logger.warn(this._logPrefix + ". fallback to JSONP");
      this._settings.useWS = false;
      this._dequeue();
    } else if (this._settings.useWS) {
      if (this._reConnectAttemptsCount <= 5) {
        if (this._reConnectionTimeoutId) {
          clearTimeout(this._reConnectionTimeoutId);
          this._reConnectionTimeoutId = undefined;
        }

        const reConnectionTimeout = this._getNextConnectionRetryTimeout(this._reConnectAttemptsCount);

        logger.log(this._logPrefix + " retry connect in " + reConnectionTimeout + " ms");
        this._reConnectAttemptsCount++;

        this._reConnectionTimeoutId = setTimeout(() => {
          this._openSocket();
        }, reConnectionTimeout);
      } else {
        this._reConnectAttemptsCount = 0;
        logger.log(this._logPrefix + " maximum reconnect attempts count reached");
      }
    }
  }

  public pause(isResumable = true): void {
    if (
      !this._settings.useWS ||
      this._socket?.readyState === WebSocket.CLOSED ||
      this._socket?.readyState === WebSocket.CLOSING
    ) {
      return;
    }

    this._isReConnectionPaused = true;
    if (this._reConnectionTimeoutId) {
      clearTimeout(this._reConnectionTimeoutId);
      this._reConnectionTimeoutId = undefined;
    }

    if (this._socket) {
      this._socket.onclose = undefined;
      this._socket.onerror = undefined;
      this._socket.close();
    }

    if (!isResumable) {
      this._closing = false;
      this._noop.active = true;
      this._noop.lastTime = undefined;
      this._isReConnectionPaused = false;
      this._reConnectAttemptsCount = 0;
      this.reconnect();
    }
  }

  public resume(): boolean {
    if (!this._sessionID || !this._settings.useWS || !this._isReConnectionPaused) {
      return false;
    }

    this._closing = false;
    this._noop.active = true;
    this._noop.lastTime = undefined;
    this._isReConnectionPaused = false;
    this._reConnectAttemptsCount = 0;
    this.reconnect();

    return true;
  }

  private _sendNoop(): void {
    if (!this._sessionID) {
      return;
    }

    const command = this._send(new cmdcontrol.CMDP_NOOP());
    this._noop.lastId = command.params._uniq;
  }

  private _processCommand(command: cmdcontrol.ICOMMAND): void {
    const func = this._jumpTable[command.action];
    if (func) {
      func(command);
    }
  }

  // noinspection JSUnusedLocalSymbols
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private _processCmpdInit(_response: cmdcontrol.CMDP_INIT_RESPONSE): void {
    this._initProcessed = true;
  }

  private _processCmdpLogin(response: cmdcontrol.CMDP_LOGIN_RESPONSE): void {
    if (response.result.code === cmdcontrol.ResultCode.OK) {
      this._sessionID = response.values.sessionID;
      this._sendNoop();
    }
  }

  private _processCmdpNoop(response: cmdcontrol.CMDP_NOOP_RESPONSE): void {
    const currentTime = Math.floor(Date.now() / 1000);

    switch (response.result.code) {
      case cmdcontrol.ResultCode.SESSION_NOT_FOUND:
      case cmdcontrol.ResultCode.NETWORK_ERROR:
        logger.warn(this._logPrefix + "NOOP stopped");
        break;
      default:
        if (!this._noop.lastTime || currentTime - this._noop.lastTime < INACTIVE_SESSION_TIMEOUT) {
          this._noop.lastTime = currentTime;
        }

        if (this._noop.lastId === response.params._uniq) {
          this._sendNoop();
        }
    }
  }

  private _processCmdcDsReload(command: cmdcontrol.CMDC_DSRELOAD): void {
    logger.warn(this._logPrefix + cmdcontrol.ACTION.CMDC_DSRELOAD, command);
    this._dsreload = true;
    if (this._socket) {
      this._socket.close();
    }

    this._markAllQueueEntriesAsQueued();

    this._platformUrl = command.params.PlatformUrl;
    this._settings.host = command.params.wsshost;
    this._settings.wssport = command.params.wssport;
    this._settings.wsspath = command.params.wsspath;
    this.connect();
  }

  private _markAllQueueEntriesAsQueued(): void {
    this._queue.forEach((entry) => {
      // do not re enqueue the NOOP because of duplicate
      if (!entry.queued && entry.command.action !== cmdcontrol.ACTION.CMDP_NOOP) {
        entry.queued = true;
      }
    });
  }
}
