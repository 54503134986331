import {call, put, select} from 'typed-redux-saga';
import {EnumSetTp} from 'cmd-control-client-lib';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {sessionClientOnlyActions} from '@messenger/core/src/Redux/Session/Actions';
import {streamClientToServerActions} from '@messenger/core/src/Redux/Stream/Actions';
import {getNavigationContext} from '@messenger/core/src/Redux/sagaContext';
import {ALL_CHATS} from '@messenger/core/src/BusinessLogic/Constants';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';
import selectSetTp from '@messenger/core/src/Redux/Session/Selectors/selectSetTp';
import {mediaDeviceClientOnlyActions} from '@messenger/core/src/Redux/MediaDevice/Actions';
import {EnumStartStreamStep} from '@messenger/core/src/Redux/Stream/slice';
import {selectStartStreamStep} from '@messenger/core/src/Redux/Stream/Selectors/selectStartStreamStep';

import selectBrowserCapabilities from 'src/Redux/Stream/Selectors/selectBrowserCapabilities';

const processGoOnlineSaga = function* () {
	try {
		const {goToChatMessages, isChatPage} = yield* getNavigationContext();
		const capabilities = yield* select(selectBrowserCapabilities);

		if (!isChatPage(ALL_CHATS)) {
			yield* call(goToChatMessages, ALL_CHATS);
		}

		const setTpValue = yield* select(selectSetTp);
		const startStreamStep = yield* select(selectStartStreamStep);

		if (setTpValue === EnumSetTp.OFFLINE) {
			yield* put(streamClientOnlyActions.resetStartStreamStep());
			yield* put(streamClientToServerActions.startSend(capabilities));
			yield* put(sessionClientOnlyActions.setTp(EnumSetTp.GOING_ONLINE));
		} else if (startStreamStep === EnumStartStreamStep.THIRD_PARTY_STREAM_CONFIG) {
			yield* put(streamClientOnlyActions.setStartStreamStep(EnumStartStreamStep.WEBRTC_STREAM_CONFIG));
			yield* put(
				mediaDeviceClientOnlyActions.requestMediaDevicePermissions({
					isMicrophoneRequired: true,
				}),
			);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processGoOnlineSaga'});
	}
};

export default processGoOnlineSaga;
