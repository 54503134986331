import _ from 'lodash';

import {createSelector} from '@messenger/core/src/Utils/Redux';
import ChannelFilterMatcher from '@messenger/core/src/BusinessLogic/ChannelFilterMatcher';
import {selectChannelIdsByGroupIds} from '@messenger/core/src/Redux/ChannelGroups/Selectors/selectChannelIdsByGroupIds';
import selectRootState from '@messenger/core/src/Redux/selectRootState';

import selectChannelsFilter from './selectChannelsFilter';

export const selectChannelFilterMatcher = createSelector(
	[selectChannelsFilter, selectRootState],
	(channelsFilter, rootState) => {
		const filterGroups = _.chain(channelsFilter).get('filterGroupId').split(',').compact().value();

		const channelIdsWhiteList = _.isEmpty(filterGroups)
			? undefined
			: selectChannelIdsByGroupIds(rootState, filterGroups);

		return new ChannelFilterMatcher(channelsFilter, channelIdsWhiteList);
	},
);
