import {takeEvery} from 'typed-redux-saga';

import processLoginSubmit from '@messenger/core/src/Redux/LoginForm/Sagas/processLoginSubmit';
import processLoginResult from '@messenger/core/src/Redux/LoginForm/Sagas/processLoginResult';
import rememberCredentialsIfNeeded from '@messenger/core/src/Redux/LoginForm/Sagas/rememberCredentialsIfNeeded';
import {loginFormClientOnlyActions} from '@messenger/core/src/Redux/LoginForm/Actions/loginFormClientOnlyActions';
import logSuccessLoginSaga from '@messenger/core/src/Redux/LoginForm/Sagas/logSuccessLoginSaga';

function* loginFormSagaWatcher() {
	yield* takeEvery(loginFormClientOnlyActions.submit.type, processLoginSubmit);
	yield* takeEvery(loginFormClientOnlyActions.submitResponseReceived.type, processLoginResult);
	yield* takeEvery(loginFormClientOnlyActions.submitSuccess.type, rememberCredentialsIfNeeded);
	yield* takeEvery(loginFormClientOnlyActions.submitSuccess.type, logSuccessLoginSaga);
}

export default loginFormSagaWatcher;
