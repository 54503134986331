import _ from 'lodash';
import {EnumCurrency, InitialCurrencies, SupportedLanguage} from 'cmd-control-client-lib';

import {getLocaleByLanguage} from '@messenger/core/src/Utils/Locales/getLocaleByLanguage';
import {getNumberFormattingOptions} from '@messenger/core/src/Utils/Numbers/getNumberFormattingOptions';
import {PRICES_PRECISION} from '@messenger/core/src/BusinessLogic/Constants';

const formatCurrency = (
	currencies: InitialCurrencies,
	currentLanguage: SupportedLanguage | string, // string added for i18n.language support
	currency = EnumCurrency.EURO,
	value: number,
	isRoundedIfInteger = false,
): string => {
	if (currency === EnumCurrency.VOUCHER_CREDIT) {
		return value + ' ' + _.get(currencies, [currency, 'symbol'], '');
	}

	return new Intl.NumberFormat(getLocaleByLanguage(currentLanguage), {
		style: 'currency',
		currency: currency,
		...getNumberFormattingOptions(value, PRICES_PRECISION, isRoundedIfInteger),
	}).format(value);
};

export default formatCurrency;
