import {PayloadAction} from '@reduxjs/toolkit';
import {call} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';

function* muteMediaStreamAudioSaga(action: PayloadAction<boolean>) {
	try {
		const webRtcApi = ServiceFactory.webRtcApi;

		if (webRtcApi.mediaStream) {
			if (action.payload) {
				yield* call([webRtcApi, webRtcApi.muteAudioTrack]);
			} else {
				yield* call([webRtcApi, webRtcApi.unMuteAudioTrack]);
			}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'muteMediaStreamAudioSaga'});
	}
}

export default muteMediaStreamAudioSaga;
