import {put, select} from 'typed-redux-saga';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectContactNoteVMById} from '@messenger/core/src/Redux/ContactNote/Selectors/defaultSelectors';
import {contactNoteClientOnlyActions} from '@messenger/core/src/Redux/ContactNote/Actions/contactNoteClientOnlyActions';
import {contactNoteClientToServerActions} from '@messenger/core/src/Redux/ContactNote/Actions/contactNoteClientToServerAction';
import {getChannelIdForSelector} from '@messenger/core/src/Redux/Channels/Selectors/defaultSelectors';

export function* updateContactNoteSaga(action: ReturnType<typeof contactNoteClientOnlyActions.updateContactNote>) {
	try {
		const {channelId} = action.payload;

		const oldContactNote = yield* select(selectContactNoteVMById, getChannelIdForSelector(channelId));

		yield* put(
			contactNoteClientToServerActions.saveContactNote(
				oldContactNote ? {...oldContactNote, ...action.payload} : action.payload,
			),
		);
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'updateContactNoteSaga'});
	}
}
