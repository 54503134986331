import {createSlice, SliceCaseReducers} from '@reduxjs/toolkit';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {abuseReportClientOnlyActions} from '@messenger/core/src/Redux/AbuseReport/Actions/abusereportClientOnlyActions';

export type TAbuseReportState = {
	isSending: boolean;
	error: undefined | string;
};

export const defaultState: TAbuseReportState = {
	isSending: false,
	error: undefined,
};

const abuseReportSlice = createSlice<TAbuseReportState, SliceCaseReducers<TAbuseReportState>, EnumStore>({
	name: EnumStore.ABUSEREPORT,
	initialState: defaultState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(abuseReportClientOnlyActions.resetError, (state) => {
			state.error = undefined;
		});
		builder.addCase(abuseReportClientOnlyActions.sent, (state) => {
			state.error = undefined;
			state.isSending = true;
		});
		builder.addCase(abuseReportClientOnlyActions.success, (state) => {
			state.isSending = false;
		});
		builder.addCase(abuseReportClientOnlyActions.failed, (state, action) => {
			state.error = action.payload.error;
			state.isSending = false;
		});
	},
});

export default abuseReportSlice;
