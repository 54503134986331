import {createEntityAdapter, EntityId} from '@reduxjs/toolkit';
import {SnackbarMessage} from 'notistack';

import {TAbstractNotificationOptions} from '@messenger/core/src/Services/UINotification';
import {EnumNotificationStatus} from '@messenger/core/src/Redux/Notifications/EnumNotificationStatus';
import EnumSnackbarNotificationKeys from '@messenger/core/src/BusinessLogic/EnumSnackbarNotificationKeys';

export const notificationsAdapter = createEntityAdapter<TNotification>({
	selectId: (notification): EntityId => notification.key,
});

export type TNotification = {
	key: string | EnumSnackbarNotificationKeys;
	notificationTitle: string | undefined;
	message: SnackbarMessage;
	options: TAbstractNotificationOptions;
	status: EnumNotificationStatus;
	isOnlyMobile: boolean | undefined;
	isPositionBottom: boolean | undefined;
	isOnlyDesktop?: boolean;
};
