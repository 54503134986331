import {ResultCode} from 'cmd-control-client-lib';
import {call, put} from 'typed-redux-saga';

import {EnumLocalStorageKeys} from '@messenger/core/src/BusinessLogic/EnumLocalStorageKeys';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import EnumStoreType from '@messenger/core/src/BusinessLogic/EnumStoreType';
import {mediaDeviceClientOnlyActions} from '@messenger/core/src/Redux/MediaDevice/Actions';
import {sessionServerToClientActions} from '@messenger/core/src/Redux/Session/Actions';

function* loadMediaDeviceFromStoreSaga(action: ReturnType<typeof sessionServerToClientActions.loggedIn>) {
	try {
		if (action.meta.result.code !== ResultCode.OK) {
			return;
		}

		const api = ServiceFactory.store;
		const devices = yield* call([api, api.get], EnumLocalStorageKeys.MEDIA_DEVICES, {storeType: EnumStoreType.LOCAL});
		const devicesInfo = yield* call([api, api.get], EnumLocalStorageKeys.MEDIA_DEVICES_INFO, {
			storeType: EnumStoreType.LOCAL,
		});

		if (devices) {
			yield* put(mediaDeviceClientOnlyActions.setChosenMediaDevicesFromStore(devices));
		}

		if (devicesInfo) {
			yield* put(mediaDeviceClientOnlyActions.setChosenMediaDevicesInfoFromStore(devicesInfo));
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'loadMediaDeviceFromStoreSaga'});
	}
}

export default loadMediaDeviceFromStoreSaga;
