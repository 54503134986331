import {EnumProductId} from 'cmd-control-client-lib';

import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';

export interface IGuest {
	guestType: EnumGuestType;
	chatId?: string;
	channelId?: string;
	username: string;
	isVoyeur: boolean;
	isTeamChannel: boolean;
	isTvAdmin: boolean;
	isAdmin: boolean;
	isPartner: boolean;
	isRegular: boolean;
	isLobbyChat: boolean;
	isGroupChat: boolean;
	isPrivateChat: boolean;
	canPurchaseMedia: boolean;
	canReceiveAudio: boolean;
	canReceiveVideo: boolean;
	canReceiveImage: boolean;
	isAvs?: boolean;
	productId: EnumProductId;
	isOnline: boolean;
	avatar?: string;
	lang?: string;
	isBanned: boolean;
}

export type TGuestIdentity =
	| {guestType: EnumGuestType.CHANNEL; channelId: string; chatId?: undefined}
	| {guestType: EnumGuestType.CHAT; chatId: string; channelId?: string}
	| {guestType: EnumGuestType.ALL; chatId?: undefined; channelId?: undefined}
	| {guestType: EnumGuestType.BULK; chatId?: undefined; channelId?: undefined};

export const bulkGuest: IGuest = {
	guestType: EnumGuestType.BULK,
	canReceiveImage: true,
	canReceiveVideo: true,
	canReceiveAudio: true,
	canPurchaseMedia: true,
	isTeamChannel: false,
	isVoyeur: false,
	isAdmin: false,
	isTvAdmin: false,
	isBanned: false,
	isOnline: false,
	isGroupChat: false,
	isLobbyChat: false,
	isPrivateChat: false,
	isPartner: false,
	productId: EnumProductId.Default,
	username: '',
	isRegular: false,
};
