import _ from 'lodash';
import {take, select, call, put} from 'typed-redux-saga';
import {EnumSetTp} from 'cmd-control-client-lib';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {selectIsStreamStartedFromCurrentInstance} from '@messenger/core/src/Redux/Stream/Selectors/selectIsStreamStartedFromCurrentInstance';
import {TSessionUpdatedAction, sessionServerToClientActions} from '@messenger/core/src/Redux/Session/Actions';
import selectSetTp from '@messenger/core/src/Redux/Session/Selectors/selectSetTp';
import {streamClientToServerActions} from '@messenger/core/src/Redux/Stream/Actions';
import {selectIsWebRtcOutputStreamActive} from '@messenger/core/src/Redux/Stream/Selectors/selectIsWebRtcOutputStreamActive';
import {mediaDeviceClientOnlyActions} from '@messenger/core/src/Redux/MediaDevice/Actions';
import {streamClientOnlyActions} from '@messenger/core/src/Redux/Stream/Actions/streamClientOnlyActions';

import {switchToLobbyModeIfIntendedSaga} from 'src/Redux/MediaDevice/Sagas/switchToLobbyModeIfIntendedSaga';
import {setStreamingDeviceStatusSaga} from 'src/Redux/MediaDevice/Sagas/setStreamingDeviceStatusSaga';
import streamingGoOfflineSaga from 'src/Redux/Stream/Sagas/streamingGoOfflineSaga';

export const processWebRtcStreamStarted = function* (action: TSessionUpdatedAction) {
	try {
		if (_.isUndefined(_.get(action.payload, 'setTp'))) {
			return;
		}

		const prevSetTpValue: EnumSetTp = yield* select(selectSetTp);
		const isStreamStartedFromCurrentInstance = yield* select(selectIsStreamStartedFromCurrentInstance);
		const isWebRtcOutputStreamActive = yield* select(selectIsWebRtcOutputStreamActive);

		switch (prevSetTpValue) {
			case EnumSetTp.OFFLINE:
				if (ServiceFactory.webRtcApi.mediaStream) {
					yield* call(streamingGoOfflineSaga);
				}

				return;

			case EnumSetTp.ONLINE:
				if (!isWebRtcOutputStreamActive) {
					yield* put(mediaDeviceClientOnlyActions.releaseMediaDevicePermissions());
					yield* put(streamClientOnlyActions.setIsStreamStartedFromCurrentInstance(false));
				}

				return;

			case EnumSetTp.GOING_ONLINE:
				if (isStreamStartedFromCurrentInstance) {
					while (true) {
						yield* take([sessionServerToClientActions.sessionUpdated.type, streamClientToServerActions.stopSend.type]);

						const nextSetTpValue: EnumSetTp = yield* select(selectSetTp);

						if (nextSetTpValue === EnumSetTp.ONLINE) {
							yield* call(switchToLobbyModeIfIntendedSaga);
							yield* call(setStreamingDeviceStatusSaga);

							return;
						}
					}
				}
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'processWebRtcStreamStarted', action});
	}
};
