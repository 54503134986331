// @ts-ignore
import SoundMeterProcessor from 'worklet-loader?name=static/js/soundMeterProcessor.[contenthash:8].worklet.js!src/Services/WebRtc/SoundMeterProcessor';

class SoundMeter {
	private context: AudioContext;
	public instant = 0.0;
	private mic: MediaStreamAudioSourceNode | undefined;
	private worklet: AudioWorkletNode | undefined;

	constructor(context: AudioContext) {
		this.context = context;
	}

	async connectToSource(stream: MediaStream, callback: (error?: Error) => void) {
		try {
			if (!this.worklet) {
				await this.loadAudioWorkletModule();
			}

			this.connect(stream, callback);
		} catch (e) {
			// TODO: handle (!(e instanceof Error)) correctly
			callback(e as Error);
		}
	}

	stop() {
		if (this.mic) {
			this.mic.disconnect();
		}

		if (this.worklet) {
			this.worklet.disconnect();
		}
	}

	private async loadAudioWorkletModule(): Promise<void> {
		await fetch(SoundMeterProcessor)
			.then((response) => response.text())
			.then((text) => {
				const blob = new Blob([text], {type: 'application/javascript; charset=utf-8'});
				const objectUrl = URL.createObjectURL(blob);

				return this.context.audioWorklet.addModule(objectUrl).finally(() => URL.revokeObjectURL(objectUrl));
			});

		this.worklet = new AudioWorkletNode(this.context, 'sound-meter-processor');

		this.worklet.port.onmessage = (event) => {
			this.instant = event.data;
		};
	}

	private connect(stream: MediaStream, callback: (error?: Error) => void) {
		if (!this.worklet) {
			throw new Error('AudioWorklet not ready.');
		}

		this.mic = this.context.createMediaStreamSource(stream);
		this.mic.connect(this.worklet);
		this.worklet.connect(this.context.destination);
		callback();
	}
}

export default SoundMeter;
