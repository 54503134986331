import {put} from 'typed-redux-saga';
import {MSettingNameEnum} from 'cmd-control-client-lib';
import {isUndefined} from 'lodash';

import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {modelSettingsClientToServerActions} from '@messenger/core/src/Redux/ModelSettings/Actions/modelSettingsClientToServerActions';
import {modelSettingsClientOnlyActions} from '@messenger/core/src/Redux/ModelSettings/Actions/modelSettingsClientOnlyActions';

export const saveVideoChatSettingsSaga = function* ({
	payload: {messengerPrice, videoChatPrice, privateChatPrice},
}: ReturnType<typeof modelSettingsClientOnlyActions.saveVideoChatSettings>) {
	try {
		if (!isUndefined(messengerPrice)) {
			yield* put(
				modelSettingsClientToServerActions.setVideoChatPrice(MSettingNameEnum.MESSENGER_PRICE, messengerPrice),
			);
		}

		if (!isUndefined(videoChatPrice)) {
			yield* put(
				modelSettingsClientToServerActions.setVideoChatPrice(MSettingNameEnum.VIDEO_CHAT_PRICE, videoChatPrice),
			);
		}

		if (!isUndefined(privateChatPrice)) {
			yield* put(
				modelSettingsClientToServerActions.setVideoChatPrice(MSettingNameEnum.PRIVATE_CHAT_PRICE, privateChatPrice),
			);
		}
	} catch (error) {
		ServiceFactory.logService.error(error, {saga: 'saveVideoChatSettingsSaga'});
	}
};
