import {createSlice} from '@reduxjs/toolkit';
import _ from 'lodash';
import {EnumBooleanStringifiedExtended, EnumChannelFilterValues, EnumMediaType} from 'cmd-control-client-lib';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import ServiceFactory from '@messenger/core/src/Services/ServiceFactory';
import {channelsClientOnlyActions, channelsServerToClientActions} from '@messenger/core/src/Redux/Channels/Actions';
import {channelAdapter} from '@messenger/core/src/Redux/Channels/entityAdapter';
import {initialState} from '@messenger/core/src/Redux/Channels/initialState';
import userPossibilitiesFilters from '@messenger/core/src/BusinessLogic/UserPossibilitiesFilters';

const skipSummaryFields = ['_t2', '_ds'];
const defaultLimit = `${ServiceFactory.env.getNormalChannelsLimit()}`;
const defaultSkip = `${EnumBooleanStringifiedExtended.FALSY}`;

export const channelsSlice = createSlice({
	name: EnumStore.CHANNELS,
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(channelsClientOnlyActions.resetAll, () => initialState);
		builder.addCase(channelsClientOnlyActions.append, channelAdapter.addOne);
		builder.addCase(channelsClientOnlyActions.remove, channelAdapter.removeOne);
		builder.addCase(channelsClientOnlyActions.resetFilter, (state, action) => {
			const currentSummaryTotal = parseInt(_.get(state, 'summary.channels', initialState.filter.limit), 10);
			const currentSummaryArchived = parseInt(_.get(state, 'summary.archived', 0), 10);

			if (action.payload?.keepUserAbilitiesFilter) {
				state.filter = _.assign(state.filter, _.omit(initialState.filter, userPossibilitiesFilters));
			} else {
				state.filter = initialState.filter;
			}

			state.canLoadMore = _.size(state.entities) < currentSummaryTotal - currentSummaryArchived;
		});
		builder.addCase(channelsClientOnlyActions.setTeamChannelId, (state, action) => {
			state.teamChannelId = action.payload;
		});
		builder.addCase(channelsClientOnlyActions.decrementLoading, (state) => {
			const newCounter = state.loadingCounter - 1;

			state.loadingCounter = newCounter;
			state.isLoading = newCounter > 0;
		});
		builder.addCase(channelsClientOnlyActions.incrementLoading, (state) => {
			state.loadingCounter++;
			state.isLoading = true;
		});
		builder.addCase(channelsServerToClientActions.channelSummaryReceived, (state, action) => {
			state.summary = _.omit(action.payload, skipSummaryFields);
		});

		builder.addCase(channelsClientOnlyActions.setSelected, (state, action) => {
			state.selectedId = action.payload;
		});
		builder.addCase(channelsClientOnlyActions.resetSelected, (state) => {
			state.selectedId = undefined;
		});
		builder.addCase(channelsClientOnlyActions.setFilters, (state, action) => {
			state.filter = _.assign(state.filter, action.payload);
			state.filter.limit = defaultLimit;
			state.filter.skip = defaultSkip;
			state.canLoadMore = true;
		});
		builder.addCase(channelsClientOnlyActions.setFilterContactName, (state, action) => {
			state.filter.filterContactName = action.payload;
			state.filter.filterArchived = action.payload ? EnumChannelFilterValues.ANY : EnumChannelFilterValues.EXCLUDE;
			state.filter.limit = ServiceFactory.env.getSearchChannelsLimit().toString();
			state.filter.skip = defaultSkip;
			state.canLoadMore = true;
		});
		builder.addCase(channelsClientOnlyActions.setUserPossibilitiesFilter, (state, action) => {
			if (!action.payload.mediaType) {
				return;
			}

			switch (action.payload.mediaType) {
				case EnumMediaType.BITMAP:
					state.filter.filterCanReceiveImage = EnumChannelFilterValues.ONLY;
					break;
				case EnumMediaType.VIDEO:
					state.filter.filterCanReceiveVideo = EnumChannelFilterValues.ONLY;
					break;
				case EnumMediaType.AUDIO:
					state.filter.filterCanReceiveAudio = EnumChannelFilterValues.ONLY;
					break;
			}

			const shouldBeAbleToPurchase = !action.payload.isFree;

			state.filter.filterCanPurchaseMedia = shouldBeAbleToPurchase
				? EnumChannelFilterValues.ONLY
				: initialState.filter.filterCanPurchaseMedia;

			state.filter.limit = defaultLimit;
			state.filter.skip = defaultSkip;
			state.canLoadMore = true;
		});
		builder.addCase(channelsClientOnlyActions.setFilterSkip, (state, action) => {
			state.filter.skip = action.payload;
		});
		builder.addCase(channelsClientOnlyActions.setVisibleChannelsLimit, (state, action) => {
			state.visibleChannelsLimit = action.payload;
		});
		builder.addCase(channelsClientOnlyActions.updateChannel, channelAdapter.updateOne);
		builder.addCase(channelsClientOnlyActions.upsertMany, (state, action) => {
			const channelIds = _.keys(action.payload);
			let withAnotherChannelState = state;

			for (let i = 0; i < _.keys(action.payload).length; i++) {
				const channelId = channelIds[i];

				for (let j = 0; j < _.size(action.payload[channelId]); j++) {
					const channel = _.get(action.payload, [channelId, j]);

					if (channel) {
						withAnotherChannelState = channelAdapter.upsertOne(withAnotherChannelState, channel);
					}
				}
			}

			return withAnotherChannelState;
		});
		builder.addCase(channelsClientOnlyActions.addMany, channelAdapter.addMany);
		builder.addCase(
			channelsClientOnlyActions.setChannelsWithHistory,
			(state, {payload: {channelIds}}: ReturnType<typeof channelsClientOnlyActions.setChannelsWithHistory>) => {
				state.channelsWithHistory = _.union(state.channelsWithHistory, channelIds);
			},
		);
		builder.addCase(channelsClientOnlyActions.setTargets, (state, action) => {
			state.targetIds = action.payload.channelIds;
			state.targetGroupIds = action.payload.groupIds || [];
		});
		builder.addCase(channelsClientOnlyActions.addToTargets, (state, action) => {
			state.targetIds.push(...action.payload.channelIds);

			if (action.payload.groupIds) {
				state.targetGroupIds.push(...action.payload.groupIds);
			}
		});
		builder.addCase(channelsClientOnlyActions.removeFromTargets, (state, action) => {
			state.targetIds = _.without(state.targetIds, ...action.payload.channelIds);

			if (action.payload.groupIds) {
				state.targetGroupIds = _.without(state.targetGroupIds, ...action.payload.groupIds);
			}

			if (_.isEmpty(state.targetIds) && _.isEmpty(state.targetGroupIds) && !action.payload.permanentSelectMode) {
				state.isTargetsSelectEnabled = false;
			}
		});
		builder.addCase(channelsClientOnlyActions.filterTargets, (state, action) => {
			state.targetIds = _.difference(state.targetIds, action.payload.channelsToDelete);
		});
		builder.addCase(channelsClientOnlyActions.resetTargetsSelection, (state) => {
			state.targetIds = [];
			state.targetGroupIds = [];
		});
		builder.addCase(channelsClientOnlyActions.toggleTargetsSelectMode, (state) => {
			state.isTargetsSelectEnabled = !state.isTargetsSelectEnabled;
		});
		builder.addCase(channelsClientOnlyActions.resetTargetsSelectMode, (state) => {
			state.isTargetsSelectEnabled = false;
			state.targetIds = [];
			state.targetGroupIds = [];
		});
		builder.addCase(channelsClientOnlyActions.setCanLoadMore, (state, action) => {
			state.canLoadMore = action.payload;
		});
		builder.addCase(channelsClientOnlyActions.addTypingChannel, (state, action) => {
			state.typingChannels = _.union(state.typingChannels, [action.payload]);
		});
		builder.addCase(channelsClientOnlyActions.removeTypingChannel, (state, action) => {
			state.typingChannels = _.without(state.typingChannels, action.payload);
		});
		builder.addCase(channelsClientOnlyActions.toggleNoteExpanded, (state, action) => {
			if (!_.isUndefined(action.payload)) {
				if (state.notesCollapsed.includes(action.payload)) {
					state.notesCollapsed = _.without(state.notesCollapsed, action.payload);
				} else {
					state.notesCollapsed = _.union(state.notesCollapsed, [action.payload]);
				}
			}
		});
	},
});
