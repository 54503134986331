import _ from 'lodash';

import {TChannelsMap} from '@messenger/core/src/Redux/ChannelsMap/types';

export const modifyChannelsMap = (
	channelsMap: Record<string, TChannelsMap>,
	channelId: string,
	currentWeight?: string,
	newWeight?: string,
) => {
	const isMoveUp = !_.isUndefined(newWeight) && (_.isUndefined(currentWeight) || newWeight > currentWeight);
	let maxWeight: string;
	let minWeight: string | undefined = undefined;

	if (!_.isUndefined(currentWeight) && !_.isUndefined(newWeight)) {
		maxWeight = newWeight > currentWeight ? newWeight : currentWeight;
		minWeight = newWeight < currentWeight ? newWeight : currentWeight;
	} else if (_.isUndefined(currentWeight) && !_.isUndefined(newWeight)) {
		maxWeight = newWeight;
	} else if (!_.isUndefined(currentWeight) && _.isUndefined(newWeight)) {
		maxWeight = currentWeight;
	} else {
		return channelsMap;
	}

	let lastChannelMapItemId: number | undefined = undefined;
	let currentChannelsMapItemId: number | undefined = undefined;

	channelsMap = _.chain(channelsMap)
		.reduce((result: Record<number, TChannelsMap>, channelsMapItem) => {
			if (channelsMapItem.channelId === channelId) {
				currentChannelsMapItemId = channelsMapItem.id;
			} else {
				if ((_.isUndefined(minWeight) || channelsMapItem.weight >= minWeight) && channelsMapItem.weight <= maxWeight) {
					if (_.isUndefined(lastChannelMapItemId)) {
						lastChannelMapItemId = channelsMapItem.id;
					} else {
						lastChannelMapItemId = isMoveUp
							? Math.min(lastChannelMapItemId, channelsMapItem.id)
							: Math.max(lastChannelMapItemId, channelsMapItem.id);
					}

					const id = channelsMapItem.id + (isMoveUp ? 1 : -1);

					result[id] = {...channelsMapItem, id};
				} else {
					result[channelsMapItem.id] = channelsMapItem;
				}
			}

			return result;
		}, {})
		.value();

	if (!_.isUndefined(newWeight)) {
		lastChannelMapItemId = _.isUndefined(lastChannelMapItemId) ? currentChannelsMapItemId : lastChannelMapItemId;

		if (!_.isUndefined(lastChannelMapItemId)) {
			let newChannelMapItemId: number | undefined = undefined;

			if (lastChannelMapItemId === 0) {
				newChannelMapItemId = lastChannelMapItemId;
			} else {
				const nextChannelMapItem = _.find(channelsMap, {
					id: lastChannelMapItemId + (isMoveUp ? -1 : 1),
				});

				if (
					!_.isUndefined(nextChannelMapItem) &&
					((isMoveUp && nextChannelMapItem.weight > newWeight) || (!isMoveUp && nextChannelMapItem.weight < newWeight))
				) {
					newChannelMapItemId = lastChannelMapItemId;
				}
			}

			if (!_.isUndefined(newChannelMapItemId)) {
				channelsMap[newChannelMapItemId] = {
					id: newChannelMapItemId,
					channelId,
					weight: newWeight,
				};
			}
		}
	}

	return channelsMap;
};
