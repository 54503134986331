import {EnumBooleanStringified, EnumBooleanDigitized, EnumBooleanStringifiedExtended} from 'cmd-control-client-lib';
import _ from 'lodash';

export const stripTags = (inBound: string): string => {
	return inBound.replace(/(<([^>]+)>)/gi, '');
};

export const parseBoolean = (
	value: boolean | EnumBooleanStringified | EnumBooleanStringifiedExtended | EnumBooleanDigitized | string | undefined,
) => {
	if (_.isBoolean(value)) {
		return value;
	}

	return _.includes([EnumBooleanDigitized.TRUE, EnumBooleanStringified.TRUE], value);
};
