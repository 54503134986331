import {put, select, call} from 'typed-redux-saga';

import {clientClientOnlyActions} from '@messenger/core/src/Redux/Client/Actions/clientClientOnlyActions';
import {EnumGuestType} from '@messenger/core/src/Types/EnumGuestType';
import selectGuestTypeFilter from '@messenger/core/src/Redux/Client/Selectors/GuestTypeFilter/selectGuestTypeFilter';

import {resetChannelsMapSaga} from 'src/Redux/ChannelsMap/Sagas/resetChannelsMapSaga';

export const filterChannelsOnlySaga = function* () {
	const guestTypeFilter = yield* select(selectGuestTypeFilter);

	yield* put(clientClientOnlyActions.setGuestTypeFilter(EnumGuestType.CHANNEL));

	if (guestTypeFilter === EnumGuestType.CHAT) {
		yield* call(resetChannelsMapSaga);
	}
};
