import {createSlice, EntityState} from '@reduxjs/toolkit';
import _ from 'lodash';

import EnumStore from '@messenger/core/src/BusinessLogic/EnumStore';
import {
	channelGroupsEntityAdapter,
	TChannelGroup,
} from '@messenger/core/src/Redux/ChannelGroups/channelGroupsEntityAdapter';
import {channelGroupsClientToServerActions} from '@messenger/core/src/Redux/ChannelGroups/Actions/channelGroupsClientToServerActions';
import {channelGroupsServerToClientActions} from '@messenger/core/src/Redux/ChannelGroups/Actions/channelGroupsServerToClientActions';
import {channelGroupsClientOnlyActions} from '@messenger/core/src/Redux/ChannelGroups/Actions/channelGroupsClientOnlyActions';

export const initialState: TChannelGroupsState = channelGroupsEntityAdapter.getInitialState({
	isLoading: false,
	updatingGroups: [],
	channelIds: [],
});

const channelGroupsSlice = createSlice({
	name: EnumStore.CHANNEL_GROUPS,
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(channelGroupsClientOnlyActions.updateGroup, channelGroupsEntityAdapter.upsertOne);
		builder.addCase(channelGroupsClientOnlyActions.upsertMany, channelGroupsEntityAdapter.setMany);
		builder.addCase(channelGroupsClientToServerActions.getChannelGroups, (state, {payload: {channelId}}) => {
			if (channelId) {
				state.channelIds = _.uniq([...state.channelIds, ..._.split(channelId, ',')]);
			}

			state.isLoading = true;
		});
		builder.addCase(channelGroupsServerToClientActions.channelGroupsReceivedMany, (state) => {
			state.isLoading = false;
		});
		builder.addCase(channelGroupsClientOnlyActions.resetStore, () => initialState);
		builder.addCase(channelGroupsClientToServerActions.addChannelsToGroup, (state, {payload: {groupId}}) => {
			if (groupId) {
				state.updatingGroups.push(groupId);
			}
		});
		builder.addCase(channelGroupsClientToServerActions.removeChannelsFromGroup, (state, {payload: {groupId}}) => {
			if (groupId) {
				state.updatingGroups.push(groupId);
			}
		});
		builder.addCase(channelGroupsServerToClientActions.addedChannelsToGroup, (state, {meta}) => {
			const groupId = meta.params.groupId;

			if (_.includes(state.updatingGroups, groupId)) {
				_.pull(state.updatingGroups, groupId);
			}
		});
		builder.addCase(channelGroupsServerToClientActions.removedChannelsFromGroup, (state, {meta}) => {
			const groupId = meta.params.groupId;

			if (_.includes(state.updatingGroups, groupId)) {
				_.pull(state.updatingGroups, groupId);
			}
		});
	},
});

export type TChannelGroupsState = EntityState<TChannelGroup> & {
	isLoading: boolean;
	updatingGroups: string[];
	channelIds: string[];
};

export default channelGroupsSlice;
